import { useEffect, useState } from "react";
import {
  Box,
  SimpleGrid,
  GridItem,
  Input,
  FormLabel,
  Button,
  useToast,
  Flex,
  IconButton,
  chakra,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import validator from "validator";
import { FcList } from "react-icons/fc";
import { Editor } from "@tinymce/tinymce-react";
import ReactSelect from "react-select";
import { Link } from "react-router-dom";
import parser from "html-react-parser";

import PageLayout from "../../components/ui/PageLayout";
import {
  CreatableType,
  CreateProductCustomMutationVariables,
  LanguageOptions,
  ProductQuery,
  UpdateOneProductMutation,
  UpdateOneProductMutationVariables,
  UpdateProductMutationVariables,
  useCalculatePriceMutation,
  useCategoriesQuery,
  useCreateOneStaffLogMutation,
  useUpdateDescriptionMutation,
  useUpdateOneSeoMutation,
} from "../../generated/graphql";
import { createSlug } from "../../utils/slug";
import UploadDropzone from "../../components/dropzone/UploadDropzone";
import SkeletonGroup from "../../components/ui/SkeletonGroup";
import getLocalizedDescription from "../../utils/getLocalizedDescriptions";
import { DescriptionProperty, SeoProperty } from "../../types/descriptionProperty";
import { FaCalculator } from "react-icons/fa";
import getLocalizedSeo from "../../utils/getLocalizedSeos";
import CloudinaryImage from "../../components/CloudinaryImage";
import SeoForms from "../../components/seo/SeoForm";
import { useStaffStore } from "../../store/staff";
import { updatedDiff } from "deep-object-diff";

interface Props {
  handleUpdate?: (data: UpdateOneProductMutationVariables) => Promise<any>;
  id?: string;
  data?: ProductQuery["product"];
  actionType: "CREATE" | "UPDATE";
  loading: boolean;
}

const Form: React.FC<Props> = ({ handleUpdate, id, data, actionType, loading }) => {
  
  const [seos, setSeo] = useState<SeoProperty[]>(getLocalizedSeo({ seos: data?.seos }));
  const [updateSeoMutation, { loading: updateSeoLoading }] = useUpdateOneSeoMutation();
  const [language, setLanguage] = useState<LanguageOptions>(LanguageOptions.DE);

  
  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog] = useCreateOneStaffLogMutation();


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (actionType === "UPDATE") {
      if (data?.seos && data?.seos.length > 0) {
        for await (const iterator of seos) {
          console.log("🌵💜🐢", "AA", data?.seos.length);

          await updateSeoMutation({
            variables: {
              where: { id: iterator.seoId },
              data: {
                title: { set: iterator.title ?? "" },
                description: { set: iterator.description ?? "" },
                keywords: { set: iterator.keywords ?? "" },
              },
            },
          });
        }
      }
      await handleUpdate!({
        where: {
          id: id,
        },
        data: {
          ...(data?.seos.length === 0 && {
            seos: {
              create: seos.map((seo) => ({
                title: seo.title,
                description: seo.description,
                keywords: seo.keywords,
                language: seo.language as LanguageOptions,
              })),
            },
          }),
        },
      });

      await Promise.allSettled([
        createLog({
          variables: {
            data: {
              json: {
                action: "UPDATE",
                message: `Updated Seo for Product ${
                  data?.descriptions.find((a) => a.language === "EN")?.title
                } by ${staff?.name}`,
                diffs: {
                  old: data?.seos,
                  diff: updatedDiff(data?.seos as any, seos),
                },
              },
              tableName: "Product",
              staff: {
                connect: {
                  id: staff?.id,
                },
              },
            },
          },
        }),
      ]);
    }
  };

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          {actionType === "CREATE" ? "Create" : "Update"} Product {data?.descriptions.find((d) => d.language === language)?.title}
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton to="/product" as={Link} icon={<FcList />} aria-label="All Items" />
        </Flex>
      </Flex>

      <chakra.form onSubmit={handleSubmit}>
        {data?.photos && data?.photos.length > 0 && (
          <SimpleGrid columns={2}>
            {data?.photos.map((photo, i) => (
              <GridItem w={"auto"} colSpan={1} key={i}>
                <CloudinaryImage publicId={photo} width={160} />
              </GridItem>
            ))}
          </SimpleGrid>
        )}
        <SimpleGrid columns={4} gap={10}>
          <GridItem w={"full"} colSpan={2}>
            <SeoForms setLang={setLanguage} seos={seos} setSeo={setSeo} />
            <Button type="submit" colorScheme="green">Save</Button>
          </GridItem>
          <GridItem colSpan={2}>
            {data?.descriptions && data?.descriptions.length > 0 && (
              <Box my={10}>
                <Box>
                  <Text fontSize={24}>
                    <strong>Title</strong> ({language})
                  </Text>
                  <Text fontSize={18}>
                    {data.descriptions.find((d) => d.language === language)?.title}
                  </Text>
                </Box>
                <Box my={5}>
                  <Text fontSize={24}>
                    <strong>Description</strong>
                  </Text>
                  <Text>
                    {parser(
                      data.descriptions.find((d) => d.language === language)
                        ?.description || ""
                    )}
                  </Text>
                </Box>
              </Box>
            )}
          </GridItem>
        </SimpleGrid>
      </chakra.form>
      
    </PageLayout>
  );
};

export default Form;
