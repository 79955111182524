import { useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Flex,
  useToast,
  Checkbox,
  Button,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import {
  SortOrder,
  useProductsQuery,
  useRefreshProductPricesMutation,
} from "../../generated/graphql";

import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import Description from "../../components/description/Description";

const ProductUpdater = () => {
  const toast = useToast();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const [refreshMutation, { loading: refreshing }] = useRefreshProductPricesMutation();

  const { loading, error, data, refetch } = useProductsQuery({
    // fetchPolicy: "network-only",
    variables: {
      orderBy: {
        createdAt: SortOrder.desc,
      },
      where: {
        categoryId: {
          not: {
            equals: "b9315d0e-90a3-4964-9d5d-d7cbd04109e9",
          },
        },
      },
    },
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectAll(true);
      setSelectedIds(data?.products.items.map((c) => c.id) || []);
    } else {
      setSelectAll(false);
      setSelectedIds([]);
    }
  };

  const handleRefreshSelected = async () => {
    try {
      await refreshMutation({
        variables: {
          productIds: selectedIds,
        },
      });
      toast({
        title: "Ürün fiyatları güncellendi",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setSelectedIds([]);
      return refetch();
    } catch (error) {
      return toast({
        title: "Ürün fiyatları güncellenemedi",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          Product List
        </Box>
      </Flex>
      <Button
        mb={6}
        colorScheme="teal"
        onClick={handleRefreshSelected}
        isDisabled={isEmpty(selectedIds) || refreshing}
        isLoading={refreshing}
      >
        Update Selections
      </Button>
      <Table>
        <Thead>
          <Tr>
            <Th>
              <Checkbox isChecked={selectAll} onChange={handleSelectAll} />
            </Th>
            <Th>Title</Th>
            <Th w={"100px"} textAlign={"center"}>
              SKU
            </Th>
            <Th>Barcode</Th>
            <Th>Category</Th>
            <Th>Price</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.products.items.map((c, i) => {
            return (
              <Tr key={i}>
                <Td>
                  <Checkbox
                    isChecked={!!selectedIds.find((id) => id === c.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedIds((prev) => [...prev, c.id]);
                      } else {
                        setSelectedIds((prev) => prev.filter((data) => data !== c.id));
                      }
                    }}
                  />
                </Td>
                <Td>
                  <Description
                    field="product"
                    id={c.id}
                    descriptions={c.descriptions}
                    refetch={refetch}
                  />
                </Td>
                <Td w={"100px"} textAlign={"center"}>
                  {c?.sku}
                </Td>
                <Td>{c?.barcode}</Td>
                <Td>{c.category.descriptions[0].title}</Td>

                <Td>{c.price}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </PageLayout>
  );
};

export default ProductUpdater;
