import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

interface Props {
  boxProps?: BoxProps;
}
const PageLayout: React.FC<Props> = ({ children, boxProps }) => {
  return (
    <Box bgColor={"white"} p={6} rounded="md" shadow={"md"} {...boxProps}>
      {children}
    </Box>
  );
};

export default PageLayout;
