import React, { useState } from "react";
import {
  GridItem,
  Input,
  FormLabel,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Divider,
  Box,
  Heading,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { LanguageOptions } from "../../generated/graphql";
import { SeoProperty } from "../../types/descriptionProperty";
import { Editor } from "@tinymce/tinymce-react";

const editorSettings = {
  skin: "snow",
  icons: "thin",
  placeholder: "Description...",
  height: 300,
  menubar: false,
  min_height: 200,
  max_height: 500,

  plugins: [
    "advlist autolink lists link image charmap print preview anchor",
    "searchreplace visualblocks code fullscreen",
    "insertdatetime media table paste code wordcount",
  ],
  toolbar:
    "undo redo | formatselect | " +
    "bold italic backcolor forecolor | bullist numlist outdent indent | " +
    "removeformat | code",
};

const SeoForms = ({
  seos,
  setSeo,
  setLang,
}: {
  seos: SeoProperty[];
  setSeo: React.Dispatch<React.SetStateAction<SeoProperty[]>>;
  setLang: React.Dispatch<React.SetStateAction<LanguageOptions>>;
}) => {
  return (
    <Box w={"full"}>
      <Tabs>
        <TabList>
          {Object.values(LanguageOptions).map((lang) => (
            <Tab onClick={() => setLang(lang as LanguageOptions)}>{lang}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {Object.values(LanguageOptions).map((lang) => (
            <TabPanel>
              <Text fontSize={24}>
                <strong>Seo Info</strong> ({lang})
              </Text>
              <Box>
                <FormLabel>Title</FormLabel>
                <Input
                  value={seos.find((d) => d.language === lang)?.title || ""}
                  onChange={(e) => {
                    const newDescriptions = [...seos];
                    newDescriptions.find((d) => d.language === lang)!.title =
                      e.target.value;

                    setSeo(newDescriptions);
                  }}
                  placeholder="Title"
                />
              </Box>
              <GridItem my={4} colSpan={5}>
                <FormLabel>Description</FormLabel>
                <Textarea
                  value={
                    seos.find((description) => description.language === lang)
                      ?.description || ""
                  }
                  onChange={(e) => {
                    const newDescriptions = [...seos];
                    newDescriptions.find((d) => d.language === lang)!.description =
                      e.target.value;
                    setSeo(newDescriptions);
                  }}
                />
              </GridItem>
              <GridItem my={4} colSpan={5}>
                <FormLabel>Keywords</FormLabel>
                <Input
                  value={seos.find((d) => d.language === lang)?.keywords || ""}
                  onChange={(e) => {
                    const newDescriptions = [...seos];
                    newDescriptions.find((d) => d.language === lang)!.keywords =
                      e.target.value;

                    setSeo(newDescriptions);
                  }}
                  placeholder="keywords"
                />
              </GridItem>
              <GridItem my={4} colSpan={5}>
                <Divider />
              </GridItem>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default SeoForms;

/*

    {Object.values(LanguageOptions).map((lang) => (
        <Box>
          <Text fontSize={24}>
            <strong>Seo Info</strong> ({lang})
          </Text>
          <Box>
            <FormLabel>Title</FormLabel>
            <Input
              value={seos.find((d) => d.language === lang)?.title || ""}
              onChange={(e) => {
                const newDescriptions = [...seos];
                newDescriptions.find((d) => d.language === lang)!.title = e.target.value;

                setSeo(newDescriptions);
              }}
              placeholder="Title"
            />
          </Box>
          <GridItem my={4} colSpan={5}>
            <FormLabel>Description</FormLabel>
            <Textarea
              value={
                seos.find((description) => description.language === lang)?.description ||
                ""
              }
              onChange={(e) => {
                const newDescriptions = [...seos];
                newDescriptions.find((d) => d.language === lang)!.description =
                  e.target.value;
                setSeo(newDescriptions);
              }}
            />
          </GridItem>
          <GridItem my={4} colSpan={5}>
            <FormLabel>Keywords</FormLabel>
            <Input
              value={seos.find((d) => d.language === lang)?.keywords || ""}
              onChange={(e) => {
                const newDescriptions = [...seos];
                newDescriptions.find((d) => d.language === lang)!.keywords =
                  e.target.value;

                setSeo(newDescriptions);
              }}
              placeholder="keywords"
            />
          </GridItem>
          <GridItem my={4} colSpan={5}>
            <Divider />
          </GridItem>
          <Divider my={8} />
        </Box>
      ))}

*/
