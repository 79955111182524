import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider as Provider,
  split,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";

let httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
}) as any;

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "Apollo-Require-Preflight": "true",
    },
  };
});

httpLink = authLink.concat(httpLink);

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_WS_URL,
    connectionParams: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" && definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

export const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
    mutate: {
      fetchPolicy: "network-only",
    },
  },
});

export default function ApolloProvider(props: any) {
  return <Provider client={client} {...props} />;
}
