import { Description, LanguageOptions } from "../generated/graphql";

export const descriptionToText = (descriptions: Description[], locale: string) => {
  let description = descriptions.find((d) => d.language === locale.toUpperCase());
  if (!description) {
    description = descriptions.find((d) => d.language === LanguageOptions.EN);
  }

  return description;
};
