import {
  Badge,
  Box,
  Button,
  chakra,
  Divider,
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { BsCartFill, BsCurrencyDollar } from "react-icons/bs";

import SkeletonGroup from "../components/ui/SkeletonGroup";
import { StaffRoleType, useDashboardStatisticsQuery } from "../generated/graphql";
import { useStaffStore } from "../store/staff";

function Dashboard() {
  const { staff } = useStaffStore();

  return (
    <>
      <Box p={4} bgColor="white" shadow="md" rounded="base" mb={6}>
        <Heading mb={1} size="lg">
          Juwelberg SEO & Marketing Dashboard
        </Heading>
      </Box>
      {/* {!sliderData ? (
        <Box p={6} bgColor="white" my={4}>
          <SkeletonGroup amount={6} />
        </Box>
      ) : (
        <SliderForm data={sliderData.slider} />
      )} */}
      {/* {staff?.role !== StaffRoleType.PRODUCT && (
        <Box p={4} bgColor="white" shadow="md" rounded="base">
          <Heading mb={1} size="lg">
            Statistics 📊
          </Heading>
          <Divider />

          {!data ? (
            <Box p={6} bgColor="white" my={4}>
              <SkeletonGroup amount={6} />
            </Box>
          ) : (
            <>
              <SimpleGrid
                columns={{
                  base: 1,
                  md: 4,
                }}
                gap={4}
                mt={4}
              >
                <Box p={5} bg="gray.100" rounded="md" display="flex" flexDir="column">
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                      <Icon as={BsCartFill} mr={2} color="blue.400" />{" "}
                      <chakra.span fontWeight="semibold">Orders</chakra.span>
                    </Box>
                    <Badge>Today</Badge>
                  </Box>
                  <Heading size="lg" mt={2}>
                    {data.dashboardStatistics.todayOrderCount}
                  </Heading>
                </Box>

                <Box p={5} bg="gray.100" rounded="md" display="flex" flexDir="column">
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                      <Icon as={BsCurrencyDollar} mr={2} color="blue.400" />{" "}
                      <chakra.span fontWeight="semibold">Earnings</chakra.span>
                    </Box>
                    <Badge>Today</Badge>
                  </Box>
                  <Heading size="lg" mt={2}>
                    {data.dashboardStatistics.todayEarnings?.toFixed(2)}
                  </Heading>
                </Box>

                <Box p={5} bg="gray.100" rounded="md" display="flex" flexDir="column">
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                      <Icon as={BsCartFill} mr={2} color="blue.400" />{" "}
                      <chakra.span fontWeight="semibold">Orders</chakra.span>
                    </Box>
                    <Badge colorScheme="pink">7 day</Badge>
                  </Box>
                  <Heading size="lg" mt={2}>
                    {data.dashboardStatistics.weeklyOrderCount}
                  </Heading>
                </Box>

                <Box p={5} bg="gray.100" rounded="md" display="flex" flexDir="column">
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                      <Icon as={BsCurrencyDollar} mr={2} color="blue.400" />{" "}
                      <chakra.span fontWeight="semibold">Earnings</chakra.span>
                    </Box>
                    <Badge colorScheme="pink">7 day</Badge>
                  </Box>
                  <Heading size="lg" mt={2}>
                    {data.dashboardStatistics.weeklyEarnings?.toFixed(2)}
                  </Heading>
                </Box>
              </SimpleGrid>

              <Box mt={6}>
                <Heading my={4} size="lg">
                  Pending orders
                </Heading>
                <Flex overflow="auto">
                  <Table variant="custom">
                    <Thead>
                      <Tr>
                        <Th>#</Th>
                        <Th>Date</Th>
                        <Th>Customer</Th>
                        <Th>Product Count</Th>
                        <Th>Total Amount</Th>
                        <Th>Fee Paid</Th>
                        <Th>Status</Th>
                        <Th isNumeric></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data?.dashboardStatistics.pendingOrders?.map((order, i) => (
                        <Tr key={order.id}>
                          <Td w="4%">{i + 1}</Td>
                          <Td w="10%">
                            {dayjs(order.createdAt).format("DD/MM/YYYY HH:mm")}
                          </Td>
                          <Td>{`${order.customer.name} ${order.customer.surname}`}</Td>
                          <Td>{order._count?.products}</Td>
                          <Td>{order.payment?.price}</Td>
                          <Td>{order.payment?.paidPrice}</Td>
                          <Td>
                            <Badge colorScheme="blue">{order.orderStatus || ""}</Badge>
                          </Td>
                          <Td isNumeric>
                            <Button size="sm" as={Link} to={`/orders/detail/${order.id}`}>
                              Detail
                            </Button>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Flex>
              </Box>
            </>
          )}
        </Box>
      )} */}
    </>
  );
}

export default Dashboard;
