import { BrowserRouter, Routes, Route } from "react-router-dom";

import RequireAuth from "./RequireAuth";
import NotRequireAuth from "./NotRequireAuth";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Page from "../pages/Page/Page";
import Description from "../pages/Description/Form";
import Category from "../pages/Category/Category";
import SubCategory from "../pages/SubCategory/SubCategory";
import Product from "../pages/Product/Product";

import ProductUpdater from "../pages/Product/ProductUpdater";
import BlogPages from "../pages/Blog/Blog";

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<NotRequireAuth />}>
          <Route index element={<Login />} />
        </Route>
        <Route element={<RequireAuth />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="/category/*" element={<Category />} />
          <Route path="/subcategory/*" element={<SubCategory />} />
          <Route path="/product/*" element={<Product />} />
          <Route path="/page/*" element={<Page />} />
          <Route
            path="/description/:action/:language/:field/:fieldId/*"
            element={<Description />}
          />
          <Route path="/productUpdater" element={<ProductUpdater />} />
          <Route path="/blog/*" element={<BlogPages />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
