import { ProductsQuery } from "../generated/graphql";

interface Params {
  seos?: ProductsQuery["products"]["items"][0]["seos"];
}

const getLocalizedSeo = (params: Params) => {
  /* const localizedDescriptions = params.descriptions?.map((desc) => {
    const { title, description, id: descriptionId, language } = desc;
    return { title, description, descriptionId, language };
  });

  return localizedDescriptions; */

  const supportedLanguages = ["EN", "DE", "FR", "IT", "ES", "TR"];

  const localizedDescriptions = supportedLanguages.map((language) => {
    const localizedDescription = params.seos?.find((desc) => desc.language === language);

    if (localizedDescription) {
      const { title, description, id: seoId, keywords } = localizedDescription;
      return { title, description, seoId, language, keywords };
    }

    return { title: "", description: "", seoId: "", language, keywords: "" };
  });

  return localizedDescriptions;
};

export default getLocalizedSeo;
