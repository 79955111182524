import {
  CircularProgress,
  Flex,
  FlexProps,
  CircularProgressProps,
} from "@chakra-ui/react";

type Props = {
  flexProps?: FlexProps;
  progressProps?: CircularProgressProps;
};

const CircularLoading: React.VFC<Props> = (props) => {
  return (
    <Flex alignItems="center" justifyContent="center" {...props.flexProps}>
      <CircularProgress isIndeterminate {...props.progressProps} />
    </Flex>
  );
};

export default CircularLoading;
