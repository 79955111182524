import { useState } from "react";
import { createSlug } from "../../utils/slug";
import { Editor } from "@tinymce/tinymce-react";
import {
  Box,
  SimpleGrid,
  GridItem,
  Input,
  FormLabel,
  Button,
  useToast,
  Flex,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { FcList } from "react-icons/fc";
import validator from "validator";

import PageLayout from "../../components/ui/PageLayout";
import {
  CreatePageMutationVariables,
  LanguageOptions,
  PageQuery,
  UpdatePageMutationVariables,
  useCreateOneStaffLogMutation,
  useUpdateDescriptionMutation,
  useUpdateOneSeoMutation,
} from "../../generated/graphql";
import { DescriptionProperty, SeoProperty } from "../../types/descriptionProperty";
import getLocalizedDescription from "../../utils/getLocalizedDescriptions";
import getLocalizedSeo from "../../utils/getLocalizedSeos";
import CloudinaryImage from "../../components/CloudinaryImage";
import HTMLReactParser from "html-react-parser";
import SeoForms from "../../components/seo/SeoForm";
import { useStaffStore } from "../../store/staff";
import { updatedDiff } from "deep-object-diff";

interface Props {
  handleCreatePage?: (data: CreatePageMutationVariables) => Promise<any>;
  handleUpdatePage?: (data: UpdatePageMutationVariables) => Promise<any>;
  id?: string;
  data?: PageQuery["page"];
  actionType: "CREATE" | "UPDATE";
}

const Form: React.FC<Props> = ({
  handleCreatePage,
  handleUpdatePage,
  id,
  data,
  actionType,
}) => {
  const toast = useToast();

  const [seos, setSeo] = useState<SeoProperty[]>(getLocalizedSeo({ seos: data?.seos }));
  const [updateSeoMutation, { loading: updateSeoLoading }] = useUpdateOneSeoMutation();
  const [language, setLanguage] = useState<LanguageOptions>(LanguageOptions.DE);

  
  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog] = useCreateOneStaffLogMutation();


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      if (actionType === "UPDATE") {
        if (data?.seos && data?.seos.length > 0) {
          for await (const iterator of seos) {
            console.log("🌵💜🐢", "AA", data?.seos.length);

            await updateSeoMutation({
              variables: {
                where: { id: iterator.seoId },
                data: {
                  title: { set: iterator.title ?? "" },
                  description: { set: iterator.description ?? "" },
                  keywords: { set: iterator.keywords ?? "" },
                },
              },
            });
          }
        }
        await handleUpdatePage!({
          where: {
            id: id,
          },
          data: {
            ...(data?.seos.length === 0 && {
              seos: {
                create: seos.map((seo) => ({
                  title: seo.title,
                  description: seo.description,
                  keywords: seo.keywords,
                  language: seo.language as LanguageOptions,
                })),
              },
            }),
          },
        });

        await Promise.allSettled([
          createLog({
            variables: {
              data: {
                json: {
                  action: "UPDATE",
                  message: `Updated Seo for Page ${
                    data?.descriptions.find((a) => a.language === "EN")?.title
                  } by ${staff?.name}`,
                  diffs: {
                    old: data?.seos,
                    diff: updatedDiff(data?.seos as any, seos),
                  },
                },
                tableName: "Page",
                staff: {
                  connect: {
                    id: staff?.id,
                  },
                },
              },
            },
          }),
        ]);
      }
    } catch (error) {}
  };

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          {actionType === "CREATE" ? "Create" : "Update"} Page
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton to={`/page`} as={Link} icon={<FcList />} aria-label="All Items" />
        </Flex>
      </Flex>
      <form onSubmit={handleSubmit}>
        <Box
          width={{
            base: "100%",
          }}
        >
          <SimpleGrid
            columns={4}
            gap={{
              base: 6,
              md: 6,
            }}
          >
            <GridItem w={"full"} colSpan={2}>
              <SeoForms setLang={setLanguage} seos={seos} setSeo={setSeo} />
            </GridItem>
            <GridItem colSpan={2}>
              {data?.descriptions && data?.descriptions.length > 0 && (
                <Box my={10}>
                  <Box>
                    <Text fontSize={24}>
                      <strong>Title</strong> ({language})
                    </Text>
                    <Text fontSize={18}>
                      {data.descriptions.find((d) => d.language === language)?.title}
                    </Text>
                  </Box>
                  <Box my={5}>
                    <Text fontSize={24}>
                      <strong>Description</strong>
                    </Text>
                    <Text>
                      {HTMLReactParser(
                        data.descriptions.find((d) => d.language === language)
                          ?.description || ""
                      )}
                    </Text>
                  </Box>
                </Box>
              )}
            </GridItem>
          </SimpleGrid>
          <Button
            colorScheme={"green"}
            type="submit"
            mt={6}
            isDisabled={updateSeoLoading}
            isLoading={updateSeoLoading}
          >
            Save
          </Button>
        </Box>
      </form>
    </PageLayout>
  );
};

export default Form;
