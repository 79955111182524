import { Flex, Stack, Button, Heading } from "@chakra-ui/react";
import { BsBoxSeam, BsSpeedometer } from "react-icons/bs";
import { FiUsers, FiPercent, FiShoppingCart, FiFlag } from "react-icons/fi";
import { VscSymbolClass } from "react-icons/vsc";
import { GiPearlNecklace } from "react-icons/gi";
import { TbReceiptTax } from "react-icons/tb";
import { FaUserCog, FaParachuteBox, FaWrench, FaBloggerB } from "react-icons/fa";
import { RiFilePaper2Line, RiSlideshow3Line } from "react-icons/ri";
import { BiCategory } from "react-icons/bi";

import { useStaffStore } from "../../../store/staff";
import SidebarMenuItem from "./SidebarMenuItem";
import { StaffRoleType, useCreateOneStaffLogMutation } from "../../../generated/graphql";

function Sidebar() {
  const { logoutStaff, staff } = useStaffStore();
  const [createLog] = useCreateOneStaffLogMutation();

  return (
    <Flex
      flexDirection="column"
      bgColor="gray.800"
      width={{
        base: "full",
        md: "15%",
      }}
      py={4}
      px={2}
    >
      <Stack direction="column" spacing={2} color="white" alignContent="start">
        <Heading px={2} fontSize="lg" mb={6}>
          Juwelberg
        </Heading>
        <SidebarMenuItem icon={BsSpeedometer} title="Menu" url="/" />

        {(staff?.role === StaffRoleType.ADMIN ||
          staff?.role === StaffRoleType.SEO ||
          staff?.role === StaffRoleType.SUPERADMIN) && (
          <SidebarMenuItem icon={VscSymbolClass} title="Categories" url="/category" />
        )}
        {(staff?.role === StaffRoleType.SEO ||
          staff?.role === StaffRoleType.ADMIN ||
          staff?.role === StaffRoleType.SUPERADMIN) && (
          <SidebarMenuItem
            icon={VscSymbolClass}
            title="Sub Categories"
            url="/subcategory"
          />
        )}

        {(staff?.role === StaffRoleType.ADMIN ||
          staff?.role === StaffRoleType.SUPERADMIN ||
          staff?.role === StaffRoleType.PRODUCT ||
          staff?.role === StaffRoleType.SEO) && (
          <SidebarMenuItem icon={BsBoxSeam} title="Products" url="/product" />
        )}

        {(staff?.role === StaffRoleType.ADMIN ||
          staff?.role === StaffRoleType.SEO ||
          staff?.role === StaffRoleType.SUPERADMIN) && (
          <SidebarMenuItem icon={FaBloggerB} title="Blog" url="/blog" />
        )}

        {(staff?.role === StaffRoleType.ADMIN ||
          staff?.role === StaffRoleType.SEO ||
          staff?.role === StaffRoleType.SUPERADMIN) && (
          <SidebarMenuItem icon={RiFilePaper2Line} title="Pages" url="/page" />
        )}
      </Stack>

      <Button
        mt="auto"
        colorScheme="red"
        onClick={async () => {
          await Promise.allSettled([
            createLog({
              variables: {
                data: {
                  json: {
                    action: "LOGOUT",
                    message: `Logged out by ${staff?.name}`,
                  },
                  tableName: "Staff",
                  staff: {
                    connect: {
                      id: staff?.id,
                    },
                  },
                },
              },
            }),
          ]);
          logoutStaff();
          window.location.href = "/";
        }}
      >
        Log out
      </Button>
    </Flex>
  );
}

export default Sidebar;
