import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import { StaffLoginMutation, StaffRoleType } from "../generated/graphql";

export type DecodedUser = {
  id: string;
  iat?: number;
  exp?: number;
  name?: string;
  email?: string;
  token?: string;
  role?: StaffRoleType;
};

type IStaffStore = {
  staff: DecodedUser | null;
  loginStaff: (payload: StaffLoginMutation["staffLogin"]) => void;
  logoutStaff: () => void;
  setStaff: (payload: StaffLoginMutation["staffLogin"] | null) => void;
};

export const useStaffStore = create<IStaffStore>(
  persist(
    devtools(
      (set) => ({
        staff: null,
        loginStaff(payload) {
          set((state) => {
            localStorage.setItem("token", payload.token);
            return {
              ...state,
              staff: payload,
            };
          });
        },
        logoutStaff() {
          set((state) => {
            localStorage.removeItem("token");
            return {
              ...state,
              staff: null,
            };
          });
        },
        setStaff(payload) {
          set((state) => ({
            ...state,
            staff: payload,
          }));
        },
      }),
      { name: "staff" }
    ),
    { name: "@juwelberg/staff" }
  )
);
