import { ProductsQuery } from "../generated/graphql";

interface Params {
  descriptions?: ProductsQuery["products"]["items"][0]["descriptions"];
}

const getLocalizedDescription = (params: Params) => {
  /* const localizedDescriptions = params.descriptions?.map((desc) => {
    const { title, description, id: descriptionId, language } = desc;
    return { title, description, descriptionId, language };
  });

  return localizedDescriptions; */

  const supportedLanguages = ["EN", "DE", "FR", "IT", "ES", "TR"];

  const localizedDescriptions = supportedLanguages.map((language) => {
    const localizedDescription = params.descriptions?.find(
      (desc) => desc.language === language
    );

    if (localizedDescription) {
      const { title, description, id: descriptionId } = localizedDescription;
      return { title, description, descriptionId, language };
    }

    return { title: "", description: "", descriptionId: "", language };
  });

  return localizedDescriptions;
};

export default getLocalizedDescription;
