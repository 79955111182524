import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { LanguageOptions, PagesQuery } from "../../generated/graphql";
import { Tbody, Td, Tr } from "@chakra-ui/react";

const SortableItem = SortableElement(
  ({ value, productOrder }: { value: PagesQuery["pages"][0]; productOrder: number }) => (
    <Tr>
      <Td textAlign="center">{productOrder + 1}</Td>
      <Td textAlign="center">
        {value.descriptions.find((d) => d.language === LanguageOptions.EN)?.title}
      </Td>
    </Tr>
  )
);

const SortableList = SortableContainer(({ items }: { items: PagesQuery["pages"] }) => {
  return (
    <Tbody>
      {items.map((value, index) => {
        return (
          <SortableItem
            key={`item-${index}`}
            index={index}
            productOrder={index}
            value={value}
          />
        );
      })}
    </Tbody>
  );
});

class OrderList extends React.Component<
  {
    items: PagesQuery["pages"];
    setOrder: (data: any) => void;
  },
  { setOrder: any; items: PagesQuery["pages"] }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      items: this.props.items,
      setOrder: this.props.setOrder,
    };
  }

  onSortEnd = ({ oldIndex, newIndex }: { oldIndex: any; newIndex: any }) => {
    this.setState(({ items }) => {
      const newOrder = arrayMoveImmutable(items, oldIndex, newIndex);
      this.state.setOrder(newOrder);
      return {
        items: newOrder,
      };
    });
  };

  render() {
    return <SortableList items={this.state.items} onSortEnd={this.onSortEnd} />;
  }
}

export default OrderList;
