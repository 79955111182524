import { Icon, CircularProgressProps, useToast } from "@chakra-ui/react";
import { BsFillCheckSquareFill, BsFillXSquareFill } from "react-icons/bs";
import { useSwitchProductStatusMutation } from "../../generated/graphql";
import CircularLoading from "../circularLoading/CircularLoading";

type Props = {
  status: boolean;
  productId: string;
  progressProps?: CircularProgressProps;
  refetch: () => any;
};

function ProductUpdateStatusBox({ status, progressProps, refetch, productId }: Props) {
  const [switchProductStatus, { loading }] = useSwitchProductStatusMutation();
  const toast = useToast();

  const switchItemStatus = async (id: string, status: boolean) => {
    try {
      await switchProductStatus({
        variables: { data: { productId: id, status: !status } },
      });
      refetch();
    } catch (error: any) {
      return toast({
        title: "Hata",
        description: error.message || "Bir hata oluştu",
        isClosable: true,
        duration: 2000,
        status: "error",
      });
    }
  };

  if (loading) {
    return <CircularLoading progressProps={progressProps} />;
  }
  return status ? (
    <Icon
      as={BsFillCheckSquareFill}
      color="green.500"
      w={4}
      h={4}
      onClick={() => switchItemStatus(productId, status)}
    />
  ) : (
    <Icon
      as={BsFillXSquareFill}
      color="red.500"
      w={4}
      h={4}
      onClick={() => switchItemStatus(productId, status)}
    />
  );
}

export default ProductUpdateStatusBox;
