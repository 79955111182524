import {
  Box,
  Button,
  Flex,
  FormLabel,
  GridItem,
  IconButton,
  Input,
  Link,
  Select,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Editor } from "@tinymce/tinymce-react";
import { FC, FormEvent, useEffect, useState } from "react";
import { FcList } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import getSlug, { createSlug } from "speakingurl";
import UploadDropzone from "../../components/dropzone/UploadDropzone";
import {
  LanguageOptions,
  useGetBlogCategoriesQuery,
  useCreateOneBlogMutation,
  GetBlogQuery,
  useUpdateDescriptionMutation,
  useUpdateOneBlogMutation,
  useUpdateOneSeoMutation,
  useCreateOneStaffLogMutation,
} from "../../generated/graphql";
import { useStaffStore } from "../../store/staff";
import DescriptionTabs from "../../components/description/DescriptionForm";
import { SeoProperty } from "../../types/descriptionProperty";
import getLocalizedSeo from "../../utils/getLocalizedSeos";
import CloudinaryImage from "../../components/CloudinaryImage";
import parser from "html-react-parser";
import SeoForms from "../../components/seo/SeoForm";
import { updatedDiff } from "deep-object-diff";

const editorSettings = {
  skin: "snow",
  icons: "thin",
  placeholder: "Description...",
  height: 300,
  menubar: false,
  min_height: 200,
  max_height: 500,

  plugins: [
    "advlist autolink lists link image charmap print preview anchor",
    "searchreplace visualblocks code fullscreen",
    "insertdatetime media table paste code wordcount",
  ],
  toolbar:
    "undo redo | formatselect | " +
    "bold italic backcolor forecolor | bullist numlist outdent indent | " +
    "removeformat | code",
};

type Props = {
  actionType: "CREATE" | "UPDATE";
  blog?: GetBlogQuery["getBlog"];
};

const Form: FC<Props> = ({ actionType, blog }) => {
  const [photo, setPhoto] = useState(() => {
    return blog?.photo || "";
  });
  const [descriptions, setDescriptions] = useState<any[]>(() => {
    return blog?.descriptions || [];
  });
  //
  const { data: blogCategories } = useGetBlogCategoriesQuery();
  const [selectedCategory, setSelectedCategory] = useState<string | null>(() => {
    return blog?.category?.id || "";
  });
  const [status, setStatus] = useState<boolean>(() => {
    return blog?.status || false;
  });
  const [createOneBlog, { loading: createLoading }] = useCreateOneBlogMutation();
  const navigate = useNavigate();
  const [updateDescriptionMutation] = useUpdateDescriptionMutation();
  const [updateBlog] = useUpdateOneBlogMutation();
  const toast = useToast();

  const [seos, setSeo] = useState<SeoProperty[]>(getLocalizedSeo({ seos: blog?.seos }));
  const [updateSeoMutation, { loading: updateSeoLoading }] = useUpdateOneSeoMutation();
  const [language, setLanguage] = useState<LanguageOptions>(LanguageOptions.DE);

  const [staff] = useStaffStore((store) => [store.staff]);
  const [createLog] = useCreateOneStaffLogMutation();

  const onSubmitHandler = async (e: FormEvent) => {
    e.preventDefault();
    try {
      if (actionType === "UPDATE") {
        if (blog?.seos && blog?.seos.length > 0) {
          for await (const iterator of seos) {
            await updateSeoMutation({
              variables: {
                where: { id: iterator.seoId },
                data: {
                  title: { set: iterator.title ?? "" },
                  description: { set: iterator.description ?? "" },
                  keywords: { set: iterator.keywords ?? "" },
                },
              },
            });
          }
        }
        await updateBlog({
          variables: {
            where: {
              id: blog?.id || "",
            },
            data: {
              ...(blog?.seos.length === 0 && {
                seos: {
                  create: seos.map((seo) => ({
                    title: seo.title,
                    description: seo.description,
                    keywords: seo.keywords,
                    language: seo.language as LanguageOptions,
                  })),
                },
              }),
            },
          },
        });

        await Promise.allSettled([
          createLog({
            variables: {
              data: {
                json: {
                  action: "UPDATE",
                  message: `Updated Seo for Blog ${
                    blog?.descriptions.find((a) => a.language === "EN")?.title
                  } by ${staff?.name}`,
                  diffs: {
                    old: blog?.seos,
                    diff: updatedDiff(blog?.seos as any, seos),
                  },
                },
                tableName: "Blog",
                staff: {
                  connect: {
                    id: staff?.id,
                  },
                },
              },
            },
          }),
        ]);
      }

      toast({
        title: "Success",
        description: "Process completed successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/blog");
    } catch (error: any) {
      toast({
        title: "Error",
        description: error?.message || "error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (blog) {
      setPhoto(blog.photo);
      setDescriptions(
        Array.from(
          blog.descriptions.map((desc) => ({
            description: desc.description,
            title: desc.title,
            language: desc.language,
            slug: getSlug(desc.title),
            // @ts-ignore
            id: desc?.id as string,
          }))
        )
      );
      setSelectedCategory(blog.category?.id || null);
      setStatus(blog.status);
    }
  }, [blog]);

  return (
    <Box>
      <form onSubmit={onSubmitHandler}>
        <CloudinaryImage publicId={blog?.photo ?? ""} width={160} />

        <SimpleGrid columns={4} gap={12}>
          <GridItem w={"full"} colSpan={2}>
            <SeoForms setLang={setLanguage} seos={seos} setSeo={setSeo} />
          </GridItem>
          <GridItem colSpan={2}>
            {blog?.descriptions && blog?.descriptions.length > 0 && (
              <Box my={10}>
                <Box>
                  <Text fontSize={24}>
                    <strong>Title</strong> ({language})
                  </Text>
                  <Text fontSize={18}>
                    {blog.descriptions.find((d) => d.language === language)?.title}
                  </Text>
                </Box>
                <Box my={5}>
                  <Text fontSize={24}>
                    <strong>Description</strong>
                  </Text>
                  <Text>
                    {parser(
                      blog.descriptions.find((d) => d.language === language)
                        ?.description || ""
                    )}
                  </Text>
                </Box>
              </Box>
            )}
          </GridItem>
        </SimpleGrid>
        <Box my={4}>
          <Button type="submit" colorScheme="green" disabled={createLoading}>
            Save
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default Form;
