import {
  Box,
  Flex,
  IconButton,
  Link,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Image,
  Badge,
} from "@chakra-ui/react";
import { FC } from "react";
import { FcEditImage, FcPlus, FcRefresh } from "react-icons/fc";
import { IoMdReorder } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import PageLayout from "../../components/ui/PageLayout";
import {
  LanguageOptions,
  useBlogsQuery,
  useDeleteOneBlogMutation,
  useUpdateDescriptionMutation,
} from "../../generated/graphql";
import CloudinaryImage from "../../components/CloudinaryImage";
import DeleteItem from "../../components/delete/DeleteItem";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";

const All: FC = () => {
  const { data, refetch, loading, error } = useBlogsQuery({
    variables: {},
  });
  const navigate = useNavigate();
  const [deleteBlog] = useDeleteOneBlogMutation();
  const [updateDescription] = useUpdateDescriptionMutation();

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          Blog
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            icon={<FcRefresh />}
            aria-label="Refetch Query"
            onClick={() => refetch()}
          />
        </Flex>
      </Flex>

      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Photo</Th>
            <Th>Title</Th>
            <Th>Category Name</Th>
            <Th textAlign="center">Status</Th>
            <Th textAlign="center">Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.blogs.map((blog, index) => (
            <Tr key={index}>
              <Td>{index + 1}</Td>
              <Td>
                {blog?.photo ? (
                  <CloudinaryImage width={80} publicId={blog.photo} />
                ) : (
                  <Image maxW="80px" src={"/noimg.png"} alt="Product" />
                )}
              </Td>
              <Td>
                {blog.descriptions.find((d) => d.language === LanguageOptions.EN)
                  ?.title || ""}
              </Td>
              <Td>
                {blog.category?.descriptions.find(
                  (d) => d.language === LanguageOptions.EN
                )?.title || ""}
              </Td>
              <Td textAlign="center">
                <Badge
                  colorScheme={blog?.status ? "green" : "red"}
                  variant={"solid"}
                  children={blog?.status ? "Active" : "Inactive"}
                />
              </Td>
              <Td>
                <Flex justifyContent={"center"}>
                  <Box mx={2}>
                    <IconButton
                      aria-label="Edit"
                      icon={<FcEditImage />}
                      onClick={() => navigate(`/blog/update/${blog.id}`)}
                    />
                  </Box>
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </PageLayout>
  );
};

export default All;
