import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** GraphQL Scalar representing the Prisma.Decimal type, based on Decimal.js library. */
  Decimal: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AddBasketInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  price: Scalars['Float'];
  productId: Scalars['ID'];
  quantity?: InputMaybe<Scalars['Int']>;
  ringColorId?: InputMaybe<Scalars['String']>;
  ringColorPurityId?: InputMaybe<Scalars['String']>;
  ringSizeId?: InputMaybe<Scalars['String']>;
  ringText?: InputMaybe<Scalars['String']>;
  selectedCreatableIds: Array<Scalars['ID']>;
  stoneGroupId?: InputMaybe<Scalars['String']>;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type AggregateBasket = {
  __typename?: 'AggregateBasket';
  _count?: Maybe<BasketCountAggregate>;
  _max?: Maybe<BasketMaxAggregate>;
  _min?: Maybe<BasketMinAggregate>;
};

export type AggregateBasketItem = {
  __typename?: 'AggregateBasketItem';
  _avg?: Maybe<BasketItemAvgAggregate>;
  _count?: Maybe<BasketItemCountAggregate>;
  _max?: Maybe<BasketItemMaxAggregate>;
  _min?: Maybe<BasketItemMinAggregate>;
  _sum?: Maybe<BasketItemSumAggregate>;
};

export type AggregateBlog = {
  __typename?: 'AggregateBlog';
  _count?: Maybe<BlogCountAggregate>;
  _max?: Maybe<BlogMaxAggregate>;
  _min?: Maybe<BlogMinAggregate>;
};

export type AggregateBlogCategory = {
  __typename?: 'AggregateBlogCategory';
  _count?: Maybe<BlogCategoryCountAggregate>;
  _max?: Maybe<BlogCategoryMaxAggregate>;
  _min?: Maybe<BlogCategoryMinAggregate>;
};

export type AggregateCategory = {
  __typename?: 'AggregateCategory';
  _avg?: Maybe<CategoryAvgAggregate>;
  _count?: Maybe<CategoryCountAggregate>;
  _max?: Maybe<CategoryMaxAggregate>;
  _min?: Maybe<CategoryMinAggregate>;
  _sum?: Maybe<CategorySumAggregate>;
};

export type AggregateCreatable = {
  __typename?: 'AggregateCreatable';
  _avg?: Maybe<CreatableAvgAggregate>;
  _count?: Maybe<CreatableCountAggregate>;
  _max?: Maybe<CreatableMaxAggregate>;
  _min?: Maybe<CreatableMinAggregate>;
  _sum?: Maybe<CreatableSumAggregate>;
};

export type AggregateCustomer = {
  __typename?: 'AggregateCustomer';
  _avg?: Maybe<CustomerAvgAggregate>;
  _count?: Maybe<CustomerCountAggregate>;
  _max?: Maybe<CustomerMaxAggregate>;
  _min?: Maybe<CustomerMinAggregate>;
  _sum?: Maybe<CustomerSumAggregate>;
};

export type AggregateDescription = {
  __typename?: 'AggregateDescription';
  _count?: Maybe<DescriptionCountAggregate>;
  _max?: Maybe<DescriptionMaxAggregate>;
  _min?: Maybe<DescriptionMinAggregate>;
};

export type AggregateDiscountCoupon = {
  __typename?: 'AggregateDiscountCoupon';
  _avg?: Maybe<DiscountCouponAvgAggregate>;
  _count?: Maybe<DiscountCouponCountAggregate>;
  _max?: Maybe<DiscountCouponMaxAggregate>;
  _min?: Maybe<DiscountCouponMinAggregate>;
  _sum?: Maybe<DiscountCouponSumAggregate>;
};

export type AggregateOrder = {
  __typename?: 'AggregateOrder';
  _avg?: Maybe<OrderAvgAggregate>;
  _count?: Maybe<OrderCountAggregate>;
  _max?: Maybe<OrderMaxAggregate>;
  _min?: Maybe<OrderMinAggregate>;
  _sum?: Maybe<OrderSumAggregate>;
};

export type AggregatePage = {
  __typename?: 'AggregatePage';
  _avg?: Maybe<PageAvgAggregate>;
  _count?: Maybe<PageCountAggregate>;
  _max?: Maybe<PageMaxAggregate>;
  _min?: Maybe<PageMinAggregate>;
  _sum?: Maybe<PageSumAggregate>;
};

export type AggregateProduct = {
  __typename?: 'AggregateProduct';
  _avg?: Maybe<ProductAvgAggregate>;
  _count?: Maybe<ProductCountAggregate>;
  _max?: Maybe<ProductMaxAggregate>;
  _min?: Maybe<ProductMinAggregate>;
  _sum?: Maybe<ProductSumAggregate>;
};

export type AggregateProductPriceConstant = {
  __typename?: 'AggregateProductPriceConstant';
  _count?: Maybe<ProductPriceConstantCountAggregate>;
  _max?: Maybe<ProductPriceConstantMaxAggregate>;
  _min?: Maybe<ProductPriceConstantMinAggregate>;
};

export type AggregateProductRingStone = {
  __typename?: 'AggregateProductRingStone';
  _avg?: Maybe<ProductRingStoneAvgAggregate>;
  _count?: Maybe<ProductRingStoneCountAggregate>;
  _max?: Maybe<ProductRingStoneMaxAggregate>;
  _min?: Maybe<ProductRingStoneMinAggregate>;
  _sum?: Maybe<ProductRingStoneSumAggregate>;
};

export type AggregateProductRingStoneGroup = {
  __typename?: 'AggregateProductRingStoneGroup';
  _count?: Maybe<ProductRingStoneGroupCountAggregate>;
  _max?: Maybe<ProductRingStoneGroupMaxAggregate>;
  _min?: Maybe<ProductRingStoneGroupMinAggregate>;
};

export type AggregateRingColor = {
  __typename?: 'AggregateRingColor';
  _avg?: Maybe<RingColorAvgAggregate>;
  _count?: Maybe<RingColorCountAggregate>;
  _max?: Maybe<RingColorMaxAggregate>;
  _min?: Maybe<RingColorMinAggregate>;
  _sum?: Maybe<RingColorSumAggregate>;
};

export type AggregateRingColorPurity = {
  __typename?: 'AggregateRingColorPurity';
  _avg?: Maybe<RingColorPurityAvgAggregate>;
  _count?: Maybe<RingColorPurityCountAggregate>;
  _max?: Maybe<RingColorPurityMaxAggregate>;
  _min?: Maybe<RingColorPurityMinAggregate>;
  _sum?: Maybe<RingColorPuritySumAggregate>;
};

export type AggregateRingSize = {
  __typename?: 'AggregateRingSize';
  _avg?: Maybe<RingSizeAvgAggregate>;
  _count?: Maybe<RingSizeCountAggregate>;
  _max?: Maybe<RingSizeMaxAggregate>;
  _min?: Maybe<RingSizeMinAggregate>;
  _sum?: Maybe<RingSizeSumAggregate>;
};

export type AggregateRingSizeStandart = {
  __typename?: 'AggregateRingSizeStandart';
  _count?: Maybe<RingSizeStandartCountAggregate>;
  _max?: Maybe<RingSizeStandartMaxAggregate>;
  _min?: Maybe<RingSizeStandartMinAggregate>;
};

export type AggregateRingStoneCarat = {
  __typename?: 'AggregateRingStoneCarat';
  _count?: Maybe<RingStoneCaratCountAggregate>;
  _max?: Maybe<RingStoneCaratMaxAggregate>;
  _min?: Maybe<RingStoneCaratMinAggregate>;
};

export type AggregateRingStoneCaratInfo = {
  __typename?: 'AggregateRingStoneCaratInfo';
  _avg?: Maybe<RingStoneCaratInfoAvgAggregate>;
  _count?: Maybe<RingStoneCaratInfoCountAggregate>;
  _max?: Maybe<RingStoneCaratInfoMaxAggregate>;
  _min?: Maybe<RingStoneCaratInfoMinAggregate>;
  _sum?: Maybe<RingStoneCaratInfoSumAggregate>;
};

export type AggregateRingStoneShape = {
  __typename?: 'AggregateRingStoneShape';
  _count?: Maybe<RingStoneShapeCountAggregate>;
  _max?: Maybe<RingStoneShapeMaxAggregate>;
  _min?: Maybe<RingStoneShapeMinAggregate>;
};

export type AggregateSeo = {
  __typename?: 'AggregateSeo';
  _count?: Maybe<SeoCountAggregate>;
  _max?: Maybe<SeoMaxAggregate>;
  _min?: Maybe<SeoMinAggregate>;
};

export type AggregateSlider = {
  __typename?: 'AggregateSlider';
  _count?: Maybe<SliderCountAggregate>;
  _max?: Maybe<SliderMaxAggregate>;
  _min?: Maybe<SliderMinAggregate>;
};

export type AggregateStaff = {
  __typename?: 'AggregateStaff';
  _count?: Maybe<StaffCountAggregate>;
  _max?: Maybe<StaffMaxAggregate>;
  _min?: Maybe<StaffMinAggregate>;
};

export type AggregateStaffLog = {
  __typename?: 'AggregateStaffLog';
  _count?: Maybe<StaffLogCountAggregate>;
  _max?: Maybe<StaffLogMaxAggregate>;
  _min?: Maybe<StaffLogMinAggregate>;
};

export type AggregateStyle = {
  __typename?: 'AggregateStyle';
  _avg?: Maybe<StyleAvgAggregate>;
  _count?: Maybe<StyleCountAggregate>;
  _max?: Maybe<StyleMaxAggregate>;
  _min?: Maybe<StyleMinAggregate>;
  _sum?: Maybe<StyleSumAggregate>;
};

export type Basket = {
  __typename?: 'Basket';
  _count?: Maybe<BasketCount>;
  basketItem: Array<BasketItem>;
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerId: Scalars['String'];
  id: Scalars['String'];
};


export type BasketBasketItemArgs = {
  cursor?: InputMaybe<BasketItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<BasketItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BasketItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BasketItemWhereInput>;
};

export type BasketCount = {
  __typename?: 'BasketCount';
  basketItem: Scalars['Int'];
};

export type BasketCountAggregate = {
  __typename?: 'BasketCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  customerId: Scalars['Int'];
  id: Scalars['Int'];
};

export type BasketCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type BasketCreateInput = {
  basketItem?: InputMaybe<BasketItemCreateNestedManyWithoutBasketInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer: CustomerCreateNestedOneWithoutBasketInput;
  id?: InputMaybe<Scalars['String']>;
};

export type BasketCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
};

export type BasketCreateNestedOneWithoutBasketItemInput = {
  connect?: InputMaybe<BasketWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BasketCreateOrConnectWithoutBasketItemInput>;
  create?: InputMaybe<BasketCreateWithoutBasketItemInput>;
};

export type BasketCreateNestedOneWithoutCustomerInput = {
  connect?: InputMaybe<BasketWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BasketCreateOrConnectWithoutCustomerInput>;
  create?: InputMaybe<BasketCreateWithoutCustomerInput>;
};

export type BasketCreateOrConnectWithoutBasketItemInput = {
  create: BasketCreateWithoutBasketItemInput;
  where: BasketWhereUniqueInput;
};

export type BasketCreateOrConnectWithoutCustomerInput = {
  create: BasketCreateWithoutCustomerInput;
  where: BasketWhereUniqueInput;
};

export type BasketCreateWithoutBasketItemInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer: CustomerCreateNestedOneWithoutBasketInput;
  id?: InputMaybe<Scalars['String']>;
};

export type BasketCreateWithoutCustomerInput = {
  basketItem?: InputMaybe<BasketItemCreateNestedManyWithoutBasketInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
};

export type BasketGroupBy = {
  __typename?: 'BasketGroupBy';
  _count?: Maybe<BasketCountAggregate>;
  _max?: Maybe<BasketMaxAggregate>;
  _min?: Maybe<BasketMinAggregate>;
  createdAt: Scalars['DateTime'];
  customerId: Scalars['String'];
  id: Scalars['String'];
};

export type BasketInputPayload = {
  __typename?: 'BasketInputPayload';
  id: Scalars['String'];
  result: Scalars['String'];
};

export type BasketItem = {
  __typename?: 'BasketItem';
  _count?: Maybe<BasketItemCount>;
  additionalInfo?: Maybe<Scalars['String']>;
  basket: Basket;
  basketId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  product: Product;
  productId: Scalars['String'];
  productSelectedCreatables: Array<ProductBasketCreatable>;
  quantity: Scalars['Int'];
  ringColor?: Maybe<RingColor>;
  ringColorId?: Maybe<Scalars['String']>;
  ringColorPurity?: Maybe<RingColorPurity>;
  ringColorPurityId?: Maybe<Scalars['String']>;
  ringSize?: Maybe<RingSize>;
  ringSizeId?: Maybe<Scalars['String']>;
  ringText?: Maybe<Scalars['String']>;
  selectedPrice: Scalars['Decimal'];
  stoneGroup?: Maybe<ProductRingStoneGroup>;
  stoneGroupId?: Maybe<Scalars['String']>;
};


export type BasketItemProductSelectedCreatablesArgs = {
  cursor?: InputMaybe<ProductBasketCreatableWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductBasketCreatableScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductBasketCreatableOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductBasketCreatableWhereInput>;
};

export type BasketItemAvgAggregate = {
  __typename?: 'BasketItemAvgAggregate';
  quantity?: Maybe<Scalars['Float']>;
  selectedPrice?: Maybe<Scalars['Decimal']>;
};

export type BasketItemAvgOrderByAggregateInput = {
  quantity?: InputMaybe<SortOrder>;
  selectedPrice?: InputMaybe<SortOrder>;
};

export type BasketItemCount = {
  __typename?: 'BasketItemCount';
  productSelectedCreatables: Scalars['Int'];
};

export type BasketItemCountAggregate = {
  __typename?: 'BasketItemCountAggregate';
  _all: Scalars['Int'];
  additionalInfo: Scalars['Int'];
  basketId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  productId: Scalars['Int'];
  quantity: Scalars['Int'];
  ringColorId: Scalars['Int'];
  ringColorPurityId: Scalars['Int'];
  ringSizeId: Scalars['Int'];
  ringText: Scalars['Int'];
  selectedPrice: Scalars['Int'];
  stoneGroupId: Scalars['Int'];
};

export type BasketItemCountOrderByAggregateInput = {
  additionalInfo?: InputMaybe<SortOrder>;
  basketId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  ringColorId?: InputMaybe<SortOrder>;
  ringColorPurityId?: InputMaybe<SortOrder>;
  ringSizeId?: InputMaybe<SortOrder>;
  ringText?: InputMaybe<SortOrder>;
  selectedPrice?: InputMaybe<SortOrder>;
  stoneGroupId?: InputMaybe<SortOrder>;
};

export type BasketItemCreateInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  basket: BasketCreateNestedOneWithoutBasketItemInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  product: ProductCreateNestedOneWithoutBasketItemsInput;
  productSelectedCreatables?: InputMaybe<ProductBasketCreatableCreateNestedManyWithoutBasketItemInput>;
  quantity?: InputMaybe<Scalars['Int']>;
  ringColor?: InputMaybe<RingColorCreateNestedOneWithoutBasketItemsInput>;
  ringColorPurity?: InputMaybe<RingColorPurityCreateNestedOneWithoutBasketItemsInput>;
  ringSize?: InputMaybe<RingSizeCreateNestedOneWithoutBasketItemsInput>;
  ringText?: InputMaybe<Scalars['String']>;
  selectedPrice: Scalars['Decimal'];
  stoneGroup?: InputMaybe<ProductRingStoneGroupCreateNestedOneWithoutBasketItemsInput>;
};

export type BasketItemCreateManyBasketInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
  quantity?: InputMaybe<Scalars['Int']>;
  ringColorId?: InputMaybe<Scalars['String']>;
  ringColorPurityId?: InputMaybe<Scalars['String']>;
  ringSizeId?: InputMaybe<Scalars['String']>;
  ringText?: InputMaybe<Scalars['String']>;
  selectedPrice: Scalars['Decimal'];
  stoneGroupId?: InputMaybe<Scalars['String']>;
};

export type BasketItemCreateManyBasketInputEnvelope = {
  data: Array<BasketItemCreateManyBasketInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BasketItemCreateManyInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  basketId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
  quantity?: InputMaybe<Scalars['Int']>;
  ringColorId?: InputMaybe<Scalars['String']>;
  ringColorPurityId?: InputMaybe<Scalars['String']>;
  ringSizeId?: InputMaybe<Scalars['String']>;
  ringText?: InputMaybe<Scalars['String']>;
  selectedPrice: Scalars['Decimal'];
  stoneGroupId?: InputMaybe<Scalars['String']>;
};

export type BasketItemCreateManyProductInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  basketId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  ringColorId?: InputMaybe<Scalars['String']>;
  ringColorPurityId?: InputMaybe<Scalars['String']>;
  ringSizeId?: InputMaybe<Scalars['String']>;
  ringText?: InputMaybe<Scalars['String']>;
  selectedPrice: Scalars['Decimal'];
  stoneGroupId?: InputMaybe<Scalars['String']>;
};

export type BasketItemCreateManyProductInputEnvelope = {
  data: Array<BasketItemCreateManyProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BasketItemCreateManyRingColorInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  basketId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
  quantity?: InputMaybe<Scalars['Int']>;
  ringColorPurityId?: InputMaybe<Scalars['String']>;
  ringSizeId?: InputMaybe<Scalars['String']>;
  ringText?: InputMaybe<Scalars['String']>;
  selectedPrice: Scalars['Decimal'];
  stoneGroupId?: InputMaybe<Scalars['String']>;
};

export type BasketItemCreateManyRingColorInputEnvelope = {
  data: Array<BasketItemCreateManyRingColorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BasketItemCreateManyRingColorPurityInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  basketId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
  quantity?: InputMaybe<Scalars['Int']>;
  ringColorId?: InputMaybe<Scalars['String']>;
  ringSizeId?: InputMaybe<Scalars['String']>;
  ringText?: InputMaybe<Scalars['String']>;
  selectedPrice: Scalars['Decimal'];
  stoneGroupId?: InputMaybe<Scalars['String']>;
};

export type BasketItemCreateManyRingColorPurityInputEnvelope = {
  data: Array<BasketItemCreateManyRingColorPurityInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BasketItemCreateManyRingSizeInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  basketId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
  quantity?: InputMaybe<Scalars['Int']>;
  ringColorId?: InputMaybe<Scalars['String']>;
  ringColorPurityId?: InputMaybe<Scalars['String']>;
  ringText?: InputMaybe<Scalars['String']>;
  selectedPrice: Scalars['Decimal'];
  stoneGroupId?: InputMaybe<Scalars['String']>;
};

export type BasketItemCreateManyRingSizeInputEnvelope = {
  data: Array<BasketItemCreateManyRingSizeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BasketItemCreateManyStoneGroupInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  basketId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
  quantity?: InputMaybe<Scalars['Int']>;
  ringColorId?: InputMaybe<Scalars['String']>;
  ringColorPurityId?: InputMaybe<Scalars['String']>;
  ringSizeId?: InputMaybe<Scalars['String']>;
  ringText?: InputMaybe<Scalars['String']>;
  selectedPrice: Scalars['Decimal'];
};

export type BasketItemCreateManyStoneGroupInputEnvelope = {
  data: Array<BasketItemCreateManyStoneGroupInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BasketItemCreateNestedManyWithoutBasketInput = {
  connect?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BasketItemCreateOrConnectWithoutBasketInput>>;
  create?: InputMaybe<Array<BasketItemCreateWithoutBasketInput>>;
  createMany?: InputMaybe<BasketItemCreateManyBasketInputEnvelope>;
};

export type BasketItemCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BasketItemCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<BasketItemCreateWithoutProductInput>>;
  createMany?: InputMaybe<BasketItemCreateManyProductInputEnvelope>;
};

export type BasketItemCreateNestedManyWithoutRingColorInput = {
  connect?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BasketItemCreateOrConnectWithoutRingColorInput>>;
  create?: InputMaybe<Array<BasketItemCreateWithoutRingColorInput>>;
  createMany?: InputMaybe<BasketItemCreateManyRingColorInputEnvelope>;
};

export type BasketItemCreateNestedManyWithoutRingColorPurityInput = {
  connect?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BasketItemCreateOrConnectWithoutRingColorPurityInput>>;
  create?: InputMaybe<Array<BasketItemCreateWithoutRingColorPurityInput>>;
  createMany?: InputMaybe<BasketItemCreateManyRingColorPurityInputEnvelope>;
};

export type BasketItemCreateNestedManyWithoutRingSizeInput = {
  connect?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BasketItemCreateOrConnectWithoutRingSizeInput>>;
  create?: InputMaybe<Array<BasketItemCreateWithoutRingSizeInput>>;
  createMany?: InputMaybe<BasketItemCreateManyRingSizeInputEnvelope>;
};

export type BasketItemCreateNestedManyWithoutStoneGroupInput = {
  connect?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BasketItemCreateOrConnectWithoutStoneGroupInput>>;
  create?: InputMaybe<Array<BasketItemCreateWithoutStoneGroupInput>>;
  createMany?: InputMaybe<BasketItemCreateManyStoneGroupInputEnvelope>;
};

export type BasketItemCreateNestedOneWithoutProductSelectedCreatablesInput = {
  connect?: InputMaybe<BasketItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BasketItemCreateOrConnectWithoutProductSelectedCreatablesInput>;
  create?: InputMaybe<BasketItemCreateWithoutProductSelectedCreatablesInput>;
};

export type BasketItemCreateOrConnectWithoutBasketInput = {
  create: BasketItemCreateWithoutBasketInput;
  where: BasketItemWhereUniqueInput;
};

export type BasketItemCreateOrConnectWithoutProductInput = {
  create: BasketItemCreateWithoutProductInput;
  where: BasketItemWhereUniqueInput;
};

export type BasketItemCreateOrConnectWithoutProductSelectedCreatablesInput = {
  create: BasketItemCreateWithoutProductSelectedCreatablesInput;
  where: BasketItemWhereUniqueInput;
};

export type BasketItemCreateOrConnectWithoutRingColorInput = {
  create: BasketItemCreateWithoutRingColorInput;
  where: BasketItemWhereUniqueInput;
};

export type BasketItemCreateOrConnectWithoutRingColorPurityInput = {
  create: BasketItemCreateWithoutRingColorPurityInput;
  where: BasketItemWhereUniqueInput;
};

export type BasketItemCreateOrConnectWithoutRingSizeInput = {
  create: BasketItemCreateWithoutRingSizeInput;
  where: BasketItemWhereUniqueInput;
};

export type BasketItemCreateOrConnectWithoutStoneGroupInput = {
  create: BasketItemCreateWithoutStoneGroupInput;
  where: BasketItemWhereUniqueInput;
};

export type BasketItemCreateWithoutBasketInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  product: ProductCreateNestedOneWithoutBasketItemsInput;
  productSelectedCreatables?: InputMaybe<ProductBasketCreatableCreateNestedManyWithoutBasketItemInput>;
  quantity?: InputMaybe<Scalars['Int']>;
  ringColor?: InputMaybe<RingColorCreateNestedOneWithoutBasketItemsInput>;
  ringColorPurity?: InputMaybe<RingColorPurityCreateNestedOneWithoutBasketItemsInput>;
  ringSize?: InputMaybe<RingSizeCreateNestedOneWithoutBasketItemsInput>;
  ringText?: InputMaybe<Scalars['String']>;
  selectedPrice: Scalars['Decimal'];
  stoneGroup?: InputMaybe<ProductRingStoneGroupCreateNestedOneWithoutBasketItemsInput>;
};

export type BasketItemCreateWithoutProductInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  basket: BasketCreateNestedOneWithoutBasketItemInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  productSelectedCreatables?: InputMaybe<ProductBasketCreatableCreateNestedManyWithoutBasketItemInput>;
  quantity?: InputMaybe<Scalars['Int']>;
  ringColor?: InputMaybe<RingColorCreateNestedOneWithoutBasketItemsInput>;
  ringColorPurity?: InputMaybe<RingColorPurityCreateNestedOneWithoutBasketItemsInput>;
  ringSize?: InputMaybe<RingSizeCreateNestedOneWithoutBasketItemsInput>;
  ringText?: InputMaybe<Scalars['String']>;
  selectedPrice: Scalars['Decimal'];
  stoneGroup?: InputMaybe<ProductRingStoneGroupCreateNestedOneWithoutBasketItemsInput>;
};

export type BasketItemCreateWithoutProductSelectedCreatablesInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  basket: BasketCreateNestedOneWithoutBasketItemInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  product: ProductCreateNestedOneWithoutBasketItemsInput;
  quantity?: InputMaybe<Scalars['Int']>;
  ringColor?: InputMaybe<RingColorCreateNestedOneWithoutBasketItemsInput>;
  ringColorPurity?: InputMaybe<RingColorPurityCreateNestedOneWithoutBasketItemsInput>;
  ringSize?: InputMaybe<RingSizeCreateNestedOneWithoutBasketItemsInput>;
  ringText?: InputMaybe<Scalars['String']>;
  selectedPrice: Scalars['Decimal'];
  stoneGroup?: InputMaybe<ProductRingStoneGroupCreateNestedOneWithoutBasketItemsInput>;
};

export type BasketItemCreateWithoutRingColorInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  basket: BasketCreateNestedOneWithoutBasketItemInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  product: ProductCreateNestedOneWithoutBasketItemsInput;
  productSelectedCreatables?: InputMaybe<ProductBasketCreatableCreateNestedManyWithoutBasketItemInput>;
  quantity?: InputMaybe<Scalars['Int']>;
  ringColorPurity?: InputMaybe<RingColorPurityCreateNestedOneWithoutBasketItemsInput>;
  ringSize?: InputMaybe<RingSizeCreateNestedOneWithoutBasketItemsInput>;
  ringText?: InputMaybe<Scalars['String']>;
  selectedPrice: Scalars['Decimal'];
  stoneGroup?: InputMaybe<ProductRingStoneGroupCreateNestedOneWithoutBasketItemsInput>;
};

export type BasketItemCreateWithoutRingColorPurityInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  basket: BasketCreateNestedOneWithoutBasketItemInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  product: ProductCreateNestedOneWithoutBasketItemsInput;
  productSelectedCreatables?: InputMaybe<ProductBasketCreatableCreateNestedManyWithoutBasketItemInput>;
  quantity?: InputMaybe<Scalars['Int']>;
  ringColor?: InputMaybe<RingColorCreateNestedOneWithoutBasketItemsInput>;
  ringSize?: InputMaybe<RingSizeCreateNestedOneWithoutBasketItemsInput>;
  ringText?: InputMaybe<Scalars['String']>;
  selectedPrice: Scalars['Decimal'];
  stoneGroup?: InputMaybe<ProductRingStoneGroupCreateNestedOneWithoutBasketItemsInput>;
};

export type BasketItemCreateWithoutRingSizeInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  basket: BasketCreateNestedOneWithoutBasketItemInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  product: ProductCreateNestedOneWithoutBasketItemsInput;
  productSelectedCreatables?: InputMaybe<ProductBasketCreatableCreateNestedManyWithoutBasketItemInput>;
  quantity?: InputMaybe<Scalars['Int']>;
  ringColor?: InputMaybe<RingColorCreateNestedOneWithoutBasketItemsInput>;
  ringColorPurity?: InputMaybe<RingColorPurityCreateNestedOneWithoutBasketItemsInput>;
  ringText?: InputMaybe<Scalars['String']>;
  selectedPrice: Scalars['Decimal'];
  stoneGroup?: InputMaybe<ProductRingStoneGroupCreateNestedOneWithoutBasketItemsInput>;
};

export type BasketItemCreateWithoutStoneGroupInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  basket: BasketCreateNestedOneWithoutBasketItemInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  product: ProductCreateNestedOneWithoutBasketItemsInput;
  productSelectedCreatables?: InputMaybe<ProductBasketCreatableCreateNestedManyWithoutBasketItemInput>;
  quantity?: InputMaybe<Scalars['Int']>;
  ringColor?: InputMaybe<RingColorCreateNestedOneWithoutBasketItemsInput>;
  ringColorPurity?: InputMaybe<RingColorPurityCreateNestedOneWithoutBasketItemsInput>;
  ringSize?: InputMaybe<RingSizeCreateNestedOneWithoutBasketItemsInput>;
  ringText?: InputMaybe<Scalars['String']>;
  selectedPrice: Scalars['Decimal'];
};

export type BasketItemGroupBy = {
  __typename?: 'BasketItemGroupBy';
  _avg?: Maybe<BasketItemAvgAggregate>;
  _count?: Maybe<BasketItemCountAggregate>;
  _max?: Maybe<BasketItemMaxAggregate>;
  _min?: Maybe<BasketItemMinAggregate>;
  _sum?: Maybe<BasketItemSumAggregate>;
  additionalInfo?: Maybe<Scalars['String']>;
  basketId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  productId: Scalars['String'];
  quantity: Scalars['Int'];
  ringColorId?: Maybe<Scalars['String']>;
  ringColorPurityId?: Maybe<Scalars['String']>;
  ringSizeId?: Maybe<Scalars['String']>;
  ringText?: Maybe<Scalars['String']>;
  selectedPrice: Scalars['Decimal'];
  stoneGroupId?: Maybe<Scalars['String']>;
};

export type BasketItemListRelationFilter = {
  every?: InputMaybe<BasketItemWhereInput>;
  none?: InputMaybe<BasketItemWhereInput>;
  some?: InputMaybe<BasketItemWhereInput>;
};

export type BasketItemMaxAggregate = {
  __typename?: 'BasketItemMaxAggregate';
  additionalInfo?: Maybe<Scalars['String']>;
  basketId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  ringColorId?: Maybe<Scalars['String']>;
  ringColorPurityId?: Maybe<Scalars['String']>;
  ringSizeId?: Maybe<Scalars['String']>;
  ringText?: Maybe<Scalars['String']>;
  selectedPrice?: Maybe<Scalars['Decimal']>;
  stoneGroupId?: Maybe<Scalars['String']>;
};

export type BasketItemMaxOrderByAggregateInput = {
  additionalInfo?: InputMaybe<SortOrder>;
  basketId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  ringColorId?: InputMaybe<SortOrder>;
  ringColorPurityId?: InputMaybe<SortOrder>;
  ringSizeId?: InputMaybe<SortOrder>;
  ringText?: InputMaybe<SortOrder>;
  selectedPrice?: InputMaybe<SortOrder>;
  stoneGroupId?: InputMaybe<SortOrder>;
};

export type BasketItemMinAggregate = {
  __typename?: 'BasketItemMinAggregate';
  additionalInfo?: Maybe<Scalars['String']>;
  basketId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  ringColorId?: Maybe<Scalars['String']>;
  ringColorPurityId?: Maybe<Scalars['String']>;
  ringSizeId?: Maybe<Scalars['String']>;
  ringText?: Maybe<Scalars['String']>;
  selectedPrice?: Maybe<Scalars['Decimal']>;
  stoneGroupId?: Maybe<Scalars['String']>;
};

export type BasketItemMinOrderByAggregateInput = {
  additionalInfo?: InputMaybe<SortOrder>;
  basketId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  ringColorId?: InputMaybe<SortOrder>;
  ringColorPurityId?: InputMaybe<SortOrder>;
  ringSizeId?: InputMaybe<SortOrder>;
  ringText?: InputMaybe<SortOrder>;
  selectedPrice?: InputMaybe<SortOrder>;
  stoneGroupId?: InputMaybe<SortOrder>;
};

export type BasketItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BasketItemOrderByWithAggregationInput = {
  _avg?: InputMaybe<BasketItemAvgOrderByAggregateInput>;
  _count?: InputMaybe<BasketItemCountOrderByAggregateInput>;
  _max?: InputMaybe<BasketItemMaxOrderByAggregateInput>;
  _min?: InputMaybe<BasketItemMinOrderByAggregateInput>;
  _sum?: InputMaybe<BasketItemSumOrderByAggregateInput>;
  additionalInfo?: InputMaybe<SortOrder>;
  basketId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  ringColorId?: InputMaybe<SortOrder>;
  ringColorPurityId?: InputMaybe<SortOrder>;
  ringSizeId?: InputMaybe<SortOrder>;
  ringText?: InputMaybe<SortOrder>;
  selectedPrice?: InputMaybe<SortOrder>;
  stoneGroupId?: InputMaybe<SortOrder>;
};

export type BasketItemOrderByWithRelationInput = {
  additionalInfo?: InputMaybe<SortOrder>;
  basket?: InputMaybe<BasketOrderByWithRelationInput>;
  basketId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  product?: InputMaybe<ProductOrderByWithRelationInput>;
  productId?: InputMaybe<SortOrder>;
  productSelectedCreatables?: InputMaybe<ProductBasketCreatableOrderByRelationAggregateInput>;
  quantity?: InputMaybe<SortOrder>;
  ringColor?: InputMaybe<RingColorOrderByWithRelationInput>;
  ringColorId?: InputMaybe<SortOrder>;
  ringColorPurity?: InputMaybe<RingColorPurityOrderByWithRelationInput>;
  ringColorPurityId?: InputMaybe<SortOrder>;
  ringSize?: InputMaybe<RingSizeOrderByWithRelationInput>;
  ringSizeId?: InputMaybe<SortOrder>;
  ringText?: InputMaybe<SortOrder>;
  selectedPrice?: InputMaybe<SortOrder>;
  stoneGroup?: InputMaybe<ProductRingStoneGroupOrderByWithRelationInput>;
  stoneGroupId?: InputMaybe<SortOrder>;
};

export type BasketItemRelationFilter = {
  is?: InputMaybe<BasketItemWhereInput>;
  isNot?: InputMaybe<BasketItemWhereInput>;
};

export enum BasketItemScalarFieldEnum {
  additionalInfo = 'additionalInfo',
  basketId = 'basketId',
  createdAt = 'createdAt',
  id = 'id',
  productId = 'productId',
  quantity = 'quantity',
  ringColorId = 'ringColorId',
  ringColorPurityId = 'ringColorPurityId',
  ringSizeId = 'ringSizeId',
  ringText = 'ringText',
  selectedPrice = 'selectedPrice',
  stoneGroupId = 'stoneGroupId'
}

export type BasketItemScalarWhereInput = {
  AND?: InputMaybe<Array<BasketItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<BasketItemScalarWhereInput>>;
  OR?: InputMaybe<Array<BasketItemScalarWhereInput>>;
  additionalInfo?: InputMaybe<StringNullableFilter>;
  basketId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  productId?: InputMaybe<StringFilter>;
  quantity?: InputMaybe<IntFilter>;
  ringColorId?: InputMaybe<StringNullableFilter>;
  ringColorPurityId?: InputMaybe<StringNullableFilter>;
  ringSizeId?: InputMaybe<StringNullableFilter>;
  ringText?: InputMaybe<StringNullableFilter>;
  selectedPrice?: InputMaybe<DecimalFilter>;
  stoneGroupId?: InputMaybe<StringNullableFilter>;
};

export type BasketItemScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<BasketItemScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<BasketItemScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<BasketItemScalarWhereWithAggregatesInput>>;
  additionalInfo?: InputMaybe<StringNullableWithAggregatesFilter>;
  basketId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  productId?: InputMaybe<StringWithAggregatesFilter>;
  quantity?: InputMaybe<IntWithAggregatesFilter>;
  ringColorId?: InputMaybe<StringNullableWithAggregatesFilter>;
  ringColorPurityId?: InputMaybe<StringNullableWithAggregatesFilter>;
  ringSizeId?: InputMaybe<StringNullableWithAggregatesFilter>;
  ringText?: InputMaybe<StringNullableWithAggregatesFilter>;
  selectedPrice?: InputMaybe<DecimalWithAggregatesFilter>;
  stoneGroupId?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type BasketItemSumAggregate = {
  __typename?: 'BasketItemSumAggregate';
  quantity?: Maybe<Scalars['Int']>;
  selectedPrice?: Maybe<Scalars['Decimal']>;
};

export type BasketItemSumOrderByAggregateInput = {
  quantity?: InputMaybe<SortOrder>;
  selectedPrice?: InputMaybe<SortOrder>;
};

export type BasketItemUpdateInput = {
  additionalInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  basket?: InputMaybe<BasketUpdateOneRequiredWithoutBasketItemNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutBasketItemsNestedInput>;
  productSelectedCreatables?: InputMaybe<ProductBasketCreatableUpdateManyWithoutBasketItemNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  ringColor?: InputMaybe<RingColorUpdateOneWithoutBasketItemsNestedInput>;
  ringColorPurity?: InputMaybe<RingColorPurityUpdateOneWithoutBasketItemsNestedInput>;
  ringSize?: InputMaybe<RingSizeUpdateOneWithoutBasketItemsNestedInput>;
  ringText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selectedPrice?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  stoneGroup?: InputMaybe<ProductRingStoneGroupUpdateOneWithoutBasketItemsNestedInput>;
};

export type BasketItemUpdateManyMutationInput = {
  additionalInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  ringText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selectedPrice?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type BasketItemUpdateManyWithWhereWithoutBasketInput = {
  data: BasketItemUpdateManyMutationInput;
  where: BasketItemScalarWhereInput;
};

export type BasketItemUpdateManyWithWhereWithoutProductInput = {
  data: BasketItemUpdateManyMutationInput;
  where: BasketItemScalarWhereInput;
};

export type BasketItemUpdateManyWithWhereWithoutRingColorInput = {
  data: BasketItemUpdateManyMutationInput;
  where: BasketItemScalarWhereInput;
};

export type BasketItemUpdateManyWithWhereWithoutRingColorPurityInput = {
  data: BasketItemUpdateManyMutationInput;
  where: BasketItemScalarWhereInput;
};

export type BasketItemUpdateManyWithWhereWithoutRingSizeInput = {
  data: BasketItemUpdateManyMutationInput;
  where: BasketItemScalarWhereInput;
};

export type BasketItemUpdateManyWithWhereWithoutStoneGroupInput = {
  data: BasketItemUpdateManyMutationInput;
  where: BasketItemScalarWhereInput;
};

export type BasketItemUpdateManyWithoutBasketNestedInput = {
  connect?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BasketItemCreateOrConnectWithoutBasketInput>>;
  create?: InputMaybe<Array<BasketItemCreateWithoutBasketInput>>;
  createMany?: InputMaybe<BasketItemCreateManyBasketInputEnvelope>;
  delete?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BasketItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  set?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  update?: InputMaybe<Array<BasketItemUpdateWithWhereUniqueWithoutBasketInput>>;
  updateMany?: InputMaybe<Array<BasketItemUpdateManyWithWhereWithoutBasketInput>>;
  upsert?: InputMaybe<Array<BasketItemUpsertWithWhereUniqueWithoutBasketInput>>;
};

export type BasketItemUpdateManyWithoutProductNestedInput = {
  connect?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BasketItemCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<BasketItemCreateWithoutProductInput>>;
  createMany?: InputMaybe<BasketItemCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BasketItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  set?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  update?: InputMaybe<Array<BasketItemUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<BasketItemUpdateManyWithWhereWithoutProductInput>>;
  upsert?: InputMaybe<Array<BasketItemUpsertWithWhereUniqueWithoutProductInput>>;
};

export type BasketItemUpdateManyWithoutRingColorNestedInput = {
  connect?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BasketItemCreateOrConnectWithoutRingColorInput>>;
  create?: InputMaybe<Array<BasketItemCreateWithoutRingColorInput>>;
  createMany?: InputMaybe<BasketItemCreateManyRingColorInputEnvelope>;
  delete?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BasketItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  set?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  update?: InputMaybe<Array<BasketItemUpdateWithWhereUniqueWithoutRingColorInput>>;
  updateMany?: InputMaybe<Array<BasketItemUpdateManyWithWhereWithoutRingColorInput>>;
  upsert?: InputMaybe<Array<BasketItemUpsertWithWhereUniqueWithoutRingColorInput>>;
};

export type BasketItemUpdateManyWithoutRingColorPurityNestedInput = {
  connect?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BasketItemCreateOrConnectWithoutRingColorPurityInput>>;
  create?: InputMaybe<Array<BasketItemCreateWithoutRingColorPurityInput>>;
  createMany?: InputMaybe<BasketItemCreateManyRingColorPurityInputEnvelope>;
  delete?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BasketItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  set?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  update?: InputMaybe<Array<BasketItemUpdateWithWhereUniqueWithoutRingColorPurityInput>>;
  updateMany?: InputMaybe<Array<BasketItemUpdateManyWithWhereWithoutRingColorPurityInput>>;
  upsert?: InputMaybe<Array<BasketItemUpsertWithWhereUniqueWithoutRingColorPurityInput>>;
};

export type BasketItemUpdateManyWithoutRingSizeNestedInput = {
  connect?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BasketItemCreateOrConnectWithoutRingSizeInput>>;
  create?: InputMaybe<Array<BasketItemCreateWithoutRingSizeInput>>;
  createMany?: InputMaybe<BasketItemCreateManyRingSizeInputEnvelope>;
  delete?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BasketItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  set?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  update?: InputMaybe<Array<BasketItemUpdateWithWhereUniqueWithoutRingSizeInput>>;
  updateMany?: InputMaybe<Array<BasketItemUpdateManyWithWhereWithoutRingSizeInput>>;
  upsert?: InputMaybe<Array<BasketItemUpsertWithWhereUniqueWithoutRingSizeInput>>;
};

export type BasketItemUpdateManyWithoutStoneGroupNestedInput = {
  connect?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BasketItemCreateOrConnectWithoutStoneGroupInput>>;
  create?: InputMaybe<Array<BasketItemCreateWithoutStoneGroupInput>>;
  createMany?: InputMaybe<BasketItemCreateManyStoneGroupInputEnvelope>;
  delete?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BasketItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  set?: InputMaybe<Array<BasketItemWhereUniqueInput>>;
  update?: InputMaybe<Array<BasketItemUpdateWithWhereUniqueWithoutStoneGroupInput>>;
  updateMany?: InputMaybe<Array<BasketItemUpdateManyWithWhereWithoutStoneGroupInput>>;
  upsert?: InputMaybe<Array<BasketItemUpsertWithWhereUniqueWithoutStoneGroupInput>>;
};

export type BasketItemUpdateOneWithoutProductSelectedCreatablesNestedInput = {
  connect?: InputMaybe<BasketItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BasketItemCreateOrConnectWithoutProductSelectedCreatablesInput>;
  create?: InputMaybe<BasketItemCreateWithoutProductSelectedCreatablesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<BasketItemUpdateWithoutProductSelectedCreatablesInput>;
  upsert?: InputMaybe<BasketItemUpsertWithoutProductSelectedCreatablesInput>;
};

export type BasketItemUpdateWithWhereUniqueWithoutBasketInput = {
  data: BasketItemUpdateWithoutBasketInput;
  where: BasketItemWhereUniqueInput;
};

export type BasketItemUpdateWithWhereUniqueWithoutProductInput = {
  data: BasketItemUpdateWithoutProductInput;
  where: BasketItemWhereUniqueInput;
};

export type BasketItemUpdateWithWhereUniqueWithoutRingColorInput = {
  data: BasketItemUpdateWithoutRingColorInput;
  where: BasketItemWhereUniqueInput;
};

export type BasketItemUpdateWithWhereUniqueWithoutRingColorPurityInput = {
  data: BasketItemUpdateWithoutRingColorPurityInput;
  where: BasketItemWhereUniqueInput;
};

export type BasketItemUpdateWithWhereUniqueWithoutRingSizeInput = {
  data: BasketItemUpdateWithoutRingSizeInput;
  where: BasketItemWhereUniqueInput;
};

export type BasketItemUpdateWithWhereUniqueWithoutStoneGroupInput = {
  data: BasketItemUpdateWithoutStoneGroupInput;
  where: BasketItemWhereUniqueInput;
};

export type BasketItemUpdateWithoutBasketInput = {
  additionalInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutBasketItemsNestedInput>;
  productSelectedCreatables?: InputMaybe<ProductBasketCreatableUpdateManyWithoutBasketItemNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  ringColor?: InputMaybe<RingColorUpdateOneWithoutBasketItemsNestedInput>;
  ringColorPurity?: InputMaybe<RingColorPurityUpdateOneWithoutBasketItemsNestedInput>;
  ringSize?: InputMaybe<RingSizeUpdateOneWithoutBasketItemsNestedInput>;
  ringText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selectedPrice?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  stoneGroup?: InputMaybe<ProductRingStoneGroupUpdateOneWithoutBasketItemsNestedInput>;
};

export type BasketItemUpdateWithoutProductInput = {
  additionalInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  basket?: InputMaybe<BasketUpdateOneRequiredWithoutBasketItemNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  productSelectedCreatables?: InputMaybe<ProductBasketCreatableUpdateManyWithoutBasketItemNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  ringColor?: InputMaybe<RingColorUpdateOneWithoutBasketItemsNestedInput>;
  ringColorPurity?: InputMaybe<RingColorPurityUpdateOneWithoutBasketItemsNestedInput>;
  ringSize?: InputMaybe<RingSizeUpdateOneWithoutBasketItemsNestedInput>;
  ringText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selectedPrice?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  stoneGroup?: InputMaybe<ProductRingStoneGroupUpdateOneWithoutBasketItemsNestedInput>;
};

export type BasketItemUpdateWithoutProductSelectedCreatablesInput = {
  additionalInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  basket?: InputMaybe<BasketUpdateOneRequiredWithoutBasketItemNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutBasketItemsNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  ringColor?: InputMaybe<RingColorUpdateOneWithoutBasketItemsNestedInput>;
  ringColorPurity?: InputMaybe<RingColorPurityUpdateOneWithoutBasketItemsNestedInput>;
  ringSize?: InputMaybe<RingSizeUpdateOneWithoutBasketItemsNestedInput>;
  ringText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selectedPrice?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  stoneGroup?: InputMaybe<ProductRingStoneGroupUpdateOneWithoutBasketItemsNestedInput>;
};

export type BasketItemUpdateWithoutRingColorInput = {
  additionalInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  basket?: InputMaybe<BasketUpdateOneRequiredWithoutBasketItemNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutBasketItemsNestedInput>;
  productSelectedCreatables?: InputMaybe<ProductBasketCreatableUpdateManyWithoutBasketItemNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  ringColorPurity?: InputMaybe<RingColorPurityUpdateOneWithoutBasketItemsNestedInput>;
  ringSize?: InputMaybe<RingSizeUpdateOneWithoutBasketItemsNestedInput>;
  ringText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selectedPrice?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  stoneGroup?: InputMaybe<ProductRingStoneGroupUpdateOneWithoutBasketItemsNestedInput>;
};

export type BasketItemUpdateWithoutRingColorPurityInput = {
  additionalInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  basket?: InputMaybe<BasketUpdateOneRequiredWithoutBasketItemNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutBasketItemsNestedInput>;
  productSelectedCreatables?: InputMaybe<ProductBasketCreatableUpdateManyWithoutBasketItemNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  ringColor?: InputMaybe<RingColorUpdateOneWithoutBasketItemsNestedInput>;
  ringSize?: InputMaybe<RingSizeUpdateOneWithoutBasketItemsNestedInput>;
  ringText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selectedPrice?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  stoneGroup?: InputMaybe<ProductRingStoneGroupUpdateOneWithoutBasketItemsNestedInput>;
};

export type BasketItemUpdateWithoutRingSizeInput = {
  additionalInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  basket?: InputMaybe<BasketUpdateOneRequiredWithoutBasketItemNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutBasketItemsNestedInput>;
  productSelectedCreatables?: InputMaybe<ProductBasketCreatableUpdateManyWithoutBasketItemNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  ringColor?: InputMaybe<RingColorUpdateOneWithoutBasketItemsNestedInput>;
  ringColorPurity?: InputMaybe<RingColorPurityUpdateOneWithoutBasketItemsNestedInput>;
  ringText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selectedPrice?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  stoneGroup?: InputMaybe<ProductRingStoneGroupUpdateOneWithoutBasketItemsNestedInput>;
};

export type BasketItemUpdateWithoutStoneGroupInput = {
  additionalInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  basket?: InputMaybe<BasketUpdateOneRequiredWithoutBasketItemNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutBasketItemsNestedInput>;
  productSelectedCreatables?: InputMaybe<ProductBasketCreatableUpdateManyWithoutBasketItemNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  ringColor?: InputMaybe<RingColorUpdateOneWithoutBasketItemsNestedInput>;
  ringColorPurity?: InputMaybe<RingColorPurityUpdateOneWithoutBasketItemsNestedInput>;
  ringSize?: InputMaybe<RingSizeUpdateOneWithoutBasketItemsNestedInput>;
  ringText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  selectedPrice?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type BasketItemUpsertWithWhereUniqueWithoutBasketInput = {
  create: BasketItemCreateWithoutBasketInput;
  update: BasketItemUpdateWithoutBasketInput;
  where: BasketItemWhereUniqueInput;
};

export type BasketItemUpsertWithWhereUniqueWithoutProductInput = {
  create: BasketItemCreateWithoutProductInput;
  update: BasketItemUpdateWithoutProductInput;
  where: BasketItemWhereUniqueInput;
};

export type BasketItemUpsertWithWhereUniqueWithoutRingColorInput = {
  create: BasketItemCreateWithoutRingColorInput;
  update: BasketItemUpdateWithoutRingColorInput;
  where: BasketItemWhereUniqueInput;
};

export type BasketItemUpsertWithWhereUniqueWithoutRingColorPurityInput = {
  create: BasketItemCreateWithoutRingColorPurityInput;
  update: BasketItemUpdateWithoutRingColorPurityInput;
  where: BasketItemWhereUniqueInput;
};

export type BasketItemUpsertWithWhereUniqueWithoutRingSizeInput = {
  create: BasketItemCreateWithoutRingSizeInput;
  update: BasketItemUpdateWithoutRingSizeInput;
  where: BasketItemWhereUniqueInput;
};

export type BasketItemUpsertWithWhereUniqueWithoutStoneGroupInput = {
  create: BasketItemCreateWithoutStoneGroupInput;
  update: BasketItemUpdateWithoutStoneGroupInput;
  where: BasketItemWhereUniqueInput;
};

export type BasketItemUpsertWithoutProductSelectedCreatablesInput = {
  create: BasketItemCreateWithoutProductSelectedCreatablesInput;
  update: BasketItemUpdateWithoutProductSelectedCreatablesInput;
};

export type BasketItemWhereInput = {
  AND?: InputMaybe<Array<BasketItemWhereInput>>;
  NOT?: InputMaybe<Array<BasketItemWhereInput>>;
  OR?: InputMaybe<Array<BasketItemWhereInput>>;
  additionalInfo?: InputMaybe<StringNullableFilter>;
  basket?: InputMaybe<BasketRelationFilter>;
  basketId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  product?: InputMaybe<ProductRelationFilter>;
  productId?: InputMaybe<StringFilter>;
  productSelectedCreatables?: InputMaybe<ProductBasketCreatableListRelationFilter>;
  quantity?: InputMaybe<IntFilter>;
  ringColor?: InputMaybe<RingColorRelationFilter>;
  ringColorId?: InputMaybe<StringNullableFilter>;
  ringColorPurity?: InputMaybe<RingColorPurityRelationFilter>;
  ringColorPurityId?: InputMaybe<StringNullableFilter>;
  ringSize?: InputMaybe<RingSizeRelationFilter>;
  ringSizeId?: InputMaybe<StringNullableFilter>;
  ringText?: InputMaybe<StringNullableFilter>;
  selectedPrice?: InputMaybe<DecimalFilter>;
  stoneGroup?: InputMaybe<ProductRingStoneGroupRelationFilter>;
  stoneGroupId?: InputMaybe<StringNullableFilter>;
};

export type BasketItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type BasketMaxAggregate = {
  __typename?: 'BasketMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type BasketMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type BasketMinAggregate = {
  __typename?: 'BasketMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type BasketMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type BasketOrderByWithAggregationInput = {
  _count?: InputMaybe<BasketCountOrderByAggregateInput>;
  _max?: InputMaybe<BasketMaxOrderByAggregateInput>;
  _min?: InputMaybe<BasketMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type BasketOrderByWithRelationInput = {
  basketItem?: InputMaybe<BasketItemOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type BasketRelationFilter = {
  is?: InputMaybe<BasketWhereInput>;
  isNot?: InputMaybe<BasketWhereInput>;
};

export enum BasketScalarFieldEnum {
  createdAt = 'createdAt',
  customerId = 'customerId',
  id = 'id'
}

export type BasketScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<BasketScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<BasketScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<BasketScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  customerId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
};

export type BasketUpdateInput = {
  basketItem?: InputMaybe<BasketItemUpdateManyWithoutBasketNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneRequiredWithoutBasketNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type BasketUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type BasketUpdateOneRequiredWithoutBasketItemNestedInput = {
  connect?: InputMaybe<BasketWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BasketCreateOrConnectWithoutBasketItemInput>;
  create?: InputMaybe<BasketCreateWithoutBasketItemInput>;
  update?: InputMaybe<BasketUpdateWithoutBasketItemInput>;
  upsert?: InputMaybe<BasketUpsertWithoutBasketItemInput>;
};

export type BasketUpdateOneWithoutCustomerNestedInput = {
  connect?: InputMaybe<BasketWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BasketCreateOrConnectWithoutCustomerInput>;
  create?: InputMaybe<BasketCreateWithoutCustomerInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<BasketUpdateWithoutCustomerInput>;
  upsert?: InputMaybe<BasketUpsertWithoutCustomerInput>;
};

export type BasketUpdateWithoutBasketItemInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneRequiredWithoutBasketNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type BasketUpdateWithoutCustomerInput = {
  basketItem?: InputMaybe<BasketItemUpdateManyWithoutBasketNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type BasketUpsertWithoutBasketItemInput = {
  create: BasketCreateWithoutBasketItemInput;
  update: BasketUpdateWithoutBasketItemInput;
};

export type BasketUpsertWithoutCustomerInput = {
  create: BasketCreateWithoutCustomerInput;
  update: BasketUpdateWithoutCustomerInput;
};

export type BasketWhereInput = {
  AND?: InputMaybe<Array<BasketWhereInput>>;
  NOT?: InputMaybe<Array<BasketWhereInput>>;
  OR?: InputMaybe<Array<BasketWhereInput>>;
  basketItem?: InputMaybe<BasketItemListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
};

export type BasketWhereUniqueInput = {
  customerId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type Blog = {
  __typename?: 'Blog';
  _count?: Maybe<BlogCount>;
  blogCategoryId?: Maybe<Scalars['String']>;
  category?: Maybe<BlogCategory>;
  createdAt: Scalars['DateTime'];
  descriptions: Array<Description>;
  id: Scalars['String'];
  photo: Scalars['String'];
  seos: Array<Seo>;
  status: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};


export type BlogDescriptionsArgs = {
  cursor?: InputMaybe<DescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<DescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};


export type BlogSeosArgs = {
  cursor?: InputMaybe<SeoWhereUniqueInput>;
  distinct?: InputMaybe<Array<SeoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SeoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SeoWhereInput>;
};

export type BlogCategory = {
  __typename?: 'BlogCategory';
  Blog: Array<Blog>;
  _count?: Maybe<BlogCategoryCount>;
  author?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  descriptions: Array<Description>;
  id: Scalars['String'];
  seos: Array<Seo>;
  status: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};


export type BlogCategoryBlogArgs = {
  cursor?: InputMaybe<BlogWhereUniqueInput>;
  distinct?: InputMaybe<Array<BlogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BlogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BlogWhereInput>;
};


export type BlogCategoryDescriptionsArgs = {
  cursor?: InputMaybe<DescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<DescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};


export type BlogCategorySeosArgs = {
  cursor?: InputMaybe<SeoWhereUniqueInput>;
  distinct?: InputMaybe<Array<SeoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SeoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SeoWhereInput>;
};

export type BlogCategoryCount = {
  __typename?: 'BlogCategoryCount';
  Blog: Scalars['Int'];
  descriptions: Scalars['Int'];
  seos: Scalars['Int'];
};

export type BlogCategoryCountAggregate = {
  __typename?: 'BlogCategoryCountAggregate';
  _all: Scalars['Int'];
  author: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type BlogCategoryCountOrderByAggregateInput = {
  author?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BlogCategoryCreateInput = {
  Blog?: InputMaybe<BlogCreateNestedManyWithoutCategoryInput>;
  author?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutBlogCategoryInput>;
  id?: InputMaybe<Scalars['String']>;
  seos?: InputMaybe<SeoCreateNestedManyWithoutBlogCategoryInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BlogCategoryCreateManyInput = {
  author?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BlogCategoryCreateNestedOneWithoutBlogInput = {
  connect?: InputMaybe<BlogCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BlogCategoryCreateOrConnectWithoutBlogInput>;
  create?: InputMaybe<BlogCategoryCreateWithoutBlogInput>;
};

export type BlogCategoryCreateNestedOneWithoutDescriptionsInput = {
  connect?: InputMaybe<BlogCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BlogCategoryCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<BlogCategoryCreateWithoutDescriptionsInput>;
};

export type BlogCategoryCreateNestedOneWithoutSeosInput = {
  connect?: InputMaybe<BlogCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BlogCategoryCreateOrConnectWithoutSeosInput>;
  create?: InputMaybe<BlogCategoryCreateWithoutSeosInput>;
};

export type BlogCategoryCreateOrConnectWithoutBlogInput = {
  create: BlogCategoryCreateWithoutBlogInput;
  where: BlogCategoryWhereUniqueInput;
};

export type BlogCategoryCreateOrConnectWithoutDescriptionsInput = {
  create: BlogCategoryCreateWithoutDescriptionsInput;
  where: BlogCategoryWhereUniqueInput;
};

export type BlogCategoryCreateOrConnectWithoutSeosInput = {
  create: BlogCategoryCreateWithoutSeosInput;
  where: BlogCategoryWhereUniqueInput;
};

export type BlogCategoryCreateWithoutBlogInput = {
  author?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutBlogCategoryInput>;
  id?: InputMaybe<Scalars['String']>;
  seos?: InputMaybe<SeoCreateNestedManyWithoutBlogCategoryInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BlogCategoryCreateWithoutDescriptionsInput = {
  Blog?: InputMaybe<BlogCreateNestedManyWithoutCategoryInput>;
  author?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  seos?: InputMaybe<SeoCreateNestedManyWithoutBlogCategoryInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BlogCategoryCreateWithoutSeosInput = {
  Blog?: InputMaybe<BlogCreateNestedManyWithoutCategoryInput>;
  author?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutBlogCategoryInput>;
  id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BlogCategoryGroupBy = {
  __typename?: 'BlogCategoryGroupBy';
  _count?: Maybe<BlogCategoryCountAggregate>;
  _max?: Maybe<BlogCategoryMaxAggregate>;
  _min?: Maybe<BlogCategoryMinAggregate>;
  author?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  status: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type BlogCategoryMaxAggregate = {
  __typename?: 'BlogCategoryMaxAggregate';
  author?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BlogCategoryMaxOrderByAggregateInput = {
  author?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BlogCategoryMinAggregate = {
  __typename?: 'BlogCategoryMinAggregate';
  author?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BlogCategoryMinOrderByAggregateInput = {
  author?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BlogCategoryOrderByWithAggregationInput = {
  _count?: InputMaybe<BlogCategoryCountOrderByAggregateInput>;
  _max?: InputMaybe<BlogCategoryMaxOrderByAggregateInput>;
  _min?: InputMaybe<BlogCategoryMinOrderByAggregateInput>;
  author?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BlogCategoryOrderByWithRelationInput = {
  Blog?: InputMaybe<BlogOrderByRelationAggregateInput>;
  author?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  descriptions?: InputMaybe<DescriptionOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  seos?: InputMaybe<SeoOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BlogCategoryRelationFilter = {
  is?: InputMaybe<BlogCategoryWhereInput>;
  isNot?: InputMaybe<BlogCategoryWhereInput>;
};

export enum BlogCategoryScalarFieldEnum {
  author = 'author',
  createdAt = 'createdAt',
  id = 'id',
  status = 'status',
  updatedAt = 'updatedAt'
}

export type BlogCategoryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<BlogCategoryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<BlogCategoryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<BlogCategoryScalarWhereWithAggregatesInput>>;
  author?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<BoolWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type BlogCategoryUpdateInput = {
  Blog?: InputMaybe<BlogUpdateManyWithoutCategoryNestedInput>;
  author?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutBlogCategoryNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutBlogCategoryNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BlogCategoryUpdateManyMutationInput = {
  author?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BlogCategoryUpdateOneWithoutBlogNestedInput = {
  connect?: InputMaybe<BlogCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BlogCategoryCreateOrConnectWithoutBlogInput>;
  create?: InputMaybe<BlogCategoryCreateWithoutBlogInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<BlogCategoryUpdateWithoutBlogInput>;
  upsert?: InputMaybe<BlogCategoryUpsertWithoutBlogInput>;
};

export type BlogCategoryUpdateOneWithoutDescriptionsNestedInput = {
  connect?: InputMaybe<BlogCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BlogCategoryCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<BlogCategoryCreateWithoutDescriptionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<BlogCategoryUpdateWithoutDescriptionsInput>;
  upsert?: InputMaybe<BlogCategoryUpsertWithoutDescriptionsInput>;
};

export type BlogCategoryUpdateOneWithoutSeosNestedInput = {
  connect?: InputMaybe<BlogCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BlogCategoryCreateOrConnectWithoutSeosInput>;
  create?: InputMaybe<BlogCategoryCreateWithoutSeosInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<BlogCategoryUpdateWithoutSeosInput>;
  upsert?: InputMaybe<BlogCategoryUpsertWithoutSeosInput>;
};

export type BlogCategoryUpdateWithoutBlogInput = {
  author?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutBlogCategoryNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutBlogCategoryNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BlogCategoryUpdateWithoutDescriptionsInput = {
  Blog?: InputMaybe<BlogUpdateManyWithoutCategoryNestedInput>;
  author?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutBlogCategoryNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BlogCategoryUpdateWithoutSeosInput = {
  Blog?: InputMaybe<BlogUpdateManyWithoutCategoryNestedInput>;
  author?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutBlogCategoryNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BlogCategoryUpsertWithoutBlogInput = {
  create: BlogCategoryCreateWithoutBlogInput;
  update: BlogCategoryUpdateWithoutBlogInput;
};

export type BlogCategoryUpsertWithoutDescriptionsInput = {
  create: BlogCategoryCreateWithoutDescriptionsInput;
  update: BlogCategoryUpdateWithoutDescriptionsInput;
};

export type BlogCategoryUpsertWithoutSeosInput = {
  create: BlogCategoryCreateWithoutSeosInput;
  update: BlogCategoryUpdateWithoutSeosInput;
};

export type BlogCategoryWhereInput = {
  AND?: InputMaybe<Array<BlogCategoryWhereInput>>;
  Blog?: InputMaybe<BlogListRelationFilter>;
  NOT?: InputMaybe<Array<BlogCategoryWhereInput>>;
  OR?: InputMaybe<Array<BlogCategoryWhereInput>>;
  author?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  descriptions?: InputMaybe<DescriptionListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  seos?: InputMaybe<SeoListRelationFilter>;
  status?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BlogCategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type BlogCount = {
  __typename?: 'BlogCount';
  descriptions: Scalars['Int'];
  seos: Scalars['Int'];
};

export type BlogCountAggregate = {
  __typename?: 'BlogCountAggregate';
  _all: Scalars['Int'];
  blogCategoryId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  photo: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type BlogCountOrderByAggregateInput = {
  blogCategoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  photo?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BlogCreateInput = {
  category?: InputMaybe<BlogCategoryCreateNestedOneWithoutBlogInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutBlogInput>;
  id?: InputMaybe<Scalars['String']>;
  photo: Scalars['String'];
  seos?: InputMaybe<SeoCreateNestedManyWithoutBlogInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BlogCreateManyCategoryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  photo: Scalars['String'];
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BlogCreateManyCategoryInputEnvelope = {
  data: Array<BlogCreateManyCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BlogCreateManyInput = {
  blogCategoryId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  photo: Scalars['String'];
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BlogCreateNestedManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<BlogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BlogCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<BlogCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<BlogCreateManyCategoryInputEnvelope>;
};

export type BlogCreateNestedOneWithoutDescriptionsInput = {
  connect?: InputMaybe<BlogWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BlogCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<BlogCreateWithoutDescriptionsInput>;
};

export type BlogCreateNestedOneWithoutSeosInput = {
  connect?: InputMaybe<BlogWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BlogCreateOrConnectWithoutSeosInput>;
  create?: InputMaybe<BlogCreateWithoutSeosInput>;
};

export type BlogCreateOrConnectWithoutCategoryInput = {
  create: BlogCreateWithoutCategoryInput;
  where: BlogWhereUniqueInput;
};

export type BlogCreateOrConnectWithoutDescriptionsInput = {
  create: BlogCreateWithoutDescriptionsInput;
  where: BlogWhereUniqueInput;
};

export type BlogCreateOrConnectWithoutSeosInput = {
  create: BlogCreateWithoutSeosInput;
  where: BlogWhereUniqueInput;
};

export type BlogCreateWithoutCategoryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutBlogInput>;
  id?: InputMaybe<Scalars['String']>;
  photo: Scalars['String'];
  seos?: InputMaybe<SeoCreateNestedManyWithoutBlogInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BlogCreateWithoutDescriptionsInput = {
  category?: InputMaybe<BlogCategoryCreateNestedOneWithoutBlogInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  photo: Scalars['String'];
  seos?: InputMaybe<SeoCreateNestedManyWithoutBlogInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BlogCreateWithoutSeosInput = {
  category?: InputMaybe<BlogCategoryCreateNestedOneWithoutBlogInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutBlogInput>;
  id?: InputMaybe<Scalars['String']>;
  photo: Scalars['String'];
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BlogGroupBy = {
  __typename?: 'BlogGroupBy';
  _count?: Maybe<BlogCountAggregate>;
  _max?: Maybe<BlogMaxAggregate>;
  _min?: Maybe<BlogMinAggregate>;
  blogCategoryId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  photo: Scalars['String'];
  status: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type BlogListRelationFilter = {
  every?: InputMaybe<BlogWhereInput>;
  none?: InputMaybe<BlogWhereInput>;
  some?: InputMaybe<BlogWhereInput>;
};

export type BlogMaxAggregate = {
  __typename?: 'BlogMaxAggregate';
  blogCategoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BlogMaxOrderByAggregateInput = {
  blogCategoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  photo?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BlogMinAggregate = {
  __typename?: 'BlogMinAggregate';
  blogCategoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BlogMinOrderByAggregateInput = {
  blogCategoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  photo?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BlogOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BlogOrderByWithAggregationInput = {
  _count?: InputMaybe<BlogCountOrderByAggregateInput>;
  _max?: InputMaybe<BlogMaxOrderByAggregateInput>;
  _min?: InputMaybe<BlogMinOrderByAggregateInput>;
  blogCategoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  photo?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BlogOrderByWithRelationInput = {
  blogCategoryId?: InputMaybe<SortOrder>;
  category?: InputMaybe<BlogCategoryOrderByWithRelationInput>;
  createdAt?: InputMaybe<SortOrder>;
  descriptions?: InputMaybe<DescriptionOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  photo?: InputMaybe<SortOrder>;
  seos?: InputMaybe<SeoOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BlogRelationFilter = {
  is?: InputMaybe<BlogWhereInput>;
  isNot?: InputMaybe<BlogWhereInput>;
};

export enum BlogScalarFieldEnum {
  blogCategoryId = 'blogCategoryId',
  createdAt = 'createdAt',
  id = 'id',
  photo = 'photo',
  status = 'status',
  updatedAt = 'updatedAt'
}

export type BlogScalarWhereInput = {
  AND?: InputMaybe<Array<BlogScalarWhereInput>>;
  NOT?: InputMaybe<Array<BlogScalarWhereInput>>;
  OR?: InputMaybe<Array<BlogScalarWhereInput>>;
  blogCategoryId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  photo?: InputMaybe<StringFilter>;
  status?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BlogScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<BlogScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<BlogScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<BlogScalarWhereWithAggregatesInput>>;
  blogCategoryId?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  photo?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<BoolWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type BlogUpdateInput = {
  category?: InputMaybe<BlogCategoryUpdateOneWithoutBlogNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutBlogNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  photo?: InputMaybe<StringFieldUpdateOperationsInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutBlogNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BlogUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  photo?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BlogUpdateManyWithWhereWithoutCategoryInput = {
  data: BlogUpdateManyMutationInput;
  where: BlogScalarWhereInput;
};

export type BlogUpdateManyWithoutCategoryNestedInput = {
  connect?: InputMaybe<Array<BlogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BlogCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<BlogCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<BlogCreateManyCategoryInputEnvelope>;
  delete?: InputMaybe<Array<BlogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BlogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BlogWhereUniqueInput>>;
  set?: InputMaybe<Array<BlogWhereUniqueInput>>;
  update?: InputMaybe<Array<BlogUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: InputMaybe<Array<BlogUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: InputMaybe<Array<BlogUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type BlogUpdateOneWithoutDescriptionsNestedInput = {
  connect?: InputMaybe<BlogWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BlogCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<BlogCreateWithoutDescriptionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<BlogUpdateWithoutDescriptionsInput>;
  upsert?: InputMaybe<BlogUpsertWithoutDescriptionsInput>;
};

export type BlogUpdateOneWithoutSeosNestedInput = {
  connect?: InputMaybe<BlogWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BlogCreateOrConnectWithoutSeosInput>;
  create?: InputMaybe<BlogCreateWithoutSeosInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<BlogUpdateWithoutSeosInput>;
  upsert?: InputMaybe<BlogUpsertWithoutSeosInput>;
};

export type BlogUpdateWithWhereUniqueWithoutCategoryInput = {
  data: BlogUpdateWithoutCategoryInput;
  where: BlogWhereUniqueInput;
};

export type BlogUpdateWithoutCategoryInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutBlogNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  photo?: InputMaybe<StringFieldUpdateOperationsInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutBlogNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BlogUpdateWithoutDescriptionsInput = {
  category?: InputMaybe<BlogCategoryUpdateOneWithoutBlogNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  photo?: InputMaybe<StringFieldUpdateOperationsInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutBlogNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BlogUpdateWithoutSeosInput = {
  category?: InputMaybe<BlogCategoryUpdateOneWithoutBlogNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutBlogNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  photo?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BlogUpsertWithWhereUniqueWithoutCategoryInput = {
  create: BlogCreateWithoutCategoryInput;
  update: BlogUpdateWithoutCategoryInput;
  where: BlogWhereUniqueInput;
};

export type BlogUpsertWithoutDescriptionsInput = {
  create: BlogCreateWithoutDescriptionsInput;
  update: BlogUpdateWithoutDescriptionsInput;
};

export type BlogUpsertWithoutSeosInput = {
  create: BlogCreateWithoutSeosInput;
  update: BlogUpdateWithoutSeosInput;
};

export type BlogWhereInput = {
  AND?: InputMaybe<Array<BlogWhereInput>>;
  NOT?: InputMaybe<Array<BlogWhereInput>>;
  OR?: InputMaybe<Array<BlogWhereInput>>;
  blogCategoryId?: InputMaybe<StringNullableFilter>;
  category?: InputMaybe<BlogCategoryRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  descriptions?: InputMaybe<DescriptionListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  photo?: InputMaybe<StringFilter>;
  seos?: InputMaybe<SeoListRelationFilter>;
  status?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BlogWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type Category = {
  __typename?: 'Category';
  _count?: Maybe<CategoryCount>;
  createdAt: Scalars['DateTime'];
  descriptions: Array<Description>;
  discountAmount: Scalars['Decimal'];
  id: Scalars['String'];
  order: Scalars['Int'];
  parentCategory?: Maybe<Category>;
  parentCategoryId?: Maybe<Scalars['String']>;
  photos: Array<Scalars['String']>;
  products: Array<Product>;
  seos: Array<Seo>;
  status: Scalars['Boolean'];
  subCategories: Array<Category>;
};


export type CategoryDescriptionsArgs = {
  cursor?: InputMaybe<DescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<DescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};


export type CategoryProductsArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type CategorySeosArgs = {
  cursor?: InputMaybe<SeoWhereUniqueInput>;
  distinct?: InputMaybe<Array<SeoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SeoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SeoWhereInput>;
};


export type CategorySubCategoriesArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryAvgAggregate = {
  __typename?: 'CategoryAvgAggregate';
  discountAmount?: Maybe<Scalars['Decimal']>;
  order?: Maybe<Scalars['Float']>;
};

export type CategoryAvgOrderByAggregateInput = {
  discountAmount?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
};

export type CategoryCount = {
  __typename?: 'CategoryCount';
  descriptions: Scalars['Int'];
  products: Scalars['Int'];
  seos: Scalars['Int'];
  subCategories: Scalars['Int'];
};

export type CategoryCountAggregate = {
  __typename?: 'CategoryCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  discountAmount: Scalars['Int'];
  id: Scalars['Int'];
  order: Scalars['Int'];
  parentCategoryId: Scalars['Int'];
  photos: Scalars['Int'];
  status: Scalars['Int'];
};

export type CategoryCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  discountAmount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  parentCategoryId?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type CategoryCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutCategoryInput>;
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  parentCategory?: InputMaybe<CategoryCreateNestedOneWithoutSubCategoriesInput>;
  photos?: InputMaybe<CategoryCreatephotosInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCategoryInput>;
  seos?: InputMaybe<SeoCreateNestedManyWithoutCategoryInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  subCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentCategoryInput>;
};

export type CategoryCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  parentCategoryId?: InputMaybe<Scalars['String']>;
  photos?: InputMaybe<CategoryCreatephotosInput>;
  status?: InputMaybe<Scalars['Boolean']>;
};

export type CategoryCreateManyParentCategoryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<CategoryCreatephotosInput>;
  status?: InputMaybe<Scalars['Boolean']>;
};

export type CategoryCreateManyParentCategoryInputEnvelope = {
  data: Array<CategoryCreateManyParentCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CategoryCreateNestedManyWithoutParentCategoryInput = {
  connect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryCreateOrConnectWithoutParentCategoryInput>>;
  create?: InputMaybe<Array<CategoryCreateWithoutParentCategoryInput>>;
  createMany?: InputMaybe<CategoryCreateManyParentCategoryInputEnvelope>;
};

export type CategoryCreateNestedOneWithoutDescriptionsInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<CategoryCreateWithoutDescriptionsInput>;
};

export type CategoryCreateNestedOneWithoutProductsInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<CategoryCreateWithoutProductsInput>;
};

export type CategoryCreateNestedOneWithoutSeosInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutSeosInput>;
  create?: InputMaybe<CategoryCreateWithoutSeosInput>;
};

export type CategoryCreateNestedOneWithoutSubCategoriesInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutSubCategoriesInput>;
  create?: InputMaybe<CategoryCreateWithoutSubCategoriesInput>;
};

export type CategoryCreateOrConnectWithoutDescriptionsInput = {
  create: CategoryCreateWithoutDescriptionsInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutParentCategoryInput = {
  create: CategoryCreateWithoutParentCategoryInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutProductsInput = {
  create: CategoryCreateWithoutProductsInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutSeosInput = {
  create: CategoryCreateWithoutSeosInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutSubCategoriesInput = {
  create: CategoryCreateWithoutSubCategoriesInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateWithoutDescriptionsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  parentCategory?: InputMaybe<CategoryCreateNestedOneWithoutSubCategoriesInput>;
  photos?: InputMaybe<CategoryCreatephotosInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCategoryInput>;
  seos?: InputMaybe<SeoCreateNestedManyWithoutCategoryInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  subCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentCategoryInput>;
};

export type CategoryCreateWithoutParentCategoryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutCategoryInput>;
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<CategoryCreatephotosInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCategoryInput>;
  seos?: InputMaybe<SeoCreateNestedManyWithoutCategoryInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  subCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentCategoryInput>;
};

export type CategoryCreateWithoutProductsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutCategoryInput>;
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  parentCategory?: InputMaybe<CategoryCreateNestedOneWithoutSubCategoriesInput>;
  photos?: InputMaybe<CategoryCreatephotosInput>;
  seos?: InputMaybe<SeoCreateNestedManyWithoutCategoryInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  subCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentCategoryInput>;
};

export type CategoryCreateWithoutSeosInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutCategoryInput>;
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  parentCategory?: InputMaybe<CategoryCreateNestedOneWithoutSubCategoriesInput>;
  photos?: InputMaybe<CategoryCreatephotosInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCategoryInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  subCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentCategoryInput>;
};

export type CategoryCreateWithoutSubCategoriesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutCategoryInput>;
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  parentCategory?: InputMaybe<CategoryCreateNestedOneWithoutSubCategoriesInput>;
  photos?: InputMaybe<CategoryCreatephotosInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCategoryInput>;
  seos?: InputMaybe<SeoCreateNestedManyWithoutCategoryInput>;
  status?: InputMaybe<Scalars['Boolean']>;
};

export type CategoryCreatephotosInput = {
  set: Array<Scalars['String']>;
};

export type CategoryGroupBy = {
  __typename?: 'CategoryGroupBy';
  _avg?: Maybe<CategoryAvgAggregate>;
  _count?: Maybe<CategoryCountAggregate>;
  _max?: Maybe<CategoryMaxAggregate>;
  _min?: Maybe<CategoryMinAggregate>;
  _sum?: Maybe<CategorySumAggregate>;
  createdAt: Scalars['DateTime'];
  discountAmount: Scalars['Decimal'];
  id: Scalars['String'];
  order: Scalars['Int'];
  parentCategoryId?: Maybe<Scalars['String']>;
  photos?: Maybe<Array<Scalars['String']>>;
  status: Scalars['Boolean'];
};

export type CategoryListRelationFilter = {
  every?: InputMaybe<CategoryWhereInput>;
  none?: InputMaybe<CategoryWhereInput>;
  some?: InputMaybe<CategoryWhereInput>;
};

export type CategoryMaxAggregate = {
  __typename?: 'CategoryMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  discountAmount?: Maybe<Scalars['Decimal']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  parentCategoryId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
};

export type CategoryMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  discountAmount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  parentCategoryId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type CategoryMinAggregate = {
  __typename?: 'CategoryMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  discountAmount?: Maybe<Scalars['Decimal']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  parentCategoryId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
};

export type CategoryMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  discountAmount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  parentCategoryId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type CategoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CategoryOrderByWithAggregationInput = {
  _avg?: InputMaybe<CategoryAvgOrderByAggregateInput>;
  _count?: InputMaybe<CategoryCountOrderByAggregateInput>;
  _max?: InputMaybe<CategoryMaxOrderByAggregateInput>;
  _min?: InputMaybe<CategoryMinOrderByAggregateInput>;
  _sum?: InputMaybe<CategorySumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  discountAmount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  parentCategoryId?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type CategoryOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  descriptions?: InputMaybe<DescriptionOrderByRelationAggregateInput>;
  discountAmount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  parentCategory?: InputMaybe<CategoryOrderByWithRelationInput>;
  parentCategoryId?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  products?: InputMaybe<ProductOrderByRelationAggregateInput>;
  seos?: InputMaybe<SeoOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  subCategories?: InputMaybe<CategoryOrderByRelationAggregateInput>;
};

export type CategoryRelationFilter = {
  is?: InputMaybe<CategoryWhereInput>;
  isNot?: InputMaybe<CategoryWhereInput>;
};

export enum CategoryScalarFieldEnum {
  createdAt = 'createdAt',
  discountAmount = 'discountAmount',
  id = 'id',
  order = 'order',
  parentCategoryId = 'parentCategoryId',
  photos = 'photos',
  status = 'status'
}

export type CategoryScalarWhereInput = {
  AND?: InputMaybe<Array<CategoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<CategoryScalarWhereInput>>;
  OR?: InputMaybe<Array<CategoryScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  discountAmount?: InputMaybe<DecimalFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  parentCategoryId?: InputMaybe<StringNullableFilter>;
  photos?: InputMaybe<StringNullableListFilter>;
  status?: InputMaybe<BoolFilter>;
};

export type CategoryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CategoryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CategoryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CategoryScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  discountAmount?: InputMaybe<DecimalWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  order?: InputMaybe<IntWithAggregatesFilter>;
  parentCategoryId?: InputMaybe<StringNullableWithAggregatesFilter>;
  photos?: InputMaybe<StringNullableListFilter>;
  status?: InputMaybe<BoolWithAggregatesFilter>;
};

export type CategorySumAggregate = {
  __typename?: 'CategorySumAggregate';
  discountAmount?: Maybe<Scalars['Decimal']>;
  order?: Maybe<Scalars['Int']>;
};

export type CategorySumOrderByAggregateInput = {
  discountAmount?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
};

export type CategoryUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutCategoryNestedInput>;
  discountAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  parentCategory?: InputMaybe<CategoryUpdateOneWithoutSubCategoriesNestedInput>;
  photos?: InputMaybe<CategoryUpdatephotosInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCategoryNestedInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutCategoryNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subCategories?: InputMaybe<CategoryUpdateManyWithoutParentCategoryNestedInput>;
};

export type CategoryUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  discountAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<CategoryUpdatephotosInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CategoryUpdateManyWithWhereWithoutParentCategoryInput = {
  data: CategoryUpdateManyMutationInput;
  where: CategoryScalarWhereInput;
};

export type CategoryUpdateManyWithoutParentCategoryNestedInput = {
  connect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryCreateOrConnectWithoutParentCategoryInput>>;
  create?: InputMaybe<Array<CategoryCreateWithoutParentCategoryInput>>;
  createMany?: InputMaybe<CategoryCreateManyParentCategoryInputEnvelope>;
  delete?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  set?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<CategoryUpdateWithWhereUniqueWithoutParentCategoryInput>>;
  updateMany?: InputMaybe<Array<CategoryUpdateManyWithWhereWithoutParentCategoryInput>>;
  upsert?: InputMaybe<Array<CategoryUpsertWithWhereUniqueWithoutParentCategoryInput>>;
};

export type CategoryUpdateOneRequiredWithoutProductsNestedInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<CategoryCreateWithoutProductsInput>;
  update?: InputMaybe<CategoryUpdateWithoutProductsInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutProductsInput>;
};

export type CategoryUpdateOneWithoutDescriptionsNestedInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<CategoryCreateWithoutDescriptionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CategoryUpdateWithoutDescriptionsInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutDescriptionsInput>;
};

export type CategoryUpdateOneWithoutSeosNestedInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutSeosInput>;
  create?: InputMaybe<CategoryCreateWithoutSeosInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CategoryUpdateWithoutSeosInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutSeosInput>;
};

export type CategoryUpdateOneWithoutSubCategoriesNestedInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutSubCategoriesInput>;
  create?: InputMaybe<CategoryCreateWithoutSubCategoriesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CategoryUpdateWithoutSubCategoriesInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutSubCategoriesInput>;
};

export type CategoryUpdateWithWhereUniqueWithoutParentCategoryInput = {
  data: CategoryUpdateWithoutParentCategoryInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryUpdateWithoutDescriptionsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  discountAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  parentCategory?: InputMaybe<CategoryUpdateOneWithoutSubCategoriesNestedInput>;
  photos?: InputMaybe<CategoryUpdatephotosInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCategoryNestedInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutCategoryNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subCategories?: InputMaybe<CategoryUpdateManyWithoutParentCategoryNestedInput>;
};

export type CategoryUpdateWithoutParentCategoryInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutCategoryNestedInput>;
  discountAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<CategoryUpdatephotosInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCategoryNestedInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutCategoryNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subCategories?: InputMaybe<CategoryUpdateManyWithoutParentCategoryNestedInput>;
};

export type CategoryUpdateWithoutProductsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutCategoryNestedInput>;
  discountAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  parentCategory?: InputMaybe<CategoryUpdateOneWithoutSubCategoriesNestedInput>;
  photos?: InputMaybe<CategoryUpdatephotosInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutCategoryNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subCategories?: InputMaybe<CategoryUpdateManyWithoutParentCategoryNestedInput>;
};

export type CategoryUpdateWithoutSeosInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutCategoryNestedInput>;
  discountAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  parentCategory?: InputMaybe<CategoryUpdateOneWithoutSubCategoriesNestedInput>;
  photos?: InputMaybe<CategoryUpdatephotosInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCategoryNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  subCategories?: InputMaybe<CategoryUpdateManyWithoutParentCategoryNestedInput>;
};

export type CategoryUpdateWithoutSubCategoriesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutCategoryNestedInput>;
  discountAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  parentCategory?: InputMaybe<CategoryUpdateOneWithoutSubCategoriesNestedInput>;
  photos?: InputMaybe<CategoryUpdatephotosInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCategoryNestedInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutCategoryNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CategoryUpdatephotosInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CategoryUpsertWithWhereUniqueWithoutParentCategoryInput = {
  create: CategoryCreateWithoutParentCategoryInput;
  update: CategoryUpdateWithoutParentCategoryInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryUpsertWithoutDescriptionsInput = {
  create: CategoryCreateWithoutDescriptionsInput;
  update: CategoryUpdateWithoutDescriptionsInput;
};

export type CategoryUpsertWithoutProductsInput = {
  create: CategoryCreateWithoutProductsInput;
  update: CategoryUpdateWithoutProductsInput;
};

export type CategoryUpsertWithoutSeosInput = {
  create: CategoryCreateWithoutSeosInput;
  update: CategoryUpdateWithoutSeosInput;
};

export type CategoryUpsertWithoutSubCategoriesInput = {
  create: CategoryCreateWithoutSubCategoriesInput;
  update: CategoryUpdateWithoutSubCategoriesInput;
};

export type CategoryWhereInput = {
  AND?: InputMaybe<Array<CategoryWhereInput>>;
  NOT?: InputMaybe<Array<CategoryWhereInput>>;
  OR?: InputMaybe<Array<CategoryWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  descriptions?: InputMaybe<DescriptionListRelationFilter>;
  discountAmount?: InputMaybe<DecimalFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  parentCategory?: InputMaybe<CategoryRelationFilter>;
  parentCategoryId?: InputMaybe<StringNullableFilter>;
  photos?: InputMaybe<StringNullableListFilter>;
  products?: InputMaybe<ProductListRelationFilter>;
  seos?: InputMaybe<SeoListRelationFilter>;
  status?: InputMaybe<BoolFilter>;
  subCategories?: InputMaybe<CategoryListRelationFilter>;
};

export type CategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type CountriesPayload = {
  __typename?: 'CountriesPayload';
  count: Scalars['Int'];
  items: Array<Country>;
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['String'];
  label: Scalars['String'];
  shippingPrice: Scalars['Decimal'];
  tax?: Maybe<Tax>;
  value: Scalars['String'];
};

export type CountryCreateNestedOneWithoutTaxInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutTaxInput>;
  create?: InputMaybe<CountryCreateWithoutTaxInput>;
};

export type CountryCreateOrConnectWithoutTaxInput = {
  create: CountryCreateWithoutTaxInput;
  where: CountryWhereUniqueInput;
};

export type CountryCreateWithoutTaxInput = {
  id?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  shippingPrice?: InputMaybe<Scalars['Decimal']>;
  value: Scalars['String'];
};

export type CountryOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  shippingPrice?: InputMaybe<SortOrder>;
  tax?: InputMaybe<TaxOrderByWithRelationInput>;
  value?: InputMaybe<SortOrder>;
};

export type CountryRelationFilter = {
  is?: InputMaybe<CountryWhereInput>;
  isNot?: InputMaybe<CountryWhereInput>;
};

export type CountryUpdateInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<StringFieldUpdateOperationsInput>;
  shippingPrice?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  tax?: InputMaybe<TaxUpdateOneWithoutCountryNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CountryUpdateOneRequiredWithoutTaxNestedInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutTaxInput>;
  create?: InputMaybe<CountryCreateWithoutTaxInput>;
  update?: InputMaybe<CountryUpdateWithoutTaxInput>;
  upsert?: InputMaybe<CountryUpsertWithoutTaxInput>;
};

export type CountryUpdateWithoutTaxInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<StringFieldUpdateOperationsInput>;
  shippingPrice?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CountryUpsertWithoutTaxInput = {
  create: CountryCreateWithoutTaxInput;
  update: CountryUpdateWithoutTaxInput;
};

export type CountryWhereInput = {
  AND?: InputMaybe<Array<CountryWhereInput>>;
  NOT?: InputMaybe<Array<CountryWhereInput>>;
  OR?: InputMaybe<Array<CountryWhereInput>>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  shippingPrice?: InputMaybe<DecimalFilter>;
  tax?: InputMaybe<TaxRelationFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CountryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Creatable = {
  __typename?: 'Creatable';
  ProductBasketCreatable: Array<ProductBasketCreatable>;
  _count?: Maybe<CreatableCount>;
  createdAt: Scalars['DateTime'];
  descriptions: Array<Description>;
  id: Scalars['String'];
  operation: CreatableOperationType;
  optionalValue?: Maybe<Scalars['Decimal']>;
  productCreatables: Array<ProductCreatable>;
  type: CreatableType;
  value: Scalars['Decimal'];
};


export type CreatableProductBasketCreatableArgs = {
  cursor?: InputMaybe<ProductBasketCreatableWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductBasketCreatableScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductBasketCreatableOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductBasketCreatableWhereInput>;
};


export type CreatableDescriptionsArgs = {
  cursor?: InputMaybe<DescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<DescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};


export type CreatableProductCreatablesArgs = {
  cursor?: InputMaybe<ProductCreatableWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductCreatableScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductCreatableOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductCreatableWhereInput>;
};

export type CreatableAvgAggregate = {
  __typename?: 'CreatableAvgAggregate';
  optionalValue?: Maybe<Scalars['Decimal']>;
  value?: Maybe<Scalars['Decimal']>;
};

export type CreatableAvgOrderByAggregateInput = {
  optionalValue?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type CreatableCount = {
  __typename?: 'CreatableCount';
  ProductBasketCreatable: Scalars['Int'];
  descriptions: Scalars['Int'];
  productCreatables: Scalars['Int'];
};

export type CreatableCountAggregate = {
  __typename?: 'CreatableCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  operation: Scalars['Int'];
  optionalValue: Scalars['Int'];
  type: Scalars['Int'];
  value: Scalars['Int'];
};

export type CreatableCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  operation?: InputMaybe<SortOrder>;
  optionalValue?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type CreatableCreateInput = {
  ProductBasketCreatable?: InputMaybe<ProductBasketCreatableCreateNestedManyWithoutCreatableInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutCreatableInput>;
  id?: InputMaybe<Scalars['String']>;
  operation: CreatableOperationType;
  optionalValue?: InputMaybe<Scalars['Decimal']>;
  productCreatables?: InputMaybe<ProductCreatableCreateNestedManyWithoutCreatableInput>;
  type: CreatableType;
  value: Scalars['Decimal'];
};

export type CreatableCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  operation: CreatableOperationType;
  optionalValue?: InputMaybe<Scalars['Decimal']>;
  type: CreatableType;
  value: Scalars['Decimal'];
};

export type CreatableCreateNestedOneWithoutDescriptionsInput = {
  connect?: InputMaybe<CreatableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CreatableCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<CreatableCreateWithoutDescriptionsInput>;
};

export type CreatableCreateNestedOneWithoutProductBasketCreatableInput = {
  connect?: InputMaybe<CreatableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CreatableCreateOrConnectWithoutProductBasketCreatableInput>;
  create?: InputMaybe<CreatableCreateWithoutProductBasketCreatableInput>;
};

export type CreatableCreateNestedOneWithoutProductCreatablesInput = {
  connect?: InputMaybe<CreatableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CreatableCreateOrConnectWithoutProductCreatablesInput>;
  create?: InputMaybe<CreatableCreateWithoutProductCreatablesInput>;
};

export type CreatableCreateOrConnectWithoutDescriptionsInput = {
  create: CreatableCreateWithoutDescriptionsInput;
  where: CreatableWhereUniqueInput;
};

export type CreatableCreateOrConnectWithoutProductBasketCreatableInput = {
  create: CreatableCreateWithoutProductBasketCreatableInput;
  where: CreatableWhereUniqueInput;
};

export type CreatableCreateOrConnectWithoutProductCreatablesInput = {
  create: CreatableCreateWithoutProductCreatablesInput;
  where: CreatableWhereUniqueInput;
};

export type CreatableCreateWithoutDescriptionsInput = {
  ProductBasketCreatable?: InputMaybe<ProductBasketCreatableCreateNestedManyWithoutCreatableInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  operation: CreatableOperationType;
  optionalValue?: InputMaybe<Scalars['Decimal']>;
  productCreatables?: InputMaybe<ProductCreatableCreateNestedManyWithoutCreatableInput>;
  type: CreatableType;
  value: Scalars['Decimal'];
};

export type CreatableCreateWithoutProductBasketCreatableInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutCreatableInput>;
  id?: InputMaybe<Scalars['String']>;
  operation: CreatableOperationType;
  optionalValue?: InputMaybe<Scalars['Decimal']>;
  productCreatables?: InputMaybe<ProductCreatableCreateNestedManyWithoutCreatableInput>;
  type: CreatableType;
  value: Scalars['Decimal'];
};

export type CreatableCreateWithoutProductCreatablesInput = {
  ProductBasketCreatable?: InputMaybe<ProductBasketCreatableCreateNestedManyWithoutCreatableInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutCreatableInput>;
  id?: InputMaybe<Scalars['String']>;
  operation: CreatableOperationType;
  optionalValue?: InputMaybe<Scalars['Decimal']>;
  type: CreatableType;
  value: Scalars['Decimal'];
};

export type CreatableGroupBy = {
  __typename?: 'CreatableGroupBy';
  _avg?: Maybe<CreatableAvgAggregate>;
  _count?: Maybe<CreatableCountAggregate>;
  _max?: Maybe<CreatableMaxAggregate>;
  _min?: Maybe<CreatableMinAggregate>;
  _sum?: Maybe<CreatableSumAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  operation: CreatableOperationType;
  optionalValue?: Maybe<Scalars['Decimal']>;
  type: CreatableType;
  value: Scalars['Decimal'];
};

export type CreatableMaxAggregate = {
  __typename?: 'CreatableMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  operation?: Maybe<CreatableOperationType>;
  optionalValue?: Maybe<Scalars['Decimal']>;
  type?: Maybe<CreatableType>;
  value?: Maybe<Scalars['Decimal']>;
};

export type CreatableMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  operation?: InputMaybe<SortOrder>;
  optionalValue?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type CreatableMinAggregate = {
  __typename?: 'CreatableMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  operation?: Maybe<CreatableOperationType>;
  optionalValue?: Maybe<Scalars['Decimal']>;
  type?: Maybe<CreatableType>;
  value?: Maybe<Scalars['Decimal']>;
};

export type CreatableMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  operation?: InputMaybe<SortOrder>;
  optionalValue?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export enum CreatableOperationType {
  ADDITION = 'ADDITION',
  MULTIPLICATION = 'MULTIPLICATION'
}

export type CreatableOrderByWithAggregationInput = {
  _avg?: InputMaybe<CreatableAvgOrderByAggregateInput>;
  _count?: InputMaybe<CreatableCountOrderByAggregateInput>;
  _max?: InputMaybe<CreatableMaxOrderByAggregateInput>;
  _min?: InputMaybe<CreatableMinOrderByAggregateInput>;
  _sum?: InputMaybe<CreatableSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  operation?: InputMaybe<SortOrder>;
  optionalValue?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type CreatableOrderByWithRelationInput = {
  ProductBasketCreatable?: InputMaybe<ProductBasketCreatableOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  descriptions?: InputMaybe<DescriptionOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  operation?: InputMaybe<SortOrder>;
  optionalValue?: InputMaybe<SortOrder>;
  productCreatables?: InputMaybe<ProductCreatableOrderByRelationAggregateInput>;
  type?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type CreatableRelationFilter = {
  is?: InputMaybe<CreatableWhereInput>;
  isNot?: InputMaybe<CreatableWhereInput>;
};

export enum CreatableScalarFieldEnum {
  createdAt = 'createdAt',
  id = 'id',
  operation = 'operation',
  optionalValue = 'optionalValue',
  type = 'type',
  value = 'value'
}

export type CreatableScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CreatableScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CreatableScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CreatableScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  operation?: InputMaybe<EnumCreatableOperationTypeWithAggregatesFilter>;
  optionalValue?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  type?: InputMaybe<EnumCreatableTypeWithAggregatesFilter>;
  value?: InputMaybe<DecimalWithAggregatesFilter>;
};

export type CreatableSumAggregate = {
  __typename?: 'CreatableSumAggregate';
  optionalValue?: Maybe<Scalars['Decimal']>;
  value?: Maybe<Scalars['Decimal']>;
};

export type CreatableSumOrderByAggregateInput = {
  optionalValue?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export enum CreatableType {
  LABOR = 'LABOR',
  MATERIAL = 'MATERIAL'
}

export type CreatableUpdateInput = {
  ProductBasketCreatable?: InputMaybe<ProductBasketCreatableUpdateManyWithoutCreatableNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutCreatableNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  operation?: InputMaybe<EnumCreatableOperationTypeFieldUpdateOperationsInput>;
  optionalValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  productCreatables?: InputMaybe<ProductCreatableUpdateManyWithoutCreatableNestedInput>;
  type?: InputMaybe<EnumCreatableTypeFieldUpdateOperationsInput>;
  value?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type CreatableUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  operation?: InputMaybe<EnumCreatableOperationTypeFieldUpdateOperationsInput>;
  optionalValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCreatableTypeFieldUpdateOperationsInput>;
  value?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type CreatableUpdateOneRequiredWithoutProductBasketCreatableNestedInput = {
  connect?: InputMaybe<CreatableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CreatableCreateOrConnectWithoutProductBasketCreatableInput>;
  create?: InputMaybe<CreatableCreateWithoutProductBasketCreatableInput>;
  update?: InputMaybe<CreatableUpdateWithoutProductBasketCreatableInput>;
  upsert?: InputMaybe<CreatableUpsertWithoutProductBasketCreatableInput>;
};

export type CreatableUpdateOneRequiredWithoutProductCreatablesNestedInput = {
  connect?: InputMaybe<CreatableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CreatableCreateOrConnectWithoutProductCreatablesInput>;
  create?: InputMaybe<CreatableCreateWithoutProductCreatablesInput>;
  update?: InputMaybe<CreatableUpdateWithoutProductCreatablesInput>;
  upsert?: InputMaybe<CreatableUpsertWithoutProductCreatablesInput>;
};

export type CreatableUpdateOneWithoutDescriptionsNestedInput = {
  connect?: InputMaybe<CreatableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CreatableCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<CreatableCreateWithoutDescriptionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CreatableUpdateWithoutDescriptionsInput>;
  upsert?: InputMaybe<CreatableUpsertWithoutDescriptionsInput>;
};

export type CreatableUpdateWithoutDescriptionsInput = {
  ProductBasketCreatable?: InputMaybe<ProductBasketCreatableUpdateManyWithoutCreatableNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  operation?: InputMaybe<EnumCreatableOperationTypeFieldUpdateOperationsInput>;
  optionalValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  productCreatables?: InputMaybe<ProductCreatableUpdateManyWithoutCreatableNestedInput>;
  type?: InputMaybe<EnumCreatableTypeFieldUpdateOperationsInput>;
  value?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type CreatableUpdateWithoutProductBasketCreatableInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutCreatableNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  operation?: InputMaybe<EnumCreatableOperationTypeFieldUpdateOperationsInput>;
  optionalValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  productCreatables?: InputMaybe<ProductCreatableUpdateManyWithoutCreatableNestedInput>;
  type?: InputMaybe<EnumCreatableTypeFieldUpdateOperationsInput>;
  value?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type CreatableUpdateWithoutProductCreatablesInput = {
  ProductBasketCreatable?: InputMaybe<ProductBasketCreatableUpdateManyWithoutCreatableNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutCreatableNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  operation?: InputMaybe<EnumCreatableOperationTypeFieldUpdateOperationsInput>;
  optionalValue?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCreatableTypeFieldUpdateOperationsInput>;
  value?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type CreatableUpsertWithoutDescriptionsInput = {
  create: CreatableCreateWithoutDescriptionsInput;
  update: CreatableUpdateWithoutDescriptionsInput;
};

export type CreatableUpsertWithoutProductBasketCreatableInput = {
  create: CreatableCreateWithoutProductBasketCreatableInput;
  update: CreatableUpdateWithoutProductBasketCreatableInput;
};

export type CreatableUpsertWithoutProductCreatablesInput = {
  create: CreatableCreateWithoutProductCreatablesInput;
  update: CreatableUpdateWithoutProductCreatablesInput;
};

export type CreatableWhereInput = {
  AND?: InputMaybe<Array<CreatableWhereInput>>;
  NOT?: InputMaybe<Array<CreatableWhereInput>>;
  OR?: InputMaybe<Array<CreatableWhereInput>>;
  ProductBasketCreatable?: InputMaybe<ProductBasketCreatableListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  descriptions?: InputMaybe<DescriptionListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  operation?: InputMaybe<EnumCreatableOperationTypeFilter>;
  optionalValue?: InputMaybe<DecimalNullableFilter>;
  productCreatables?: InputMaybe<ProductCreatableListRelationFilter>;
  type?: InputMaybe<EnumCreatableTypeFilter>;
  value?: InputMaybe<DecimalFilter>;
};

export type CreatableWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type CreateCheckoutFormInput = {
  cartId: Scalars['String'];
  couponCode?: InputMaybe<Scalars['String']>;
  identityNumber: Scalars['String'];
  locale: Scalars['String'];
  paymentAddress: Scalars['String'];
  paymentAddressId: Scalars['String'];
  paymentCity: Scalars['String'];
  paymentCountry: Scalars['String'];
  paymentEmail: Scalars['String'];
  paymentFirstName: Scalars['String'];
  paymentLastName: Scalars['String'];
  paymentPhoneNumber: Scalars['String'];
  paymentPostalCode: Scalars['String'];
  shippingAddress: Scalars['String'];
  shippingAddressId: Scalars['String'];
  shippingCity: Scalars['String'];
  shippingCountry: Scalars['String'];
  shippingEmail: Scalars['String'];
  shippingFirstName: Scalars['String'];
  shippingLastName: Scalars['String'];
  shippingPhoneNumber: Scalars['String'];
  shippingPostalCode: Scalars['String'];
};

export enum CustomProductsOrderByFilter {
  AZ_ASC = 'AZ_ASC',
  AZ_DESC = 'AZ_DESC',
  OR_ASC = 'OR_ASC',
  OR_DESC = 'OR_DESC',
  PR_ASC = 'PR_ASC',
  PR_DESC = 'PR_DESC'
}

export type CustomProductsPayload = {
  __typename?: 'CustomProductsPayload';
  count: Scalars['Int'];
  items: Array<ICustomProductsPayload>;
};

export type Customer = {
  __typename?: 'Customer';
  TempProduct: Array<TempProduct>;
  _count?: Maybe<CustomerCount>;
  address?: Maybe<Scalars['String']>;
  basket?: Maybe<Basket>;
  city?: Maybe<Scalars['JSON']>;
  country?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  dealerDiscount: Scalars['Int'];
  discountCoupons: Array<DiscountCoupon>;
  email: Scalars['String'];
  emailActivated: Scalars['Boolean'];
  id: Scalars['String'];
  identityNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orders: Array<Order>;
  phoneActivated: Scalars['Boolean'];
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  role: CustomerRole;
  surname: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type CustomerTempProductArgs = {
  cursor?: InputMaybe<TempProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<TempProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TempProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TempProductWhereInput>;
};


export type CustomerDiscountCouponsArgs = {
  cursor?: InputMaybe<DiscountCouponWhereUniqueInput>;
  distinct?: InputMaybe<Array<DiscountCouponScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DiscountCouponOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DiscountCouponWhereInput>;
};


export type CustomerOrdersArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderWhereInput>;
};

export type CustomerAuthPayload = {
  __typename?: 'CustomerAuthPayload';
  /** Basket ID */
  basketId?: Maybe<Scalars['String']>;
  dealerDiscount: Scalars['Int'];
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  role: CustomerRole;
  surname: Scalars['String'];
  token: Scalars['String'];
};

export type CustomerAvgAggregate = {
  __typename?: 'CustomerAvgAggregate';
  dealerDiscount?: Maybe<Scalars['Float']>;
};

export type CustomerAvgOrderByAggregateInput = {
  dealerDiscount?: InputMaybe<SortOrder>;
};

export type CustomerCount = {
  __typename?: 'CustomerCount';
  TempProduct: Scalars['Int'];
  discountCoupons: Scalars['Int'];
  orders: Scalars['Int'];
};

export type CustomerCountAggregate = {
  __typename?: 'CustomerCountAggregate';
  _all: Scalars['Int'];
  address: Scalars['Int'];
  city: Scalars['Int'];
  country: Scalars['Int'];
  createdAt: Scalars['Int'];
  dealerDiscount: Scalars['Int'];
  email: Scalars['Int'];
  emailActivated: Scalars['Int'];
  id: Scalars['Int'];
  identityNumber: Scalars['Int'];
  name: Scalars['Int'];
  password: Scalars['Int'];
  phoneActivated: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  postalCode: Scalars['Int'];
  role: Scalars['Int'];
  surname: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CustomerCountOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dealerDiscount?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  emailActivated?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  identityNumber?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  phoneActivated?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  postalCode?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  surname?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CustomerCreateInput = {
  TempProduct?: InputMaybe<TempProductCreateNestedManyWithoutCustomerInput>;
  address?: InputMaybe<Scalars['String']>;
  basket?: InputMaybe<BasketCreateNestedOneWithoutCustomerInput>;
  city?: InputMaybe<Scalars['JSON']>;
  country?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dealerDiscount?: InputMaybe<Scalars['Int']>;
  discountCoupons?: InputMaybe<DiscountCouponCreateNestedManyWithoutCustomerInput>;
  email: Scalars['String'];
  emailActivated?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  identityNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  password: Scalars['String'];
  phoneActivated?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<CustomerRole>;
  surname: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CustomerCreateManyInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['JSON']>;
  country?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dealerDiscount?: InputMaybe<Scalars['Int']>;
  email: Scalars['String'];
  emailActivated?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  identityNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  password: Scalars['String'];
  phoneActivated?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<CustomerRole>;
  surname: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CustomerCreateNestedOneWithoutBasketInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutBasketInput>;
  create?: InputMaybe<CustomerCreateWithoutBasketInput>;
};

export type CustomerCreateNestedOneWithoutDiscountCouponsInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutDiscountCouponsInput>;
  create?: InputMaybe<CustomerCreateWithoutDiscountCouponsInput>;
};

export type CustomerCreateNestedOneWithoutOrdersInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutOrdersInput>;
  create?: InputMaybe<CustomerCreateWithoutOrdersInput>;
};

export type CustomerCreateOrConnectWithoutBasketInput = {
  create: CustomerCreateWithoutBasketInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutDiscountCouponsInput = {
  create: CustomerCreateWithoutDiscountCouponsInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutOrdersInput = {
  create: CustomerCreateWithoutOrdersInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateWithoutBasketInput = {
  TempProduct?: InputMaybe<TempProductCreateNestedManyWithoutCustomerInput>;
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['JSON']>;
  country?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dealerDiscount?: InputMaybe<Scalars['Int']>;
  discountCoupons?: InputMaybe<DiscountCouponCreateNestedManyWithoutCustomerInput>;
  email: Scalars['String'];
  emailActivated?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  identityNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  password: Scalars['String'];
  phoneActivated?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<CustomerRole>;
  surname: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CustomerCreateWithoutDiscountCouponsInput = {
  TempProduct?: InputMaybe<TempProductCreateNestedManyWithoutCustomerInput>;
  address?: InputMaybe<Scalars['String']>;
  basket?: InputMaybe<BasketCreateNestedOneWithoutCustomerInput>;
  city?: InputMaybe<Scalars['JSON']>;
  country?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dealerDiscount?: InputMaybe<Scalars['Int']>;
  email: Scalars['String'];
  emailActivated?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  identityNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  password: Scalars['String'];
  phoneActivated?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<CustomerRole>;
  surname: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CustomerCreateWithoutOrdersInput = {
  TempProduct?: InputMaybe<TempProductCreateNestedManyWithoutCustomerInput>;
  address?: InputMaybe<Scalars['String']>;
  basket?: InputMaybe<BasketCreateNestedOneWithoutCustomerInput>;
  city?: InputMaybe<Scalars['JSON']>;
  country?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dealerDiscount?: InputMaybe<Scalars['Int']>;
  discountCoupons?: InputMaybe<DiscountCouponCreateNestedManyWithoutCustomerInput>;
  email: Scalars['String'];
  emailActivated?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  identityNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  password: Scalars['String'];
  phoneActivated?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<CustomerRole>;
  surname: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CustomerGroupBy = {
  __typename?: 'CustomerGroupBy';
  _avg?: Maybe<CustomerAvgAggregate>;
  _count?: Maybe<CustomerCountAggregate>;
  _max?: Maybe<CustomerMaxAggregate>;
  _min?: Maybe<CustomerMinAggregate>;
  _sum?: Maybe<CustomerSumAggregate>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['JSON']>;
  country?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  dealerDiscount: Scalars['Int'];
  email: Scalars['String'];
  emailActivated: Scalars['Boolean'];
  id: Scalars['String'];
  identityNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  password: Scalars['String'];
  phoneActivated: Scalars['Boolean'];
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  role: CustomerRole;
  surname: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CustomerMaxAggregate = {
  __typename?: 'CustomerMaxAggregate';
  address?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dealerDiscount?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  emailActivated?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  identityNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phoneActivated?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  role?: Maybe<CustomerRole>;
  surname?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerMaxOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dealerDiscount?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  emailActivated?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  identityNumber?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  phoneActivated?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  postalCode?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  surname?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CustomerMinAggregate = {
  __typename?: 'CustomerMinAggregate';
  address?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dealerDiscount?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  emailActivated?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  identityNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phoneActivated?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  role?: Maybe<CustomerRole>;
  surname?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerMinOrderByAggregateInput = {
  address?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dealerDiscount?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  emailActivated?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  identityNumber?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  phoneActivated?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  postalCode?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  surname?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CustomerOrderByWithAggregationInput = {
  _avg?: InputMaybe<CustomerAvgOrderByAggregateInput>;
  _count?: InputMaybe<CustomerCountOrderByAggregateInput>;
  _max?: InputMaybe<CustomerMaxOrderByAggregateInput>;
  _min?: InputMaybe<CustomerMinOrderByAggregateInput>;
  _sum?: InputMaybe<CustomerSumOrderByAggregateInput>;
  address?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dealerDiscount?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  emailActivated?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  identityNumber?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  phoneActivated?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  postalCode?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  surname?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CustomerOrderByWithRelationInput = {
  TempProduct?: InputMaybe<TempProductOrderByRelationAggregateInput>;
  address?: InputMaybe<SortOrder>;
  basket?: InputMaybe<BasketOrderByWithRelationInput>;
  city?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dealerDiscount?: InputMaybe<SortOrder>;
  discountCoupons?: InputMaybe<DiscountCouponOrderByRelationAggregateInput>;
  email?: InputMaybe<SortOrder>;
  emailActivated?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  identityNumber?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  orders?: InputMaybe<OrderOrderByRelationAggregateInput>;
  password?: InputMaybe<SortOrder>;
  phoneActivated?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  postalCode?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  surname?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CustomerRelationFilter = {
  is?: InputMaybe<CustomerWhereInput>;
  isNot?: InputMaybe<CustomerWhereInput>;
};

export enum CustomerRole {
  DEALER = 'DEALER',
  STANDARD = 'STANDARD'
}

export enum CustomerScalarFieldEnum {
  address = 'address',
  city = 'city',
  country = 'country',
  createdAt = 'createdAt',
  dealerDiscount = 'dealerDiscount',
  email = 'email',
  emailActivated = 'emailActivated',
  id = 'id',
  identityNumber = 'identityNumber',
  name = 'name',
  password = 'password',
  phoneActivated = 'phoneActivated',
  phoneNumber = 'phoneNumber',
  postalCode = 'postalCode',
  role = 'role',
  surname = 'surname',
  updatedAt = 'updatedAt'
}

export type CustomerScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CustomerScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CustomerScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CustomerScalarWhereWithAggregatesInput>>;
  address?: InputMaybe<StringNullableWithAggregatesFilter>;
  city?: InputMaybe<JsonNullableWithAggregatesFilter>;
  country?: InputMaybe<JsonNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  dealerDiscount?: InputMaybe<IntWithAggregatesFilter>;
  email?: InputMaybe<StringWithAggregatesFilter>;
  emailActivated?: InputMaybe<BoolWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  identityNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  password?: InputMaybe<StringWithAggregatesFilter>;
  phoneActivated?: InputMaybe<BoolWithAggregatesFilter>;
  phoneNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  postalCode?: InputMaybe<StringNullableWithAggregatesFilter>;
  role?: InputMaybe<EnumCustomerRoleWithAggregatesFilter>;
  surname?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type CustomerSumAggregate = {
  __typename?: 'CustomerSumAggregate';
  dealerDiscount?: Maybe<Scalars['Int']>;
};

export type CustomerSumOrderByAggregateInput = {
  dealerDiscount?: InputMaybe<SortOrder>;
};

export type CustomerUpdateInput = {
  TempProduct?: InputMaybe<TempProductUpdateManyWithoutCustomerNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  basket?: InputMaybe<BasketUpdateOneWithoutCustomerNestedInput>;
  city?: InputMaybe<Scalars['JSON']>;
  country?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dealerDiscount?: InputMaybe<IntFieldUpdateOperationsInput>;
  discountCoupons?: InputMaybe<DiscountCouponUpdateManyWithoutCustomerNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailActivated?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identityNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneActivated?: InputMaybe<BoolFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumCustomerRoleFieldUpdateOperationsInput>;
  surname?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomerUpdateManyMutationInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<Scalars['JSON']>;
  country?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dealerDiscount?: InputMaybe<IntFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailActivated?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identityNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneActivated?: InputMaybe<BoolFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumCustomerRoleFieldUpdateOperationsInput>;
  surname?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomerUpdateOneRequiredWithoutBasketNestedInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutBasketInput>;
  create?: InputMaybe<CustomerCreateWithoutBasketInput>;
  update?: InputMaybe<CustomerUpdateWithoutBasketInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutBasketInput>;
};

export type CustomerUpdateOneRequiredWithoutOrdersNestedInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutOrdersInput>;
  create?: InputMaybe<CustomerCreateWithoutOrdersInput>;
  update?: InputMaybe<CustomerUpdateWithoutOrdersInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutOrdersInput>;
};

export type CustomerUpdateOneWithoutDiscountCouponsNestedInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutDiscountCouponsInput>;
  create?: InputMaybe<CustomerCreateWithoutDiscountCouponsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CustomerUpdateWithoutDiscountCouponsInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutDiscountCouponsInput>;
};

export type CustomerUpdateWithoutBasketInput = {
  TempProduct?: InputMaybe<TempProductUpdateManyWithoutCustomerNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<Scalars['JSON']>;
  country?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dealerDiscount?: InputMaybe<IntFieldUpdateOperationsInput>;
  discountCoupons?: InputMaybe<DiscountCouponUpdateManyWithoutCustomerNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailActivated?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identityNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneActivated?: InputMaybe<BoolFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumCustomerRoleFieldUpdateOperationsInput>;
  surname?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomerUpdateWithoutDiscountCouponsInput = {
  TempProduct?: InputMaybe<TempProductUpdateManyWithoutCustomerNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  basket?: InputMaybe<BasketUpdateOneWithoutCustomerNestedInput>;
  city?: InputMaybe<Scalars['JSON']>;
  country?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dealerDiscount?: InputMaybe<IntFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailActivated?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identityNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneActivated?: InputMaybe<BoolFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumCustomerRoleFieldUpdateOperationsInput>;
  surname?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomerUpdateWithoutOrdersInput = {
  TempProduct?: InputMaybe<TempProductUpdateManyWithoutCustomerNestedInput>;
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  basket?: InputMaybe<BasketUpdateOneWithoutCustomerNestedInput>;
  city?: InputMaybe<Scalars['JSON']>;
  country?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dealerDiscount?: InputMaybe<IntFieldUpdateOperationsInput>;
  discountCoupons?: InputMaybe<DiscountCouponUpdateManyWithoutCustomerNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailActivated?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identityNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneActivated?: InputMaybe<BoolFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumCustomerRoleFieldUpdateOperationsInput>;
  surname?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomerUpsertWithoutBasketInput = {
  create: CustomerCreateWithoutBasketInput;
  update: CustomerUpdateWithoutBasketInput;
};

export type CustomerUpsertWithoutDiscountCouponsInput = {
  create: CustomerCreateWithoutDiscountCouponsInput;
  update: CustomerUpdateWithoutDiscountCouponsInput;
};

export type CustomerUpsertWithoutOrdersInput = {
  create: CustomerCreateWithoutOrdersInput;
  update: CustomerUpdateWithoutOrdersInput;
};

export type CustomerWhereInput = {
  AND?: InputMaybe<Array<CustomerWhereInput>>;
  NOT?: InputMaybe<Array<CustomerWhereInput>>;
  OR?: InputMaybe<Array<CustomerWhereInput>>;
  TempProduct?: InputMaybe<TempProductListRelationFilter>;
  address?: InputMaybe<StringNullableFilter>;
  basket?: InputMaybe<BasketRelationFilter>;
  city?: InputMaybe<JsonNullableFilter>;
  country?: InputMaybe<JsonNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dealerDiscount?: InputMaybe<IntFilter>;
  discountCoupons?: InputMaybe<DiscountCouponListRelationFilter>;
  email?: InputMaybe<StringFilter>;
  emailActivated?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  identityNumber?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  orders?: InputMaybe<OrderListRelationFilter>;
  password?: InputMaybe<StringFilter>;
  phoneActivated?: InputMaybe<BoolFilter>;
  phoneNumber?: InputMaybe<StringNullableFilter>;
  postalCode?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumCustomerRoleFilter>;
  surname?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CustomerWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type CustomersPayload = {
  __typename?: 'CustomersPayload';
  count: Scalars['Int'];
  items: Array<Customer>;
};

export type DashboardStatisticsPayload = {
  __typename?: 'DashboardStatisticsPayload';
  last4Products?: Maybe<Array<Product>>;
  pendingOrders?: Maybe<Array<Order>>;
  todayEarnings?: Maybe<Scalars['Float']>;
  todayOrderCount?: Maybe<Scalars['Int']>;
  weeklyEarnings?: Maybe<Scalars['Float']>;
  weeklyOrderCount?: Maybe<Scalars['Int']>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DecimalFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Decimal']>;
  divide?: InputMaybe<Scalars['Decimal']>;
  increment?: InputMaybe<Scalars['Decimal']>;
  multiply?: InputMaybe<Scalars['Decimal']>;
  set?: InputMaybe<Scalars['Decimal']>;
};

export type DecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type DecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type DecimalNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDecimalNullableFilter>;
  _min?: InputMaybe<NestedDecimalNullableFilter>;
  _sum?: InputMaybe<NestedDecimalNullableFilter>;
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type DecimalWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDecimalFilter>;
  _min?: InputMaybe<NestedDecimalFilter>;
  _sum?: InputMaybe<NestedDecimalFilter>;
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type Description = {
  __typename?: 'Description';
  Blog?: Maybe<Blog>;
  BlogCategory?: Maybe<BlogCategory>;
  blogCategoryId?: Maybe<Scalars['String']>;
  blogId?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars['String']>;
  creatable?: Maybe<Creatable>;
  creatableId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  discountCoupon?: Maybe<DiscountCoupon>;
  discountCouponId?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  language: LanguageOptions;
  page?: Maybe<Page>;
  pageId?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['String']>;
  ringColor?: Maybe<RingColor>;
  ringColorId?: Maybe<Scalars['String']>;
  slider?: Maybe<Slider>;
  sliderId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  style?: Maybe<Style>;
  styleId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DescriptionCountAggregate = {
  __typename?: 'DescriptionCountAggregate';
  _all: Scalars['Int'];
  blogCategoryId: Scalars['Int'];
  blogId: Scalars['Int'];
  buttonText: Scalars['Int'];
  buttonUrl: Scalars['Int'];
  categoryId: Scalars['Int'];
  creatableId: Scalars['Int'];
  description: Scalars['Int'];
  discountCouponId: Scalars['Int'];
  headline: Scalars['Int'];
  id: Scalars['Int'];
  language: Scalars['Int'];
  pageId: Scalars['Int'];
  productId: Scalars['Int'];
  ringColorId: Scalars['Int'];
  sliderId: Scalars['Int'];
  slug: Scalars['Int'];
  styleId: Scalars['Int'];
  title: Scalars['Int'];
};

export type DescriptionCountOrderByAggregateInput = {
  blogCategoryId?: InputMaybe<SortOrder>;
  blogId?: InputMaybe<SortOrder>;
  buttonText?: InputMaybe<SortOrder>;
  buttonUrl?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  creatableId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  discountCouponId?: InputMaybe<SortOrder>;
  headline?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  pageId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  ringColorId?: InputMaybe<SortOrder>;
  sliderId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  styleId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type DescriptionCreateInput = {
  Blog?: InputMaybe<BlogCreateNestedOneWithoutDescriptionsInput>;
  BlogCategory?: InputMaybe<BlogCategoryCreateNestedOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  creatable?: InputMaybe<CreatableCreateNestedOneWithoutDescriptionsInput>;
  description?: InputMaybe<Scalars['String']>;
  discountCoupon?: InputMaybe<DiscountCouponCreateNestedOneWithoutDescriptionsInput>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  page?: InputMaybe<PageCreateNestedOneWithoutDescriptionsInput>;
  product?: InputMaybe<ProductCreateNestedOneWithoutDescriptionsInput>;
  ringColor?: InputMaybe<RingColorCreateNestedOneWithoutDescriptionsInput>;
  slider?: InputMaybe<SliderCreateNestedOneWithoutDescriptionsInput>;
  slug: Scalars['String'];
  style?: InputMaybe<StyleCreateNestedOneWithoutDescriptionsInput>;
  title: Scalars['String'];
};

export type DescriptionCreateManyBlogCategoryInput = {
  blogId?: InputMaybe<Scalars['String']>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  creatableId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  discountCouponId?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  pageId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  ringColorId?: InputMaybe<Scalars['String']>;
  sliderId?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  styleId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DescriptionCreateManyBlogCategoryInputEnvelope = {
  data: Array<DescriptionCreateManyBlogCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DescriptionCreateManyBlogInput = {
  blogCategoryId?: InputMaybe<Scalars['String']>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  creatableId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  discountCouponId?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  pageId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  ringColorId?: InputMaybe<Scalars['String']>;
  sliderId?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  styleId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DescriptionCreateManyBlogInputEnvelope = {
  data: Array<DescriptionCreateManyBlogInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DescriptionCreateManyCategoryInput = {
  blogCategoryId?: InputMaybe<Scalars['String']>;
  blogId?: InputMaybe<Scalars['String']>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  creatableId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  discountCouponId?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  pageId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  ringColorId?: InputMaybe<Scalars['String']>;
  sliderId?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  styleId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DescriptionCreateManyCategoryInputEnvelope = {
  data: Array<DescriptionCreateManyCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DescriptionCreateManyCreatableInput = {
  blogCategoryId?: InputMaybe<Scalars['String']>;
  blogId?: InputMaybe<Scalars['String']>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  discountCouponId?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  pageId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  ringColorId?: InputMaybe<Scalars['String']>;
  sliderId?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  styleId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DescriptionCreateManyCreatableInputEnvelope = {
  data: Array<DescriptionCreateManyCreatableInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DescriptionCreateManyDiscountCouponInput = {
  blogCategoryId?: InputMaybe<Scalars['String']>;
  blogId?: InputMaybe<Scalars['String']>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  creatableId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  pageId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  ringColorId?: InputMaybe<Scalars['String']>;
  sliderId?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  styleId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DescriptionCreateManyDiscountCouponInputEnvelope = {
  data: Array<DescriptionCreateManyDiscountCouponInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DescriptionCreateManyInput = {
  blogCategoryId?: InputMaybe<Scalars['String']>;
  blogId?: InputMaybe<Scalars['String']>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  creatableId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  discountCouponId?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  pageId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  ringColorId?: InputMaybe<Scalars['String']>;
  sliderId?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  styleId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DescriptionCreateManyPageInput = {
  blogCategoryId?: InputMaybe<Scalars['String']>;
  blogId?: InputMaybe<Scalars['String']>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  creatableId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  discountCouponId?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  productId?: InputMaybe<Scalars['String']>;
  ringColorId?: InputMaybe<Scalars['String']>;
  sliderId?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  styleId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DescriptionCreateManyPageInputEnvelope = {
  data: Array<DescriptionCreateManyPageInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DescriptionCreateManyProductInput = {
  blogCategoryId?: InputMaybe<Scalars['String']>;
  blogId?: InputMaybe<Scalars['String']>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  creatableId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  discountCouponId?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  pageId?: InputMaybe<Scalars['String']>;
  ringColorId?: InputMaybe<Scalars['String']>;
  sliderId?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  styleId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DescriptionCreateManyProductInputEnvelope = {
  data: Array<DescriptionCreateManyProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DescriptionCreateManyRingColorInput = {
  blogCategoryId?: InputMaybe<Scalars['String']>;
  blogId?: InputMaybe<Scalars['String']>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  creatableId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  discountCouponId?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  pageId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  sliderId?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  styleId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DescriptionCreateManyRingColorInputEnvelope = {
  data: Array<DescriptionCreateManyRingColorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DescriptionCreateManySliderInput = {
  blogCategoryId?: InputMaybe<Scalars['String']>;
  blogId?: InputMaybe<Scalars['String']>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  creatableId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  discountCouponId?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  pageId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  ringColorId?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  styleId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DescriptionCreateManySliderInputEnvelope = {
  data: Array<DescriptionCreateManySliderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DescriptionCreateManyStyleInput = {
  blogCategoryId?: InputMaybe<Scalars['String']>;
  blogId?: InputMaybe<Scalars['String']>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  creatableId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  discountCouponId?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  pageId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  ringColorId?: InputMaybe<Scalars['String']>;
  sliderId?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type DescriptionCreateManyStyleInputEnvelope = {
  data: Array<DescriptionCreateManyStyleInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DescriptionCreateNestedManyWithoutBlogCategoryInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutBlogCategoryInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutBlogCategoryInput>>;
  createMany?: InputMaybe<DescriptionCreateManyBlogCategoryInputEnvelope>;
};

export type DescriptionCreateNestedManyWithoutBlogInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutBlogInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutBlogInput>>;
  createMany?: InputMaybe<DescriptionCreateManyBlogInputEnvelope>;
};

export type DescriptionCreateNestedManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<DescriptionCreateManyCategoryInputEnvelope>;
};

export type DescriptionCreateNestedManyWithoutCreatableInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutCreatableInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutCreatableInput>>;
  createMany?: InputMaybe<DescriptionCreateManyCreatableInputEnvelope>;
};

export type DescriptionCreateNestedManyWithoutDiscountCouponInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutDiscountCouponInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutDiscountCouponInput>>;
  createMany?: InputMaybe<DescriptionCreateManyDiscountCouponInputEnvelope>;
};

export type DescriptionCreateNestedManyWithoutPageInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutPageInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutPageInput>>;
  createMany?: InputMaybe<DescriptionCreateManyPageInputEnvelope>;
};

export type DescriptionCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutProductInput>>;
  createMany?: InputMaybe<DescriptionCreateManyProductInputEnvelope>;
};

export type DescriptionCreateNestedManyWithoutRingColorInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutRingColorInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutRingColorInput>>;
  createMany?: InputMaybe<DescriptionCreateManyRingColorInputEnvelope>;
};

export type DescriptionCreateNestedManyWithoutSliderInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutSliderInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutSliderInput>>;
  createMany?: InputMaybe<DescriptionCreateManySliderInputEnvelope>;
};

export type DescriptionCreateNestedManyWithoutStyleInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutStyleInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutStyleInput>>;
  createMany?: InputMaybe<DescriptionCreateManyStyleInputEnvelope>;
};

export type DescriptionCreateOrConnectWithoutBlogCategoryInput = {
  create: DescriptionCreateWithoutBlogCategoryInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionCreateOrConnectWithoutBlogInput = {
  create: DescriptionCreateWithoutBlogInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionCreateOrConnectWithoutCategoryInput = {
  create: DescriptionCreateWithoutCategoryInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionCreateOrConnectWithoutCreatableInput = {
  create: DescriptionCreateWithoutCreatableInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionCreateOrConnectWithoutDiscountCouponInput = {
  create: DescriptionCreateWithoutDiscountCouponInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionCreateOrConnectWithoutPageInput = {
  create: DescriptionCreateWithoutPageInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionCreateOrConnectWithoutProductInput = {
  create: DescriptionCreateWithoutProductInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionCreateOrConnectWithoutRingColorInput = {
  create: DescriptionCreateWithoutRingColorInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionCreateOrConnectWithoutSliderInput = {
  create: DescriptionCreateWithoutSliderInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionCreateOrConnectWithoutStyleInput = {
  create: DescriptionCreateWithoutStyleInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionCreateWithoutBlogCategoryInput = {
  Blog?: InputMaybe<BlogCreateNestedOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  creatable?: InputMaybe<CreatableCreateNestedOneWithoutDescriptionsInput>;
  description?: InputMaybe<Scalars['String']>;
  discountCoupon?: InputMaybe<DiscountCouponCreateNestedOneWithoutDescriptionsInput>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  page?: InputMaybe<PageCreateNestedOneWithoutDescriptionsInput>;
  product?: InputMaybe<ProductCreateNestedOneWithoutDescriptionsInput>;
  ringColor?: InputMaybe<RingColorCreateNestedOneWithoutDescriptionsInput>;
  slider?: InputMaybe<SliderCreateNestedOneWithoutDescriptionsInput>;
  slug: Scalars['String'];
  style?: InputMaybe<StyleCreateNestedOneWithoutDescriptionsInput>;
  title: Scalars['String'];
};

export type DescriptionCreateWithoutBlogInput = {
  BlogCategory?: InputMaybe<BlogCategoryCreateNestedOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  creatable?: InputMaybe<CreatableCreateNestedOneWithoutDescriptionsInput>;
  description?: InputMaybe<Scalars['String']>;
  discountCoupon?: InputMaybe<DiscountCouponCreateNestedOneWithoutDescriptionsInput>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  page?: InputMaybe<PageCreateNestedOneWithoutDescriptionsInput>;
  product?: InputMaybe<ProductCreateNestedOneWithoutDescriptionsInput>;
  ringColor?: InputMaybe<RingColorCreateNestedOneWithoutDescriptionsInput>;
  slider?: InputMaybe<SliderCreateNestedOneWithoutDescriptionsInput>;
  slug: Scalars['String'];
  style?: InputMaybe<StyleCreateNestedOneWithoutDescriptionsInput>;
  title: Scalars['String'];
};

export type DescriptionCreateWithoutCategoryInput = {
  Blog?: InputMaybe<BlogCreateNestedOneWithoutDescriptionsInput>;
  BlogCategory?: InputMaybe<BlogCategoryCreateNestedOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  creatable?: InputMaybe<CreatableCreateNestedOneWithoutDescriptionsInput>;
  description?: InputMaybe<Scalars['String']>;
  discountCoupon?: InputMaybe<DiscountCouponCreateNestedOneWithoutDescriptionsInput>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  page?: InputMaybe<PageCreateNestedOneWithoutDescriptionsInput>;
  product?: InputMaybe<ProductCreateNestedOneWithoutDescriptionsInput>;
  ringColor?: InputMaybe<RingColorCreateNestedOneWithoutDescriptionsInput>;
  slider?: InputMaybe<SliderCreateNestedOneWithoutDescriptionsInput>;
  slug: Scalars['String'];
  style?: InputMaybe<StyleCreateNestedOneWithoutDescriptionsInput>;
  title: Scalars['String'];
};

export type DescriptionCreateWithoutCreatableInput = {
  Blog?: InputMaybe<BlogCreateNestedOneWithoutDescriptionsInput>;
  BlogCategory?: InputMaybe<BlogCategoryCreateNestedOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  description?: InputMaybe<Scalars['String']>;
  discountCoupon?: InputMaybe<DiscountCouponCreateNestedOneWithoutDescriptionsInput>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  page?: InputMaybe<PageCreateNestedOneWithoutDescriptionsInput>;
  product?: InputMaybe<ProductCreateNestedOneWithoutDescriptionsInput>;
  ringColor?: InputMaybe<RingColorCreateNestedOneWithoutDescriptionsInput>;
  slider?: InputMaybe<SliderCreateNestedOneWithoutDescriptionsInput>;
  slug: Scalars['String'];
  style?: InputMaybe<StyleCreateNestedOneWithoutDescriptionsInput>;
  title: Scalars['String'];
};

export type DescriptionCreateWithoutDiscountCouponInput = {
  Blog?: InputMaybe<BlogCreateNestedOneWithoutDescriptionsInput>;
  BlogCategory?: InputMaybe<BlogCategoryCreateNestedOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  creatable?: InputMaybe<CreatableCreateNestedOneWithoutDescriptionsInput>;
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  page?: InputMaybe<PageCreateNestedOneWithoutDescriptionsInput>;
  product?: InputMaybe<ProductCreateNestedOneWithoutDescriptionsInput>;
  ringColor?: InputMaybe<RingColorCreateNestedOneWithoutDescriptionsInput>;
  slider?: InputMaybe<SliderCreateNestedOneWithoutDescriptionsInput>;
  slug: Scalars['String'];
  style?: InputMaybe<StyleCreateNestedOneWithoutDescriptionsInput>;
  title: Scalars['String'];
};

export type DescriptionCreateWithoutPageInput = {
  Blog?: InputMaybe<BlogCreateNestedOneWithoutDescriptionsInput>;
  BlogCategory?: InputMaybe<BlogCategoryCreateNestedOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  creatable?: InputMaybe<CreatableCreateNestedOneWithoutDescriptionsInput>;
  description?: InputMaybe<Scalars['String']>;
  discountCoupon?: InputMaybe<DiscountCouponCreateNestedOneWithoutDescriptionsInput>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  product?: InputMaybe<ProductCreateNestedOneWithoutDescriptionsInput>;
  ringColor?: InputMaybe<RingColorCreateNestedOneWithoutDescriptionsInput>;
  slider?: InputMaybe<SliderCreateNestedOneWithoutDescriptionsInput>;
  slug: Scalars['String'];
  style?: InputMaybe<StyleCreateNestedOneWithoutDescriptionsInput>;
  title: Scalars['String'];
};

export type DescriptionCreateWithoutProductInput = {
  Blog?: InputMaybe<BlogCreateNestedOneWithoutDescriptionsInput>;
  BlogCategory?: InputMaybe<BlogCategoryCreateNestedOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  creatable?: InputMaybe<CreatableCreateNestedOneWithoutDescriptionsInput>;
  description?: InputMaybe<Scalars['String']>;
  discountCoupon?: InputMaybe<DiscountCouponCreateNestedOneWithoutDescriptionsInput>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  page?: InputMaybe<PageCreateNestedOneWithoutDescriptionsInput>;
  ringColor?: InputMaybe<RingColorCreateNestedOneWithoutDescriptionsInput>;
  slider?: InputMaybe<SliderCreateNestedOneWithoutDescriptionsInput>;
  slug: Scalars['String'];
  style?: InputMaybe<StyleCreateNestedOneWithoutDescriptionsInput>;
  title: Scalars['String'];
};

export type DescriptionCreateWithoutRingColorInput = {
  Blog?: InputMaybe<BlogCreateNestedOneWithoutDescriptionsInput>;
  BlogCategory?: InputMaybe<BlogCategoryCreateNestedOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  creatable?: InputMaybe<CreatableCreateNestedOneWithoutDescriptionsInput>;
  description?: InputMaybe<Scalars['String']>;
  discountCoupon?: InputMaybe<DiscountCouponCreateNestedOneWithoutDescriptionsInput>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  page?: InputMaybe<PageCreateNestedOneWithoutDescriptionsInput>;
  product?: InputMaybe<ProductCreateNestedOneWithoutDescriptionsInput>;
  slider?: InputMaybe<SliderCreateNestedOneWithoutDescriptionsInput>;
  slug: Scalars['String'];
  style?: InputMaybe<StyleCreateNestedOneWithoutDescriptionsInput>;
  title: Scalars['String'];
};

export type DescriptionCreateWithoutSliderInput = {
  Blog?: InputMaybe<BlogCreateNestedOneWithoutDescriptionsInput>;
  BlogCategory?: InputMaybe<BlogCategoryCreateNestedOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  creatable?: InputMaybe<CreatableCreateNestedOneWithoutDescriptionsInput>;
  description?: InputMaybe<Scalars['String']>;
  discountCoupon?: InputMaybe<DiscountCouponCreateNestedOneWithoutDescriptionsInput>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  page?: InputMaybe<PageCreateNestedOneWithoutDescriptionsInput>;
  product?: InputMaybe<ProductCreateNestedOneWithoutDescriptionsInput>;
  ringColor?: InputMaybe<RingColorCreateNestedOneWithoutDescriptionsInput>;
  slug: Scalars['String'];
  style?: InputMaybe<StyleCreateNestedOneWithoutDescriptionsInput>;
  title: Scalars['String'];
};

export type DescriptionCreateWithoutStyleInput = {
  Blog?: InputMaybe<BlogCreateNestedOneWithoutDescriptionsInput>;
  BlogCategory?: InputMaybe<BlogCategoryCreateNestedOneWithoutDescriptionsInput>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CategoryCreateNestedOneWithoutDescriptionsInput>;
  creatable?: InputMaybe<CreatableCreateNestedOneWithoutDescriptionsInput>;
  description?: InputMaybe<Scalars['String']>;
  discountCoupon?: InputMaybe<DiscountCouponCreateNestedOneWithoutDescriptionsInput>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  page?: InputMaybe<PageCreateNestedOneWithoutDescriptionsInput>;
  product?: InputMaybe<ProductCreateNestedOneWithoutDescriptionsInput>;
  ringColor?: InputMaybe<RingColorCreateNestedOneWithoutDescriptionsInput>;
  slider?: InputMaybe<SliderCreateNestedOneWithoutDescriptionsInput>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type DescriptionGroupBy = {
  __typename?: 'DescriptionGroupBy';
  _count?: Maybe<DescriptionCountAggregate>;
  _max?: Maybe<DescriptionMaxAggregate>;
  _min?: Maybe<DescriptionMinAggregate>;
  blogCategoryId?: Maybe<Scalars['String']>;
  blogId?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  creatableId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  discountCouponId?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  language: LanguageOptions;
  pageId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  ringColorId?: Maybe<Scalars['String']>;
  sliderId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  styleId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DescriptionListRelationFilter = {
  every?: InputMaybe<DescriptionWhereInput>;
  none?: InputMaybe<DescriptionWhereInput>;
  some?: InputMaybe<DescriptionWhereInput>;
};

export type DescriptionMaxAggregate = {
  __typename?: 'DescriptionMaxAggregate';
  blogCategoryId?: Maybe<Scalars['String']>;
  blogId?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  creatableId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  discountCouponId?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  language?: Maybe<LanguageOptions>;
  pageId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  ringColorId?: Maybe<Scalars['String']>;
  sliderId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  styleId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type DescriptionMaxOrderByAggregateInput = {
  blogCategoryId?: InputMaybe<SortOrder>;
  blogId?: InputMaybe<SortOrder>;
  buttonText?: InputMaybe<SortOrder>;
  buttonUrl?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  creatableId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  discountCouponId?: InputMaybe<SortOrder>;
  headline?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  pageId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  ringColorId?: InputMaybe<SortOrder>;
  sliderId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  styleId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type DescriptionMinAggregate = {
  __typename?: 'DescriptionMinAggregate';
  blogCategoryId?: Maybe<Scalars['String']>;
  blogId?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  creatableId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  discountCouponId?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  language?: Maybe<LanguageOptions>;
  pageId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  ringColorId?: Maybe<Scalars['String']>;
  sliderId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  styleId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type DescriptionMinOrderByAggregateInput = {
  blogCategoryId?: InputMaybe<SortOrder>;
  blogId?: InputMaybe<SortOrder>;
  buttonText?: InputMaybe<SortOrder>;
  buttonUrl?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  creatableId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  discountCouponId?: InputMaybe<SortOrder>;
  headline?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  pageId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  ringColorId?: InputMaybe<SortOrder>;
  sliderId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  styleId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type DescriptionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DescriptionOrderByWithAggregationInput = {
  _count?: InputMaybe<DescriptionCountOrderByAggregateInput>;
  _max?: InputMaybe<DescriptionMaxOrderByAggregateInput>;
  _min?: InputMaybe<DescriptionMinOrderByAggregateInput>;
  blogCategoryId?: InputMaybe<SortOrder>;
  blogId?: InputMaybe<SortOrder>;
  buttonText?: InputMaybe<SortOrder>;
  buttonUrl?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  creatableId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  discountCouponId?: InputMaybe<SortOrder>;
  headline?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  pageId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  ringColorId?: InputMaybe<SortOrder>;
  sliderId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  styleId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type DescriptionOrderByWithRelationInput = {
  Blog?: InputMaybe<BlogOrderByWithRelationInput>;
  BlogCategory?: InputMaybe<BlogCategoryOrderByWithRelationInput>;
  blogCategoryId?: InputMaybe<SortOrder>;
  blogId?: InputMaybe<SortOrder>;
  buttonText?: InputMaybe<SortOrder>;
  buttonUrl?: InputMaybe<SortOrder>;
  category?: InputMaybe<CategoryOrderByWithRelationInput>;
  categoryId?: InputMaybe<SortOrder>;
  creatable?: InputMaybe<CreatableOrderByWithRelationInput>;
  creatableId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  discountCoupon?: InputMaybe<DiscountCouponOrderByWithRelationInput>;
  discountCouponId?: InputMaybe<SortOrder>;
  headline?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  page?: InputMaybe<PageOrderByWithRelationInput>;
  pageId?: InputMaybe<SortOrder>;
  product?: InputMaybe<ProductOrderByWithRelationInput>;
  productId?: InputMaybe<SortOrder>;
  ringColor?: InputMaybe<RingColorOrderByWithRelationInput>;
  ringColorId?: InputMaybe<SortOrder>;
  slider?: InputMaybe<SliderOrderByWithRelationInput>;
  sliderId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  style?: InputMaybe<StyleOrderByWithRelationInput>;
  styleId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export enum DescriptionScalarFieldEnum {
  blogCategoryId = 'blogCategoryId',
  blogId = 'blogId',
  buttonText = 'buttonText',
  buttonUrl = 'buttonUrl',
  categoryId = 'categoryId',
  creatableId = 'creatableId',
  description = 'description',
  discountCouponId = 'discountCouponId',
  headline = 'headline',
  id = 'id',
  language = 'language',
  pageId = 'pageId',
  productId = 'productId',
  ringColorId = 'ringColorId',
  sliderId = 'sliderId',
  slug = 'slug',
  styleId = 'styleId',
  title = 'title'
}

export type DescriptionScalarWhereInput = {
  AND?: InputMaybe<Array<DescriptionScalarWhereInput>>;
  NOT?: InputMaybe<Array<DescriptionScalarWhereInput>>;
  OR?: InputMaybe<Array<DescriptionScalarWhereInput>>;
  blogCategoryId?: InputMaybe<StringNullableFilter>;
  blogId?: InputMaybe<StringNullableFilter>;
  buttonText?: InputMaybe<StringNullableFilter>;
  buttonUrl?: InputMaybe<StringNullableFilter>;
  categoryId?: InputMaybe<StringNullableFilter>;
  creatableId?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  discountCouponId?: InputMaybe<StringNullableFilter>;
  headline?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  language?: InputMaybe<EnumLanguageOptionsFilter>;
  pageId?: InputMaybe<StringNullableFilter>;
  productId?: InputMaybe<StringNullableFilter>;
  ringColorId?: InputMaybe<StringNullableFilter>;
  sliderId?: InputMaybe<StringNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  styleId?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
};

export type DescriptionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<DescriptionScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<DescriptionScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<DescriptionScalarWhereWithAggregatesInput>>;
  blogCategoryId?: InputMaybe<StringNullableWithAggregatesFilter>;
  blogId?: InputMaybe<StringNullableWithAggregatesFilter>;
  buttonText?: InputMaybe<StringNullableWithAggregatesFilter>;
  buttonUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  categoryId?: InputMaybe<StringNullableWithAggregatesFilter>;
  creatableId?: InputMaybe<StringNullableWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  discountCouponId?: InputMaybe<StringNullableWithAggregatesFilter>;
  headline?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  language?: InputMaybe<EnumLanguageOptionsWithAggregatesFilter>;
  pageId?: InputMaybe<StringNullableWithAggregatesFilter>;
  productId?: InputMaybe<StringNullableWithAggregatesFilter>;
  ringColorId?: InputMaybe<StringNullableWithAggregatesFilter>;
  sliderId?: InputMaybe<StringNullableWithAggregatesFilter>;
  slug?: InputMaybe<StringWithAggregatesFilter>;
  styleId?: InputMaybe<StringNullableWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
};

export type DescriptionUpdateInput = {
  Blog?: InputMaybe<BlogUpdateOneWithoutDescriptionsNestedInput>;
  BlogCategory?: InputMaybe<BlogCategoryUpdateOneWithoutDescriptionsNestedInput>;
  buttonText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<CategoryUpdateOneWithoutDescriptionsNestedInput>;
  creatable?: InputMaybe<CreatableUpdateOneWithoutDescriptionsNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountCoupon?: InputMaybe<DiscountCouponUpdateOneWithoutDescriptionsNestedInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  page?: InputMaybe<PageUpdateOneWithoutDescriptionsNestedInput>;
  product?: InputMaybe<ProductUpdateOneWithoutDescriptionsNestedInput>;
  ringColor?: InputMaybe<RingColorUpdateOneWithoutDescriptionsNestedInput>;
  slider?: InputMaybe<SliderUpdateOneWithoutDescriptionsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  style?: InputMaybe<StyleUpdateOneWithoutDescriptionsNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DescriptionUpdateManyMutationInput = {
  buttonText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DescriptionUpdateManyWithWhereWithoutBlogCategoryInput = {
  data: DescriptionUpdateManyMutationInput;
  where: DescriptionScalarWhereInput;
};

export type DescriptionUpdateManyWithWhereWithoutBlogInput = {
  data: DescriptionUpdateManyMutationInput;
  where: DescriptionScalarWhereInput;
};

export type DescriptionUpdateManyWithWhereWithoutCategoryInput = {
  data: DescriptionUpdateManyMutationInput;
  where: DescriptionScalarWhereInput;
};

export type DescriptionUpdateManyWithWhereWithoutCreatableInput = {
  data: DescriptionUpdateManyMutationInput;
  where: DescriptionScalarWhereInput;
};

export type DescriptionUpdateManyWithWhereWithoutDiscountCouponInput = {
  data: DescriptionUpdateManyMutationInput;
  where: DescriptionScalarWhereInput;
};

export type DescriptionUpdateManyWithWhereWithoutPageInput = {
  data: DescriptionUpdateManyMutationInput;
  where: DescriptionScalarWhereInput;
};

export type DescriptionUpdateManyWithWhereWithoutProductInput = {
  data: DescriptionUpdateManyMutationInput;
  where: DescriptionScalarWhereInput;
};

export type DescriptionUpdateManyWithWhereWithoutRingColorInput = {
  data: DescriptionUpdateManyMutationInput;
  where: DescriptionScalarWhereInput;
};

export type DescriptionUpdateManyWithWhereWithoutSliderInput = {
  data: DescriptionUpdateManyMutationInput;
  where: DescriptionScalarWhereInput;
};

export type DescriptionUpdateManyWithWhereWithoutStyleInput = {
  data: DescriptionUpdateManyMutationInput;
  where: DescriptionScalarWhereInput;
};

export type DescriptionUpdateManyWithoutBlogCategoryNestedInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutBlogCategoryInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutBlogCategoryInput>>;
  createMany?: InputMaybe<DescriptionCreateManyBlogCategoryInputEnvelope>;
  delete?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DescriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<DescriptionUpdateWithWhereUniqueWithoutBlogCategoryInput>>;
  updateMany?: InputMaybe<Array<DescriptionUpdateManyWithWhereWithoutBlogCategoryInput>>;
  upsert?: InputMaybe<Array<DescriptionUpsertWithWhereUniqueWithoutBlogCategoryInput>>;
};

export type DescriptionUpdateManyWithoutBlogNestedInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutBlogInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutBlogInput>>;
  createMany?: InputMaybe<DescriptionCreateManyBlogInputEnvelope>;
  delete?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DescriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<DescriptionUpdateWithWhereUniqueWithoutBlogInput>>;
  updateMany?: InputMaybe<Array<DescriptionUpdateManyWithWhereWithoutBlogInput>>;
  upsert?: InputMaybe<Array<DescriptionUpsertWithWhereUniqueWithoutBlogInput>>;
};

export type DescriptionUpdateManyWithoutCategoryNestedInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<DescriptionCreateManyCategoryInputEnvelope>;
  delete?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DescriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<DescriptionUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: InputMaybe<Array<DescriptionUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: InputMaybe<Array<DescriptionUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type DescriptionUpdateManyWithoutCreatableNestedInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutCreatableInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutCreatableInput>>;
  createMany?: InputMaybe<DescriptionCreateManyCreatableInputEnvelope>;
  delete?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DescriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<DescriptionUpdateWithWhereUniqueWithoutCreatableInput>>;
  updateMany?: InputMaybe<Array<DescriptionUpdateManyWithWhereWithoutCreatableInput>>;
  upsert?: InputMaybe<Array<DescriptionUpsertWithWhereUniqueWithoutCreatableInput>>;
};

export type DescriptionUpdateManyWithoutDiscountCouponNestedInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutDiscountCouponInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutDiscountCouponInput>>;
  createMany?: InputMaybe<DescriptionCreateManyDiscountCouponInputEnvelope>;
  delete?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DescriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<DescriptionUpdateWithWhereUniqueWithoutDiscountCouponInput>>;
  updateMany?: InputMaybe<Array<DescriptionUpdateManyWithWhereWithoutDiscountCouponInput>>;
  upsert?: InputMaybe<Array<DescriptionUpsertWithWhereUniqueWithoutDiscountCouponInput>>;
};

export type DescriptionUpdateManyWithoutPageNestedInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutPageInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutPageInput>>;
  createMany?: InputMaybe<DescriptionCreateManyPageInputEnvelope>;
  delete?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DescriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<DescriptionUpdateWithWhereUniqueWithoutPageInput>>;
  updateMany?: InputMaybe<Array<DescriptionUpdateManyWithWhereWithoutPageInput>>;
  upsert?: InputMaybe<Array<DescriptionUpsertWithWhereUniqueWithoutPageInput>>;
};

export type DescriptionUpdateManyWithoutProductNestedInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutProductInput>>;
  createMany?: InputMaybe<DescriptionCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DescriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<DescriptionUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<DescriptionUpdateManyWithWhereWithoutProductInput>>;
  upsert?: InputMaybe<Array<DescriptionUpsertWithWhereUniqueWithoutProductInput>>;
};

export type DescriptionUpdateManyWithoutRingColorNestedInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutRingColorInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutRingColorInput>>;
  createMany?: InputMaybe<DescriptionCreateManyRingColorInputEnvelope>;
  delete?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DescriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<DescriptionUpdateWithWhereUniqueWithoutRingColorInput>>;
  updateMany?: InputMaybe<Array<DescriptionUpdateManyWithWhereWithoutRingColorInput>>;
  upsert?: InputMaybe<Array<DescriptionUpsertWithWhereUniqueWithoutRingColorInput>>;
};

export type DescriptionUpdateManyWithoutSliderNestedInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutSliderInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutSliderInput>>;
  createMany?: InputMaybe<DescriptionCreateManySliderInputEnvelope>;
  delete?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DescriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<DescriptionUpdateWithWhereUniqueWithoutSliderInput>>;
  updateMany?: InputMaybe<Array<DescriptionUpdateManyWithWhereWithoutSliderInput>>;
  upsert?: InputMaybe<Array<DescriptionUpsertWithWhereUniqueWithoutSliderInput>>;
};

export type DescriptionUpdateManyWithoutStyleNestedInput = {
  connect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DescriptionCreateOrConnectWithoutStyleInput>>;
  create?: InputMaybe<Array<DescriptionCreateWithoutStyleInput>>;
  createMany?: InputMaybe<DescriptionCreateManyStyleInputEnvelope>;
  delete?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DescriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<DescriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<DescriptionUpdateWithWhereUniqueWithoutStyleInput>>;
  updateMany?: InputMaybe<Array<DescriptionUpdateManyWithWhereWithoutStyleInput>>;
  upsert?: InputMaybe<Array<DescriptionUpsertWithWhereUniqueWithoutStyleInput>>;
};

export type DescriptionUpdateWithWhereUniqueWithoutBlogCategoryInput = {
  data: DescriptionUpdateWithoutBlogCategoryInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpdateWithWhereUniqueWithoutBlogInput = {
  data: DescriptionUpdateWithoutBlogInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpdateWithWhereUniqueWithoutCategoryInput = {
  data: DescriptionUpdateWithoutCategoryInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpdateWithWhereUniqueWithoutCreatableInput = {
  data: DescriptionUpdateWithoutCreatableInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpdateWithWhereUniqueWithoutDiscountCouponInput = {
  data: DescriptionUpdateWithoutDiscountCouponInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpdateWithWhereUniqueWithoutPageInput = {
  data: DescriptionUpdateWithoutPageInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpdateWithWhereUniqueWithoutProductInput = {
  data: DescriptionUpdateWithoutProductInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpdateWithWhereUniqueWithoutRingColorInput = {
  data: DescriptionUpdateWithoutRingColorInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpdateWithWhereUniqueWithoutSliderInput = {
  data: DescriptionUpdateWithoutSliderInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpdateWithWhereUniqueWithoutStyleInput = {
  data: DescriptionUpdateWithoutStyleInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpdateWithoutBlogCategoryInput = {
  Blog?: InputMaybe<BlogUpdateOneWithoutDescriptionsNestedInput>;
  buttonText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<CategoryUpdateOneWithoutDescriptionsNestedInput>;
  creatable?: InputMaybe<CreatableUpdateOneWithoutDescriptionsNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountCoupon?: InputMaybe<DiscountCouponUpdateOneWithoutDescriptionsNestedInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  page?: InputMaybe<PageUpdateOneWithoutDescriptionsNestedInput>;
  product?: InputMaybe<ProductUpdateOneWithoutDescriptionsNestedInput>;
  ringColor?: InputMaybe<RingColorUpdateOneWithoutDescriptionsNestedInput>;
  slider?: InputMaybe<SliderUpdateOneWithoutDescriptionsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  style?: InputMaybe<StyleUpdateOneWithoutDescriptionsNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DescriptionUpdateWithoutBlogInput = {
  BlogCategory?: InputMaybe<BlogCategoryUpdateOneWithoutDescriptionsNestedInput>;
  buttonText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<CategoryUpdateOneWithoutDescriptionsNestedInput>;
  creatable?: InputMaybe<CreatableUpdateOneWithoutDescriptionsNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountCoupon?: InputMaybe<DiscountCouponUpdateOneWithoutDescriptionsNestedInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  page?: InputMaybe<PageUpdateOneWithoutDescriptionsNestedInput>;
  product?: InputMaybe<ProductUpdateOneWithoutDescriptionsNestedInput>;
  ringColor?: InputMaybe<RingColorUpdateOneWithoutDescriptionsNestedInput>;
  slider?: InputMaybe<SliderUpdateOneWithoutDescriptionsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  style?: InputMaybe<StyleUpdateOneWithoutDescriptionsNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DescriptionUpdateWithoutCategoryInput = {
  Blog?: InputMaybe<BlogUpdateOneWithoutDescriptionsNestedInput>;
  BlogCategory?: InputMaybe<BlogCategoryUpdateOneWithoutDescriptionsNestedInput>;
  buttonText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  creatable?: InputMaybe<CreatableUpdateOneWithoutDescriptionsNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountCoupon?: InputMaybe<DiscountCouponUpdateOneWithoutDescriptionsNestedInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  page?: InputMaybe<PageUpdateOneWithoutDescriptionsNestedInput>;
  product?: InputMaybe<ProductUpdateOneWithoutDescriptionsNestedInput>;
  ringColor?: InputMaybe<RingColorUpdateOneWithoutDescriptionsNestedInput>;
  slider?: InputMaybe<SliderUpdateOneWithoutDescriptionsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  style?: InputMaybe<StyleUpdateOneWithoutDescriptionsNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DescriptionUpdateWithoutCreatableInput = {
  Blog?: InputMaybe<BlogUpdateOneWithoutDescriptionsNestedInput>;
  BlogCategory?: InputMaybe<BlogCategoryUpdateOneWithoutDescriptionsNestedInput>;
  buttonText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<CategoryUpdateOneWithoutDescriptionsNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountCoupon?: InputMaybe<DiscountCouponUpdateOneWithoutDescriptionsNestedInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  page?: InputMaybe<PageUpdateOneWithoutDescriptionsNestedInput>;
  product?: InputMaybe<ProductUpdateOneWithoutDescriptionsNestedInput>;
  ringColor?: InputMaybe<RingColorUpdateOneWithoutDescriptionsNestedInput>;
  slider?: InputMaybe<SliderUpdateOneWithoutDescriptionsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  style?: InputMaybe<StyleUpdateOneWithoutDescriptionsNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DescriptionUpdateWithoutDiscountCouponInput = {
  Blog?: InputMaybe<BlogUpdateOneWithoutDescriptionsNestedInput>;
  BlogCategory?: InputMaybe<BlogCategoryUpdateOneWithoutDescriptionsNestedInput>;
  buttonText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<CategoryUpdateOneWithoutDescriptionsNestedInput>;
  creatable?: InputMaybe<CreatableUpdateOneWithoutDescriptionsNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  page?: InputMaybe<PageUpdateOneWithoutDescriptionsNestedInput>;
  product?: InputMaybe<ProductUpdateOneWithoutDescriptionsNestedInput>;
  ringColor?: InputMaybe<RingColorUpdateOneWithoutDescriptionsNestedInput>;
  slider?: InputMaybe<SliderUpdateOneWithoutDescriptionsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  style?: InputMaybe<StyleUpdateOneWithoutDescriptionsNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DescriptionUpdateWithoutPageInput = {
  Blog?: InputMaybe<BlogUpdateOneWithoutDescriptionsNestedInput>;
  BlogCategory?: InputMaybe<BlogCategoryUpdateOneWithoutDescriptionsNestedInput>;
  buttonText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<CategoryUpdateOneWithoutDescriptionsNestedInput>;
  creatable?: InputMaybe<CreatableUpdateOneWithoutDescriptionsNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountCoupon?: InputMaybe<DiscountCouponUpdateOneWithoutDescriptionsNestedInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutDescriptionsNestedInput>;
  ringColor?: InputMaybe<RingColorUpdateOneWithoutDescriptionsNestedInput>;
  slider?: InputMaybe<SliderUpdateOneWithoutDescriptionsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  style?: InputMaybe<StyleUpdateOneWithoutDescriptionsNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DescriptionUpdateWithoutProductInput = {
  Blog?: InputMaybe<BlogUpdateOneWithoutDescriptionsNestedInput>;
  BlogCategory?: InputMaybe<BlogCategoryUpdateOneWithoutDescriptionsNestedInput>;
  buttonText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<CategoryUpdateOneWithoutDescriptionsNestedInput>;
  creatable?: InputMaybe<CreatableUpdateOneWithoutDescriptionsNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountCoupon?: InputMaybe<DiscountCouponUpdateOneWithoutDescriptionsNestedInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  page?: InputMaybe<PageUpdateOneWithoutDescriptionsNestedInput>;
  ringColor?: InputMaybe<RingColorUpdateOneWithoutDescriptionsNestedInput>;
  slider?: InputMaybe<SliderUpdateOneWithoutDescriptionsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  style?: InputMaybe<StyleUpdateOneWithoutDescriptionsNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DescriptionUpdateWithoutRingColorInput = {
  Blog?: InputMaybe<BlogUpdateOneWithoutDescriptionsNestedInput>;
  BlogCategory?: InputMaybe<BlogCategoryUpdateOneWithoutDescriptionsNestedInput>;
  buttonText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<CategoryUpdateOneWithoutDescriptionsNestedInput>;
  creatable?: InputMaybe<CreatableUpdateOneWithoutDescriptionsNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountCoupon?: InputMaybe<DiscountCouponUpdateOneWithoutDescriptionsNestedInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  page?: InputMaybe<PageUpdateOneWithoutDescriptionsNestedInput>;
  product?: InputMaybe<ProductUpdateOneWithoutDescriptionsNestedInput>;
  slider?: InputMaybe<SliderUpdateOneWithoutDescriptionsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  style?: InputMaybe<StyleUpdateOneWithoutDescriptionsNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DescriptionUpdateWithoutSliderInput = {
  Blog?: InputMaybe<BlogUpdateOneWithoutDescriptionsNestedInput>;
  BlogCategory?: InputMaybe<BlogCategoryUpdateOneWithoutDescriptionsNestedInput>;
  buttonText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<CategoryUpdateOneWithoutDescriptionsNestedInput>;
  creatable?: InputMaybe<CreatableUpdateOneWithoutDescriptionsNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountCoupon?: InputMaybe<DiscountCouponUpdateOneWithoutDescriptionsNestedInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  page?: InputMaybe<PageUpdateOneWithoutDescriptionsNestedInput>;
  product?: InputMaybe<ProductUpdateOneWithoutDescriptionsNestedInput>;
  ringColor?: InputMaybe<RingColorUpdateOneWithoutDescriptionsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  style?: InputMaybe<StyleUpdateOneWithoutDescriptionsNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DescriptionUpdateWithoutStyleInput = {
  Blog?: InputMaybe<BlogUpdateOneWithoutDescriptionsNestedInput>;
  BlogCategory?: InputMaybe<BlogCategoryUpdateOneWithoutDescriptionsNestedInput>;
  buttonText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buttonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<CategoryUpdateOneWithoutDescriptionsNestedInput>;
  creatable?: InputMaybe<CreatableUpdateOneWithoutDescriptionsNestedInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountCoupon?: InputMaybe<DiscountCouponUpdateOneWithoutDescriptionsNestedInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  page?: InputMaybe<PageUpdateOneWithoutDescriptionsNestedInput>;
  product?: InputMaybe<ProductUpdateOneWithoutDescriptionsNestedInput>;
  ringColor?: InputMaybe<RingColorUpdateOneWithoutDescriptionsNestedInput>;
  slider?: InputMaybe<SliderUpdateOneWithoutDescriptionsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DescriptionUpsertWithWhereUniqueWithoutBlogCategoryInput = {
  create: DescriptionCreateWithoutBlogCategoryInput;
  update: DescriptionUpdateWithoutBlogCategoryInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpsertWithWhereUniqueWithoutBlogInput = {
  create: DescriptionCreateWithoutBlogInput;
  update: DescriptionUpdateWithoutBlogInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpsertWithWhereUniqueWithoutCategoryInput = {
  create: DescriptionCreateWithoutCategoryInput;
  update: DescriptionUpdateWithoutCategoryInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpsertWithWhereUniqueWithoutCreatableInput = {
  create: DescriptionCreateWithoutCreatableInput;
  update: DescriptionUpdateWithoutCreatableInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpsertWithWhereUniqueWithoutDiscountCouponInput = {
  create: DescriptionCreateWithoutDiscountCouponInput;
  update: DescriptionUpdateWithoutDiscountCouponInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpsertWithWhereUniqueWithoutPageInput = {
  create: DescriptionCreateWithoutPageInput;
  update: DescriptionUpdateWithoutPageInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpsertWithWhereUniqueWithoutProductInput = {
  create: DescriptionCreateWithoutProductInput;
  update: DescriptionUpdateWithoutProductInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpsertWithWhereUniqueWithoutRingColorInput = {
  create: DescriptionCreateWithoutRingColorInput;
  update: DescriptionUpdateWithoutRingColorInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpsertWithWhereUniqueWithoutSliderInput = {
  create: DescriptionCreateWithoutSliderInput;
  update: DescriptionUpdateWithoutSliderInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionUpsertWithWhereUniqueWithoutStyleInput = {
  create: DescriptionCreateWithoutStyleInput;
  update: DescriptionUpdateWithoutStyleInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionWhereInput = {
  AND?: InputMaybe<Array<DescriptionWhereInput>>;
  Blog?: InputMaybe<BlogRelationFilter>;
  BlogCategory?: InputMaybe<BlogCategoryRelationFilter>;
  NOT?: InputMaybe<Array<DescriptionWhereInput>>;
  OR?: InputMaybe<Array<DescriptionWhereInput>>;
  blogCategoryId?: InputMaybe<StringNullableFilter>;
  blogId?: InputMaybe<StringNullableFilter>;
  buttonText?: InputMaybe<StringNullableFilter>;
  buttonUrl?: InputMaybe<StringNullableFilter>;
  category?: InputMaybe<CategoryRelationFilter>;
  categoryId?: InputMaybe<StringNullableFilter>;
  creatable?: InputMaybe<CreatableRelationFilter>;
  creatableId?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  discountCoupon?: InputMaybe<DiscountCouponRelationFilter>;
  discountCouponId?: InputMaybe<StringNullableFilter>;
  headline?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  language?: InputMaybe<EnumLanguageOptionsFilter>;
  page?: InputMaybe<PageRelationFilter>;
  pageId?: InputMaybe<StringNullableFilter>;
  product?: InputMaybe<ProductRelationFilter>;
  productId?: InputMaybe<StringNullableFilter>;
  ringColor?: InputMaybe<RingColorRelationFilter>;
  ringColorId?: InputMaybe<StringNullableFilter>;
  slider?: InputMaybe<SliderRelationFilter>;
  sliderId?: InputMaybe<StringNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  style?: InputMaybe<StyleRelationFilter>;
  styleId?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
};

export type DescriptionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type DiscountCoupon = {
  __typename?: 'DiscountCoupon';
  _count?: Maybe<DiscountCouponCount>;
  amount: Scalars['Decimal'];
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['String']>;
  descriptions: Array<Description>;
  id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  isPercentage: Scalars['Boolean'];
  orders: Array<Order>;
  status: Scalars['Boolean'];
  validUntil: Scalars['DateTime'];
};


export type DiscountCouponDescriptionsArgs = {
  cursor?: InputMaybe<DescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<DescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};


export type DiscountCouponOrdersArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderWhereInput>;
};

export type DiscountCouponAvgAggregate = {
  __typename?: 'DiscountCouponAvgAggregate';
  amount?: Maybe<Scalars['Decimal']>;
};

export type DiscountCouponAvgOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
};

export type DiscountCouponCount = {
  __typename?: 'DiscountCouponCount';
  descriptions: Scalars['Int'];
  orders: Scalars['Int'];
};

export type DiscountCouponCountAggregate = {
  __typename?: 'DiscountCouponCountAggregate';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  code: Scalars['Int'];
  createdAt: Scalars['Int'];
  customerId: Scalars['Int'];
  id: Scalars['Int'];
  image: Scalars['Int'];
  isPercentage: Scalars['Int'];
  status: Scalars['Int'];
  validUntil: Scalars['Int'];
};

export type DiscountCouponCountOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  isPercentage?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  validUntil?: InputMaybe<SortOrder>;
};

export type DiscountCouponCreateInput = {
  amount: Scalars['Decimal'];
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutDiscountCouponsInput>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutDiscountCouponInput>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  isPercentage: Scalars['Boolean'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutDiscountCouponInput>;
  status: Scalars['Boolean'];
  validUntil: Scalars['DateTime'];
};

export type DiscountCouponCreateManyCustomerInput = {
  amount: Scalars['Decimal'];
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  isPercentage: Scalars['Boolean'];
  status: Scalars['Boolean'];
  validUntil: Scalars['DateTime'];
};

export type DiscountCouponCreateManyCustomerInputEnvelope = {
  data: Array<DiscountCouponCreateManyCustomerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DiscountCouponCreateManyInput = {
  amount: Scalars['Decimal'];
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  isPercentage: Scalars['Boolean'];
  status: Scalars['Boolean'];
  validUntil: Scalars['DateTime'];
};

export type DiscountCouponCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<DiscountCouponWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DiscountCouponCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<DiscountCouponCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<DiscountCouponCreateManyCustomerInputEnvelope>;
};

export type DiscountCouponCreateNestedOneWithoutDescriptionsInput = {
  connect?: InputMaybe<DiscountCouponWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DiscountCouponCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<DiscountCouponCreateWithoutDescriptionsInput>;
};

export type DiscountCouponCreateNestedOneWithoutOrdersInput = {
  connect?: InputMaybe<DiscountCouponWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DiscountCouponCreateOrConnectWithoutOrdersInput>;
  create?: InputMaybe<DiscountCouponCreateWithoutOrdersInput>;
};

export type DiscountCouponCreateOrConnectWithoutCustomerInput = {
  create: DiscountCouponCreateWithoutCustomerInput;
  where: DiscountCouponWhereUniqueInput;
};

export type DiscountCouponCreateOrConnectWithoutDescriptionsInput = {
  create: DiscountCouponCreateWithoutDescriptionsInput;
  where: DiscountCouponWhereUniqueInput;
};

export type DiscountCouponCreateOrConnectWithoutOrdersInput = {
  create: DiscountCouponCreateWithoutOrdersInput;
  where: DiscountCouponWhereUniqueInput;
};

export type DiscountCouponCreateWithoutCustomerInput = {
  amount: Scalars['Decimal'];
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutDiscountCouponInput>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  isPercentage: Scalars['Boolean'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutDiscountCouponInput>;
  status: Scalars['Boolean'];
  validUntil: Scalars['DateTime'];
};

export type DiscountCouponCreateWithoutDescriptionsInput = {
  amount: Scalars['Decimal'];
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutDiscountCouponsInput>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  isPercentage: Scalars['Boolean'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutDiscountCouponInput>;
  status: Scalars['Boolean'];
  validUntil: Scalars['DateTime'];
};

export type DiscountCouponCreateWithoutOrdersInput = {
  amount: Scalars['Decimal'];
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutDiscountCouponsInput>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutDiscountCouponInput>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  isPercentage: Scalars['Boolean'];
  status: Scalars['Boolean'];
  validUntil: Scalars['DateTime'];
};

export type DiscountCouponGroupBy = {
  __typename?: 'DiscountCouponGroupBy';
  _avg?: Maybe<DiscountCouponAvgAggregate>;
  _count?: Maybe<DiscountCouponCountAggregate>;
  _max?: Maybe<DiscountCouponMaxAggregate>;
  _min?: Maybe<DiscountCouponMinAggregate>;
  _sum?: Maybe<DiscountCouponSumAggregate>;
  amount: Scalars['Decimal'];
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customerId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  isPercentage: Scalars['Boolean'];
  status: Scalars['Boolean'];
  validUntil: Scalars['DateTime'];
};

export type DiscountCouponListRelationFilter = {
  every?: InputMaybe<DiscountCouponWhereInput>;
  none?: InputMaybe<DiscountCouponWhereInput>;
  some?: InputMaybe<DiscountCouponWhereInput>;
};

export type DiscountCouponMaxAggregate = {
  __typename?: 'DiscountCouponMaxAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isPercentage?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  validUntil?: Maybe<Scalars['DateTime']>;
};

export type DiscountCouponMaxOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  isPercentage?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  validUntil?: InputMaybe<SortOrder>;
};

export type DiscountCouponMinAggregate = {
  __typename?: 'DiscountCouponMinAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isPercentage?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  validUntil?: Maybe<Scalars['DateTime']>;
};

export type DiscountCouponMinOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  isPercentage?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  validUntil?: InputMaybe<SortOrder>;
};

export type DiscountCouponOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DiscountCouponOrderByWithAggregationInput = {
  _avg?: InputMaybe<DiscountCouponAvgOrderByAggregateInput>;
  _count?: InputMaybe<DiscountCouponCountOrderByAggregateInput>;
  _max?: InputMaybe<DiscountCouponMaxOrderByAggregateInput>;
  _min?: InputMaybe<DiscountCouponMinOrderByAggregateInput>;
  _sum?: InputMaybe<DiscountCouponSumOrderByAggregateInput>;
  amount?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  isPercentage?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  validUntil?: InputMaybe<SortOrder>;
};

export type DiscountCouponOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
  descriptions?: InputMaybe<DescriptionOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  isPercentage?: InputMaybe<SortOrder>;
  orders?: InputMaybe<OrderOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  validUntil?: InputMaybe<SortOrder>;
};

export type DiscountCouponRelationFilter = {
  is?: InputMaybe<DiscountCouponWhereInput>;
  isNot?: InputMaybe<DiscountCouponWhereInput>;
};

export enum DiscountCouponScalarFieldEnum {
  amount = 'amount',
  code = 'code',
  createdAt = 'createdAt',
  customerId = 'customerId',
  id = 'id',
  image = 'image',
  isPercentage = 'isPercentage',
  status = 'status',
  validUntil = 'validUntil'
}

export type DiscountCouponScalarWhereInput = {
  AND?: InputMaybe<Array<DiscountCouponScalarWhereInput>>;
  NOT?: InputMaybe<Array<DiscountCouponScalarWhereInput>>;
  OR?: InputMaybe<Array<DiscountCouponScalarWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  image?: InputMaybe<StringNullableFilter>;
  isPercentage?: InputMaybe<BoolFilter>;
  status?: InputMaybe<BoolFilter>;
  validUntil?: InputMaybe<DateTimeFilter>;
};

export type DiscountCouponScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<DiscountCouponScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<DiscountCouponScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<DiscountCouponScalarWhereWithAggregatesInput>>;
  amount?: InputMaybe<DecimalWithAggregatesFilter>;
  code?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  customerId?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  image?: InputMaybe<StringNullableWithAggregatesFilter>;
  isPercentage?: InputMaybe<BoolWithAggregatesFilter>;
  status?: InputMaybe<BoolWithAggregatesFilter>;
  validUntil?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type DiscountCouponSumAggregate = {
  __typename?: 'DiscountCouponSumAggregate';
  amount?: Maybe<Scalars['Decimal']>;
};

export type DiscountCouponSumOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
};

export type DiscountCouponUpdateInput = {
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutDiscountCouponsNestedInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutDiscountCouponNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isPercentage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutDiscountCouponNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  validUntil?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DiscountCouponUpdateManyMutationInput = {
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isPercentage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  validUntil?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DiscountCouponUpdateManyWithWhereWithoutCustomerInput = {
  data: DiscountCouponUpdateManyMutationInput;
  where: DiscountCouponScalarWhereInput;
};

export type DiscountCouponUpdateManyWithoutCustomerNestedInput = {
  connect?: InputMaybe<Array<DiscountCouponWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DiscountCouponCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<DiscountCouponCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<DiscountCouponCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<DiscountCouponWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DiscountCouponScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DiscountCouponWhereUniqueInput>>;
  set?: InputMaybe<Array<DiscountCouponWhereUniqueInput>>;
  update?: InputMaybe<Array<DiscountCouponUpdateWithWhereUniqueWithoutCustomerInput>>;
  updateMany?: InputMaybe<Array<DiscountCouponUpdateManyWithWhereWithoutCustomerInput>>;
  upsert?: InputMaybe<Array<DiscountCouponUpsertWithWhereUniqueWithoutCustomerInput>>;
};

export type DiscountCouponUpdateOneWithoutDescriptionsNestedInput = {
  connect?: InputMaybe<DiscountCouponWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DiscountCouponCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<DiscountCouponCreateWithoutDescriptionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DiscountCouponUpdateWithoutDescriptionsInput>;
  upsert?: InputMaybe<DiscountCouponUpsertWithoutDescriptionsInput>;
};

export type DiscountCouponUpdateOneWithoutOrdersNestedInput = {
  connect?: InputMaybe<DiscountCouponWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DiscountCouponCreateOrConnectWithoutOrdersInput>;
  create?: InputMaybe<DiscountCouponCreateWithoutOrdersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DiscountCouponUpdateWithoutOrdersInput>;
  upsert?: InputMaybe<DiscountCouponUpsertWithoutOrdersInput>;
};

export type DiscountCouponUpdateWithWhereUniqueWithoutCustomerInput = {
  data: DiscountCouponUpdateWithoutCustomerInput;
  where: DiscountCouponWhereUniqueInput;
};

export type DiscountCouponUpdateWithoutCustomerInput = {
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutDiscountCouponNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isPercentage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutDiscountCouponNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  validUntil?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DiscountCouponUpdateWithoutDescriptionsInput = {
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutDiscountCouponsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isPercentage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutDiscountCouponNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  validUntil?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DiscountCouponUpdateWithoutOrdersInput = {
  amount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutDiscountCouponsNestedInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutDiscountCouponNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isPercentage?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  validUntil?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DiscountCouponUpsertWithWhereUniqueWithoutCustomerInput = {
  create: DiscountCouponCreateWithoutCustomerInput;
  update: DiscountCouponUpdateWithoutCustomerInput;
  where: DiscountCouponWhereUniqueInput;
};

export type DiscountCouponUpsertWithoutDescriptionsInput = {
  create: DiscountCouponCreateWithoutDescriptionsInput;
  update: DiscountCouponUpdateWithoutDescriptionsInput;
};

export type DiscountCouponUpsertWithoutOrdersInput = {
  create: DiscountCouponCreateWithoutOrdersInput;
  update: DiscountCouponUpdateWithoutOrdersInput;
};

export type DiscountCouponWhereInput = {
  AND?: InputMaybe<Array<DiscountCouponWhereInput>>;
  NOT?: InputMaybe<Array<DiscountCouponWhereInput>>;
  OR?: InputMaybe<Array<DiscountCouponWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringNullableFilter>;
  descriptions?: InputMaybe<DescriptionListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  image?: InputMaybe<StringNullableFilter>;
  isPercentage?: InputMaybe<BoolFilter>;
  orders?: InputMaybe<OrderListRelationFilter>;
  status?: InputMaybe<BoolFilter>;
  validUntil?: InputMaybe<DateTimeFilter>;
};

export type DiscountCouponWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export enum DiscountType {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE'
}

export type EnumCreatableOperationTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<CreatableOperationType>;
};

export type EnumCreatableOperationTypeFilter = {
  equals?: InputMaybe<CreatableOperationType>;
  in?: InputMaybe<Array<CreatableOperationType>>;
  not?: InputMaybe<NestedEnumCreatableOperationTypeFilter>;
  notIn?: InputMaybe<Array<CreatableOperationType>>;
};

export type EnumCreatableOperationTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCreatableOperationTypeFilter>;
  _min?: InputMaybe<NestedEnumCreatableOperationTypeFilter>;
  equals?: InputMaybe<CreatableOperationType>;
  in?: InputMaybe<Array<CreatableOperationType>>;
  not?: InputMaybe<NestedEnumCreatableOperationTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CreatableOperationType>>;
};

export type EnumCreatableTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<CreatableType>;
};

export type EnumCreatableTypeFilter = {
  equals?: InputMaybe<CreatableType>;
  in?: InputMaybe<Array<CreatableType>>;
  not?: InputMaybe<NestedEnumCreatableTypeFilter>;
  notIn?: InputMaybe<Array<CreatableType>>;
};

export type EnumCreatableTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCreatableTypeFilter>;
  _min?: InputMaybe<NestedEnumCreatableTypeFilter>;
  equals?: InputMaybe<CreatableType>;
  in?: InputMaybe<Array<CreatableType>>;
  not?: InputMaybe<NestedEnumCreatableTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CreatableType>>;
};

export type EnumCustomerRoleFieldUpdateOperationsInput = {
  set?: InputMaybe<CustomerRole>;
};

export type EnumCustomerRoleFilter = {
  equals?: InputMaybe<CustomerRole>;
  in?: InputMaybe<Array<CustomerRole>>;
  not?: InputMaybe<NestedEnumCustomerRoleFilter>;
  notIn?: InputMaybe<Array<CustomerRole>>;
};

export type EnumCustomerRoleWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCustomerRoleFilter>;
  _min?: InputMaybe<NestedEnumCustomerRoleFilter>;
  equals?: InputMaybe<CustomerRole>;
  in?: InputMaybe<Array<CustomerRole>>;
  not?: InputMaybe<NestedEnumCustomerRoleWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CustomerRole>>;
};

export type EnumDiscountTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<DiscountType>;
};

export type EnumDiscountTypeFilter = {
  equals?: InputMaybe<DiscountType>;
  in?: InputMaybe<Array<DiscountType>>;
  not?: InputMaybe<NestedEnumDiscountTypeFilter>;
  notIn?: InputMaybe<Array<DiscountType>>;
};

export type EnumDiscountTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumDiscountTypeFilter>;
  _min?: InputMaybe<NestedEnumDiscountTypeFilter>;
  equals?: InputMaybe<DiscountType>;
  in?: InputMaybe<Array<DiscountType>>;
  not?: InputMaybe<NestedEnumDiscountTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<DiscountType>>;
};

export type EnumLanguageOptionsFieldUpdateOperationsInput = {
  set?: InputMaybe<LanguageOptions>;
};

export type EnumLanguageOptionsFilter = {
  equals?: InputMaybe<LanguageOptions>;
  in?: InputMaybe<Array<LanguageOptions>>;
  not?: InputMaybe<NestedEnumLanguageOptionsFilter>;
  notIn?: InputMaybe<Array<LanguageOptions>>;
};

export type EnumLanguageOptionsWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumLanguageOptionsFilter>;
  _min?: InputMaybe<NestedEnumLanguageOptionsFilter>;
  equals?: InputMaybe<LanguageOptions>;
  in?: InputMaybe<Array<LanguageOptions>>;
  not?: InputMaybe<NestedEnumLanguageOptionsWithAggregatesFilter>;
  notIn?: InputMaybe<Array<LanguageOptions>>;
};

export type EnumOrderStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<OrderStatus>;
};

export type EnumOrderStatusFilter = {
  equals?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  not?: InputMaybe<NestedEnumOrderStatusFilter>;
  notIn?: InputMaybe<Array<OrderStatus>>;
};

export type EnumOrderStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumOrderStatusFilter>;
  _min?: InputMaybe<NestedEnumOrderStatusFilter>;
  equals?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  not?: InputMaybe<NestedEnumOrderStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<OrderStatus>>;
};

export type EnumPageTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<PageType>;
};

export type EnumPageTypeFilter = {
  equals?: InputMaybe<PageType>;
  in?: InputMaybe<Array<PageType>>;
  not?: InputMaybe<NestedEnumPageTypeFilter>;
  notIn?: InputMaybe<Array<PageType>>;
};

export type EnumPageTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPageTypeFilter>;
  _min?: InputMaybe<NestedEnumPageTypeFilter>;
  equals?: InputMaybe<PageType>;
  in?: InputMaybe<Array<PageType>>;
  not?: InputMaybe<NestedEnumPageTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PageType>>;
};

export type EnumPaymentTypeNullableFilter = {
  equals?: InputMaybe<PaymentType>;
  in?: InputMaybe<Array<PaymentType>>;
  not?: InputMaybe<NestedEnumPaymentTypeNullableFilter>;
  notIn?: InputMaybe<Array<PaymentType>>;
};

export type EnumPaymentTypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumPaymentTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumPaymentTypeNullableFilter>;
  equals?: InputMaybe<PaymentType>;
  in?: InputMaybe<Array<PaymentType>>;
  not?: InputMaybe<NestedEnumPaymentTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PaymentType>>;
};

export type EnumRingStoneCaratQualityFieldUpdateOperationsInput = {
  set?: InputMaybe<RingStoneCaratQuality>;
};

export type EnumRingStoneCaratQualityFilter = {
  equals?: InputMaybe<RingStoneCaratQuality>;
  in?: InputMaybe<Array<RingStoneCaratQuality>>;
  not?: InputMaybe<NestedEnumRingStoneCaratQualityFilter>;
  notIn?: InputMaybe<Array<RingStoneCaratQuality>>;
};

export type EnumRingStoneCaratQualityWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumRingStoneCaratQualityFilter>;
  _min?: InputMaybe<NestedEnumRingStoneCaratQualityFilter>;
  equals?: InputMaybe<RingStoneCaratQuality>;
  in?: InputMaybe<Array<RingStoneCaratQuality>>;
  not?: InputMaybe<NestedEnumRingStoneCaratQualityWithAggregatesFilter>;
  notIn?: InputMaybe<Array<RingStoneCaratQuality>>;
};

export type EnumStaffRoleTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<StaffRoleType>;
};

export type EnumStaffRoleTypeFilter = {
  equals?: InputMaybe<StaffRoleType>;
  in?: InputMaybe<Array<StaffRoleType>>;
  not?: InputMaybe<NestedEnumStaffRoleTypeFilter>;
  notIn?: InputMaybe<Array<StaffRoleType>>;
};

export type EnumStaffRoleTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumStaffRoleTypeFilter>;
  _min?: InputMaybe<NestedEnumStaffRoleTypeFilter>;
  equals?: InputMaybe<StaffRoleType>;
  in?: InputMaybe<Array<StaffRoleType>>;
  not?: InputMaybe<NestedEnumStaffRoleTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<StaffRoleType>>;
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']>;
  divide?: InputMaybe<Scalars['Float']>;
  increment?: InputMaybe<Scalars['Float']>;
  multiply?: InputMaybe<Scalars['Float']>;
  set?: InputMaybe<Scalars['Float']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedFloatNullableFilter>;
  _min?: InputMaybe<NestedFloatNullableFilter>;
  _sum?: InputMaybe<NestedFloatNullableFilter>;
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type GetAllLogs = {
  __typename?: 'GetAllLogs';
  count: Scalars['Float'];
  logs: Array<StaffLog>;
};

export type GuestBasketItem = {
  __typename?: 'GuestBasketItem';
  additionalInfo?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  product: Product;
  productSelectedCreatables: Array<ProductCreatable>;
  quantity: Scalars['Int'];
  ringColorId?: Maybe<Scalars['String']>;
  ringColorPurityId?: Maybe<Scalars['String']>;
  ringSizeId?: Maybe<Scalars['String']>;
  ringText?: Maybe<Scalars['String']>;
  selectedPrice: Scalars['Float'];
  stoneGroupId?: Maybe<Scalars['String']>;
};

export type HomePagePayload = {
  __typename?: 'HomePagePayload';
  blogs: Array<Blog>;
  categories: Array<Category>;
  latestProducts: Array<Product>;
  page: Page;
  sliders: Array<Slider>;
  styles: Array<Style>;
};

export type ICustomProductsPayload = {
  __typename?: 'ICustomProductsPayload';
  categoryDescription?: Maybe<Scalars['String']>;
  categorySlug: Scalars['String'];
  categoryTitle: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  discountAmount: Scalars['Int'];
  discountType: DiscountType;
  discountedPrice: Scalars['Float'];
  id: Scalars['String'];
  order: Scalars['Float'];
  originalPrice: Scalars['Float'];
  photos: Array<Scalars['String']>;
  price: Scalars['Float'];
  slug: Scalars['String'];
  stoneGroups?: Maybe<Array<ProductRingStoneGroup>>;
  title: Scalars['String'];
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type JsonFilter = {
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type JsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type JsonNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['JSON']>>;
  has?: InputMaybe<Scalars['JSON']>;
  hasEvery?: InputMaybe<Array<Scalars['JSON']>>;
  hasSome?: InputMaybe<Array<Scalars['JSON']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type JsonNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedJsonNullableFilter>;
  _min?: InputMaybe<NestedJsonNullableFilter>;
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type JsonWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedJsonFilter>;
  _min?: InputMaybe<NestedJsonFilter>;
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export enum LanguageOptions {
  DE = 'DE',
  EN = 'EN',
  ES = 'ES',
  FR = 'FR',
  IT = 'IT',
  TR = 'TR'
}

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  CustomProperty: Scalars['JSONObject'];
  addBasket: BasketInputPayload;
  addGuestBasket: BasketInputPayload;
  calculatePrice: Scalars['Float'];
  clearGuestBasket: BasketInputPayload;
  createCheckoutForm: Scalars['String'];
  createGuestBasket: BasketInputPayload;
  createManyBasket: AffectedRowsOutput;
  createManyBasketItem: AffectedRowsOutput;
  createManyBlog: AffectedRowsOutput;
  createManyBlogCategory: AffectedRowsOutput;
  createManyCategory: AffectedRowsOutput;
  createManyCreatable: AffectedRowsOutput;
  createManyCustomer: AffectedRowsOutput;
  createManyDescription: AffectedRowsOutput;
  createManyDiscountCoupon: AffectedRowsOutput;
  createManyOrder: AffectedRowsOutput;
  createManyPage: AffectedRowsOutput;
  createManyProduct: AffectedRowsOutput;
  createManyProductPriceConstant: AffectedRowsOutput;
  createManyProductRingStone: AffectedRowsOutput;
  createManyProductRingStoneGroup: AffectedRowsOutput;
  createManyRingColor: AffectedRowsOutput;
  createManyRingColorPurity: AffectedRowsOutput;
  createManyRingSize: AffectedRowsOutput;
  createManyRingSizeStandart: AffectedRowsOutput;
  createManyRingStoneCarat: AffectedRowsOutput;
  createManyRingStoneCaratInfo: AffectedRowsOutput;
  createManyRingStoneShape: AffectedRowsOutput;
  createManySeo: AffectedRowsOutput;
  createManySlider: AffectedRowsOutput;
  createManyStaff: AffectedRowsOutput;
  createManyStaffLog: AffectedRowsOutput;
  createManyStyle: AffectedRowsOutput;
  createOneBasket: Basket;
  createOneBasketItem: BasketItem;
  createOneBlog: Blog;
  createOneBlogCategory: BlogCategory;
  createOneCategory: Category;
  createOneCreatable: Creatable;
  createOneCustomer: Customer;
  createOneDescription: Description;
  createOneDiscountCoupon: DiscountCoupon;
  createOneOrder: Order;
  createOnePage: Page;
  createOneProduct: Product;
  createOneProductPriceConstant: ProductPriceConstant;
  createOneProductRingStone: ProductRingStone;
  createOneProductRingStoneGroup: ProductRingStoneGroup;
  createOneRingColor: RingColor;
  createOneRingColorPurity: RingColorPurity;
  createOneRingSize: RingSize;
  createOneRingSizeStandart: RingSizeStandart;
  createOneRingStoneCarat: RingStoneCarat;
  createOneRingStoneCaratInfo: RingStoneCaratInfo;
  createOneRingStoneShape: RingStoneShape;
  createOneSeo: Seo;
  createOneSlider: Slider;
  createOneStaff: Staff;
  createOneStaffLog: StaffLog;
  createOneStyle: Style;
  createOneTax: Tax;
  createProductCustom: Product;
  customerLogin: CustomerAuthPayload;
  /** Registration */
  customerRegister: CustomerAuthPayload;
  deleteManyBasket: AffectedRowsOutput;
  deleteManyBasketItem: AffectedRowsOutput;
  deleteManyBlog: AffectedRowsOutput;
  deleteManyBlogCategory: AffectedRowsOutput;
  deleteManyCategory: AffectedRowsOutput;
  deleteManyCreatable: AffectedRowsOutput;
  deleteManyCustomer: AffectedRowsOutput;
  deleteManyDescription: AffectedRowsOutput;
  deleteManyDiscountCoupon: AffectedRowsOutput;
  deleteManyOrder: AffectedRowsOutput;
  deleteManyPage: AffectedRowsOutput;
  deleteManyProduct: AffectedRowsOutput;
  deleteManyProductPriceConstant: AffectedRowsOutput;
  deleteManyProductRingStone: AffectedRowsOutput;
  deleteManyProductRingStoneGroup: AffectedRowsOutput;
  deleteManyRingColor: AffectedRowsOutput;
  deleteManyRingColorPurity: AffectedRowsOutput;
  deleteManyRingSize: AffectedRowsOutput;
  deleteManyRingSizeStandart: AffectedRowsOutput;
  deleteManyRingStoneCarat: AffectedRowsOutput;
  deleteManyRingStoneCaratInfo: AffectedRowsOutput;
  deleteManyRingStoneShape: AffectedRowsOutput;
  deleteManySeo: AffectedRowsOutput;
  deleteManySlider: AffectedRowsOutput;
  deleteManyStaff: AffectedRowsOutput;
  deleteManyStaffLog: AffectedRowsOutput;
  deleteManyStyle: AffectedRowsOutput;
  deleteOneBasket?: Maybe<Basket>;
  deleteOneBasketItem?: Maybe<BasketItem>;
  deleteOneBlog?: Maybe<Blog>;
  deleteOneBlogCategory?: Maybe<BlogCategory>;
  deleteOneCategory?: Maybe<Category>;
  deleteOneCreatable?: Maybe<Creatable>;
  deleteOneCustomer?: Maybe<Customer>;
  deleteOneDescription?: Maybe<Description>;
  deleteOneDiscountCoupon?: Maybe<DiscountCoupon>;
  deleteOneOrder?: Maybe<Order>;
  deleteOnePage?: Maybe<Page>;
  deleteOneProduct?: Maybe<Product>;
  deleteOneProductPriceConstant?: Maybe<ProductPriceConstant>;
  deleteOneProductRingStone?: Maybe<ProductRingStone>;
  deleteOneProductRingStoneGroup?: Maybe<ProductRingStoneGroup>;
  deleteOneRingColor?: Maybe<RingColor>;
  deleteOneRingColorPurity?: Maybe<RingColorPurity>;
  deleteOneRingSize?: Maybe<RingSize>;
  deleteOneRingSizeStandart?: Maybe<RingSizeStandart>;
  deleteOneRingStoneCarat?: Maybe<RingStoneCarat>;
  deleteOneRingStoneCaratInfo?: Maybe<RingStoneCaratInfo>;
  deleteOneRingStoneShape?: Maybe<RingStoneShape>;
  deleteOneSeo?: Maybe<Seo>;
  deleteOneSlider?: Maybe<Slider>;
  deleteOneStaff?: Maybe<Staff>;
  deleteOneStaffLog?: Maybe<StaffLog>;
  deleteOneStyle?: Maybe<Style>;
  deleteOneTax?: Maybe<Tax>;
  forgotChangePassword: Scalars['Boolean'];
  getCountryByIp: Country;
  makeCategoryDiscount: Scalars['Boolean'];
  makeProductDiscount: Scalars['Boolean'];
  productUploadImages: Scalars['Boolean'];
  refreshProductPrices: Scalars['Boolean'];
  removeBasket: BasketInputPayload;
  removeGuestBasket: BasketInputPayload;
  sendForgetPasswordEmail: Scalars['Boolean'];
  setGuestBasketForNewUser: Scalars['String'];
  staffLogin: StaffLoginPayload;
  staffLogout: Scalars['Boolean'];
  switchProductStatus: Scalars['Boolean'];
  updateBasket: BasketInputPayload;
  updateManyBasket: AffectedRowsOutput;
  updateManyBasketItem: AffectedRowsOutput;
  updateManyBlog: AffectedRowsOutput;
  updateManyBlogCategory: AffectedRowsOutput;
  updateManyCategory: AffectedRowsOutput;
  updateManyCreatable: AffectedRowsOutput;
  updateManyCustomer: AffectedRowsOutput;
  updateManyDescription: AffectedRowsOutput;
  updateManyDiscountCoupon: AffectedRowsOutput;
  updateManyOrder: AffectedRowsOutput;
  updateManyPage: AffectedRowsOutput;
  updateManyProduct: AffectedRowsOutput;
  updateManyProductPriceConstant: AffectedRowsOutput;
  updateManyProductRingStone: AffectedRowsOutput;
  updateManyProductRingStoneGroup: AffectedRowsOutput;
  updateManyRingColor: AffectedRowsOutput;
  updateManyRingColorPurity: AffectedRowsOutput;
  updateManyRingSize: AffectedRowsOutput;
  updateManyRingSizeStandart: AffectedRowsOutput;
  updateManyRingStoneCarat: AffectedRowsOutput;
  updateManyRingStoneCaratInfo: AffectedRowsOutput;
  updateManyRingStoneShape: AffectedRowsOutput;
  updateManySeo: AffectedRowsOutput;
  updateManySlider: AffectedRowsOutput;
  updateManyStaff: AffectedRowsOutput;
  updateManyStaffLog: AffectedRowsOutput;
  updateManyStyle: AffectedRowsOutput;
  updateMeCustomer: Scalars['Boolean'];
  updateOneBasket?: Maybe<Basket>;
  updateOneBasketItem?: Maybe<BasketItem>;
  updateOneBlog?: Maybe<Blog>;
  updateOneBlogCategory?: Maybe<BlogCategory>;
  updateOneCategory?: Maybe<Category>;
  updateOneCountry?: Maybe<Country>;
  updateOneCreatable?: Maybe<Creatable>;
  updateOneCustomer?: Maybe<Customer>;
  updateOneDescription?: Maybe<Description>;
  updateOneDiscountCoupon?: Maybe<DiscountCoupon>;
  updateOneOrder?: Maybe<Order>;
  updateOnePage?: Maybe<Page>;
  updateOneProduct?: Maybe<Product>;
  updateOneProductPriceConstant?: Maybe<ProductPriceConstant>;
  updateOneProductRingStone?: Maybe<ProductRingStone>;
  updateOneProductRingStoneGroup?: Maybe<ProductRingStoneGroup>;
  updateOneRingColor?: Maybe<RingColor>;
  updateOneRingColorPurity?: Maybe<RingColorPurity>;
  updateOneRingSize?: Maybe<RingSize>;
  updateOneRingSizeStandart?: Maybe<RingSizeStandart>;
  updateOneRingStoneCarat?: Maybe<RingStoneCarat>;
  updateOneRingStoneCaratInfo?: Maybe<RingStoneCaratInfo>;
  updateOneRingStoneShape?: Maybe<RingStoneShape>;
  updateOneSeo?: Maybe<Seo>;
  updateOneSlider?: Maybe<Slider>;
  updateOneStaff?: Maybe<Staff>;
  updateOneStaffLog?: Maybe<StaffLog>;
  updateOneStyle?: Maybe<Style>;
  updateOneTax?: Maybe<Tax>;
  updateProduct: Product;
  updateProductSizes: Scalars['Boolean'];
  uploadFile: Scalars['JSONObject'];
  upsertOneBasket: Basket;
  upsertOneBasketItem: BasketItem;
  upsertOneBlog: Blog;
  upsertOneBlogCategory: BlogCategory;
  upsertOneCategory: Category;
  upsertOneCreatable: Creatable;
  upsertOneCustomer: Customer;
  upsertOneDescription: Description;
  upsertOneDiscountCoupon: DiscountCoupon;
  upsertOneOrder: Order;
  upsertOnePage: Page;
  upsertOneProduct: Product;
  upsertOneProductPriceConstant: ProductPriceConstant;
  upsertOneProductRingStone: ProductRingStone;
  upsertOneProductRingStoneGroup: ProductRingStoneGroup;
  upsertOneRingColor: RingColor;
  upsertOneRingColorPurity: RingColorPurity;
  upsertOneRingSize: RingSize;
  upsertOneRingSizeStandart: RingSizeStandart;
  upsertOneRingStoneCarat: RingStoneCarat;
  upsertOneRingStoneCaratInfo: RingStoneCaratInfo;
  upsertOneRingStoneShape: RingStoneShape;
  upsertOneSeo: Seo;
  upsertOneSlider: Slider;
  upsertOneStaff: Staff;
  upsertOneStaffLog: StaffLog;
  upsertOneStyle: Style;
  useDiscountCoupon: DiscountCoupon;
};


export type MutationAddBasketArgs = {
  basketId: Scalars['String'];
};


export type MutationAddGuestBasketArgs = {
  basketItemId?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  data: AddBasketInput;
};


export type MutationCalculatePriceArgs = {
  data: ProductCreateCustomInput;
};


export type MutationClearGuestBasketArgs = {
  code: Scalars['String'];
};


export type MutationCreateCheckoutFormArgs = {
  data: CreateCheckoutFormInput;
};


export type MutationCreateManyBasketArgs = {
  data: Array<BasketCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyBasketItemArgs = {
  data: Array<BasketItemCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyBlogArgs = {
  data: Array<BlogCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyBlogCategoryArgs = {
  data: Array<BlogCategoryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCategoryArgs = {
  data: Array<CategoryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCreatableArgs = {
  data: Array<CreatableCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyCustomerArgs = {
  data: Array<CustomerCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyDescriptionArgs = {
  data: Array<DescriptionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyDiscountCouponArgs = {
  data: Array<DiscountCouponCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyOrderArgs = {
  data: Array<OrderCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyPageArgs = {
  data: Array<PageCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyProductArgs = {
  data: Array<ProductCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyProductPriceConstantArgs = {
  data: Array<ProductPriceConstantCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyProductRingStoneArgs = {
  data: Array<ProductRingStoneCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyProductRingStoneGroupArgs = {
  data: Array<ProductRingStoneGroupCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyRingColorArgs = {
  data: Array<RingColorCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyRingColorPurityArgs = {
  data: Array<RingColorPurityCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyRingSizeArgs = {
  data: Array<RingSizeCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyRingSizeStandartArgs = {
  data: Array<RingSizeStandartCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyRingStoneCaratArgs = {
  data: Array<RingStoneCaratCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyRingStoneCaratInfoArgs = {
  data: Array<RingStoneCaratInfoCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyRingStoneShapeArgs = {
  data: Array<RingStoneShapeCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManySeoArgs = {
  data: Array<SeoCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManySliderArgs = {
  data: Array<SliderCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyStaffArgs = {
  data: Array<StaffCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyStaffLogArgs = {
  data: Array<StaffLogCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyStyleArgs = {
  data: Array<StyleCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateOneBasketArgs = {
  data: BasketCreateInput;
};


export type MutationCreateOneBasketItemArgs = {
  data: BasketItemCreateInput;
};


export type MutationCreateOneBlogArgs = {
  data: BlogCreateInput;
};


export type MutationCreateOneBlogCategoryArgs = {
  data: BlogCategoryCreateInput;
};


export type MutationCreateOneCategoryArgs = {
  data: CategoryCreateInput;
};


export type MutationCreateOneCreatableArgs = {
  data: CreatableCreateInput;
};


export type MutationCreateOneCustomerArgs = {
  data: CustomerCreateInput;
};


export type MutationCreateOneDescriptionArgs = {
  data: DescriptionCreateInput;
};


export type MutationCreateOneDiscountCouponArgs = {
  data: DiscountCouponCreateInput;
};


export type MutationCreateOneOrderArgs = {
  data: OrderCreateInput;
};


export type MutationCreateOnePageArgs = {
  data: PageCreateInput;
};


export type MutationCreateOneProductArgs = {
  data: ProductCreateInput;
};


export type MutationCreateOneProductPriceConstantArgs = {
  data: ProductPriceConstantCreateInput;
};


export type MutationCreateOneProductRingStoneArgs = {
  data: ProductRingStoneCreateInput;
};


export type MutationCreateOneProductRingStoneGroupArgs = {
  data: ProductRingStoneGroupCreateInput;
};


export type MutationCreateOneRingColorArgs = {
  data: RingColorCreateInput;
};


export type MutationCreateOneRingColorPurityArgs = {
  data: RingColorPurityCreateInput;
};


export type MutationCreateOneRingSizeArgs = {
  data: RingSizeCreateInput;
};


export type MutationCreateOneRingSizeStandartArgs = {
  data: RingSizeStandartCreateInput;
};


export type MutationCreateOneRingStoneCaratArgs = {
  data: RingStoneCaratCreateInput;
};


export type MutationCreateOneRingStoneCaratInfoArgs = {
  data: RingStoneCaratInfoCreateInput;
};


export type MutationCreateOneRingStoneShapeArgs = {
  data: RingStoneShapeCreateInput;
};


export type MutationCreateOneSeoArgs = {
  data: SeoCreateInput;
};


export type MutationCreateOneSliderArgs = {
  data: SliderCreateInput;
};


export type MutationCreateOneStaffArgs = {
  data: StaffCreateInput;
};


export type MutationCreateOneStaffLogArgs = {
  data: StaffLogCreateInput;
};


export type MutationCreateOneStyleArgs = {
  data: StyleCreateInput;
};


export type MutationCreateOneTaxArgs = {
  data: TaxCreateInput;
};


export type MutationCreateProductCustomArgs = {
  data: ProductCreateCustomInput;
};


export type MutationCustomerLoginArgs = {
  data: LoginInput;
};


export type MutationCustomerRegisterArgs = {
  data: CustomerCreateInput;
};


export type MutationDeleteManyBasketArgs = {
  where?: InputMaybe<BasketWhereInput>;
};


export type MutationDeleteManyBasketItemArgs = {
  where?: InputMaybe<BasketItemWhereInput>;
};


export type MutationDeleteManyBlogArgs = {
  where?: InputMaybe<BlogWhereInput>;
};


export type MutationDeleteManyBlogCategoryArgs = {
  where?: InputMaybe<BlogCategoryWhereInput>;
};


export type MutationDeleteManyCategoryArgs = {
  where?: InputMaybe<CategoryWhereInput>;
};


export type MutationDeleteManyCreatableArgs = {
  where?: InputMaybe<CreatableWhereInput>;
};


export type MutationDeleteManyCustomerArgs = {
  where?: InputMaybe<CustomerWhereInput>;
};


export type MutationDeleteManyDescriptionArgs = {
  where?: InputMaybe<DescriptionWhereInput>;
};


export type MutationDeleteManyDiscountCouponArgs = {
  where?: InputMaybe<DiscountCouponWhereInput>;
};


export type MutationDeleteManyOrderArgs = {
  where?: InputMaybe<OrderWhereInput>;
};


export type MutationDeleteManyPageArgs = {
  where?: InputMaybe<PageWhereInput>;
};


export type MutationDeleteManyProductArgs = {
  where?: InputMaybe<ProductWhereInput>;
};


export type MutationDeleteManyProductPriceConstantArgs = {
  where?: InputMaybe<ProductPriceConstantWhereInput>;
};


export type MutationDeleteManyProductRingStoneArgs = {
  where?: InputMaybe<ProductRingStoneWhereInput>;
};


export type MutationDeleteManyProductRingStoneGroupArgs = {
  where?: InputMaybe<ProductRingStoneGroupWhereInput>;
};


export type MutationDeleteManyRingColorArgs = {
  where?: InputMaybe<RingColorWhereInput>;
};


export type MutationDeleteManyRingColorPurityArgs = {
  where?: InputMaybe<RingColorPurityWhereInput>;
};


export type MutationDeleteManyRingSizeArgs = {
  where?: InputMaybe<RingSizeWhereInput>;
};


export type MutationDeleteManyRingSizeStandartArgs = {
  where?: InputMaybe<RingSizeStandartWhereInput>;
};


export type MutationDeleteManyRingStoneCaratArgs = {
  where?: InputMaybe<RingStoneCaratWhereInput>;
};


export type MutationDeleteManyRingStoneCaratInfoArgs = {
  where?: InputMaybe<RingStoneCaratInfoWhereInput>;
};


export type MutationDeleteManyRingStoneShapeArgs = {
  where?: InputMaybe<RingStoneShapeWhereInput>;
};


export type MutationDeleteManySeoArgs = {
  where?: InputMaybe<SeoWhereInput>;
};


export type MutationDeleteManySliderArgs = {
  where?: InputMaybe<SliderWhereInput>;
};


export type MutationDeleteManyStaffArgs = {
  where?: InputMaybe<StaffWhereInput>;
};


export type MutationDeleteManyStaffLogArgs = {
  where?: InputMaybe<StaffLogWhereInput>;
};


export type MutationDeleteManyStyleArgs = {
  where?: InputMaybe<StyleWhereInput>;
};


export type MutationDeleteOneBasketArgs = {
  where: BasketWhereUniqueInput;
};


export type MutationDeleteOneBasketItemArgs = {
  where: BasketItemWhereUniqueInput;
};


export type MutationDeleteOneBlogArgs = {
  where: BlogWhereUniqueInput;
};


export type MutationDeleteOneBlogCategoryArgs = {
  where: BlogCategoryWhereUniqueInput;
};


export type MutationDeleteOneCategoryArgs = {
  where: CategoryWhereUniqueInput;
};


export type MutationDeleteOneCreatableArgs = {
  where: CreatableWhereUniqueInput;
};


export type MutationDeleteOneCustomerArgs = {
  where: CustomerWhereUniqueInput;
};


export type MutationDeleteOneDescriptionArgs = {
  where: DescriptionWhereUniqueInput;
};


export type MutationDeleteOneDiscountCouponArgs = {
  where: DiscountCouponWhereUniqueInput;
};


export type MutationDeleteOneOrderArgs = {
  where: OrderWhereUniqueInput;
};


export type MutationDeleteOnePageArgs = {
  where: PageWhereUniqueInput;
};


export type MutationDeleteOneProductArgs = {
  where: ProductWhereUniqueInput;
};


export type MutationDeleteOneProductPriceConstantArgs = {
  where: ProductPriceConstantWhereUniqueInput;
};


export type MutationDeleteOneProductRingStoneArgs = {
  where: ProductRingStoneWhereUniqueInput;
};


export type MutationDeleteOneProductRingStoneGroupArgs = {
  where: ProductRingStoneGroupWhereUniqueInput;
};


export type MutationDeleteOneRingColorArgs = {
  where: RingColorWhereUniqueInput;
};


export type MutationDeleteOneRingColorPurityArgs = {
  where: RingColorPurityWhereUniqueInput;
};


export type MutationDeleteOneRingSizeArgs = {
  where: RingSizeWhereUniqueInput;
};


export type MutationDeleteOneRingSizeStandartArgs = {
  where: RingSizeStandartWhereUniqueInput;
};


export type MutationDeleteOneRingStoneCaratArgs = {
  where: RingStoneCaratWhereUniqueInput;
};


export type MutationDeleteOneRingStoneCaratInfoArgs = {
  where: RingStoneCaratInfoWhereUniqueInput;
};


export type MutationDeleteOneRingStoneShapeArgs = {
  where: RingStoneShapeWhereUniqueInput;
};


export type MutationDeleteOneSeoArgs = {
  where: SeoWhereUniqueInput;
};


export type MutationDeleteOneSliderArgs = {
  where: SliderWhereUniqueInput;
};


export type MutationDeleteOneStaffArgs = {
  where: StaffWhereUniqueInput;
};


export type MutationDeleteOneStaffLogArgs = {
  where: StaffLogWhereUniqueInput;
};


export type MutationDeleteOneStyleArgs = {
  where: StyleWhereUniqueInput;
};


export type MutationDeleteOneTaxArgs = {
  where: TaxWhereUniqueInput;
};


export type MutationForgotChangePasswordArgs = {
  code: Scalars['String'];
  newPassword: StringFieldUpdateOperationsInput;
};


export type MutationMakeCategoryDiscountArgs = {
  discountAmount: Scalars['Float'];
  where: CategoryWhereUniqueInput;
};


export type MutationMakeProductDiscountArgs = {
  discountAmount: Scalars['Int'];
  discountType: DiscountType;
  productId: Scalars['String'];
};


export type MutationProductUploadImagesArgs = {
  files: Array<Scalars['String']>;
  productId: Scalars['String'];
};


export type MutationRefreshProductPricesArgs = {
  productIds: Array<Scalars['String']>;
};


export type MutationRemoveBasketArgs = {
  basketId: Scalars['String'];
};


export type MutationRemoveGuestBasketArgs = {
  basketItemId: Scalars['String'];
  code: Scalars['String'];
};


export type MutationSendForgetPasswordEmailArgs = {
  email: Scalars['String'];
};


export type MutationSetGuestBasketForNewUserArgs = {
  data: Array<SetGuestBasketForNewUserInput>;
};


export type MutationStaffLoginArgs = {
  data: LoginInput;
};


export type MutationStaffLogoutArgs = {
  data: StaffLogoutPayload;
};


export type MutationSwitchProductStatusArgs = {
  data: SwitchProductStatusInput;
};


export type MutationUpdateBasketArgs = {
  data: AddBasketInput;
};


export type MutationUpdateManyBasketArgs = {
  data: BasketUpdateManyMutationInput;
  where?: InputMaybe<BasketWhereInput>;
};


export type MutationUpdateManyBasketItemArgs = {
  data: BasketItemUpdateManyMutationInput;
  where?: InputMaybe<BasketItemWhereInput>;
};


export type MutationUpdateManyBlogArgs = {
  data: BlogUpdateManyMutationInput;
  where?: InputMaybe<BlogWhereInput>;
};


export type MutationUpdateManyBlogCategoryArgs = {
  data: BlogCategoryUpdateManyMutationInput;
  where?: InputMaybe<BlogCategoryWhereInput>;
};


export type MutationUpdateManyCategoryArgs = {
  data: CategoryUpdateManyMutationInput;
  where?: InputMaybe<CategoryWhereInput>;
};


export type MutationUpdateManyCreatableArgs = {
  data: CreatableUpdateManyMutationInput;
  where?: InputMaybe<CreatableWhereInput>;
};


export type MutationUpdateManyCustomerArgs = {
  data: CustomerUpdateManyMutationInput;
  where?: InputMaybe<CustomerWhereInput>;
};


export type MutationUpdateManyDescriptionArgs = {
  data: DescriptionUpdateManyMutationInput;
  where?: InputMaybe<DescriptionWhereInput>;
};


export type MutationUpdateManyDiscountCouponArgs = {
  data: DiscountCouponUpdateManyMutationInput;
  where?: InputMaybe<DiscountCouponWhereInput>;
};


export type MutationUpdateManyOrderArgs = {
  data: OrderUpdateManyMutationInput;
  where?: InputMaybe<OrderWhereInput>;
};


export type MutationUpdateManyPageArgs = {
  data: PageUpdateManyMutationInput;
  where?: InputMaybe<PageWhereInput>;
};


export type MutationUpdateManyProductArgs = {
  data: ProductUpdateManyMutationInput;
  where?: InputMaybe<ProductWhereInput>;
};


export type MutationUpdateManyProductPriceConstantArgs = {
  data: ProductPriceConstantUpdateManyMutationInput;
  where?: InputMaybe<ProductPriceConstantWhereInput>;
};


export type MutationUpdateManyProductRingStoneArgs = {
  data: ProductRingStoneUpdateManyMutationInput;
  where?: InputMaybe<ProductRingStoneWhereInput>;
};


export type MutationUpdateManyProductRingStoneGroupArgs = {
  data: ProductRingStoneGroupUpdateManyMutationInput;
  where?: InputMaybe<ProductRingStoneGroupWhereInput>;
};


export type MutationUpdateManyRingColorArgs = {
  data: RingColorUpdateManyMutationInput;
  where?: InputMaybe<RingColorWhereInput>;
};


export type MutationUpdateManyRingColorPurityArgs = {
  data: RingColorPurityUpdateManyMutationInput;
  where?: InputMaybe<RingColorPurityWhereInput>;
};


export type MutationUpdateManyRingSizeArgs = {
  data: RingSizeUpdateManyMutationInput;
  where?: InputMaybe<RingSizeWhereInput>;
};


export type MutationUpdateManyRingSizeStandartArgs = {
  data: RingSizeStandartUpdateManyMutationInput;
  where?: InputMaybe<RingSizeStandartWhereInput>;
};


export type MutationUpdateManyRingStoneCaratArgs = {
  data: RingStoneCaratUpdateManyMutationInput;
  where?: InputMaybe<RingStoneCaratWhereInput>;
};


export type MutationUpdateManyRingStoneCaratInfoArgs = {
  data: RingStoneCaratInfoUpdateManyMutationInput;
  where?: InputMaybe<RingStoneCaratInfoWhereInput>;
};


export type MutationUpdateManyRingStoneShapeArgs = {
  data: RingStoneShapeUpdateManyMutationInput;
  where?: InputMaybe<RingStoneShapeWhereInput>;
};


export type MutationUpdateManySeoArgs = {
  data: SeoUpdateManyMutationInput;
  where?: InputMaybe<SeoWhereInput>;
};


export type MutationUpdateManySliderArgs = {
  data: SliderUpdateManyMutationInput;
  where?: InputMaybe<SliderWhereInput>;
};


export type MutationUpdateManyStaffArgs = {
  data: StaffUpdateManyMutationInput;
  where?: InputMaybe<StaffWhereInput>;
};


export type MutationUpdateManyStaffLogArgs = {
  data: StaffLogUpdateManyMutationInput;
  where?: InputMaybe<StaffLogWhereInput>;
};


export type MutationUpdateManyStyleArgs = {
  data: StyleUpdateManyMutationInput;
  where?: InputMaybe<StyleWhereInput>;
};


export type MutationUpdateMeCustomerArgs = {
  data: UpdateMeInput;
};


export type MutationUpdateOneBasketArgs = {
  data: BasketUpdateInput;
  where: BasketWhereUniqueInput;
};


export type MutationUpdateOneBasketItemArgs = {
  data: BasketItemUpdateInput;
  where: BasketItemWhereUniqueInput;
};


export type MutationUpdateOneBlogArgs = {
  data: BlogUpdateInput;
  where: BlogWhereUniqueInput;
};


export type MutationUpdateOneBlogCategoryArgs = {
  data: BlogCategoryUpdateInput;
  where: BlogCategoryWhereUniqueInput;
};


export type MutationUpdateOneCategoryArgs = {
  data: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};


export type MutationUpdateOneCountryArgs = {
  data: CountryUpdateInput;
  where: CountryWhereUniqueInput;
};


export type MutationUpdateOneCreatableArgs = {
  data: CreatableUpdateInput;
  where: CreatableWhereUniqueInput;
};


export type MutationUpdateOneCustomerArgs = {
  data: CustomerUpdateInput;
  where: CustomerWhereUniqueInput;
};


export type MutationUpdateOneDescriptionArgs = {
  data: DescriptionUpdateInput;
  where: DescriptionWhereUniqueInput;
};


export type MutationUpdateOneDiscountCouponArgs = {
  data: DiscountCouponUpdateInput;
  where: DiscountCouponWhereUniqueInput;
};


export type MutationUpdateOneOrderArgs = {
  data: OrderUpdateInput;
  where: OrderWhereUniqueInput;
};


export type MutationUpdateOnePageArgs = {
  data: PageUpdateInput;
  where: PageWhereUniqueInput;
};


export type MutationUpdateOneProductArgs = {
  data: ProductUpdateInput;
  where: ProductWhereUniqueInput;
};


export type MutationUpdateOneProductPriceConstantArgs = {
  data: ProductPriceConstantUpdateInput;
  where: ProductPriceConstantWhereUniqueInput;
};


export type MutationUpdateOneProductRingStoneArgs = {
  data: ProductRingStoneUpdateInput;
  where: ProductRingStoneWhereUniqueInput;
};


export type MutationUpdateOneProductRingStoneGroupArgs = {
  data: ProductRingStoneGroupUpdateInput;
  where: ProductRingStoneGroupWhereUniqueInput;
};


export type MutationUpdateOneRingColorArgs = {
  data: RingColorUpdateInput;
  where: RingColorWhereUniqueInput;
};


export type MutationUpdateOneRingColorPurityArgs = {
  data: RingColorPurityUpdateInput;
  where: RingColorPurityWhereUniqueInput;
};


export type MutationUpdateOneRingSizeArgs = {
  data: RingSizeUpdateInput;
  where: RingSizeWhereUniqueInput;
};


export type MutationUpdateOneRingSizeStandartArgs = {
  data: RingSizeStandartUpdateInput;
  where: RingSizeStandartWhereUniqueInput;
};


export type MutationUpdateOneRingStoneCaratArgs = {
  data: RingStoneCaratUpdateInput;
  where: RingStoneCaratWhereUniqueInput;
};


export type MutationUpdateOneRingStoneCaratInfoArgs = {
  data: RingStoneCaratInfoUpdateInput;
  where: RingStoneCaratInfoWhereUniqueInput;
};


export type MutationUpdateOneRingStoneShapeArgs = {
  data: RingStoneShapeUpdateInput;
  where: RingStoneShapeWhereUniqueInput;
};


export type MutationUpdateOneSeoArgs = {
  data: SeoUpdateInput;
  where: SeoWhereUniqueInput;
};


export type MutationUpdateOneSliderArgs = {
  data: SliderUpdateInput;
  where: SliderWhereUniqueInput;
};


export type MutationUpdateOneStaffArgs = {
  data: StaffUpdateInput;
  where: StaffWhereUniqueInput;
};


export type MutationUpdateOneStaffLogArgs = {
  data: StaffLogUpdateInput;
  where: StaffLogWhereUniqueInput;
};


export type MutationUpdateOneStyleArgs = {
  data: StyleUpdateInput;
  where: StyleWhereUniqueInput;
};


export type MutationUpdateOneTaxArgs = {
  data: TaxUpdateInput;
  where: TaxWhereUniqueInput;
};


export type MutationUpdateProductArgs = {
  data: ProductUpdateCustomInput;
  where: ProductWhereUniqueInput;
};


export type MutationUpdateProductSizesArgs = {
  categoryName: Scalars['String'];
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
};


export type MutationUpsertOneBasketArgs = {
  create: BasketCreateInput;
  update: BasketUpdateInput;
  where: BasketWhereUniqueInput;
};


export type MutationUpsertOneBasketItemArgs = {
  create: BasketItemCreateInput;
  update: BasketItemUpdateInput;
  where: BasketItemWhereUniqueInput;
};


export type MutationUpsertOneBlogArgs = {
  create: BlogCreateInput;
  update: BlogUpdateInput;
  where: BlogWhereUniqueInput;
};


export type MutationUpsertOneBlogCategoryArgs = {
  create: BlogCategoryCreateInput;
  update: BlogCategoryUpdateInput;
  where: BlogCategoryWhereUniqueInput;
};


export type MutationUpsertOneCategoryArgs = {
  create: CategoryCreateInput;
  update: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};


export type MutationUpsertOneCreatableArgs = {
  create: CreatableCreateInput;
  update: CreatableUpdateInput;
  where: CreatableWhereUniqueInput;
};


export type MutationUpsertOneCustomerArgs = {
  create: CustomerCreateInput;
  update: CustomerUpdateInput;
  where: CustomerWhereUniqueInput;
};


export type MutationUpsertOneDescriptionArgs = {
  create: DescriptionCreateInput;
  update: DescriptionUpdateInput;
  where: DescriptionWhereUniqueInput;
};


export type MutationUpsertOneDiscountCouponArgs = {
  create: DiscountCouponCreateInput;
  update: DiscountCouponUpdateInput;
  where: DiscountCouponWhereUniqueInput;
};


export type MutationUpsertOneOrderArgs = {
  create: OrderCreateInput;
  update: OrderUpdateInput;
  where: OrderWhereUniqueInput;
};


export type MutationUpsertOnePageArgs = {
  create: PageCreateInput;
  update: PageUpdateInput;
  where: PageWhereUniqueInput;
};


export type MutationUpsertOneProductArgs = {
  create: ProductCreateInput;
  update: ProductUpdateInput;
  where: ProductWhereUniqueInput;
};


export type MutationUpsertOneProductPriceConstantArgs = {
  create: ProductPriceConstantCreateInput;
  update: ProductPriceConstantUpdateInput;
  where: ProductPriceConstantWhereUniqueInput;
};


export type MutationUpsertOneProductRingStoneArgs = {
  create: ProductRingStoneCreateInput;
  update: ProductRingStoneUpdateInput;
  where: ProductRingStoneWhereUniqueInput;
};


export type MutationUpsertOneProductRingStoneGroupArgs = {
  create: ProductRingStoneGroupCreateInput;
  update: ProductRingStoneGroupUpdateInput;
  where: ProductRingStoneGroupWhereUniqueInput;
};


export type MutationUpsertOneRingColorArgs = {
  create: RingColorCreateInput;
  update: RingColorUpdateInput;
  where: RingColorWhereUniqueInput;
};


export type MutationUpsertOneRingColorPurityArgs = {
  create: RingColorPurityCreateInput;
  update: RingColorPurityUpdateInput;
  where: RingColorPurityWhereUniqueInput;
};


export type MutationUpsertOneRingSizeArgs = {
  create: RingSizeCreateInput;
  update: RingSizeUpdateInput;
  where: RingSizeWhereUniqueInput;
};


export type MutationUpsertOneRingSizeStandartArgs = {
  create: RingSizeStandartCreateInput;
  update: RingSizeStandartUpdateInput;
  where: RingSizeStandartWhereUniqueInput;
};


export type MutationUpsertOneRingStoneCaratArgs = {
  create: RingStoneCaratCreateInput;
  update: RingStoneCaratUpdateInput;
  where: RingStoneCaratWhereUniqueInput;
};


export type MutationUpsertOneRingStoneCaratInfoArgs = {
  create: RingStoneCaratInfoCreateInput;
  update: RingStoneCaratInfoUpdateInput;
  where: RingStoneCaratInfoWhereUniqueInput;
};


export type MutationUpsertOneRingStoneShapeArgs = {
  create: RingStoneShapeCreateInput;
  update: RingStoneShapeUpdateInput;
  where: RingStoneShapeWhereUniqueInput;
};


export type MutationUpsertOneSeoArgs = {
  create: SeoCreateInput;
  update: SeoUpdateInput;
  where: SeoWhereUniqueInput;
};


export type MutationUpsertOneSliderArgs = {
  create: SliderCreateInput;
  update: SliderUpdateInput;
  where: SliderWhereUniqueInput;
};


export type MutationUpsertOneStaffArgs = {
  create: StaffCreateInput;
  update: StaffUpdateInput;
  where: StaffWhereUniqueInput;
};


export type MutationUpsertOneStaffLogArgs = {
  create: StaffLogCreateInput;
  update: StaffLogUpdateInput;
  where: StaffLogWhereUniqueInput;
};


export type MutationUpsertOneStyleArgs = {
  create: StyleCreateInput;
  update: StyleUpdateInput;
  where: StyleWhereUniqueInput;
};


export type MutationUseDiscountCouponArgs = {
  couponCode: Scalars['String'];
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedDecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedDecimalNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDecimalNullableFilter>;
  _min?: InputMaybe<NestedDecimalNullableFilter>;
  _sum?: InputMaybe<NestedDecimalNullableFilter>;
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedDecimalWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDecimalFilter>;
  _min?: InputMaybe<NestedDecimalFilter>;
  _sum?: InputMaybe<NestedDecimalFilter>;
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedEnumCreatableOperationTypeFilter = {
  equals?: InputMaybe<CreatableOperationType>;
  in?: InputMaybe<Array<CreatableOperationType>>;
  not?: InputMaybe<NestedEnumCreatableOperationTypeFilter>;
  notIn?: InputMaybe<Array<CreatableOperationType>>;
};

export type NestedEnumCreatableOperationTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCreatableOperationTypeFilter>;
  _min?: InputMaybe<NestedEnumCreatableOperationTypeFilter>;
  equals?: InputMaybe<CreatableOperationType>;
  in?: InputMaybe<Array<CreatableOperationType>>;
  not?: InputMaybe<NestedEnumCreatableOperationTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CreatableOperationType>>;
};

export type NestedEnumCreatableTypeFilter = {
  equals?: InputMaybe<CreatableType>;
  in?: InputMaybe<Array<CreatableType>>;
  not?: InputMaybe<NestedEnumCreatableTypeFilter>;
  notIn?: InputMaybe<Array<CreatableType>>;
};

export type NestedEnumCreatableTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCreatableTypeFilter>;
  _min?: InputMaybe<NestedEnumCreatableTypeFilter>;
  equals?: InputMaybe<CreatableType>;
  in?: InputMaybe<Array<CreatableType>>;
  not?: InputMaybe<NestedEnumCreatableTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CreatableType>>;
};

export type NestedEnumCustomerRoleFilter = {
  equals?: InputMaybe<CustomerRole>;
  in?: InputMaybe<Array<CustomerRole>>;
  not?: InputMaybe<NestedEnumCustomerRoleFilter>;
  notIn?: InputMaybe<Array<CustomerRole>>;
};

export type NestedEnumCustomerRoleWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCustomerRoleFilter>;
  _min?: InputMaybe<NestedEnumCustomerRoleFilter>;
  equals?: InputMaybe<CustomerRole>;
  in?: InputMaybe<Array<CustomerRole>>;
  not?: InputMaybe<NestedEnumCustomerRoleWithAggregatesFilter>;
  notIn?: InputMaybe<Array<CustomerRole>>;
};

export type NestedEnumDiscountTypeFilter = {
  equals?: InputMaybe<DiscountType>;
  in?: InputMaybe<Array<DiscountType>>;
  not?: InputMaybe<NestedEnumDiscountTypeFilter>;
  notIn?: InputMaybe<Array<DiscountType>>;
};

export type NestedEnumDiscountTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumDiscountTypeFilter>;
  _min?: InputMaybe<NestedEnumDiscountTypeFilter>;
  equals?: InputMaybe<DiscountType>;
  in?: InputMaybe<Array<DiscountType>>;
  not?: InputMaybe<NestedEnumDiscountTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<DiscountType>>;
};

export type NestedEnumLanguageOptionsFilter = {
  equals?: InputMaybe<LanguageOptions>;
  in?: InputMaybe<Array<LanguageOptions>>;
  not?: InputMaybe<NestedEnumLanguageOptionsFilter>;
  notIn?: InputMaybe<Array<LanguageOptions>>;
};

export type NestedEnumLanguageOptionsWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumLanguageOptionsFilter>;
  _min?: InputMaybe<NestedEnumLanguageOptionsFilter>;
  equals?: InputMaybe<LanguageOptions>;
  in?: InputMaybe<Array<LanguageOptions>>;
  not?: InputMaybe<NestedEnumLanguageOptionsWithAggregatesFilter>;
  notIn?: InputMaybe<Array<LanguageOptions>>;
};

export type NestedEnumOrderStatusFilter = {
  equals?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  not?: InputMaybe<NestedEnumOrderStatusFilter>;
  notIn?: InputMaybe<Array<OrderStatus>>;
};

export type NestedEnumOrderStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumOrderStatusFilter>;
  _min?: InputMaybe<NestedEnumOrderStatusFilter>;
  equals?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  not?: InputMaybe<NestedEnumOrderStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<OrderStatus>>;
};

export type NestedEnumPageTypeFilter = {
  equals?: InputMaybe<PageType>;
  in?: InputMaybe<Array<PageType>>;
  not?: InputMaybe<NestedEnumPageTypeFilter>;
  notIn?: InputMaybe<Array<PageType>>;
};

export type NestedEnumPageTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPageTypeFilter>;
  _min?: InputMaybe<NestedEnumPageTypeFilter>;
  equals?: InputMaybe<PageType>;
  in?: InputMaybe<Array<PageType>>;
  not?: InputMaybe<NestedEnumPageTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PageType>>;
};

export type NestedEnumPaymentTypeNullableFilter = {
  equals?: InputMaybe<PaymentType>;
  in?: InputMaybe<Array<PaymentType>>;
  not?: InputMaybe<NestedEnumPaymentTypeNullableFilter>;
  notIn?: InputMaybe<Array<PaymentType>>;
};

export type NestedEnumPaymentTypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumPaymentTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumPaymentTypeNullableFilter>;
  equals?: InputMaybe<PaymentType>;
  in?: InputMaybe<Array<PaymentType>>;
  not?: InputMaybe<NestedEnumPaymentTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PaymentType>>;
};

export type NestedEnumRingStoneCaratQualityFilter = {
  equals?: InputMaybe<RingStoneCaratQuality>;
  in?: InputMaybe<Array<RingStoneCaratQuality>>;
  not?: InputMaybe<NestedEnumRingStoneCaratQualityFilter>;
  notIn?: InputMaybe<Array<RingStoneCaratQuality>>;
};

export type NestedEnumRingStoneCaratQualityWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumRingStoneCaratQualityFilter>;
  _min?: InputMaybe<NestedEnumRingStoneCaratQualityFilter>;
  equals?: InputMaybe<RingStoneCaratQuality>;
  in?: InputMaybe<Array<RingStoneCaratQuality>>;
  not?: InputMaybe<NestedEnumRingStoneCaratQualityWithAggregatesFilter>;
  notIn?: InputMaybe<Array<RingStoneCaratQuality>>;
};

export type NestedEnumStaffRoleTypeFilter = {
  equals?: InputMaybe<StaffRoleType>;
  in?: InputMaybe<Array<StaffRoleType>>;
  not?: InputMaybe<NestedEnumStaffRoleTypeFilter>;
  notIn?: InputMaybe<Array<StaffRoleType>>;
};

export type NestedEnumStaffRoleTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumStaffRoleTypeFilter>;
  _min?: InputMaybe<NestedEnumStaffRoleTypeFilter>;
  equals?: InputMaybe<StaffRoleType>;
  in?: InputMaybe<Array<StaffRoleType>>;
  not?: InputMaybe<NestedEnumStaffRoleTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<StaffRoleType>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedFloatNullableFilter>;
  _min?: InputMaybe<NestedFloatNullableFilter>;
  _sum?: InputMaybe<NestedFloatNullableFilter>;
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedJsonFilter = {
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type NestedJsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type NullableDecimalFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Decimal']>;
  divide?: InputMaybe<Scalars['Decimal']>;
  increment?: InputMaybe<Scalars['Decimal']>;
  multiply?: InputMaybe<Scalars['Decimal']>;
  set?: InputMaybe<Scalars['Decimal']>;
};

export type NullableEnumPaymentTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<PaymentType>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']>;
  divide?: InputMaybe<Scalars['Float']>;
  increment?: InputMaybe<Scalars['Float']>;
  multiply?: InputMaybe<Scalars['Float']>;
  set?: InputMaybe<Scalars['Float']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  _count?: Maybe<OrderCount>;
  agreementSale?: Maybe<Scalars['String']>;
  conversationId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerId: Scalars['String'];
  discountCoupon?: Maybe<DiscountCoupon>;
  discountCouponId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  identityNumber?: Maybe<Scalars['String']>;
  informationSale?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  isCompleted: Scalars['Boolean'];
  isShipped: Scalars['Boolean'];
  language?: Maybe<Scalars['String']>;
  orderHistories: Array<OrderHistory>;
  orderStatus: OrderStatus;
  payment?: Maybe<Payment>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentCompany?: Maybe<Scalars['String']>;
  paymentCountry?: Maybe<Scalars['String']>;
  paymentEmail?: Maybe<Scalars['String']>;
  paymentFirstName?: Maybe<Scalars['String']>;
  paymentLastName?: Maybe<Scalars['String']>;
  paymentPhoneNumber?: Maybe<Scalars['String']>;
  paymentPostalCode?: Maybe<Scalars['String']>;
  paymentTaxNumber?: Maybe<Scalars['String']>;
  paymentTaxOffice?: Maybe<Scalars['String']>;
  paymentType?: Maybe<PaymentType>;
  products: Array<ProductOrder>;
  shippingAddress?: Maybe<Scalars['String']>;
  shippingCity?: Maybe<Scalars['String']>;
  shippingCompany?: Maybe<Scalars['String']>;
  shippingConvertedPrice?: Maybe<Scalars['Float']>;
  shippingCountry?: Maybe<Scalars['String']>;
  shippingEmail?: Maybe<Scalars['String']>;
  shippingFirstName?: Maybe<Scalars['String']>;
  shippingLastName?: Maybe<Scalars['String']>;
  shippingNumber?: Maybe<Scalars['String']>;
  shippingPhoneNumber?: Maybe<Scalars['String']>;
  shippingPostalCode?: Maybe<Scalars['String']>;
  shippingPrice?: Maybe<Scalars['Float']>;
  shippingTitle?: Maybe<Scalars['String']>;
};


export type OrderOrderHistoriesArgs = {
  cursor?: InputMaybe<OrderHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderHistoryWhereInput>;
};


export type OrderProductsArgs = {
  cursor?: InputMaybe<ProductOrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductOrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductOrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductOrderWhereInput>;
};

export type OrderAvgAggregate = {
  __typename?: 'OrderAvgAggregate';
  shippingConvertedPrice?: Maybe<Scalars['Float']>;
  shippingPrice?: Maybe<Scalars['Float']>;
};

export type OrderAvgOrderByAggregateInput = {
  shippingConvertedPrice?: InputMaybe<SortOrder>;
  shippingPrice?: InputMaybe<SortOrder>;
};

export type OrderCount = {
  __typename?: 'OrderCount';
  orderHistories: Scalars['Int'];
  products: Scalars['Int'];
};

export type OrderCountAggregate = {
  __typename?: 'OrderCountAggregate';
  _all: Scalars['Int'];
  agreementSale: Scalars['Int'];
  conversationId: Scalars['Int'];
  createdAt: Scalars['Int'];
  customerId: Scalars['Int'];
  discountCouponId: Scalars['Int'];
  id: Scalars['Int'];
  identityNumber: Scalars['Int'];
  informationSale: Scalars['Int'];
  invoiceNumber: Scalars['Int'];
  isCompleted: Scalars['Int'];
  isShipped: Scalars['Int'];
  language: Scalars['Int'];
  orderStatus: Scalars['Int'];
  paymentAddress: Scalars['Int'];
  paymentCity: Scalars['Int'];
  paymentCompany: Scalars['Int'];
  paymentCountry: Scalars['Int'];
  paymentEmail: Scalars['Int'];
  paymentFirstName: Scalars['Int'];
  paymentLastName: Scalars['Int'];
  paymentPhoneNumber: Scalars['Int'];
  paymentPostalCode: Scalars['Int'];
  paymentTaxNumber: Scalars['Int'];
  paymentTaxOffice: Scalars['Int'];
  paymentType: Scalars['Int'];
  shippingAddress: Scalars['Int'];
  shippingCity: Scalars['Int'];
  shippingCompany: Scalars['Int'];
  shippingConvertedPrice: Scalars['Int'];
  shippingCountry: Scalars['Int'];
  shippingEmail: Scalars['Int'];
  shippingFirstName: Scalars['Int'];
  shippingLastName: Scalars['Int'];
  shippingNumber: Scalars['Int'];
  shippingPhoneNumber: Scalars['Int'];
  shippingPostalCode: Scalars['Int'];
  shippingPrice: Scalars['Int'];
  shippingTitle: Scalars['Int'];
};

export type OrderCountOrderByAggregateInput = {
  agreementSale?: InputMaybe<SortOrder>;
  conversationId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  discountCouponId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  identityNumber?: InputMaybe<SortOrder>;
  informationSale?: InputMaybe<SortOrder>;
  invoiceNumber?: InputMaybe<SortOrder>;
  isCompleted?: InputMaybe<SortOrder>;
  isShipped?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  orderStatus?: InputMaybe<SortOrder>;
  paymentAddress?: InputMaybe<SortOrder>;
  paymentCity?: InputMaybe<SortOrder>;
  paymentCompany?: InputMaybe<SortOrder>;
  paymentCountry?: InputMaybe<SortOrder>;
  paymentEmail?: InputMaybe<SortOrder>;
  paymentFirstName?: InputMaybe<SortOrder>;
  paymentLastName?: InputMaybe<SortOrder>;
  paymentPhoneNumber?: InputMaybe<SortOrder>;
  paymentPostalCode?: InputMaybe<SortOrder>;
  paymentTaxNumber?: InputMaybe<SortOrder>;
  paymentTaxOffice?: InputMaybe<SortOrder>;
  paymentType?: InputMaybe<SortOrder>;
  shippingAddress?: InputMaybe<SortOrder>;
  shippingCity?: InputMaybe<SortOrder>;
  shippingCompany?: InputMaybe<SortOrder>;
  shippingConvertedPrice?: InputMaybe<SortOrder>;
  shippingCountry?: InputMaybe<SortOrder>;
  shippingEmail?: InputMaybe<SortOrder>;
  shippingFirstName?: InputMaybe<SortOrder>;
  shippingLastName?: InputMaybe<SortOrder>;
  shippingNumber?: InputMaybe<SortOrder>;
  shippingPhoneNumber?: InputMaybe<SortOrder>;
  shippingPostalCode?: InputMaybe<SortOrder>;
  shippingPrice?: InputMaybe<SortOrder>;
  shippingTitle?: InputMaybe<SortOrder>;
};

export type OrderCreateInput = {
  agreementSale?: InputMaybe<Scalars['String']>;
  conversationId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer: CustomerCreateNestedOneWithoutOrdersInput;
  discountCoupon?: InputMaybe<DiscountCouponCreateNestedOneWithoutOrdersInput>;
  id?: InputMaybe<Scalars['String']>;
  identityNumber?: InputMaybe<Scalars['String']>;
  informationSale?: InputMaybe<Scalars['String']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  isCompleted?: InputMaybe<Scalars['Boolean']>;
  isShipped?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  orderHistories?: InputMaybe<OrderHistoryCreateNestedManyWithoutOrderInput>;
  orderStatus: OrderStatus;
  payment?: InputMaybe<PaymentCreateNestedOneWithoutOrderInput>;
  paymentAddress?: InputMaybe<Scalars['String']>;
  paymentCity?: InputMaybe<Scalars['String']>;
  paymentCompany?: InputMaybe<Scalars['String']>;
  paymentCountry?: InputMaybe<Scalars['String']>;
  paymentEmail?: InputMaybe<Scalars['String']>;
  paymentFirstName?: InputMaybe<Scalars['String']>;
  paymentLastName?: InputMaybe<Scalars['String']>;
  paymentPhoneNumber?: InputMaybe<Scalars['String']>;
  paymentPostalCode?: InputMaybe<Scalars['String']>;
  paymentTaxNumber?: InputMaybe<Scalars['String']>;
  paymentTaxOffice?: InputMaybe<Scalars['String']>;
  paymentType?: InputMaybe<PaymentType>;
  products?: InputMaybe<ProductOrderCreateNestedManyWithoutOrderInput>;
  shippingAddress?: InputMaybe<Scalars['String']>;
  shippingCity?: InputMaybe<Scalars['String']>;
  shippingCompany?: InputMaybe<Scalars['String']>;
  shippingConvertedPrice?: InputMaybe<Scalars['Float']>;
  shippingCountry?: InputMaybe<Scalars['String']>;
  shippingEmail?: InputMaybe<Scalars['String']>;
  shippingFirstName?: InputMaybe<Scalars['String']>;
  shippingLastName?: InputMaybe<Scalars['String']>;
  shippingNumber?: InputMaybe<Scalars['String']>;
  shippingPhoneNumber?: InputMaybe<Scalars['String']>;
  shippingPostalCode?: InputMaybe<Scalars['String']>;
  shippingPrice?: InputMaybe<Scalars['Float']>;
  shippingTitle?: InputMaybe<Scalars['String']>;
};

export type OrderCreateManyCustomerInput = {
  agreementSale?: InputMaybe<Scalars['String']>;
  conversationId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  discountCouponId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identityNumber?: InputMaybe<Scalars['String']>;
  informationSale?: InputMaybe<Scalars['String']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  isCompleted?: InputMaybe<Scalars['Boolean']>;
  isShipped?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  orderStatus: OrderStatus;
  paymentAddress?: InputMaybe<Scalars['String']>;
  paymentCity?: InputMaybe<Scalars['String']>;
  paymentCompany?: InputMaybe<Scalars['String']>;
  paymentCountry?: InputMaybe<Scalars['String']>;
  paymentEmail?: InputMaybe<Scalars['String']>;
  paymentFirstName?: InputMaybe<Scalars['String']>;
  paymentLastName?: InputMaybe<Scalars['String']>;
  paymentPhoneNumber?: InputMaybe<Scalars['String']>;
  paymentPostalCode?: InputMaybe<Scalars['String']>;
  paymentTaxNumber?: InputMaybe<Scalars['String']>;
  paymentTaxOffice?: InputMaybe<Scalars['String']>;
  paymentType?: InputMaybe<PaymentType>;
  shippingAddress?: InputMaybe<Scalars['String']>;
  shippingCity?: InputMaybe<Scalars['String']>;
  shippingCompany?: InputMaybe<Scalars['String']>;
  shippingConvertedPrice?: InputMaybe<Scalars['Float']>;
  shippingCountry?: InputMaybe<Scalars['String']>;
  shippingEmail?: InputMaybe<Scalars['String']>;
  shippingFirstName?: InputMaybe<Scalars['String']>;
  shippingLastName?: InputMaybe<Scalars['String']>;
  shippingNumber?: InputMaybe<Scalars['String']>;
  shippingPhoneNumber?: InputMaybe<Scalars['String']>;
  shippingPostalCode?: InputMaybe<Scalars['String']>;
  shippingPrice?: InputMaybe<Scalars['Float']>;
  shippingTitle?: InputMaybe<Scalars['String']>;
};

export type OrderCreateManyCustomerInputEnvelope = {
  data: Array<OrderCreateManyCustomerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OrderCreateManyDiscountCouponInput = {
  agreementSale?: InputMaybe<Scalars['String']>;
  conversationId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  identityNumber?: InputMaybe<Scalars['String']>;
  informationSale?: InputMaybe<Scalars['String']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  isCompleted?: InputMaybe<Scalars['Boolean']>;
  isShipped?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  orderStatus: OrderStatus;
  paymentAddress?: InputMaybe<Scalars['String']>;
  paymentCity?: InputMaybe<Scalars['String']>;
  paymentCompany?: InputMaybe<Scalars['String']>;
  paymentCountry?: InputMaybe<Scalars['String']>;
  paymentEmail?: InputMaybe<Scalars['String']>;
  paymentFirstName?: InputMaybe<Scalars['String']>;
  paymentLastName?: InputMaybe<Scalars['String']>;
  paymentPhoneNumber?: InputMaybe<Scalars['String']>;
  paymentPostalCode?: InputMaybe<Scalars['String']>;
  paymentTaxNumber?: InputMaybe<Scalars['String']>;
  paymentTaxOffice?: InputMaybe<Scalars['String']>;
  paymentType?: InputMaybe<PaymentType>;
  shippingAddress?: InputMaybe<Scalars['String']>;
  shippingCity?: InputMaybe<Scalars['String']>;
  shippingCompany?: InputMaybe<Scalars['String']>;
  shippingConvertedPrice?: InputMaybe<Scalars['Float']>;
  shippingCountry?: InputMaybe<Scalars['String']>;
  shippingEmail?: InputMaybe<Scalars['String']>;
  shippingFirstName?: InputMaybe<Scalars['String']>;
  shippingLastName?: InputMaybe<Scalars['String']>;
  shippingNumber?: InputMaybe<Scalars['String']>;
  shippingPhoneNumber?: InputMaybe<Scalars['String']>;
  shippingPostalCode?: InputMaybe<Scalars['String']>;
  shippingPrice?: InputMaybe<Scalars['Float']>;
  shippingTitle?: InputMaybe<Scalars['String']>;
};

export type OrderCreateManyDiscountCouponInputEnvelope = {
  data: Array<OrderCreateManyDiscountCouponInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OrderCreateManyInput = {
  agreementSale?: InputMaybe<Scalars['String']>;
  conversationId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId: Scalars['String'];
  discountCouponId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identityNumber?: InputMaybe<Scalars['String']>;
  informationSale?: InputMaybe<Scalars['String']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  isCompleted?: InputMaybe<Scalars['Boolean']>;
  isShipped?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  orderStatus: OrderStatus;
  paymentAddress?: InputMaybe<Scalars['String']>;
  paymentCity?: InputMaybe<Scalars['String']>;
  paymentCompany?: InputMaybe<Scalars['String']>;
  paymentCountry?: InputMaybe<Scalars['String']>;
  paymentEmail?: InputMaybe<Scalars['String']>;
  paymentFirstName?: InputMaybe<Scalars['String']>;
  paymentLastName?: InputMaybe<Scalars['String']>;
  paymentPhoneNumber?: InputMaybe<Scalars['String']>;
  paymentPostalCode?: InputMaybe<Scalars['String']>;
  paymentTaxNumber?: InputMaybe<Scalars['String']>;
  paymentTaxOffice?: InputMaybe<Scalars['String']>;
  paymentType?: InputMaybe<PaymentType>;
  shippingAddress?: InputMaybe<Scalars['String']>;
  shippingCity?: InputMaybe<Scalars['String']>;
  shippingCompany?: InputMaybe<Scalars['String']>;
  shippingConvertedPrice?: InputMaybe<Scalars['Float']>;
  shippingCountry?: InputMaybe<Scalars['String']>;
  shippingEmail?: InputMaybe<Scalars['String']>;
  shippingFirstName?: InputMaybe<Scalars['String']>;
  shippingLastName?: InputMaybe<Scalars['String']>;
  shippingNumber?: InputMaybe<Scalars['String']>;
  shippingPhoneNumber?: InputMaybe<Scalars['String']>;
  shippingPostalCode?: InputMaybe<Scalars['String']>;
  shippingPrice?: InputMaybe<Scalars['Float']>;
  shippingTitle?: InputMaybe<Scalars['String']>;
};

export type OrderCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<OrderCreateManyCustomerInputEnvelope>;
};

export type OrderCreateNestedManyWithoutDiscountCouponInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutDiscountCouponInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutDiscountCouponInput>>;
  createMany?: InputMaybe<OrderCreateManyDiscountCouponInputEnvelope>;
};

export type OrderCreateNestedOneWithoutProductsInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<OrderCreateWithoutProductsInput>;
};

export type OrderCreateOrConnectWithoutCustomerInput = {
  create: OrderCreateWithoutCustomerInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutDiscountCouponInput = {
  create: OrderCreateWithoutDiscountCouponInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutProductsInput = {
  create: OrderCreateWithoutProductsInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateWithoutCustomerInput = {
  agreementSale?: InputMaybe<Scalars['String']>;
  conversationId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  discountCoupon?: InputMaybe<DiscountCouponCreateNestedOneWithoutOrdersInput>;
  id?: InputMaybe<Scalars['String']>;
  identityNumber?: InputMaybe<Scalars['String']>;
  informationSale?: InputMaybe<Scalars['String']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  isCompleted?: InputMaybe<Scalars['Boolean']>;
  isShipped?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  orderHistories?: InputMaybe<OrderHistoryCreateNestedManyWithoutOrderInput>;
  orderStatus: OrderStatus;
  payment?: InputMaybe<PaymentCreateNestedOneWithoutOrderInput>;
  paymentAddress?: InputMaybe<Scalars['String']>;
  paymentCity?: InputMaybe<Scalars['String']>;
  paymentCompany?: InputMaybe<Scalars['String']>;
  paymentCountry?: InputMaybe<Scalars['String']>;
  paymentEmail?: InputMaybe<Scalars['String']>;
  paymentFirstName?: InputMaybe<Scalars['String']>;
  paymentLastName?: InputMaybe<Scalars['String']>;
  paymentPhoneNumber?: InputMaybe<Scalars['String']>;
  paymentPostalCode?: InputMaybe<Scalars['String']>;
  paymentTaxNumber?: InputMaybe<Scalars['String']>;
  paymentTaxOffice?: InputMaybe<Scalars['String']>;
  paymentType?: InputMaybe<PaymentType>;
  products?: InputMaybe<ProductOrderCreateNestedManyWithoutOrderInput>;
  shippingAddress?: InputMaybe<Scalars['String']>;
  shippingCity?: InputMaybe<Scalars['String']>;
  shippingCompany?: InputMaybe<Scalars['String']>;
  shippingConvertedPrice?: InputMaybe<Scalars['Float']>;
  shippingCountry?: InputMaybe<Scalars['String']>;
  shippingEmail?: InputMaybe<Scalars['String']>;
  shippingFirstName?: InputMaybe<Scalars['String']>;
  shippingLastName?: InputMaybe<Scalars['String']>;
  shippingNumber?: InputMaybe<Scalars['String']>;
  shippingPhoneNumber?: InputMaybe<Scalars['String']>;
  shippingPostalCode?: InputMaybe<Scalars['String']>;
  shippingPrice?: InputMaybe<Scalars['Float']>;
  shippingTitle?: InputMaybe<Scalars['String']>;
};

export type OrderCreateWithoutDiscountCouponInput = {
  agreementSale?: InputMaybe<Scalars['String']>;
  conversationId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer: CustomerCreateNestedOneWithoutOrdersInput;
  id?: InputMaybe<Scalars['String']>;
  identityNumber?: InputMaybe<Scalars['String']>;
  informationSale?: InputMaybe<Scalars['String']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  isCompleted?: InputMaybe<Scalars['Boolean']>;
  isShipped?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  orderHistories?: InputMaybe<OrderHistoryCreateNestedManyWithoutOrderInput>;
  orderStatus: OrderStatus;
  payment?: InputMaybe<PaymentCreateNestedOneWithoutOrderInput>;
  paymentAddress?: InputMaybe<Scalars['String']>;
  paymentCity?: InputMaybe<Scalars['String']>;
  paymentCompany?: InputMaybe<Scalars['String']>;
  paymentCountry?: InputMaybe<Scalars['String']>;
  paymentEmail?: InputMaybe<Scalars['String']>;
  paymentFirstName?: InputMaybe<Scalars['String']>;
  paymentLastName?: InputMaybe<Scalars['String']>;
  paymentPhoneNumber?: InputMaybe<Scalars['String']>;
  paymentPostalCode?: InputMaybe<Scalars['String']>;
  paymentTaxNumber?: InputMaybe<Scalars['String']>;
  paymentTaxOffice?: InputMaybe<Scalars['String']>;
  paymentType?: InputMaybe<PaymentType>;
  products?: InputMaybe<ProductOrderCreateNestedManyWithoutOrderInput>;
  shippingAddress?: InputMaybe<Scalars['String']>;
  shippingCity?: InputMaybe<Scalars['String']>;
  shippingCompany?: InputMaybe<Scalars['String']>;
  shippingConvertedPrice?: InputMaybe<Scalars['Float']>;
  shippingCountry?: InputMaybe<Scalars['String']>;
  shippingEmail?: InputMaybe<Scalars['String']>;
  shippingFirstName?: InputMaybe<Scalars['String']>;
  shippingLastName?: InputMaybe<Scalars['String']>;
  shippingNumber?: InputMaybe<Scalars['String']>;
  shippingPhoneNumber?: InputMaybe<Scalars['String']>;
  shippingPostalCode?: InputMaybe<Scalars['String']>;
  shippingPrice?: InputMaybe<Scalars['Float']>;
  shippingTitle?: InputMaybe<Scalars['String']>;
};

export type OrderCreateWithoutProductsInput = {
  agreementSale?: InputMaybe<Scalars['String']>;
  conversationId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer: CustomerCreateNestedOneWithoutOrdersInput;
  discountCoupon?: InputMaybe<DiscountCouponCreateNestedOneWithoutOrdersInput>;
  id?: InputMaybe<Scalars['String']>;
  identityNumber?: InputMaybe<Scalars['String']>;
  informationSale?: InputMaybe<Scalars['String']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  isCompleted?: InputMaybe<Scalars['Boolean']>;
  isShipped?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  orderHistories?: InputMaybe<OrderHistoryCreateNestedManyWithoutOrderInput>;
  orderStatus: OrderStatus;
  payment?: InputMaybe<PaymentCreateNestedOneWithoutOrderInput>;
  paymentAddress?: InputMaybe<Scalars['String']>;
  paymentCity?: InputMaybe<Scalars['String']>;
  paymentCompany?: InputMaybe<Scalars['String']>;
  paymentCountry?: InputMaybe<Scalars['String']>;
  paymentEmail?: InputMaybe<Scalars['String']>;
  paymentFirstName?: InputMaybe<Scalars['String']>;
  paymentLastName?: InputMaybe<Scalars['String']>;
  paymentPhoneNumber?: InputMaybe<Scalars['String']>;
  paymentPostalCode?: InputMaybe<Scalars['String']>;
  paymentTaxNumber?: InputMaybe<Scalars['String']>;
  paymentTaxOffice?: InputMaybe<Scalars['String']>;
  paymentType?: InputMaybe<PaymentType>;
  shippingAddress?: InputMaybe<Scalars['String']>;
  shippingCity?: InputMaybe<Scalars['String']>;
  shippingCompany?: InputMaybe<Scalars['String']>;
  shippingConvertedPrice?: InputMaybe<Scalars['Float']>;
  shippingCountry?: InputMaybe<Scalars['String']>;
  shippingEmail?: InputMaybe<Scalars['String']>;
  shippingFirstName?: InputMaybe<Scalars['String']>;
  shippingLastName?: InputMaybe<Scalars['String']>;
  shippingNumber?: InputMaybe<Scalars['String']>;
  shippingPhoneNumber?: InputMaybe<Scalars['String']>;
  shippingPostalCode?: InputMaybe<Scalars['String']>;
  shippingPrice?: InputMaybe<Scalars['Float']>;
  shippingTitle?: InputMaybe<Scalars['String']>;
};

export type OrderGroupBy = {
  __typename?: 'OrderGroupBy';
  _avg?: Maybe<OrderAvgAggregate>;
  _count?: Maybe<OrderCountAggregate>;
  _max?: Maybe<OrderMaxAggregate>;
  _min?: Maybe<OrderMinAggregate>;
  _sum?: Maybe<OrderSumAggregate>;
  agreementSale?: Maybe<Scalars['String']>;
  conversationId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customerId: Scalars['String'];
  discountCouponId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  identityNumber?: Maybe<Scalars['String']>;
  informationSale?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  isCompleted: Scalars['Boolean'];
  isShipped: Scalars['Boolean'];
  language?: Maybe<Scalars['String']>;
  orderStatus: OrderStatus;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentCompany?: Maybe<Scalars['String']>;
  paymentCountry?: Maybe<Scalars['String']>;
  paymentEmail?: Maybe<Scalars['String']>;
  paymentFirstName?: Maybe<Scalars['String']>;
  paymentLastName?: Maybe<Scalars['String']>;
  paymentPhoneNumber?: Maybe<Scalars['String']>;
  paymentPostalCode?: Maybe<Scalars['String']>;
  paymentTaxNumber?: Maybe<Scalars['String']>;
  paymentTaxOffice?: Maybe<Scalars['String']>;
  paymentType?: Maybe<PaymentType>;
  shippingAddress?: Maybe<Scalars['String']>;
  shippingCity?: Maybe<Scalars['String']>;
  shippingCompany?: Maybe<Scalars['String']>;
  shippingConvertedPrice?: Maybe<Scalars['Float']>;
  shippingCountry?: Maybe<Scalars['String']>;
  shippingEmail?: Maybe<Scalars['String']>;
  shippingFirstName?: Maybe<Scalars['String']>;
  shippingLastName?: Maybe<Scalars['String']>;
  shippingNumber?: Maybe<Scalars['String']>;
  shippingPhoneNumber?: Maybe<Scalars['String']>;
  shippingPostalCode?: Maybe<Scalars['String']>;
  shippingPrice?: Maybe<Scalars['Float']>;
  shippingTitle?: Maybe<Scalars['String']>;
};

export type OrderHistory = {
  __typename?: 'OrderHistory';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  emailSent: Scalars['Boolean'];
  id: Scalars['String'];
  order: Order;
  orderId: Scalars['String'];
  title: Scalars['String'];
};

export type OrderHistoryCreateManyOrderInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  emailSent?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type OrderHistoryCreateManyOrderInputEnvelope = {
  data: Array<OrderHistoryCreateManyOrderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OrderHistoryCreateNestedManyWithoutOrderInput = {
  connect?: InputMaybe<Array<OrderHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderHistoryCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<OrderHistoryCreateWithoutOrderInput>>;
  createMany?: InputMaybe<OrderHistoryCreateManyOrderInputEnvelope>;
};

export type OrderHistoryCreateOrConnectWithoutOrderInput = {
  create: OrderHistoryCreateWithoutOrderInput;
  where: OrderHistoryWhereUniqueInput;
};

export type OrderHistoryCreateWithoutOrderInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  emailSent?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type OrderHistoryListRelationFilter = {
  every?: InputMaybe<OrderHistoryWhereInput>;
  none?: InputMaybe<OrderHistoryWhereInput>;
  some?: InputMaybe<OrderHistoryWhereInput>;
};

export type OrderHistoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OrderHistoryOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  emailSent?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export enum OrderHistoryScalarFieldEnum {
  createdAt = 'createdAt',
  description = 'description',
  emailSent = 'emailSent',
  id = 'id',
  orderId = 'orderId',
  title = 'title'
}

export type OrderHistoryScalarWhereInput = {
  AND?: InputMaybe<Array<OrderHistoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<OrderHistoryScalarWhereInput>>;
  OR?: InputMaybe<Array<OrderHistoryScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  emailSent?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  orderId?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export type OrderHistoryUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailSent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type OrderHistoryUpdateManyWithWhereWithoutOrderInput = {
  data: OrderHistoryUpdateManyMutationInput;
  where: OrderHistoryScalarWhereInput;
};

export type OrderHistoryUpdateManyWithoutOrderNestedInput = {
  connect?: InputMaybe<Array<OrderHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderHistoryCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<OrderHistoryCreateWithoutOrderInput>>;
  createMany?: InputMaybe<OrderHistoryCreateManyOrderInputEnvelope>;
  delete?: InputMaybe<Array<OrderHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderHistoryUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: InputMaybe<Array<OrderHistoryUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: InputMaybe<Array<OrderHistoryUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type OrderHistoryUpdateWithWhereUniqueWithoutOrderInput = {
  data: OrderHistoryUpdateWithoutOrderInput;
  where: OrderHistoryWhereUniqueInput;
};

export type OrderHistoryUpdateWithoutOrderInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailSent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type OrderHistoryUpsertWithWhereUniqueWithoutOrderInput = {
  create: OrderHistoryCreateWithoutOrderInput;
  update: OrderHistoryUpdateWithoutOrderInput;
  where: OrderHistoryWhereUniqueInput;
};

export type OrderHistoryWhereInput = {
  AND?: InputMaybe<Array<OrderHistoryWhereInput>>;
  NOT?: InputMaybe<Array<OrderHistoryWhereInput>>;
  OR?: InputMaybe<Array<OrderHistoryWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  emailSent?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export type OrderHistoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type OrderListRelationFilter = {
  every?: InputMaybe<OrderWhereInput>;
  none?: InputMaybe<OrderWhereInput>;
  some?: InputMaybe<OrderWhereInput>;
};

export type OrderMaxAggregate = {
  __typename?: 'OrderMaxAggregate';
  agreementSale?: Maybe<Scalars['String']>;
  conversationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  discountCouponId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identityNumber?: Maybe<Scalars['String']>;
  informationSale?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  isShipped?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  orderStatus?: Maybe<OrderStatus>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentCompany?: Maybe<Scalars['String']>;
  paymentCountry?: Maybe<Scalars['String']>;
  paymentEmail?: Maybe<Scalars['String']>;
  paymentFirstName?: Maybe<Scalars['String']>;
  paymentLastName?: Maybe<Scalars['String']>;
  paymentPhoneNumber?: Maybe<Scalars['String']>;
  paymentPostalCode?: Maybe<Scalars['String']>;
  paymentTaxNumber?: Maybe<Scalars['String']>;
  paymentTaxOffice?: Maybe<Scalars['String']>;
  paymentType?: Maybe<PaymentType>;
  shippingAddress?: Maybe<Scalars['String']>;
  shippingCity?: Maybe<Scalars['String']>;
  shippingCompany?: Maybe<Scalars['String']>;
  shippingConvertedPrice?: Maybe<Scalars['Float']>;
  shippingCountry?: Maybe<Scalars['String']>;
  shippingEmail?: Maybe<Scalars['String']>;
  shippingFirstName?: Maybe<Scalars['String']>;
  shippingLastName?: Maybe<Scalars['String']>;
  shippingNumber?: Maybe<Scalars['String']>;
  shippingPhoneNumber?: Maybe<Scalars['String']>;
  shippingPostalCode?: Maybe<Scalars['String']>;
  shippingPrice?: Maybe<Scalars['Float']>;
  shippingTitle?: Maybe<Scalars['String']>;
};

export type OrderMaxOrderByAggregateInput = {
  agreementSale?: InputMaybe<SortOrder>;
  conversationId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  discountCouponId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  identityNumber?: InputMaybe<SortOrder>;
  informationSale?: InputMaybe<SortOrder>;
  invoiceNumber?: InputMaybe<SortOrder>;
  isCompleted?: InputMaybe<SortOrder>;
  isShipped?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  orderStatus?: InputMaybe<SortOrder>;
  paymentAddress?: InputMaybe<SortOrder>;
  paymentCity?: InputMaybe<SortOrder>;
  paymentCompany?: InputMaybe<SortOrder>;
  paymentCountry?: InputMaybe<SortOrder>;
  paymentEmail?: InputMaybe<SortOrder>;
  paymentFirstName?: InputMaybe<SortOrder>;
  paymentLastName?: InputMaybe<SortOrder>;
  paymentPhoneNumber?: InputMaybe<SortOrder>;
  paymentPostalCode?: InputMaybe<SortOrder>;
  paymentTaxNumber?: InputMaybe<SortOrder>;
  paymentTaxOffice?: InputMaybe<SortOrder>;
  paymentType?: InputMaybe<SortOrder>;
  shippingAddress?: InputMaybe<SortOrder>;
  shippingCity?: InputMaybe<SortOrder>;
  shippingCompany?: InputMaybe<SortOrder>;
  shippingConvertedPrice?: InputMaybe<SortOrder>;
  shippingCountry?: InputMaybe<SortOrder>;
  shippingEmail?: InputMaybe<SortOrder>;
  shippingFirstName?: InputMaybe<SortOrder>;
  shippingLastName?: InputMaybe<SortOrder>;
  shippingNumber?: InputMaybe<SortOrder>;
  shippingPhoneNumber?: InputMaybe<SortOrder>;
  shippingPostalCode?: InputMaybe<SortOrder>;
  shippingPrice?: InputMaybe<SortOrder>;
  shippingTitle?: InputMaybe<SortOrder>;
};

export type OrderMinAggregate = {
  __typename?: 'OrderMinAggregate';
  agreementSale?: Maybe<Scalars['String']>;
  conversationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  discountCouponId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identityNumber?: Maybe<Scalars['String']>;
  informationSale?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  isShipped?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  orderStatus?: Maybe<OrderStatus>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentCompany?: Maybe<Scalars['String']>;
  paymentCountry?: Maybe<Scalars['String']>;
  paymentEmail?: Maybe<Scalars['String']>;
  paymentFirstName?: Maybe<Scalars['String']>;
  paymentLastName?: Maybe<Scalars['String']>;
  paymentPhoneNumber?: Maybe<Scalars['String']>;
  paymentPostalCode?: Maybe<Scalars['String']>;
  paymentTaxNumber?: Maybe<Scalars['String']>;
  paymentTaxOffice?: Maybe<Scalars['String']>;
  paymentType?: Maybe<PaymentType>;
  shippingAddress?: Maybe<Scalars['String']>;
  shippingCity?: Maybe<Scalars['String']>;
  shippingCompany?: Maybe<Scalars['String']>;
  shippingConvertedPrice?: Maybe<Scalars['Float']>;
  shippingCountry?: Maybe<Scalars['String']>;
  shippingEmail?: Maybe<Scalars['String']>;
  shippingFirstName?: Maybe<Scalars['String']>;
  shippingLastName?: Maybe<Scalars['String']>;
  shippingNumber?: Maybe<Scalars['String']>;
  shippingPhoneNumber?: Maybe<Scalars['String']>;
  shippingPostalCode?: Maybe<Scalars['String']>;
  shippingPrice?: Maybe<Scalars['Float']>;
  shippingTitle?: Maybe<Scalars['String']>;
};

export type OrderMinOrderByAggregateInput = {
  agreementSale?: InputMaybe<SortOrder>;
  conversationId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  discountCouponId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  identityNumber?: InputMaybe<SortOrder>;
  informationSale?: InputMaybe<SortOrder>;
  invoiceNumber?: InputMaybe<SortOrder>;
  isCompleted?: InputMaybe<SortOrder>;
  isShipped?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  orderStatus?: InputMaybe<SortOrder>;
  paymentAddress?: InputMaybe<SortOrder>;
  paymentCity?: InputMaybe<SortOrder>;
  paymentCompany?: InputMaybe<SortOrder>;
  paymentCountry?: InputMaybe<SortOrder>;
  paymentEmail?: InputMaybe<SortOrder>;
  paymentFirstName?: InputMaybe<SortOrder>;
  paymentLastName?: InputMaybe<SortOrder>;
  paymentPhoneNumber?: InputMaybe<SortOrder>;
  paymentPostalCode?: InputMaybe<SortOrder>;
  paymentTaxNumber?: InputMaybe<SortOrder>;
  paymentTaxOffice?: InputMaybe<SortOrder>;
  paymentType?: InputMaybe<SortOrder>;
  shippingAddress?: InputMaybe<SortOrder>;
  shippingCity?: InputMaybe<SortOrder>;
  shippingCompany?: InputMaybe<SortOrder>;
  shippingConvertedPrice?: InputMaybe<SortOrder>;
  shippingCountry?: InputMaybe<SortOrder>;
  shippingEmail?: InputMaybe<SortOrder>;
  shippingFirstName?: InputMaybe<SortOrder>;
  shippingLastName?: InputMaybe<SortOrder>;
  shippingNumber?: InputMaybe<SortOrder>;
  shippingPhoneNumber?: InputMaybe<SortOrder>;
  shippingPostalCode?: InputMaybe<SortOrder>;
  shippingPrice?: InputMaybe<SortOrder>;
  shippingTitle?: InputMaybe<SortOrder>;
};

export type OrderOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OrderOrderByWithAggregationInput = {
  _avg?: InputMaybe<OrderAvgOrderByAggregateInput>;
  _count?: InputMaybe<OrderCountOrderByAggregateInput>;
  _max?: InputMaybe<OrderMaxOrderByAggregateInput>;
  _min?: InputMaybe<OrderMinOrderByAggregateInput>;
  _sum?: InputMaybe<OrderSumOrderByAggregateInput>;
  agreementSale?: InputMaybe<SortOrder>;
  conversationId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  discountCouponId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  identityNumber?: InputMaybe<SortOrder>;
  informationSale?: InputMaybe<SortOrder>;
  invoiceNumber?: InputMaybe<SortOrder>;
  isCompleted?: InputMaybe<SortOrder>;
  isShipped?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  orderStatus?: InputMaybe<SortOrder>;
  paymentAddress?: InputMaybe<SortOrder>;
  paymentCity?: InputMaybe<SortOrder>;
  paymentCompany?: InputMaybe<SortOrder>;
  paymentCountry?: InputMaybe<SortOrder>;
  paymentEmail?: InputMaybe<SortOrder>;
  paymentFirstName?: InputMaybe<SortOrder>;
  paymentLastName?: InputMaybe<SortOrder>;
  paymentPhoneNumber?: InputMaybe<SortOrder>;
  paymentPostalCode?: InputMaybe<SortOrder>;
  paymentTaxNumber?: InputMaybe<SortOrder>;
  paymentTaxOffice?: InputMaybe<SortOrder>;
  paymentType?: InputMaybe<SortOrder>;
  shippingAddress?: InputMaybe<SortOrder>;
  shippingCity?: InputMaybe<SortOrder>;
  shippingCompany?: InputMaybe<SortOrder>;
  shippingConvertedPrice?: InputMaybe<SortOrder>;
  shippingCountry?: InputMaybe<SortOrder>;
  shippingEmail?: InputMaybe<SortOrder>;
  shippingFirstName?: InputMaybe<SortOrder>;
  shippingLastName?: InputMaybe<SortOrder>;
  shippingNumber?: InputMaybe<SortOrder>;
  shippingPhoneNumber?: InputMaybe<SortOrder>;
  shippingPostalCode?: InputMaybe<SortOrder>;
  shippingPrice?: InputMaybe<SortOrder>;
  shippingTitle?: InputMaybe<SortOrder>;
};

export type OrderOrderByWithRelationInput = {
  agreementSale?: InputMaybe<SortOrder>;
  conversationId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
  discountCoupon?: InputMaybe<DiscountCouponOrderByWithRelationInput>;
  discountCouponId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  identityNumber?: InputMaybe<SortOrder>;
  informationSale?: InputMaybe<SortOrder>;
  invoiceNumber?: InputMaybe<SortOrder>;
  isCompleted?: InputMaybe<SortOrder>;
  isShipped?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  orderHistories?: InputMaybe<OrderHistoryOrderByRelationAggregateInput>;
  orderStatus?: InputMaybe<SortOrder>;
  payment?: InputMaybe<PaymentOrderByWithRelationInput>;
  paymentAddress?: InputMaybe<SortOrder>;
  paymentCity?: InputMaybe<SortOrder>;
  paymentCompany?: InputMaybe<SortOrder>;
  paymentCountry?: InputMaybe<SortOrder>;
  paymentEmail?: InputMaybe<SortOrder>;
  paymentFirstName?: InputMaybe<SortOrder>;
  paymentLastName?: InputMaybe<SortOrder>;
  paymentPhoneNumber?: InputMaybe<SortOrder>;
  paymentPostalCode?: InputMaybe<SortOrder>;
  paymentTaxNumber?: InputMaybe<SortOrder>;
  paymentTaxOffice?: InputMaybe<SortOrder>;
  paymentType?: InputMaybe<SortOrder>;
  products?: InputMaybe<ProductOrderOrderByRelationAggregateInput>;
  shippingAddress?: InputMaybe<SortOrder>;
  shippingCity?: InputMaybe<SortOrder>;
  shippingCompany?: InputMaybe<SortOrder>;
  shippingConvertedPrice?: InputMaybe<SortOrder>;
  shippingCountry?: InputMaybe<SortOrder>;
  shippingEmail?: InputMaybe<SortOrder>;
  shippingFirstName?: InputMaybe<SortOrder>;
  shippingLastName?: InputMaybe<SortOrder>;
  shippingNumber?: InputMaybe<SortOrder>;
  shippingPhoneNumber?: InputMaybe<SortOrder>;
  shippingPostalCode?: InputMaybe<SortOrder>;
  shippingPrice?: InputMaybe<SortOrder>;
  shippingTitle?: InputMaybe<SortOrder>;
};

export type OrderRelationFilter = {
  is?: InputMaybe<OrderWhereInput>;
  isNot?: InputMaybe<OrderWhereInput>;
};

export enum OrderScalarFieldEnum {
  agreementSale = 'agreementSale',
  conversationId = 'conversationId',
  createdAt = 'createdAt',
  customerId = 'customerId',
  discountCouponId = 'discountCouponId',
  id = 'id',
  identityNumber = 'identityNumber',
  informationSale = 'informationSale',
  invoiceNumber = 'invoiceNumber',
  isCompleted = 'isCompleted',
  isShipped = 'isShipped',
  language = 'language',
  orderStatus = 'orderStatus',
  paymentAddress = 'paymentAddress',
  paymentCity = 'paymentCity',
  paymentCompany = 'paymentCompany',
  paymentCountry = 'paymentCountry',
  paymentEmail = 'paymentEmail',
  paymentFirstName = 'paymentFirstName',
  paymentLastName = 'paymentLastName',
  paymentPhoneNumber = 'paymentPhoneNumber',
  paymentPostalCode = 'paymentPostalCode',
  paymentTaxNumber = 'paymentTaxNumber',
  paymentTaxOffice = 'paymentTaxOffice',
  paymentType = 'paymentType',
  shippingAddress = 'shippingAddress',
  shippingCity = 'shippingCity',
  shippingCompany = 'shippingCompany',
  shippingConvertedPrice = 'shippingConvertedPrice',
  shippingCountry = 'shippingCountry',
  shippingEmail = 'shippingEmail',
  shippingFirstName = 'shippingFirstName',
  shippingLastName = 'shippingLastName',
  shippingNumber = 'shippingNumber',
  shippingPhoneNumber = 'shippingPhoneNumber',
  shippingPostalCode = 'shippingPostalCode',
  shippingPrice = 'shippingPrice',
  shippingTitle = 'shippingTitle'
}

export type OrderScalarWhereInput = {
  AND?: InputMaybe<Array<OrderScalarWhereInput>>;
  NOT?: InputMaybe<Array<OrderScalarWhereInput>>;
  OR?: InputMaybe<Array<OrderScalarWhereInput>>;
  agreementSale?: InputMaybe<StringNullableFilter>;
  conversationId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringFilter>;
  discountCouponId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  identityNumber?: InputMaybe<StringNullableFilter>;
  informationSale?: InputMaybe<StringNullableFilter>;
  invoiceNumber?: InputMaybe<StringNullableFilter>;
  isCompleted?: InputMaybe<BoolFilter>;
  isShipped?: InputMaybe<BoolFilter>;
  language?: InputMaybe<StringNullableFilter>;
  orderStatus?: InputMaybe<EnumOrderStatusFilter>;
  paymentAddress?: InputMaybe<StringNullableFilter>;
  paymentCity?: InputMaybe<StringNullableFilter>;
  paymentCompany?: InputMaybe<StringNullableFilter>;
  paymentCountry?: InputMaybe<StringNullableFilter>;
  paymentEmail?: InputMaybe<StringNullableFilter>;
  paymentFirstName?: InputMaybe<StringNullableFilter>;
  paymentLastName?: InputMaybe<StringNullableFilter>;
  paymentPhoneNumber?: InputMaybe<StringNullableFilter>;
  paymentPostalCode?: InputMaybe<StringNullableFilter>;
  paymentTaxNumber?: InputMaybe<StringNullableFilter>;
  paymentTaxOffice?: InputMaybe<StringNullableFilter>;
  paymentType?: InputMaybe<EnumPaymentTypeNullableFilter>;
  shippingAddress?: InputMaybe<StringNullableFilter>;
  shippingCity?: InputMaybe<StringNullableFilter>;
  shippingCompany?: InputMaybe<StringNullableFilter>;
  shippingConvertedPrice?: InputMaybe<FloatNullableFilter>;
  shippingCountry?: InputMaybe<StringNullableFilter>;
  shippingEmail?: InputMaybe<StringNullableFilter>;
  shippingFirstName?: InputMaybe<StringNullableFilter>;
  shippingLastName?: InputMaybe<StringNullableFilter>;
  shippingNumber?: InputMaybe<StringNullableFilter>;
  shippingPhoneNumber?: InputMaybe<StringNullableFilter>;
  shippingPostalCode?: InputMaybe<StringNullableFilter>;
  shippingPrice?: InputMaybe<FloatNullableFilter>;
  shippingTitle?: InputMaybe<StringNullableFilter>;
};

export type OrderScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<OrderScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<OrderScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<OrderScalarWhereWithAggregatesInput>>;
  agreementSale?: InputMaybe<StringNullableWithAggregatesFilter>;
  conversationId?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  customerId?: InputMaybe<StringWithAggregatesFilter>;
  discountCouponId?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  identityNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  informationSale?: InputMaybe<StringNullableWithAggregatesFilter>;
  invoiceNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  isCompleted?: InputMaybe<BoolWithAggregatesFilter>;
  isShipped?: InputMaybe<BoolWithAggregatesFilter>;
  language?: InputMaybe<StringNullableWithAggregatesFilter>;
  orderStatus?: InputMaybe<EnumOrderStatusWithAggregatesFilter>;
  paymentAddress?: InputMaybe<StringNullableWithAggregatesFilter>;
  paymentCity?: InputMaybe<StringNullableWithAggregatesFilter>;
  paymentCompany?: InputMaybe<StringNullableWithAggregatesFilter>;
  paymentCountry?: InputMaybe<StringNullableWithAggregatesFilter>;
  paymentEmail?: InputMaybe<StringNullableWithAggregatesFilter>;
  paymentFirstName?: InputMaybe<StringNullableWithAggregatesFilter>;
  paymentLastName?: InputMaybe<StringNullableWithAggregatesFilter>;
  paymentPhoneNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  paymentPostalCode?: InputMaybe<StringNullableWithAggregatesFilter>;
  paymentTaxNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  paymentTaxOffice?: InputMaybe<StringNullableWithAggregatesFilter>;
  paymentType?: InputMaybe<EnumPaymentTypeNullableWithAggregatesFilter>;
  shippingAddress?: InputMaybe<StringNullableWithAggregatesFilter>;
  shippingCity?: InputMaybe<StringNullableWithAggregatesFilter>;
  shippingCompany?: InputMaybe<StringNullableWithAggregatesFilter>;
  shippingConvertedPrice?: InputMaybe<FloatNullableWithAggregatesFilter>;
  shippingCountry?: InputMaybe<StringNullableWithAggregatesFilter>;
  shippingEmail?: InputMaybe<StringNullableWithAggregatesFilter>;
  shippingFirstName?: InputMaybe<StringNullableWithAggregatesFilter>;
  shippingLastName?: InputMaybe<StringNullableWithAggregatesFilter>;
  shippingNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  shippingPhoneNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  shippingPostalCode?: InputMaybe<StringNullableWithAggregatesFilter>;
  shippingPrice?: InputMaybe<FloatNullableWithAggregatesFilter>;
  shippingTitle?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export enum OrderStatus {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  FAILURE = 'FAILURE',
  PAID = 'PAID',
  PENDING = 'PENDING',
  RETURN = 'RETURN',
  SHIPPED = 'SHIPPED',
  WAITING_FOR_CONFIRMATION = 'WAITING_FOR_CONFIRMATION'
}

export type OrderSumAggregate = {
  __typename?: 'OrderSumAggregate';
  shippingConvertedPrice?: Maybe<Scalars['Float']>;
  shippingPrice?: Maybe<Scalars['Float']>;
};

export type OrderSumOrderByAggregateInput = {
  shippingConvertedPrice?: InputMaybe<SortOrder>;
  shippingPrice?: InputMaybe<SortOrder>;
};

export type OrderUpdateInput = {
  agreementSale?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  conversationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneRequiredWithoutOrdersNestedInput>;
  discountCoupon?: InputMaybe<DiscountCouponUpdateOneWithoutOrdersNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identityNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  informationSale?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  invoiceNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isShipped?: InputMaybe<BoolFieldUpdateOperationsInput>;
  language?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderHistories?: InputMaybe<OrderHistoryUpdateManyWithoutOrderNestedInput>;
  orderStatus?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  payment?: InputMaybe<PaymentUpdateOneWithoutOrderNestedInput>;
  paymentAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentPhoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentPostalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentTaxNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentTaxOffice?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentType?: InputMaybe<NullableEnumPaymentTypeFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductOrderUpdateManyWithoutOrderNestedInput>;
  shippingAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingConvertedPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  shippingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPhoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPostalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  shippingTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type OrderUpdateManyMutationInput = {
  agreementSale?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  conversationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identityNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  informationSale?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  invoiceNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isShipped?: InputMaybe<BoolFieldUpdateOperationsInput>;
  language?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderStatus?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  paymentAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentPhoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentPostalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentTaxNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentTaxOffice?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentType?: InputMaybe<NullableEnumPaymentTypeFieldUpdateOperationsInput>;
  shippingAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingConvertedPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  shippingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPhoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPostalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  shippingTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type OrderUpdateManyWithWhereWithoutCustomerInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithWhereWithoutDiscountCouponInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithoutCustomerNestedInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<OrderCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderUpdateWithWhereUniqueWithoutCustomerInput>>;
  updateMany?: InputMaybe<Array<OrderUpdateManyWithWhereWithoutCustomerInput>>;
  upsert?: InputMaybe<Array<OrderUpsertWithWhereUniqueWithoutCustomerInput>>;
};

export type OrderUpdateManyWithoutDiscountCouponNestedInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutDiscountCouponInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutDiscountCouponInput>>;
  createMany?: InputMaybe<OrderCreateManyDiscountCouponInputEnvelope>;
  delete?: InputMaybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderUpdateWithWhereUniqueWithoutDiscountCouponInput>>;
  updateMany?: InputMaybe<Array<OrderUpdateManyWithWhereWithoutDiscountCouponInput>>;
  upsert?: InputMaybe<Array<OrderUpsertWithWhereUniqueWithoutDiscountCouponInput>>;
};

export type OrderUpdateOneRequiredWithoutProductsNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<OrderCreateWithoutProductsInput>;
  update?: InputMaybe<OrderUpdateWithoutProductsInput>;
  upsert?: InputMaybe<OrderUpsertWithoutProductsInput>;
};

export type OrderUpdateWithWhereUniqueWithoutCustomerInput = {
  data: OrderUpdateWithoutCustomerInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithWhereUniqueWithoutDiscountCouponInput = {
  data: OrderUpdateWithoutDiscountCouponInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithoutCustomerInput = {
  agreementSale?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  conversationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  discountCoupon?: InputMaybe<DiscountCouponUpdateOneWithoutOrdersNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identityNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  informationSale?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  invoiceNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isShipped?: InputMaybe<BoolFieldUpdateOperationsInput>;
  language?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderHistories?: InputMaybe<OrderHistoryUpdateManyWithoutOrderNestedInput>;
  orderStatus?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  payment?: InputMaybe<PaymentUpdateOneWithoutOrderNestedInput>;
  paymentAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentPhoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentPostalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentTaxNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentTaxOffice?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentType?: InputMaybe<NullableEnumPaymentTypeFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductOrderUpdateManyWithoutOrderNestedInput>;
  shippingAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingConvertedPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  shippingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPhoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPostalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  shippingTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutDiscountCouponInput = {
  agreementSale?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  conversationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneRequiredWithoutOrdersNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identityNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  informationSale?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  invoiceNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isShipped?: InputMaybe<BoolFieldUpdateOperationsInput>;
  language?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderHistories?: InputMaybe<OrderHistoryUpdateManyWithoutOrderNestedInput>;
  orderStatus?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  payment?: InputMaybe<PaymentUpdateOneWithoutOrderNestedInput>;
  paymentAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentPhoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentPostalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentTaxNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentTaxOffice?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentType?: InputMaybe<NullableEnumPaymentTypeFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductOrderUpdateManyWithoutOrderNestedInput>;
  shippingAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingConvertedPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  shippingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPhoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPostalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  shippingTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutProductsInput = {
  agreementSale?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  conversationId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneRequiredWithoutOrdersNestedInput>;
  discountCoupon?: InputMaybe<DiscountCouponUpdateOneWithoutOrdersNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identityNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  informationSale?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  invoiceNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isShipped?: InputMaybe<BoolFieldUpdateOperationsInput>;
  language?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  orderHistories?: InputMaybe<OrderHistoryUpdateManyWithoutOrderNestedInput>;
  orderStatus?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  payment?: InputMaybe<PaymentUpdateOneWithoutOrderNestedInput>;
  paymentAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentPhoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentPostalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentTaxNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentTaxOffice?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentType?: InputMaybe<NullableEnumPaymentTypeFieldUpdateOperationsInput>;
  shippingAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCompany?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingConvertedPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  shippingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPhoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPostalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  shippingTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type OrderUpsertWithWhereUniqueWithoutCustomerInput = {
  create: OrderCreateWithoutCustomerInput;
  update: OrderUpdateWithoutCustomerInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithWhereUniqueWithoutDiscountCouponInput = {
  create: OrderCreateWithoutDiscountCouponInput;
  update: OrderUpdateWithoutDiscountCouponInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithoutProductsInput = {
  create: OrderCreateWithoutProductsInput;
  update: OrderUpdateWithoutProductsInput;
};

export type OrderWhereInput = {
  AND?: InputMaybe<Array<OrderWhereInput>>;
  NOT?: InputMaybe<Array<OrderWhereInput>>;
  OR?: InputMaybe<Array<OrderWhereInput>>;
  agreementSale?: InputMaybe<StringNullableFilter>;
  conversationId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringFilter>;
  discountCoupon?: InputMaybe<DiscountCouponRelationFilter>;
  discountCouponId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  identityNumber?: InputMaybe<StringNullableFilter>;
  informationSale?: InputMaybe<StringNullableFilter>;
  invoiceNumber?: InputMaybe<StringNullableFilter>;
  isCompleted?: InputMaybe<BoolFilter>;
  isShipped?: InputMaybe<BoolFilter>;
  language?: InputMaybe<StringNullableFilter>;
  orderHistories?: InputMaybe<OrderHistoryListRelationFilter>;
  orderStatus?: InputMaybe<EnumOrderStatusFilter>;
  payment?: InputMaybe<PaymentRelationFilter>;
  paymentAddress?: InputMaybe<StringNullableFilter>;
  paymentCity?: InputMaybe<StringNullableFilter>;
  paymentCompany?: InputMaybe<StringNullableFilter>;
  paymentCountry?: InputMaybe<StringNullableFilter>;
  paymentEmail?: InputMaybe<StringNullableFilter>;
  paymentFirstName?: InputMaybe<StringNullableFilter>;
  paymentLastName?: InputMaybe<StringNullableFilter>;
  paymentPhoneNumber?: InputMaybe<StringNullableFilter>;
  paymentPostalCode?: InputMaybe<StringNullableFilter>;
  paymentTaxNumber?: InputMaybe<StringNullableFilter>;
  paymentTaxOffice?: InputMaybe<StringNullableFilter>;
  paymentType?: InputMaybe<EnumPaymentTypeNullableFilter>;
  products?: InputMaybe<ProductOrderListRelationFilter>;
  shippingAddress?: InputMaybe<StringNullableFilter>;
  shippingCity?: InputMaybe<StringNullableFilter>;
  shippingCompany?: InputMaybe<StringNullableFilter>;
  shippingConvertedPrice?: InputMaybe<FloatNullableFilter>;
  shippingCountry?: InputMaybe<StringNullableFilter>;
  shippingEmail?: InputMaybe<StringNullableFilter>;
  shippingFirstName?: InputMaybe<StringNullableFilter>;
  shippingLastName?: InputMaybe<StringNullableFilter>;
  shippingNumber?: InputMaybe<StringNullableFilter>;
  shippingPhoneNumber?: InputMaybe<StringNullableFilter>;
  shippingPostalCode?: InputMaybe<StringNullableFilter>;
  shippingPrice?: InputMaybe<FloatNullableFilter>;
  shippingTitle?: InputMaybe<StringNullableFilter>;
};

export type OrderWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Page = {
  __typename?: 'Page';
  _count?: Maybe<PageCount>;
  createdAt: Scalars['DateTime'];
  descriptions: Array<Description>;
  id: Scalars['String'];
  order: Scalars['Int'];
  pageType: PageType;
  seos: Array<Seo>;
  showOnFooter: Scalars['Boolean'];
  showOnHeader: Scalars['Boolean'];
  status: Scalars['Boolean'];
};


export type PageDescriptionsArgs = {
  cursor?: InputMaybe<DescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<DescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};


export type PageSeosArgs = {
  cursor?: InputMaybe<SeoWhereUniqueInput>;
  distinct?: InputMaybe<Array<SeoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SeoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SeoWhereInput>;
};

export type PageAvgAggregate = {
  __typename?: 'PageAvgAggregate';
  order?: Maybe<Scalars['Float']>;
};

export type PageAvgOrderByAggregateInput = {
  order?: InputMaybe<SortOrder>;
};

export type PageCount = {
  __typename?: 'PageCount';
  descriptions: Scalars['Int'];
  seos: Scalars['Int'];
};

export type PageCountAggregate = {
  __typename?: 'PageCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  order: Scalars['Int'];
  pageType: Scalars['Int'];
  showOnFooter: Scalars['Int'];
  showOnHeader: Scalars['Int'];
  status: Scalars['Int'];
};

export type PageCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  pageType?: InputMaybe<SortOrder>;
  showOnFooter?: InputMaybe<SortOrder>;
  showOnHeader?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type PageCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutPageInput>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  pageType?: InputMaybe<PageType>;
  seos?: InputMaybe<SeoCreateNestedManyWithoutPageInput>;
  showOnFooter?: InputMaybe<Scalars['Boolean']>;
  showOnHeader?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
};

export type PageCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  pageType?: InputMaybe<PageType>;
  showOnFooter?: InputMaybe<Scalars['Boolean']>;
  showOnHeader?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
};

export type PageCreateNestedOneWithoutDescriptionsInput = {
  connect?: InputMaybe<PageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PageCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<PageCreateWithoutDescriptionsInput>;
};

export type PageCreateNestedOneWithoutSeosInput = {
  connect?: InputMaybe<PageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PageCreateOrConnectWithoutSeosInput>;
  create?: InputMaybe<PageCreateWithoutSeosInput>;
};

export type PageCreateOrConnectWithoutDescriptionsInput = {
  create: PageCreateWithoutDescriptionsInput;
  where: PageWhereUniqueInput;
};

export type PageCreateOrConnectWithoutSeosInput = {
  create: PageCreateWithoutSeosInput;
  where: PageWhereUniqueInput;
};

export type PageCreateWithoutDescriptionsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  pageType?: InputMaybe<PageType>;
  seos?: InputMaybe<SeoCreateNestedManyWithoutPageInput>;
  showOnFooter?: InputMaybe<Scalars['Boolean']>;
  showOnHeader?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
};

export type PageCreateWithoutSeosInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutPageInput>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  pageType?: InputMaybe<PageType>;
  showOnFooter?: InputMaybe<Scalars['Boolean']>;
  showOnHeader?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
};

export type PageGroupBy = {
  __typename?: 'PageGroupBy';
  _avg?: Maybe<PageAvgAggregate>;
  _count?: Maybe<PageCountAggregate>;
  _max?: Maybe<PageMaxAggregate>;
  _min?: Maybe<PageMinAggregate>;
  _sum?: Maybe<PageSumAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  order: Scalars['Int'];
  pageType: PageType;
  showOnFooter: Scalars['Boolean'];
  showOnHeader: Scalars['Boolean'];
  status: Scalars['Boolean'];
};

export type PageMaxAggregate = {
  __typename?: 'PageMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  pageType?: Maybe<PageType>;
  showOnFooter?: Maybe<Scalars['Boolean']>;
  showOnHeader?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
};

export type PageMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  pageType?: InputMaybe<SortOrder>;
  showOnFooter?: InputMaybe<SortOrder>;
  showOnHeader?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type PageMinAggregate = {
  __typename?: 'PageMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  pageType?: Maybe<PageType>;
  showOnFooter?: Maybe<Scalars['Boolean']>;
  showOnHeader?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
};

export type PageMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  pageType?: InputMaybe<SortOrder>;
  showOnFooter?: InputMaybe<SortOrder>;
  showOnHeader?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type PageOrderByWithAggregationInput = {
  _avg?: InputMaybe<PageAvgOrderByAggregateInput>;
  _count?: InputMaybe<PageCountOrderByAggregateInput>;
  _max?: InputMaybe<PageMaxOrderByAggregateInput>;
  _min?: InputMaybe<PageMinOrderByAggregateInput>;
  _sum?: InputMaybe<PageSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  pageType?: InputMaybe<SortOrder>;
  showOnFooter?: InputMaybe<SortOrder>;
  showOnHeader?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type PageOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  descriptions?: InputMaybe<DescriptionOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  pageType?: InputMaybe<SortOrder>;
  seos?: InputMaybe<SeoOrderByRelationAggregateInput>;
  showOnFooter?: InputMaybe<SortOrder>;
  showOnHeader?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type PageRelationFilter = {
  is?: InputMaybe<PageWhereInput>;
  isNot?: InputMaybe<PageWhereInput>;
};

export enum PageScalarFieldEnum {
  createdAt = 'createdAt',
  id = 'id',
  order = 'order',
  pageType = 'pageType',
  showOnFooter = 'showOnFooter',
  showOnHeader = 'showOnHeader',
  status = 'status'
}

export type PageScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PageScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PageScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PageScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  order?: InputMaybe<IntWithAggregatesFilter>;
  pageType?: InputMaybe<EnumPageTypeWithAggregatesFilter>;
  showOnFooter?: InputMaybe<BoolWithAggregatesFilter>;
  showOnHeader?: InputMaybe<BoolWithAggregatesFilter>;
  status?: InputMaybe<BoolWithAggregatesFilter>;
};

export type PageSumAggregate = {
  __typename?: 'PageSumAggregate';
  order?: Maybe<Scalars['Int']>;
};

export type PageSumOrderByAggregateInput = {
  order?: InputMaybe<SortOrder>;
};

export enum PageType {
  DEFAULT = 'DEFAULT',
  KVKK = 'KVKK',
  PRIVACYPOLICY = 'PRIVACYPOLICY',
  SALEAGREEMENT = 'SALEAGREEMENT',
  SALEINFO = 'SALEINFO',
  SHIPPING = 'SHIPPING',
  USERAGREEMENT = 'USERAGREEMENT'
}

export type PageUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutPageNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  pageType?: InputMaybe<EnumPageTypeFieldUpdateOperationsInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutPageNestedInput>;
  showOnFooter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  showOnHeader?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PageUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  pageType?: InputMaybe<EnumPageTypeFieldUpdateOperationsInput>;
  showOnFooter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  showOnHeader?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PageUpdateOneWithoutDescriptionsNestedInput = {
  connect?: InputMaybe<PageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PageCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<PageCreateWithoutDescriptionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<PageUpdateWithoutDescriptionsInput>;
  upsert?: InputMaybe<PageUpsertWithoutDescriptionsInput>;
};

export type PageUpdateOneWithoutSeosNestedInput = {
  connect?: InputMaybe<PageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PageCreateOrConnectWithoutSeosInput>;
  create?: InputMaybe<PageCreateWithoutSeosInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<PageUpdateWithoutSeosInput>;
  upsert?: InputMaybe<PageUpsertWithoutSeosInput>;
};

export type PageUpdateWithoutDescriptionsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  pageType?: InputMaybe<EnumPageTypeFieldUpdateOperationsInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutPageNestedInput>;
  showOnFooter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  showOnHeader?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PageUpdateWithoutSeosInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutPageNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  pageType?: InputMaybe<EnumPageTypeFieldUpdateOperationsInput>;
  showOnFooter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  showOnHeader?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PageUpsertWithoutDescriptionsInput = {
  create: PageCreateWithoutDescriptionsInput;
  update: PageUpdateWithoutDescriptionsInput;
};

export type PageUpsertWithoutSeosInput = {
  create: PageCreateWithoutSeosInput;
  update: PageUpdateWithoutSeosInput;
};

export type PageWhereInput = {
  AND?: InputMaybe<Array<PageWhereInput>>;
  NOT?: InputMaybe<Array<PageWhereInput>>;
  OR?: InputMaybe<Array<PageWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  descriptions?: InputMaybe<DescriptionListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  pageType?: InputMaybe<EnumPageTypeFilter>;
  seos?: InputMaybe<SeoListRelationFilter>;
  showOnFooter?: InputMaybe<BoolFilter>;
  showOnHeader?: InputMaybe<BoolFilter>;
  status?: InputMaybe<BoolFilter>;
};

export type PageWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Payment = {
  __typename?: 'Payment';
  authCode?: Maybe<Scalars['String']>;
  binNumber?: Maybe<Scalars['String']>;
  cardAssociation?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  discount: Scalars['Float'];
  errorCode?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  installment: Scalars['Int'];
  iyziCommissionFee: Scalars['Float'];
  iyziCommissionRateAmount: Scalars['Float'];
  lastFourDigits?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  merchantCommissionRate: Scalars['Float'];
  merchantCommissionRateAmount: Scalars['Float'];
  order: Order;
  orderId: Scalars['String'];
  paidPrice: Scalars['Float'];
  paymentId?: Maybe<Scalars['String']>;
  phase?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  status: Scalars['String'];
  systemTime?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type PaymentCreateNestedOneWithoutOrderInput = {
  connect?: InputMaybe<PaymentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PaymentCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<PaymentCreateWithoutOrderInput>;
};

export type PaymentCreateOrConnectWithoutOrderInput = {
  create: PaymentCreateWithoutOrderInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentCreateWithoutOrderInput = {
  authCode?: InputMaybe<Scalars['String']>;
  binNumber?: InputMaybe<Scalars['String']>;
  cardAssociation?: InputMaybe<Scalars['String']>;
  cardType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  discount?: InputMaybe<Scalars['Float']>;
  errorCode?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  installment?: InputMaybe<Scalars['Int']>;
  iyziCommissionFee?: InputMaybe<Scalars['Float']>;
  iyziCommissionRateAmount?: InputMaybe<Scalars['Float']>;
  lastFourDigits?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  merchantCommissionRate?: InputMaybe<Scalars['Float']>;
  merchantCommissionRateAmount?: InputMaybe<Scalars['Float']>;
  paidPrice?: InputMaybe<Scalars['Float']>;
  paymentId?: InputMaybe<Scalars['String']>;
  phase?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  status: Scalars['String'];
  systemTime?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PaymentOrderByWithRelationInput = {
  authCode?: InputMaybe<SortOrder>;
  binNumber?: InputMaybe<SortOrder>;
  cardAssociation?: InputMaybe<SortOrder>;
  cardType?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  discount?: InputMaybe<SortOrder>;
  errorCode?: InputMaybe<SortOrder>;
  errorMessage?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  installment?: InputMaybe<SortOrder>;
  iyziCommissionFee?: InputMaybe<SortOrder>;
  iyziCommissionRateAmount?: InputMaybe<SortOrder>;
  lastFourDigits?: InputMaybe<SortOrder>;
  locale?: InputMaybe<SortOrder>;
  merchantCommissionRate?: InputMaybe<SortOrder>;
  merchantCommissionRateAmount?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrder>;
  paidPrice?: InputMaybe<SortOrder>;
  paymentId?: InputMaybe<SortOrder>;
  phase?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  systemTime?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PaymentRelationFilter = {
  is?: InputMaybe<PaymentWhereInput>;
  isNot?: InputMaybe<PaymentWhereInput>;
};

export enum PaymentType {
  IYZICO = 'IYZICO',
  STRIPE = 'STRIPE'
}

export type PaymentUpdateOneWithoutOrderNestedInput = {
  connect?: InputMaybe<PaymentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PaymentCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<PaymentCreateWithoutOrderInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<PaymentUpdateWithoutOrderInput>;
  upsert?: InputMaybe<PaymentUpsertWithoutOrderInput>;
};

export type PaymentUpdateWithoutOrderInput = {
  authCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  binNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cardAssociation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cardType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  discount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  errorCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  errorMessage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  installment?: InputMaybe<IntFieldUpdateOperationsInput>;
  iyziCommissionFee?: InputMaybe<FloatFieldUpdateOperationsInput>;
  iyziCommissionRateAmount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  lastFourDigits?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locale?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  merchantCommissionRate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  merchantCommissionRateAmount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  paidPrice?: InputMaybe<FloatFieldUpdateOperationsInput>;
  paymentId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phase?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  systemTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PaymentUpsertWithoutOrderInput = {
  create: PaymentCreateWithoutOrderInput;
  update: PaymentUpdateWithoutOrderInput;
};

export type PaymentWhereInput = {
  AND?: InputMaybe<Array<PaymentWhereInput>>;
  NOT?: InputMaybe<Array<PaymentWhereInput>>;
  OR?: InputMaybe<Array<PaymentWhereInput>>;
  authCode?: InputMaybe<StringNullableFilter>;
  binNumber?: InputMaybe<StringNullableFilter>;
  cardAssociation?: InputMaybe<StringNullableFilter>;
  cardType?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  discount?: InputMaybe<FloatFilter>;
  errorCode?: InputMaybe<StringNullableFilter>;
  errorMessage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  installment?: InputMaybe<IntFilter>;
  iyziCommissionFee?: InputMaybe<FloatFilter>;
  iyziCommissionRateAmount?: InputMaybe<FloatFilter>;
  lastFourDigits?: InputMaybe<StringNullableFilter>;
  locale?: InputMaybe<StringNullableFilter>;
  merchantCommissionRate?: InputMaybe<FloatFilter>;
  merchantCommissionRateAmount?: InputMaybe<FloatFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  paidPrice?: InputMaybe<FloatFilter>;
  paymentId?: InputMaybe<StringNullableFilter>;
  phase?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<FloatFilter>;
  status?: InputMaybe<StringFilter>;
  systemTime?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PaymentWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  _count?: Maybe<ProductCount>;
  barcode?: Maybe<Scalars['String']>;
  basketItems: Array<BasketItem>;
  category: Category;
  categoryId: Scalars['String'];
  configuratorData?: Maybe<Scalars['JSON']>;
  creatables: Array<ProductCreatable>;
  createdAt: Scalars['DateTime'];
  descriptions: Array<Description>;
  discountAmount: Scalars['Decimal'];
  discountType: DiscountType;
  id: Scalars['String'];
  oldPrice?: Maybe<Scalars['Decimal']>;
  order: Scalars['Int'];
  photos: Array<Scalars['String']>;
  price: Scalars['Decimal'];
  productOrders: Array<ProductOrder>;
  productRingColors: Array<ProductRingColor>;
  productRingSizeStandarts: Array<ProductRingSizeStandart>;
  seos: Array<Seo>;
  sku?: Maybe<Scalars['String']>;
  sliders: Array<Slider>;
  status: Scalars['Boolean'];
  stoneGroups: Array<ProductRingStoneGroup>;
  style?: Maybe<Style>;
  styleId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight: Scalars['Decimal'];
};


export type ProductBasketItemsArgs = {
  cursor?: InputMaybe<BasketItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<BasketItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BasketItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BasketItemWhereInput>;
};


export type ProductCreatablesArgs = {
  cursor?: InputMaybe<ProductCreatableWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductCreatableScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductCreatableOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductCreatableWhereInput>;
};


export type ProductDescriptionsArgs = {
  cursor?: InputMaybe<DescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<DescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};


export type ProductProductOrdersArgs = {
  cursor?: InputMaybe<ProductOrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductOrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductOrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductOrderWhereInput>;
};


export type ProductProductRingColorsArgs = {
  cursor?: InputMaybe<ProductRingColorWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductRingColorScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductRingColorOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductRingColorWhereInput>;
};


export type ProductProductRingSizeStandartsArgs = {
  cursor?: InputMaybe<ProductRingSizeStandartWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductRingSizeStandartScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductRingSizeStandartOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductRingSizeStandartWhereInput>;
};


export type ProductSeosArgs = {
  cursor?: InputMaybe<SeoWhereUniqueInput>;
  distinct?: InputMaybe<Array<SeoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SeoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SeoWhereInput>;
};


export type ProductSlidersArgs = {
  cursor?: InputMaybe<SliderWhereUniqueInput>;
  distinct?: InputMaybe<Array<SliderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SliderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SliderWhereInput>;
};


export type ProductStoneGroupsArgs = {
  cursor?: InputMaybe<ProductRingStoneGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductRingStoneGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductRingStoneGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductRingStoneGroupWhereInput>;
};

export type ProductAvgAggregate = {
  __typename?: 'ProductAvgAggregate';
  discountAmount?: Maybe<Scalars['Decimal']>;
  oldPrice?: Maybe<Scalars['Decimal']>;
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Decimal']>;
  weight?: Maybe<Scalars['Decimal']>;
};

export type ProductAvgOrderByAggregateInput = {
  discountAmount?: InputMaybe<SortOrder>;
  oldPrice?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  weight?: InputMaybe<SortOrder>;
};

export type ProductBasketCreatable = {
  __typename?: 'ProductBasketCreatable';
  basketItem?: Maybe<BasketItem>;
  basketItemId?: Maybe<Scalars['String']>;
  creatable: Creatable;
  creatableId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
};

export type ProductBasketCreatableCreateManyBasketItemInput = {
  creatableId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
};

export type ProductBasketCreatableCreateManyBasketItemInputEnvelope = {
  data: Array<ProductBasketCreatableCreateManyBasketItemInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductBasketCreatableCreateManyCreatableInput = {
  basketItemId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
};

export type ProductBasketCreatableCreateManyCreatableInputEnvelope = {
  data: Array<ProductBasketCreatableCreateManyCreatableInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductBasketCreatableCreateNestedManyWithoutBasketItemInput = {
  connect?: InputMaybe<Array<ProductBasketCreatableWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductBasketCreatableCreateOrConnectWithoutBasketItemInput>>;
  create?: InputMaybe<Array<ProductBasketCreatableCreateWithoutBasketItemInput>>;
  createMany?: InputMaybe<ProductBasketCreatableCreateManyBasketItemInputEnvelope>;
};

export type ProductBasketCreatableCreateNestedManyWithoutCreatableInput = {
  connect?: InputMaybe<Array<ProductBasketCreatableWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductBasketCreatableCreateOrConnectWithoutCreatableInput>>;
  create?: InputMaybe<Array<ProductBasketCreatableCreateWithoutCreatableInput>>;
  createMany?: InputMaybe<ProductBasketCreatableCreateManyCreatableInputEnvelope>;
};

export type ProductBasketCreatableCreateOrConnectWithoutBasketItemInput = {
  create: ProductBasketCreatableCreateWithoutBasketItemInput;
  where: ProductBasketCreatableWhereUniqueInput;
};

export type ProductBasketCreatableCreateOrConnectWithoutCreatableInput = {
  create: ProductBasketCreatableCreateWithoutCreatableInput;
  where: ProductBasketCreatableWhereUniqueInput;
};

export type ProductBasketCreatableCreateWithoutBasketItemInput = {
  creatable: CreatableCreateNestedOneWithoutProductBasketCreatableInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
};

export type ProductBasketCreatableCreateWithoutCreatableInput = {
  basketItem?: InputMaybe<BasketItemCreateNestedOneWithoutProductSelectedCreatablesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
};

export type ProductBasketCreatableListRelationFilter = {
  every?: InputMaybe<ProductBasketCreatableWhereInput>;
  none?: InputMaybe<ProductBasketCreatableWhereInput>;
  some?: InputMaybe<ProductBasketCreatableWhereInput>;
};

export type ProductBasketCreatableOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProductBasketCreatableOrderByWithRelationInput = {
  basketItem?: InputMaybe<BasketItemOrderByWithRelationInput>;
  basketItemId?: InputMaybe<SortOrder>;
  creatable?: InputMaybe<CreatableOrderByWithRelationInput>;
  creatableId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export enum ProductBasketCreatableScalarFieldEnum {
  basketItemId = 'basketItemId',
  creatableId = 'creatableId',
  createdAt = 'createdAt',
  id = 'id'
}

export type ProductBasketCreatableScalarWhereInput = {
  AND?: InputMaybe<Array<ProductBasketCreatableScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProductBasketCreatableScalarWhereInput>>;
  OR?: InputMaybe<Array<ProductBasketCreatableScalarWhereInput>>;
  basketItemId?: InputMaybe<StringNullableFilter>;
  creatableId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
};

export type ProductBasketCreatableUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ProductBasketCreatableUpdateManyWithWhereWithoutBasketItemInput = {
  data: ProductBasketCreatableUpdateManyMutationInput;
  where: ProductBasketCreatableScalarWhereInput;
};

export type ProductBasketCreatableUpdateManyWithWhereWithoutCreatableInput = {
  data: ProductBasketCreatableUpdateManyMutationInput;
  where: ProductBasketCreatableScalarWhereInput;
};

export type ProductBasketCreatableUpdateManyWithoutBasketItemNestedInput = {
  connect?: InputMaybe<Array<ProductBasketCreatableWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductBasketCreatableCreateOrConnectWithoutBasketItemInput>>;
  create?: InputMaybe<Array<ProductBasketCreatableCreateWithoutBasketItemInput>>;
  createMany?: InputMaybe<ProductBasketCreatableCreateManyBasketItemInputEnvelope>;
  delete?: InputMaybe<Array<ProductBasketCreatableWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductBasketCreatableScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductBasketCreatableWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductBasketCreatableWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductBasketCreatableUpdateWithWhereUniqueWithoutBasketItemInput>>;
  updateMany?: InputMaybe<Array<ProductBasketCreatableUpdateManyWithWhereWithoutBasketItemInput>>;
  upsert?: InputMaybe<Array<ProductBasketCreatableUpsertWithWhereUniqueWithoutBasketItemInput>>;
};

export type ProductBasketCreatableUpdateManyWithoutCreatableNestedInput = {
  connect?: InputMaybe<Array<ProductBasketCreatableWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductBasketCreatableCreateOrConnectWithoutCreatableInput>>;
  create?: InputMaybe<Array<ProductBasketCreatableCreateWithoutCreatableInput>>;
  createMany?: InputMaybe<ProductBasketCreatableCreateManyCreatableInputEnvelope>;
  delete?: InputMaybe<Array<ProductBasketCreatableWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductBasketCreatableScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductBasketCreatableWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductBasketCreatableWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductBasketCreatableUpdateWithWhereUniqueWithoutCreatableInput>>;
  updateMany?: InputMaybe<Array<ProductBasketCreatableUpdateManyWithWhereWithoutCreatableInput>>;
  upsert?: InputMaybe<Array<ProductBasketCreatableUpsertWithWhereUniqueWithoutCreatableInput>>;
};

export type ProductBasketCreatableUpdateWithWhereUniqueWithoutBasketItemInput = {
  data: ProductBasketCreatableUpdateWithoutBasketItemInput;
  where: ProductBasketCreatableWhereUniqueInput;
};

export type ProductBasketCreatableUpdateWithWhereUniqueWithoutCreatableInput = {
  data: ProductBasketCreatableUpdateWithoutCreatableInput;
  where: ProductBasketCreatableWhereUniqueInput;
};

export type ProductBasketCreatableUpdateWithoutBasketItemInput = {
  creatable?: InputMaybe<CreatableUpdateOneRequiredWithoutProductBasketCreatableNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ProductBasketCreatableUpdateWithoutCreatableInput = {
  basketItem?: InputMaybe<BasketItemUpdateOneWithoutProductSelectedCreatablesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ProductBasketCreatableUpsertWithWhereUniqueWithoutBasketItemInput = {
  create: ProductBasketCreatableCreateWithoutBasketItemInput;
  update: ProductBasketCreatableUpdateWithoutBasketItemInput;
  where: ProductBasketCreatableWhereUniqueInput;
};

export type ProductBasketCreatableUpsertWithWhereUniqueWithoutCreatableInput = {
  create: ProductBasketCreatableCreateWithoutCreatableInput;
  update: ProductBasketCreatableUpdateWithoutCreatableInput;
  where: ProductBasketCreatableWhereUniqueInput;
};

export type ProductBasketCreatableWhereInput = {
  AND?: InputMaybe<Array<ProductBasketCreatableWhereInput>>;
  NOT?: InputMaybe<Array<ProductBasketCreatableWhereInput>>;
  OR?: InputMaybe<Array<ProductBasketCreatableWhereInput>>;
  basketItem?: InputMaybe<BasketItemRelationFilter>;
  basketItemId?: InputMaybe<StringNullableFilter>;
  creatable?: InputMaybe<CreatableRelationFilter>;
  creatableId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
};

export type ProductBasketCreatableWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ProductCount = {
  __typename?: 'ProductCount';
  basketItems: Scalars['Int'];
  creatables: Scalars['Int'];
  descriptions: Scalars['Int'];
  productOrders: Scalars['Int'];
  productRingColors: Scalars['Int'];
  productRingSizeStandarts: Scalars['Int'];
  seos: Scalars['Int'];
  sliders: Scalars['Int'];
  stoneGroups: Scalars['Int'];
};

export type ProductCountAggregate = {
  __typename?: 'ProductCountAggregate';
  _all: Scalars['Int'];
  barcode: Scalars['Int'];
  categoryId: Scalars['Int'];
  configuratorData: Scalars['Int'];
  createdAt: Scalars['Int'];
  discountAmount: Scalars['Int'];
  discountType: Scalars['Int'];
  id: Scalars['Int'];
  oldPrice: Scalars['Int'];
  order: Scalars['Int'];
  photos: Scalars['Int'];
  price: Scalars['Int'];
  sku: Scalars['Int'];
  status: Scalars['Int'];
  styleId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  weight: Scalars['Int'];
};

export type ProductCountOrderByAggregateInput = {
  barcode?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  configuratorData?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  discountAmount?: InputMaybe<SortOrder>;
  discountType?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  oldPrice?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  sku?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  styleId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  weight?: InputMaybe<SortOrder>;
};

export type ProductCreatable = {
  __typename?: 'ProductCreatable';
  creatable: Creatable;
  creatableId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  product: Product;
  productId: Scalars['String'];
};

export type ProductCreatableCreateManyCreatableInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
};

export type ProductCreatableCreateManyCreatableInputEnvelope = {
  data: Array<ProductCreatableCreateManyCreatableInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductCreatableCreateManyProductInput = {
  creatableId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
};

export type ProductCreatableCreateManyProductInputEnvelope = {
  data: Array<ProductCreatableCreateManyProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductCreatableCreateNestedManyWithoutCreatableInput = {
  connect?: InputMaybe<Array<ProductCreatableWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreatableCreateOrConnectWithoutCreatableInput>>;
  create?: InputMaybe<Array<ProductCreatableCreateWithoutCreatableInput>>;
  createMany?: InputMaybe<ProductCreatableCreateManyCreatableInputEnvelope>;
};

export type ProductCreatableCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<ProductCreatableWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreatableCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<ProductCreatableCreateWithoutProductInput>>;
  createMany?: InputMaybe<ProductCreatableCreateManyProductInputEnvelope>;
};

export type ProductCreatableCreateOrConnectWithoutCreatableInput = {
  create: ProductCreatableCreateWithoutCreatableInput;
  where: ProductCreatableWhereUniqueInput;
};

export type ProductCreatableCreateOrConnectWithoutProductInput = {
  create: ProductCreatableCreateWithoutProductInput;
  where: ProductCreatableWhereUniqueInput;
};

export type ProductCreatableCreateWithoutCreatableInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  product: ProductCreateNestedOneWithoutCreatablesInput;
};

export type ProductCreatableCreateWithoutProductInput = {
  creatable: CreatableCreateNestedOneWithoutProductCreatablesInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
};

export type ProductCreatableListRelationFilter = {
  every?: InputMaybe<ProductCreatableWhereInput>;
  none?: InputMaybe<ProductCreatableWhereInput>;
  some?: InputMaybe<ProductCreatableWhereInput>;
};

export type ProductCreatableOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProductCreatableOrderByWithRelationInput = {
  creatable?: InputMaybe<CreatableOrderByWithRelationInput>;
  creatableId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  product?: InputMaybe<ProductOrderByWithRelationInput>;
  productId?: InputMaybe<SortOrder>;
};

export enum ProductCreatableScalarFieldEnum {
  creatableId = 'creatableId',
  createdAt = 'createdAt',
  id = 'id',
  productId = 'productId'
}

export type ProductCreatableScalarWhereInput = {
  AND?: InputMaybe<Array<ProductCreatableScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProductCreatableScalarWhereInput>>;
  OR?: InputMaybe<Array<ProductCreatableScalarWhereInput>>;
  creatableId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  productId?: InputMaybe<StringFilter>;
};

export type ProductCreatableUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ProductCreatableUpdateManyWithWhereWithoutCreatableInput = {
  data: ProductCreatableUpdateManyMutationInput;
  where: ProductCreatableScalarWhereInput;
};

export type ProductCreatableUpdateManyWithWhereWithoutProductInput = {
  data: ProductCreatableUpdateManyMutationInput;
  where: ProductCreatableScalarWhereInput;
};

export type ProductCreatableUpdateManyWithoutCreatableNestedInput = {
  connect?: InputMaybe<Array<ProductCreatableWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreatableCreateOrConnectWithoutCreatableInput>>;
  create?: InputMaybe<Array<ProductCreatableCreateWithoutCreatableInput>>;
  createMany?: InputMaybe<ProductCreatableCreateManyCreatableInputEnvelope>;
  delete?: InputMaybe<Array<ProductCreatableWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductCreatableScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductCreatableWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductCreatableWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductCreatableUpdateWithWhereUniqueWithoutCreatableInput>>;
  updateMany?: InputMaybe<Array<ProductCreatableUpdateManyWithWhereWithoutCreatableInput>>;
  upsert?: InputMaybe<Array<ProductCreatableUpsertWithWhereUniqueWithoutCreatableInput>>;
};

export type ProductCreatableUpdateManyWithoutProductNestedInput = {
  connect?: InputMaybe<Array<ProductCreatableWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreatableCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<ProductCreatableCreateWithoutProductInput>>;
  createMany?: InputMaybe<ProductCreatableCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<ProductCreatableWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductCreatableScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductCreatableWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductCreatableWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductCreatableUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<ProductCreatableUpdateManyWithWhereWithoutProductInput>>;
  upsert?: InputMaybe<Array<ProductCreatableUpsertWithWhereUniqueWithoutProductInput>>;
};

export type ProductCreatableUpdateWithWhereUniqueWithoutCreatableInput = {
  data: ProductCreatableUpdateWithoutCreatableInput;
  where: ProductCreatableWhereUniqueInput;
};

export type ProductCreatableUpdateWithWhereUniqueWithoutProductInput = {
  data: ProductCreatableUpdateWithoutProductInput;
  where: ProductCreatableWhereUniqueInput;
};

export type ProductCreatableUpdateWithoutCreatableInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutCreatablesNestedInput>;
};

export type ProductCreatableUpdateWithoutProductInput = {
  creatable?: InputMaybe<CreatableUpdateOneRequiredWithoutProductCreatablesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ProductCreatableUpsertWithWhereUniqueWithoutCreatableInput = {
  create: ProductCreatableCreateWithoutCreatableInput;
  update: ProductCreatableUpdateWithoutCreatableInput;
  where: ProductCreatableWhereUniqueInput;
};

export type ProductCreatableUpsertWithWhereUniqueWithoutProductInput = {
  create: ProductCreatableCreateWithoutProductInput;
  update: ProductCreatableUpdateWithoutProductInput;
  where: ProductCreatableWhereUniqueInput;
};

export type ProductCreatableWhereInput = {
  AND?: InputMaybe<Array<ProductCreatableWhereInput>>;
  NOT?: InputMaybe<Array<ProductCreatableWhereInput>>;
  OR?: InputMaybe<Array<ProductCreatableWhereInput>>;
  creatable?: InputMaybe<CreatableRelationFilter>;
  creatableId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  product?: InputMaybe<ProductRelationFilter>;
  productId?: InputMaybe<StringFilter>;
};

export type ProductCreatableWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ProductCreateCustomInput = {
  barcode?: InputMaybe<Scalars['String']>;
  category: CategoryCreateNestedOneWithoutProductsInput;
  colorIds?: InputMaybe<Array<Scalars['String']>>;
  configuratorData?: InputMaybe<Scalars['String']>;
  creatableIds?: InputMaybe<Array<Scalars['String']>>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutProductInput>;
  photos?: InputMaybe<ProductCreatephotosInput>;
  price?: InputMaybe<Scalars['Float']>;
  purityId?: InputMaybe<Scalars['String']>;
  ringSizeStandartIds?: InputMaybe<Array<Scalars['String']>>;
  ringText?: InputMaybe<Scalars['Boolean']>;
  sku?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
  stoneGroups?: InputMaybe<Array<ProductStoneGroups>>;
  style?: InputMaybe<StyleCreateNestedOneWithoutProductsInput>;
  weight: Scalars['Float'];
};

export type ProductCreateInput = {
  barcode?: InputMaybe<Scalars['String']>;
  basketItems?: InputMaybe<BasketItemCreateNestedManyWithoutProductInput>;
  category: CategoryCreateNestedOneWithoutProductsInput;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  creatables?: InputMaybe<ProductCreatableCreateNestedManyWithoutProductInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutProductInput>;
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  discountType?: InputMaybe<DiscountType>;
  id?: InputMaybe<Scalars['String']>;
  oldPrice?: InputMaybe<Scalars['Decimal']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<ProductCreatephotosInput>;
  price: Scalars['Decimal'];
  productOrders?: InputMaybe<ProductOrderCreateNestedManyWithoutProductInput>;
  productRingColors?: InputMaybe<ProductRingColorCreateNestedManyWithoutProductInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartCreateNestedManyWithoutProductInput>;
  seos?: InputMaybe<SeoCreateNestedManyWithoutProductInput>;
  sku?: InputMaybe<Scalars['String']>;
  sliders?: InputMaybe<SliderCreateNestedManyWithoutProductInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  stoneGroups?: InputMaybe<ProductRingStoneGroupCreateNestedManyWithoutProductInput>;
  style?: InputMaybe<StyleCreateNestedOneWithoutProductsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight: Scalars['Decimal'];
};

export type ProductCreateManyCategoryInput = {
  barcode?: InputMaybe<Scalars['String']>;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  discountType?: InputMaybe<DiscountType>;
  id?: InputMaybe<Scalars['String']>;
  oldPrice?: InputMaybe<Scalars['Decimal']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<ProductCreatephotosInput>;
  price: Scalars['Decimal'];
  sku?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
  styleId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight: Scalars['Decimal'];
};

export type ProductCreateManyCategoryInputEnvelope = {
  data: Array<ProductCreateManyCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductCreateManyInput = {
  barcode?: InputMaybe<Scalars['String']>;
  categoryId: Scalars['String'];
  configuratorData?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  discountType?: InputMaybe<DiscountType>;
  id?: InputMaybe<Scalars['String']>;
  oldPrice?: InputMaybe<Scalars['Decimal']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<ProductCreatephotosInput>;
  price: Scalars['Decimal'];
  sku?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
  styleId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight: Scalars['Decimal'];
};

export type ProductCreateManyStyleInput = {
  barcode?: InputMaybe<Scalars['String']>;
  categoryId: Scalars['String'];
  configuratorData?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  discountType?: InputMaybe<DiscountType>;
  id?: InputMaybe<Scalars['String']>;
  oldPrice?: InputMaybe<Scalars['Decimal']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<ProductCreatephotosInput>;
  price: Scalars['Decimal'];
  sku?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight: Scalars['Decimal'];
};

export type ProductCreateManyStyleInputEnvelope = {
  data: Array<ProductCreateManyStyleInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductCreateNestedManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<ProductCreateManyCategoryInputEnvelope>;
};

export type ProductCreateNestedManyWithoutStyleInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutStyleInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutStyleInput>>;
  createMany?: InputMaybe<ProductCreateManyStyleInputEnvelope>;
};

export type ProductCreateNestedOneWithoutBasketItemsInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutBasketItemsInput>;
  create?: InputMaybe<ProductCreateWithoutBasketItemsInput>;
};

export type ProductCreateNestedOneWithoutCreatablesInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutCreatablesInput>;
  create?: InputMaybe<ProductCreateWithoutCreatablesInput>;
};

export type ProductCreateNestedOneWithoutDescriptionsInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<ProductCreateWithoutDescriptionsInput>;
};

export type ProductCreateNestedOneWithoutProductOrdersInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutProductOrdersInput>;
  create?: InputMaybe<ProductCreateWithoutProductOrdersInput>;
};

export type ProductCreateNestedOneWithoutProductRingColorsInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutProductRingColorsInput>;
  create?: InputMaybe<ProductCreateWithoutProductRingColorsInput>;
};

export type ProductCreateNestedOneWithoutProductRingSizeStandartsInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutProductRingSizeStandartsInput>;
  create?: InputMaybe<ProductCreateWithoutProductRingSizeStandartsInput>;
};

export type ProductCreateNestedOneWithoutSeosInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutSeosInput>;
  create?: InputMaybe<ProductCreateWithoutSeosInput>;
};

export type ProductCreateNestedOneWithoutSlidersInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutSlidersInput>;
  create?: InputMaybe<ProductCreateWithoutSlidersInput>;
};

export type ProductCreateNestedOneWithoutStoneGroupsInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutStoneGroupsInput>;
  create?: InputMaybe<ProductCreateWithoutStoneGroupsInput>;
};

export type ProductCreateOrConnectWithoutBasketItemsInput = {
  create: ProductCreateWithoutBasketItemsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutCategoryInput = {
  create: ProductCreateWithoutCategoryInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutCreatablesInput = {
  create: ProductCreateWithoutCreatablesInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutDescriptionsInput = {
  create: ProductCreateWithoutDescriptionsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutProductOrdersInput = {
  create: ProductCreateWithoutProductOrdersInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutProductRingColorsInput = {
  create: ProductCreateWithoutProductRingColorsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutProductRingSizeStandartsInput = {
  create: ProductCreateWithoutProductRingSizeStandartsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutSeosInput = {
  create: ProductCreateWithoutSeosInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutSlidersInput = {
  create: ProductCreateWithoutSlidersInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutStoneGroupsInput = {
  create: ProductCreateWithoutStoneGroupsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutStyleInput = {
  create: ProductCreateWithoutStyleInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateWithoutBasketItemsInput = {
  barcode?: InputMaybe<Scalars['String']>;
  category: CategoryCreateNestedOneWithoutProductsInput;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  creatables?: InputMaybe<ProductCreatableCreateNestedManyWithoutProductInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutProductInput>;
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  discountType?: InputMaybe<DiscountType>;
  id?: InputMaybe<Scalars['String']>;
  oldPrice?: InputMaybe<Scalars['Decimal']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<ProductCreatephotosInput>;
  price: Scalars['Decimal'];
  productOrders?: InputMaybe<ProductOrderCreateNestedManyWithoutProductInput>;
  productRingColors?: InputMaybe<ProductRingColorCreateNestedManyWithoutProductInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartCreateNestedManyWithoutProductInput>;
  seos?: InputMaybe<SeoCreateNestedManyWithoutProductInput>;
  sku?: InputMaybe<Scalars['String']>;
  sliders?: InputMaybe<SliderCreateNestedManyWithoutProductInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  stoneGroups?: InputMaybe<ProductRingStoneGroupCreateNestedManyWithoutProductInput>;
  style?: InputMaybe<StyleCreateNestedOneWithoutProductsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight: Scalars['Decimal'];
};

export type ProductCreateWithoutCategoryInput = {
  barcode?: InputMaybe<Scalars['String']>;
  basketItems?: InputMaybe<BasketItemCreateNestedManyWithoutProductInput>;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  creatables?: InputMaybe<ProductCreatableCreateNestedManyWithoutProductInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutProductInput>;
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  discountType?: InputMaybe<DiscountType>;
  id?: InputMaybe<Scalars['String']>;
  oldPrice?: InputMaybe<Scalars['Decimal']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<ProductCreatephotosInput>;
  price: Scalars['Decimal'];
  productOrders?: InputMaybe<ProductOrderCreateNestedManyWithoutProductInput>;
  productRingColors?: InputMaybe<ProductRingColorCreateNestedManyWithoutProductInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartCreateNestedManyWithoutProductInput>;
  seos?: InputMaybe<SeoCreateNestedManyWithoutProductInput>;
  sku?: InputMaybe<Scalars['String']>;
  sliders?: InputMaybe<SliderCreateNestedManyWithoutProductInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  stoneGroups?: InputMaybe<ProductRingStoneGroupCreateNestedManyWithoutProductInput>;
  style?: InputMaybe<StyleCreateNestedOneWithoutProductsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight: Scalars['Decimal'];
};

export type ProductCreateWithoutCreatablesInput = {
  barcode?: InputMaybe<Scalars['String']>;
  basketItems?: InputMaybe<BasketItemCreateNestedManyWithoutProductInput>;
  category: CategoryCreateNestedOneWithoutProductsInput;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutProductInput>;
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  discountType?: InputMaybe<DiscountType>;
  id?: InputMaybe<Scalars['String']>;
  oldPrice?: InputMaybe<Scalars['Decimal']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<ProductCreatephotosInput>;
  price: Scalars['Decimal'];
  productOrders?: InputMaybe<ProductOrderCreateNestedManyWithoutProductInput>;
  productRingColors?: InputMaybe<ProductRingColorCreateNestedManyWithoutProductInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartCreateNestedManyWithoutProductInput>;
  seos?: InputMaybe<SeoCreateNestedManyWithoutProductInput>;
  sku?: InputMaybe<Scalars['String']>;
  sliders?: InputMaybe<SliderCreateNestedManyWithoutProductInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  stoneGroups?: InputMaybe<ProductRingStoneGroupCreateNestedManyWithoutProductInput>;
  style?: InputMaybe<StyleCreateNestedOneWithoutProductsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight: Scalars['Decimal'];
};

export type ProductCreateWithoutDescriptionsInput = {
  barcode?: InputMaybe<Scalars['String']>;
  basketItems?: InputMaybe<BasketItemCreateNestedManyWithoutProductInput>;
  category: CategoryCreateNestedOneWithoutProductsInput;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  creatables?: InputMaybe<ProductCreatableCreateNestedManyWithoutProductInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  discountType?: InputMaybe<DiscountType>;
  id?: InputMaybe<Scalars['String']>;
  oldPrice?: InputMaybe<Scalars['Decimal']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<ProductCreatephotosInput>;
  price: Scalars['Decimal'];
  productOrders?: InputMaybe<ProductOrderCreateNestedManyWithoutProductInput>;
  productRingColors?: InputMaybe<ProductRingColorCreateNestedManyWithoutProductInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartCreateNestedManyWithoutProductInput>;
  seos?: InputMaybe<SeoCreateNestedManyWithoutProductInput>;
  sku?: InputMaybe<Scalars['String']>;
  sliders?: InputMaybe<SliderCreateNestedManyWithoutProductInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  stoneGroups?: InputMaybe<ProductRingStoneGroupCreateNestedManyWithoutProductInput>;
  style?: InputMaybe<StyleCreateNestedOneWithoutProductsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight: Scalars['Decimal'];
};

export type ProductCreateWithoutProductOrdersInput = {
  barcode?: InputMaybe<Scalars['String']>;
  basketItems?: InputMaybe<BasketItemCreateNestedManyWithoutProductInput>;
  category: CategoryCreateNestedOneWithoutProductsInput;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  creatables?: InputMaybe<ProductCreatableCreateNestedManyWithoutProductInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutProductInput>;
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  discountType?: InputMaybe<DiscountType>;
  id?: InputMaybe<Scalars['String']>;
  oldPrice?: InputMaybe<Scalars['Decimal']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<ProductCreatephotosInput>;
  price: Scalars['Decimal'];
  productRingColors?: InputMaybe<ProductRingColorCreateNestedManyWithoutProductInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartCreateNestedManyWithoutProductInput>;
  seos?: InputMaybe<SeoCreateNestedManyWithoutProductInput>;
  sku?: InputMaybe<Scalars['String']>;
  sliders?: InputMaybe<SliderCreateNestedManyWithoutProductInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  stoneGroups?: InputMaybe<ProductRingStoneGroupCreateNestedManyWithoutProductInput>;
  style?: InputMaybe<StyleCreateNestedOneWithoutProductsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight: Scalars['Decimal'];
};

export type ProductCreateWithoutProductRingColorsInput = {
  barcode?: InputMaybe<Scalars['String']>;
  basketItems?: InputMaybe<BasketItemCreateNestedManyWithoutProductInput>;
  category: CategoryCreateNestedOneWithoutProductsInput;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  creatables?: InputMaybe<ProductCreatableCreateNestedManyWithoutProductInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutProductInput>;
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  discountType?: InputMaybe<DiscountType>;
  id?: InputMaybe<Scalars['String']>;
  oldPrice?: InputMaybe<Scalars['Decimal']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<ProductCreatephotosInput>;
  price: Scalars['Decimal'];
  productOrders?: InputMaybe<ProductOrderCreateNestedManyWithoutProductInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartCreateNestedManyWithoutProductInput>;
  seos?: InputMaybe<SeoCreateNestedManyWithoutProductInput>;
  sku?: InputMaybe<Scalars['String']>;
  sliders?: InputMaybe<SliderCreateNestedManyWithoutProductInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  stoneGroups?: InputMaybe<ProductRingStoneGroupCreateNestedManyWithoutProductInput>;
  style?: InputMaybe<StyleCreateNestedOneWithoutProductsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight: Scalars['Decimal'];
};

export type ProductCreateWithoutProductRingSizeStandartsInput = {
  barcode?: InputMaybe<Scalars['String']>;
  basketItems?: InputMaybe<BasketItemCreateNestedManyWithoutProductInput>;
  category: CategoryCreateNestedOneWithoutProductsInput;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  creatables?: InputMaybe<ProductCreatableCreateNestedManyWithoutProductInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutProductInput>;
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  discountType?: InputMaybe<DiscountType>;
  id?: InputMaybe<Scalars['String']>;
  oldPrice?: InputMaybe<Scalars['Decimal']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<ProductCreatephotosInput>;
  price: Scalars['Decimal'];
  productOrders?: InputMaybe<ProductOrderCreateNestedManyWithoutProductInput>;
  productRingColors?: InputMaybe<ProductRingColorCreateNestedManyWithoutProductInput>;
  seos?: InputMaybe<SeoCreateNestedManyWithoutProductInput>;
  sku?: InputMaybe<Scalars['String']>;
  sliders?: InputMaybe<SliderCreateNestedManyWithoutProductInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  stoneGroups?: InputMaybe<ProductRingStoneGroupCreateNestedManyWithoutProductInput>;
  style?: InputMaybe<StyleCreateNestedOneWithoutProductsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight: Scalars['Decimal'];
};

export type ProductCreateWithoutSeosInput = {
  barcode?: InputMaybe<Scalars['String']>;
  basketItems?: InputMaybe<BasketItemCreateNestedManyWithoutProductInput>;
  category: CategoryCreateNestedOneWithoutProductsInput;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  creatables?: InputMaybe<ProductCreatableCreateNestedManyWithoutProductInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutProductInput>;
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  discountType?: InputMaybe<DiscountType>;
  id?: InputMaybe<Scalars['String']>;
  oldPrice?: InputMaybe<Scalars['Decimal']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<ProductCreatephotosInput>;
  price: Scalars['Decimal'];
  productOrders?: InputMaybe<ProductOrderCreateNestedManyWithoutProductInput>;
  productRingColors?: InputMaybe<ProductRingColorCreateNestedManyWithoutProductInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartCreateNestedManyWithoutProductInput>;
  sku?: InputMaybe<Scalars['String']>;
  sliders?: InputMaybe<SliderCreateNestedManyWithoutProductInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  stoneGroups?: InputMaybe<ProductRingStoneGroupCreateNestedManyWithoutProductInput>;
  style?: InputMaybe<StyleCreateNestedOneWithoutProductsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight: Scalars['Decimal'];
};

export type ProductCreateWithoutSlidersInput = {
  barcode?: InputMaybe<Scalars['String']>;
  basketItems?: InputMaybe<BasketItemCreateNestedManyWithoutProductInput>;
  category: CategoryCreateNestedOneWithoutProductsInput;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  creatables?: InputMaybe<ProductCreatableCreateNestedManyWithoutProductInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutProductInput>;
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  discountType?: InputMaybe<DiscountType>;
  id?: InputMaybe<Scalars['String']>;
  oldPrice?: InputMaybe<Scalars['Decimal']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<ProductCreatephotosInput>;
  price: Scalars['Decimal'];
  productOrders?: InputMaybe<ProductOrderCreateNestedManyWithoutProductInput>;
  productRingColors?: InputMaybe<ProductRingColorCreateNestedManyWithoutProductInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartCreateNestedManyWithoutProductInput>;
  seos?: InputMaybe<SeoCreateNestedManyWithoutProductInput>;
  sku?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
  stoneGroups?: InputMaybe<ProductRingStoneGroupCreateNestedManyWithoutProductInput>;
  style?: InputMaybe<StyleCreateNestedOneWithoutProductsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight: Scalars['Decimal'];
};

export type ProductCreateWithoutStoneGroupsInput = {
  barcode?: InputMaybe<Scalars['String']>;
  basketItems?: InputMaybe<BasketItemCreateNestedManyWithoutProductInput>;
  category: CategoryCreateNestedOneWithoutProductsInput;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  creatables?: InputMaybe<ProductCreatableCreateNestedManyWithoutProductInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutProductInput>;
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  discountType?: InputMaybe<DiscountType>;
  id?: InputMaybe<Scalars['String']>;
  oldPrice?: InputMaybe<Scalars['Decimal']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<ProductCreatephotosInput>;
  price: Scalars['Decimal'];
  productOrders?: InputMaybe<ProductOrderCreateNestedManyWithoutProductInput>;
  productRingColors?: InputMaybe<ProductRingColorCreateNestedManyWithoutProductInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartCreateNestedManyWithoutProductInput>;
  seos?: InputMaybe<SeoCreateNestedManyWithoutProductInput>;
  sku?: InputMaybe<Scalars['String']>;
  sliders?: InputMaybe<SliderCreateNestedManyWithoutProductInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  style?: InputMaybe<StyleCreateNestedOneWithoutProductsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight: Scalars['Decimal'];
};

export type ProductCreateWithoutStyleInput = {
  barcode?: InputMaybe<Scalars['String']>;
  basketItems?: InputMaybe<BasketItemCreateNestedManyWithoutProductInput>;
  category: CategoryCreateNestedOneWithoutProductsInput;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  creatables?: InputMaybe<ProductCreatableCreateNestedManyWithoutProductInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutProductInput>;
  discountAmount?: InputMaybe<Scalars['Decimal']>;
  discountType?: InputMaybe<DiscountType>;
  id?: InputMaybe<Scalars['String']>;
  oldPrice?: InputMaybe<Scalars['Decimal']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<ProductCreatephotosInput>;
  price: Scalars['Decimal'];
  productOrders?: InputMaybe<ProductOrderCreateNestedManyWithoutProductInput>;
  productRingColors?: InputMaybe<ProductRingColorCreateNestedManyWithoutProductInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartCreateNestedManyWithoutProductInput>;
  seos?: InputMaybe<SeoCreateNestedManyWithoutProductInput>;
  sku?: InputMaybe<Scalars['String']>;
  sliders?: InputMaybe<SliderCreateNestedManyWithoutProductInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  stoneGroups?: InputMaybe<ProductRingStoneGroupCreateNestedManyWithoutProductInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight: Scalars['Decimal'];
};

export type ProductCreatephotosInput = {
  set: Array<Scalars['String']>;
};

export type ProductGroupBy = {
  __typename?: 'ProductGroupBy';
  _avg?: Maybe<ProductAvgAggregate>;
  _count?: Maybe<ProductCountAggregate>;
  _max?: Maybe<ProductMaxAggregate>;
  _min?: Maybe<ProductMinAggregate>;
  _sum?: Maybe<ProductSumAggregate>;
  barcode?: Maybe<Scalars['String']>;
  categoryId: Scalars['String'];
  configuratorData?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  discountAmount: Scalars['Decimal'];
  discountType: DiscountType;
  id: Scalars['String'];
  oldPrice?: Maybe<Scalars['Decimal']>;
  order: Scalars['Int'];
  photos?: Maybe<Array<Scalars['String']>>;
  price: Scalars['Decimal'];
  sku?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
  styleId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight: Scalars['Decimal'];
};

export type ProductListRelationFilter = {
  every?: InputMaybe<ProductWhereInput>;
  none?: InputMaybe<ProductWhereInput>;
  some?: InputMaybe<ProductWhereInput>;
};

export type ProductMaxAggregate = {
  __typename?: 'ProductMaxAggregate';
  barcode?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  discountAmount?: Maybe<Scalars['Decimal']>;
  discountType?: Maybe<DiscountType>;
  id?: Maybe<Scalars['String']>;
  oldPrice?: Maybe<Scalars['Decimal']>;
  order?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Decimal']>;
  sku?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  styleId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Decimal']>;
};

export type ProductMaxOrderByAggregateInput = {
  barcode?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  discountAmount?: InputMaybe<SortOrder>;
  discountType?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  oldPrice?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  sku?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  styleId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  weight?: InputMaybe<SortOrder>;
};

export type ProductMinAggregate = {
  __typename?: 'ProductMinAggregate';
  barcode?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  discountAmount?: Maybe<Scalars['Decimal']>;
  discountType?: Maybe<DiscountType>;
  id?: Maybe<Scalars['String']>;
  oldPrice?: Maybe<Scalars['Decimal']>;
  order?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Decimal']>;
  sku?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  styleId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Decimal']>;
};

export type ProductMinOrderByAggregateInput = {
  barcode?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  discountAmount?: InputMaybe<SortOrder>;
  discountType?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  oldPrice?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  sku?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  styleId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  weight?: InputMaybe<SortOrder>;
};

export type ProductOrder = {
  __typename?: 'ProductOrder';
  convertedPrice: Scalars['Decimal'];
  creatables?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  order: Order;
  orderId: Scalars['String'];
  price: Scalars['Decimal'];
  product: Product;
  productId: Scalars['String'];
  quantity: Scalars['Int'];
  ringColor?: Maybe<Scalars['String']>;
  ringPurity?: Maybe<Scalars['String']>;
  ringSize?: Maybe<Scalars['String']>;
  ringText?: Maybe<Scalars['String']>;
  stoneGroup?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ProductOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProductOrderByWithAggregationInput = {
  _avg?: InputMaybe<ProductAvgOrderByAggregateInput>;
  _count?: InputMaybe<ProductCountOrderByAggregateInput>;
  _max?: InputMaybe<ProductMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProductMinOrderByAggregateInput>;
  _sum?: InputMaybe<ProductSumOrderByAggregateInput>;
  barcode?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  configuratorData?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  discountAmount?: InputMaybe<SortOrder>;
  discountType?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  oldPrice?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  sku?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  styleId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  weight?: InputMaybe<SortOrder>;
};

export type ProductOrderByWithRelationInput = {
  barcode?: InputMaybe<SortOrder>;
  basketItems?: InputMaybe<BasketItemOrderByRelationAggregateInput>;
  category?: InputMaybe<CategoryOrderByWithRelationInput>;
  categoryId?: InputMaybe<SortOrder>;
  configuratorData?: InputMaybe<SortOrder>;
  creatables?: InputMaybe<ProductCreatableOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  descriptions?: InputMaybe<DescriptionOrderByRelationAggregateInput>;
  discountAmount?: InputMaybe<SortOrder>;
  discountType?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  oldPrice?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  productOrders?: InputMaybe<ProductOrderOrderByRelationAggregateInput>;
  productRingColors?: InputMaybe<ProductRingColorOrderByRelationAggregateInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartOrderByRelationAggregateInput>;
  seos?: InputMaybe<SeoOrderByRelationAggregateInput>;
  sku?: InputMaybe<SortOrder>;
  sliders?: InputMaybe<SliderOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  stoneGroups?: InputMaybe<ProductRingStoneGroupOrderByRelationAggregateInput>;
  style?: InputMaybe<StyleOrderByWithRelationInput>;
  styleId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  weight?: InputMaybe<SortOrder>;
};

export type ProductOrderCreateManyOrderInput = {
  convertedPrice: Scalars['Decimal'];
  creatables?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  price: Scalars['Decimal'];
  productId: Scalars['String'];
  quantity?: InputMaybe<Scalars['Int']>;
  ringColor?: InputMaybe<Scalars['String']>;
  ringPurity?: InputMaybe<Scalars['String']>;
  ringSize?: InputMaybe<Scalars['String']>;
  ringText?: InputMaybe<Scalars['String']>;
  stoneGroup?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductOrderCreateManyOrderInputEnvelope = {
  data: Array<ProductOrderCreateManyOrderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductOrderCreateManyProductInput = {
  convertedPrice: Scalars['Decimal'];
  creatables?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  orderId: Scalars['String'];
  price: Scalars['Decimal'];
  quantity?: InputMaybe<Scalars['Int']>;
  ringColor?: InputMaybe<Scalars['String']>;
  ringPurity?: InputMaybe<Scalars['String']>;
  ringSize?: InputMaybe<Scalars['String']>;
  ringText?: InputMaybe<Scalars['String']>;
  stoneGroup?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductOrderCreateManyProductInputEnvelope = {
  data: Array<ProductOrderCreateManyProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductOrderCreateNestedManyWithoutOrderInput = {
  connect?: InputMaybe<Array<ProductOrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductOrderCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<ProductOrderCreateWithoutOrderInput>>;
  createMany?: InputMaybe<ProductOrderCreateManyOrderInputEnvelope>;
};

export type ProductOrderCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<ProductOrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductOrderCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<ProductOrderCreateWithoutProductInput>>;
  createMany?: InputMaybe<ProductOrderCreateManyProductInputEnvelope>;
};

export type ProductOrderCreateOrConnectWithoutOrderInput = {
  create: ProductOrderCreateWithoutOrderInput;
  where: ProductOrderWhereUniqueInput;
};

export type ProductOrderCreateOrConnectWithoutProductInput = {
  create: ProductOrderCreateWithoutProductInput;
  where: ProductOrderWhereUniqueInput;
};

export type ProductOrderCreateWithoutOrderInput = {
  convertedPrice: Scalars['Decimal'];
  creatables?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  price: Scalars['Decimal'];
  product: ProductCreateNestedOneWithoutProductOrdersInput;
  quantity?: InputMaybe<Scalars['Int']>;
  ringColor?: InputMaybe<Scalars['String']>;
  ringPurity?: InputMaybe<Scalars['String']>;
  ringSize?: InputMaybe<Scalars['String']>;
  ringText?: InputMaybe<Scalars['String']>;
  stoneGroup?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductOrderCreateWithoutProductInput = {
  convertedPrice: Scalars['Decimal'];
  creatables?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order: OrderCreateNestedOneWithoutProductsInput;
  price: Scalars['Decimal'];
  quantity?: InputMaybe<Scalars['Int']>;
  ringColor?: InputMaybe<Scalars['String']>;
  ringPurity?: InputMaybe<Scalars['String']>;
  ringSize?: InputMaybe<Scalars['String']>;
  ringText?: InputMaybe<Scalars['String']>;
  stoneGroup?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductOrderListRelationFilter = {
  every?: InputMaybe<ProductOrderWhereInput>;
  none?: InputMaybe<ProductOrderWhereInput>;
  some?: InputMaybe<ProductOrderWhereInput>;
};

export type ProductOrderOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProductOrderOrderByWithRelationInput = {
  convertedPrice?: InputMaybe<SortOrder>;
  creatables?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  product?: InputMaybe<ProductOrderByWithRelationInput>;
  productId?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  ringColor?: InputMaybe<SortOrder>;
  ringPurity?: InputMaybe<SortOrder>;
  ringSize?: InputMaybe<SortOrder>;
  ringText?: InputMaybe<SortOrder>;
  stoneGroup?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ProductOrderScalarFieldEnum {
  convertedPrice = 'convertedPrice',
  creatables = 'creatables',
  createdAt = 'createdAt',
  id = 'id',
  orderId = 'orderId',
  price = 'price',
  productId = 'productId',
  quantity = 'quantity',
  ringColor = 'ringColor',
  ringPurity = 'ringPurity',
  ringSize = 'ringSize',
  ringText = 'ringText',
  stoneGroup = 'stoneGroup',
  updatedAt = 'updatedAt'
}

export type ProductOrderScalarWhereInput = {
  AND?: InputMaybe<Array<ProductOrderScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProductOrderScalarWhereInput>>;
  OR?: InputMaybe<Array<ProductOrderScalarWhereInput>>;
  convertedPrice?: InputMaybe<DecimalFilter>;
  creatables?: InputMaybe<JsonNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  orderId?: InputMaybe<StringFilter>;
  price?: InputMaybe<DecimalFilter>;
  productId?: InputMaybe<StringFilter>;
  quantity?: InputMaybe<IntFilter>;
  ringColor?: InputMaybe<StringNullableFilter>;
  ringPurity?: InputMaybe<StringNullableFilter>;
  ringSize?: InputMaybe<StringNullableFilter>;
  ringText?: InputMaybe<StringNullableFilter>;
  stoneGroup?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProductOrderUpdateManyMutationInput = {
  convertedPrice?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  creatables?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  ringColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ringPurity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ringSize?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ringText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stoneGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductOrderUpdateManyWithWhereWithoutOrderInput = {
  data: ProductOrderUpdateManyMutationInput;
  where: ProductOrderScalarWhereInput;
};

export type ProductOrderUpdateManyWithWhereWithoutProductInput = {
  data: ProductOrderUpdateManyMutationInput;
  where: ProductOrderScalarWhereInput;
};

export type ProductOrderUpdateManyWithoutOrderNestedInput = {
  connect?: InputMaybe<Array<ProductOrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductOrderCreateOrConnectWithoutOrderInput>>;
  create?: InputMaybe<Array<ProductOrderCreateWithoutOrderInput>>;
  createMany?: InputMaybe<ProductOrderCreateManyOrderInputEnvelope>;
  delete?: InputMaybe<Array<ProductOrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductOrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductOrderWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductOrderWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductOrderUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: InputMaybe<Array<ProductOrderUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: InputMaybe<Array<ProductOrderUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type ProductOrderUpdateManyWithoutProductNestedInput = {
  connect?: InputMaybe<Array<ProductOrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductOrderCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<ProductOrderCreateWithoutProductInput>>;
  createMany?: InputMaybe<ProductOrderCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<ProductOrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductOrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductOrderWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductOrderWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductOrderUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<ProductOrderUpdateManyWithWhereWithoutProductInput>>;
  upsert?: InputMaybe<Array<ProductOrderUpsertWithWhereUniqueWithoutProductInput>>;
};

export type ProductOrderUpdateWithWhereUniqueWithoutOrderInput = {
  data: ProductOrderUpdateWithoutOrderInput;
  where: ProductOrderWhereUniqueInput;
};

export type ProductOrderUpdateWithWhereUniqueWithoutProductInput = {
  data: ProductOrderUpdateWithoutProductInput;
  where: ProductOrderWhereUniqueInput;
};

export type ProductOrderUpdateWithoutOrderInput = {
  convertedPrice?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  creatables?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutProductOrdersNestedInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  ringColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ringPurity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ringSize?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ringText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stoneGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductOrderUpdateWithoutProductInput = {
  convertedPrice?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  creatables?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneRequiredWithoutProductsNestedInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  ringColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ringPurity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ringSize?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ringText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stoneGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductOrderUpsertWithWhereUniqueWithoutOrderInput = {
  create: ProductOrderCreateWithoutOrderInput;
  update: ProductOrderUpdateWithoutOrderInput;
  where: ProductOrderWhereUniqueInput;
};

export type ProductOrderUpsertWithWhereUniqueWithoutProductInput = {
  create: ProductOrderCreateWithoutProductInput;
  update: ProductOrderUpdateWithoutProductInput;
  where: ProductOrderWhereUniqueInput;
};

export type ProductOrderWhereInput = {
  AND?: InputMaybe<Array<ProductOrderWhereInput>>;
  NOT?: InputMaybe<Array<ProductOrderWhereInput>>;
  OR?: InputMaybe<Array<ProductOrderWhereInput>>;
  convertedPrice?: InputMaybe<DecimalFilter>;
  creatables?: InputMaybe<JsonNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  price?: InputMaybe<DecimalFilter>;
  product?: InputMaybe<ProductRelationFilter>;
  productId?: InputMaybe<StringFilter>;
  quantity?: InputMaybe<IntFilter>;
  ringColor?: InputMaybe<StringNullableFilter>;
  ringPurity?: InputMaybe<StringNullableFilter>;
  ringSize?: InputMaybe<StringNullableFilter>;
  ringText?: InputMaybe<StringNullableFilter>;
  stoneGroup?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProductOrderWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ProductPriceConstant = {
  __typename?: 'ProductPriceConstant';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  key: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type ProductPriceConstantCountAggregate = {
  __typename?: 'ProductPriceConstantCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  key: Scalars['Int'];
  updatedAt: Scalars['Int'];
  value: Scalars['Int'];
};

export type ProductPriceConstantCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type ProductPriceConstantCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type ProductPriceConstantCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type ProductPriceConstantGroupBy = {
  __typename?: 'ProductPriceConstantGroupBy';
  _count?: Maybe<ProductPriceConstantCountAggregate>;
  _max?: Maybe<ProductPriceConstantMaxAggregate>;
  _min?: Maybe<ProductPriceConstantMinAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  key: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type ProductPriceConstantMaxAggregate = {
  __typename?: 'ProductPriceConstantMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type ProductPriceConstantMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type ProductPriceConstantMinAggregate = {
  __typename?: 'ProductPriceConstantMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type ProductPriceConstantMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type ProductPriceConstantOrderByWithAggregationInput = {
  _count?: InputMaybe<ProductPriceConstantCountOrderByAggregateInput>;
  _max?: InputMaybe<ProductPriceConstantMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProductPriceConstantMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type ProductPriceConstantOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export enum ProductPriceConstantScalarFieldEnum {
  createdAt = 'createdAt',
  id = 'id',
  key = 'key',
  updatedAt = 'updatedAt',
  value = 'value'
}

export type ProductPriceConstantScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ProductPriceConstantScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ProductPriceConstantScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ProductPriceConstantScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  key?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  value?: InputMaybe<StringWithAggregatesFilter>;
};

export type ProductPriceConstantUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ProductPriceConstantUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ProductPriceConstantWhereInput = {
  AND?: InputMaybe<Array<ProductPriceConstantWhereInput>>;
  NOT?: InputMaybe<Array<ProductPriceConstantWhereInput>>;
  OR?: InputMaybe<Array<ProductPriceConstantWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  key?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type ProductPriceConstantWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
};

export type ProductRelationFilter = {
  is?: InputMaybe<ProductWhereInput>;
  isNot?: InputMaybe<ProductWhereInput>;
};

export type ProductRingColor = {
  __typename?: 'ProductRingColor';
  color: RingColor;
  colorId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  product: Product;
  productId: Scalars['String'];
};

export type ProductRingColorCreateManyColorInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
};

export type ProductRingColorCreateManyColorInputEnvelope = {
  data: Array<ProductRingColorCreateManyColorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductRingColorCreateManyProductInput = {
  colorId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
};

export type ProductRingColorCreateManyProductInputEnvelope = {
  data: Array<ProductRingColorCreateManyProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductRingColorCreateNestedManyWithoutColorInput = {
  connect?: InputMaybe<Array<ProductRingColorWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductRingColorCreateOrConnectWithoutColorInput>>;
  create?: InputMaybe<Array<ProductRingColorCreateWithoutColorInput>>;
  createMany?: InputMaybe<ProductRingColorCreateManyColorInputEnvelope>;
};

export type ProductRingColorCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<ProductRingColorWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductRingColorCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<ProductRingColorCreateWithoutProductInput>>;
  createMany?: InputMaybe<ProductRingColorCreateManyProductInputEnvelope>;
};

export type ProductRingColorCreateOrConnectWithoutColorInput = {
  create: ProductRingColorCreateWithoutColorInput;
  where: ProductRingColorWhereUniqueInput;
};

export type ProductRingColorCreateOrConnectWithoutProductInput = {
  create: ProductRingColorCreateWithoutProductInput;
  where: ProductRingColorWhereUniqueInput;
};

export type ProductRingColorCreateWithoutColorInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  product: ProductCreateNestedOneWithoutProductRingColorsInput;
};

export type ProductRingColorCreateWithoutProductInput = {
  color: RingColorCreateNestedOneWithoutProductRingColorsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
};

export type ProductRingColorListRelationFilter = {
  every?: InputMaybe<ProductRingColorWhereInput>;
  none?: InputMaybe<ProductRingColorWhereInput>;
  some?: InputMaybe<ProductRingColorWhereInput>;
};

export type ProductRingColorOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProductRingColorOrderByWithRelationInput = {
  color?: InputMaybe<RingColorOrderByWithRelationInput>;
  colorId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  product?: InputMaybe<ProductOrderByWithRelationInput>;
  productId?: InputMaybe<SortOrder>;
};

export enum ProductRingColorScalarFieldEnum {
  colorId = 'colorId',
  createdAt = 'createdAt',
  id = 'id',
  productId = 'productId'
}

export type ProductRingColorScalarWhereInput = {
  AND?: InputMaybe<Array<ProductRingColorScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProductRingColorScalarWhereInput>>;
  OR?: InputMaybe<Array<ProductRingColorScalarWhereInput>>;
  colorId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  productId?: InputMaybe<StringFilter>;
};

export type ProductRingColorUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ProductRingColorUpdateManyWithWhereWithoutColorInput = {
  data: ProductRingColorUpdateManyMutationInput;
  where: ProductRingColorScalarWhereInput;
};

export type ProductRingColorUpdateManyWithWhereWithoutProductInput = {
  data: ProductRingColorUpdateManyMutationInput;
  where: ProductRingColorScalarWhereInput;
};

export type ProductRingColorUpdateManyWithoutColorNestedInput = {
  connect?: InputMaybe<Array<ProductRingColorWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductRingColorCreateOrConnectWithoutColorInput>>;
  create?: InputMaybe<Array<ProductRingColorCreateWithoutColorInput>>;
  createMany?: InputMaybe<ProductRingColorCreateManyColorInputEnvelope>;
  delete?: InputMaybe<Array<ProductRingColorWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductRingColorScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductRingColorWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductRingColorWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductRingColorUpdateWithWhereUniqueWithoutColorInput>>;
  updateMany?: InputMaybe<Array<ProductRingColorUpdateManyWithWhereWithoutColorInput>>;
  upsert?: InputMaybe<Array<ProductRingColorUpsertWithWhereUniqueWithoutColorInput>>;
};

export type ProductRingColorUpdateManyWithoutProductNestedInput = {
  connect?: InputMaybe<Array<ProductRingColorWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductRingColorCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<ProductRingColorCreateWithoutProductInput>>;
  createMany?: InputMaybe<ProductRingColorCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<ProductRingColorWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductRingColorScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductRingColorWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductRingColorWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductRingColorUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<ProductRingColorUpdateManyWithWhereWithoutProductInput>>;
  upsert?: InputMaybe<Array<ProductRingColorUpsertWithWhereUniqueWithoutProductInput>>;
};

export type ProductRingColorUpdateWithWhereUniqueWithoutColorInput = {
  data: ProductRingColorUpdateWithoutColorInput;
  where: ProductRingColorWhereUniqueInput;
};

export type ProductRingColorUpdateWithWhereUniqueWithoutProductInput = {
  data: ProductRingColorUpdateWithoutProductInput;
  where: ProductRingColorWhereUniqueInput;
};

export type ProductRingColorUpdateWithoutColorInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutProductRingColorsNestedInput>;
};

export type ProductRingColorUpdateWithoutProductInput = {
  color?: InputMaybe<RingColorUpdateOneRequiredWithoutProductRingColorsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ProductRingColorUpsertWithWhereUniqueWithoutColorInput = {
  create: ProductRingColorCreateWithoutColorInput;
  update: ProductRingColorUpdateWithoutColorInput;
  where: ProductRingColorWhereUniqueInput;
};

export type ProductRingColorUpsertWithWhereUniqueWithoutProductInput = {
  create: ProductRingColorCreateWithoutProductInput;
  update: ProductRingColorUpdateWithoutProductInput;
  where: ProductRingColorWhereUniqueInput;
};

export type ProductRingColorWhereInput = {
  AND?: InputMaybe<Array<ProductRingColorWhereInput>>;
  NOT?: InputMaybe<Array<ProductRingColorWhereInput>>;
  OR?: InputMaybe<Array<ProductRingColorWhereInput>>;
  color?: InputMaybe<RingColorRelationFilter>;
  colorId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  product?: InputMaybe<ProductRelationFilter>;
  productId?: InputMaybe<StringFilter>;
};

export type ProductRingColorWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ProductRingSizeStandart = {
  __typename?: 'ProductRingSizeStandart';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  product: Product;
  productId: Scalars['String'];
  ringSizeStandart: RingSizeStandart;
  ringSizeStandartId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ProductRingSizeStandartCreateManyProductInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  ringSizeStandartId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductRingSizeStandartCreateManyProductInputEnvelope = {
  data: Array<ProductRingSizeStandartCreateManyProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductRingSizeStandartCreateManyRingSizeStandartInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductRingSizeStandartCreateManyRingSizeStandartInputEnvelope = {
  data: Array<ProductRingSizeStandartCreateManyRingSizeStandartInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductRingSizeStandartCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<ProductRingSizeStandartWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductRingSizeStandartCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<ProductRingSizeStandartCreateWithoutProductInput>>;
  createMany?: InputMaybe<ProductRingSizeStandartCreateManyProductInputEnvelope>;
};

export type ProductRingSizeStandartCreateNestedManyWithoutRingSizeStandartInput = {
  connect?: InputMaybe<Array<ProductRingSizeStandartWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductRingSizeStandartCreateOrConnectWithoutRingSizeStandartInput>>;
  create?: InputMaybe<Array<ProductRingSizeStandartCreateWithoutRingSizeStandartInput>>;
  createMany?: InputMaybe<ProductRingSizeStandartCreateManyRingSizeStandartInputEnvelope>;
};

export type ProductRingSizeStandartCreateOrConnectWithoutProductInput = {
  create: ProductRingSizeStandartCreateWithoutProductInput;
  where: ProductRingSizeStandartWhereUniqueInput;
};

export type ProductRingSizeStandartCreateOrConnectWithoutRingSizeStandartInput = {
  create: ProductRingSizeStandartCreateWithoutRingSizeStandartInput;
  where: ProductRingSizeStandartWhereUniqueInput;
};

export type ProductRingSizeStandartCreateWithoutProductInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  ringSizeStandart: RingSizeStandartCreateNestedOneWithoutProductRingSizeStandartsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductRingSizeStandartCreateWithoutRingSizeStandartInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  product: ProductCreateNestedOneWithoutProductRingSizeStandartsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductRingSizeStandartListRelationFilter = {
  every?: InputMaybe<ProductRingSizeStandartWhereInput>;
  none?: InputMaybe<ProductRingSizeStandartWhereInput>;
  some?: InputMaybe<ProductRingSizeStandartWhereInput>;
};

export type ProductRingSizeStandartOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProductRingSizeStandartOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  product?: InputMaybe<ProductOrderByWithRelationInput>;
  productId?: InputMaybe<SortOrder>;
  ringSizeStandart?: InputMaybe<RingSizeStandartOrderByWithRelationInput>;
  ringSizeStandartId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ProductRingSizeStandartScalarFieldEnum {
  createdAt = 'createdAt',
  id = 'id',
  productId = 'productId',
  ringSizeStandartId = 'ringSizeStandartId',
  updatedAt = 'updatedAt'
}

export type ProductRingSizeStandartScalarWhereInput = {
  AND?: InputMaybe<Array<ProductRingSizeStandartScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProductRingSizeStandartScalarWhereInput>>;
  OR?: InputMaybe<Array<ProductRingSizeStandartScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  productId?: InputMaybe<StringFilter>;
  ringSizeStandartId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProductRingSizeStandartUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductRingSizeStandartUpdateManyWithWhereWithoutProductInput = {
  data: ProductRingSizeStandartUpdateManyMutationInput;
  where: ProductRingSizeStandartScalarWhereInput;
};

export type ProductRingSizeStandartUpdateManyWithWhereWithoutRingSizeStandartInput = {
  data: ProductRingSizeStandartUpdateManyMutationInput;
  where: ProductRingSizeStandartScalarWhereInput;
};

export type ProductRingSizeStandartUpdateManyWithoutProductNestedInput = {
  connect?: InputMaybe<Array<ProductRingSizeStandartWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductRingSizeStandartCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<ProductRingSizeStandartCreateWithoutProductInput>>;
  createMany?: InputMaybe<ProductRingSizeStandartCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<ProductRingSizeStandartWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductRingSizeStandartScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductRingSizeStandartWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductRingSizeStandartWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductRingSizeStandartUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<ProductRingSizeStandartUpdateManyWithWhereWithoutProductInput>>;
  upsert?: InputMaybe<Array<ProductRingSizeStandartUpsertWithWhereUniqueWithoutProductInput>>;
};

export type ProductRingSizeStandartUpdateManyWithoutRingSizeStandartNestedInput = {
  connect?: InputMaybe<Array<ProductRingSizeStandartWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductRingSizeStandartCreateOrConnectWithoutRingSizeStandartInput>>;
  create?: InputMaybe<Array<ProductRingSizeStandartCreateWithoutRingSizeStandartInput>>;
  createMany?: InputMaybe<ProductRingSizeStandartCreateManyRingSizeStandartInputEnvelope>;
  delete?: InputMaybe<Array<ProductRingSizeStandartWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductRingSizeStandartScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductRingSizeStandartWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductRingSizeStandartWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductRingSizeStandartUpdateWithWhereUniqueWithoutRingSizeStandartInput>>;
  updateMany?: InputMaybe<Array<ProductRingSizeStandartUpdateManyWithWhereWithoutRingSizeStandartInput>>;
  upsert?: InputMaybe<Array<ProductRingSizeStandartUpsertWithWhereUniqueWithoutRingSizeStandartInput>>;
};

export type ProductRingSizeStandartUpdateWithWhereUniqueWithoutProductInput = {
  data: ProductRingSizeStandartUpdateWithoutProductInput;
  where: ProductRingSizeStandartWhereUniqueInput;
};

export type ProductRingSizeStandartUpdateWithWhereUniqueWithoutRingSizeStandartInput = {
  data: ProductRingSizeStandartUpdateWithoutRingSizeStandartInput;
  where: ProductRingSizeStandartWhereUniqueInput;
};

export type ProductRingSizeStandartUpdateWithoutProductInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ringSizeStandart?: InputMaybe<RingSizeStandartUpdateOneRequiredWithoutProductRingSizeStandartsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductRingSizeStandartUpdateWithoutRingSizeStandartInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutProductRingSizeStandartsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductRingSizeStandartUpsertWithWhereUniqueWithoutProductInput = {
  create: ProductRingSizeStandartCreateWithoutProductInput;
  update: ProductRingSizeStandartUpdateWithoutProductInput;
  where: ProductRingSizeStandartWhereUniqueInput;
};

export type ProductRingSizeStandartUpsertWithWhereUniqueWithoutRingSizeStandartInput = {
  create: ProductRingSizeStandartCreateWithoutRingSizeStandartInput;
  update: ProductRingSizeStandartUpdateWithoutRingSizeStandartInput;
  where: ProductRingSizeStandartWhereUniqueInput;
};

export type ProductRingSizeStandartWhereInput = {
  AND?: InputMaybe<Array<ProductRingSizeStandartWhereInput>>;
  NOT?: InputMaybe<Array<ProductRingSizeStandartWhereInput>>;
  OR?: InputMaybe<Array<ProductRingSizeStandartWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  product?: InputMaybe<ProductRelationFilter>;
  productId?: InputMaybe<StringFilter>;
  ringSizeStandart?: InputMaybe<RingSizeStandartRelationFilter>;
  ringSizeStandartId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProductRingSizeStandartWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ProductRingStone = {
  __typename?: 'ProductRingStone';
  carat: RingStoneCarat;
  caratId: Scalars['String'];
  caratInfo: RingStoneCaratInfo;
  caratInfoId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  productRingStoneGroup: ProductRingStoneGroup;
  productRingStoneGroupId: Scalars['String'];
  quality: RingStoneCaratQuality;
  quantity: Scalars['Int'];
  shape?: Maybe<RingStoneShape>;
  shapeId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ProductRingStoneAvgAggregate = {
  __typename?: 'ProductRingStoneAvgAggregate';
  quantity?: Maybe<Scalars['Float']>;
};

export type ProductRingStoneAvgOrderByAggregateInput = {
  quantity?: InputMaybe<SortOrder>;
};

export type ProductRingStoneCountAggregate = {
  __typename?: 'ProductRingStoneCountAggregate';
  _all: Scalars['Int'];
  caratId: Scalars['Int'];
  caratInfoId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  productRingStoneGroupId: Scalars['Int'];
  quality: Scalars['Int'];
  quantity: Scalars['Int'];
  shapeId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ProductRingStoneCountOrderByAggregateInput = {
  caratId?: InputMaybe<SortOrder>;
  caratInfoId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  productRingStoneGroupId?: InputMaybe<SortOrder>;
  quality?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  shapeId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProductRingStoneCreateInput = {
  carat: RingStoneCaratCreateNestedOneWithoutProductRingStoneInput;
  caratInfo: RingStoneCaratInfoCreateNestedOneWithoutProductRingStoneInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  productRingStoneGroup: ProductRingStoneGroupCreateNestedOneWithoutStonesInput;
  quality: RingStoneCaratQuality;
  quantity?: InputMaybe<Scalars['Int']>;
  shape?: InputMaybe<RingStoneShapeCreateNestedOneWithoutProductRingStoneInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductRingStoneCreateManyCaratInfoInput = {
  caratId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  productRingStoneGroupId: Scalars['String'];
  quality: RingStoneCaratQuality;
  quantity?: InputMaybe<Scalars['Int']>;
  shapeId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductRingStoneCreateManyCaratInfoInputEnvelope = {
  data: Array<ProductRingStoneCreateManyCaratInfoInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductRingStoneCreateManyCaratInput = {
  caratInfoId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  productRingStoneGroupId: Scalars['String'];
  quality: RingStoneCaratQuality;
  quantity?: InputMaybe<Scalars['Int']>;
  shapeId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductRingStoneCreateManyCaratInputEnvelope = {
  data: Array<ProductRingStoneCreateManyCaratInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductRingStoneCreateManyInput = {
  caratId: Scalars['String'];
  caratInfoId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  productRingStoneGroupId: Scalars['String'];
  quality: RingStoneCaratQuality;
  quantity?: InputMaybe<Scalars['Int']>;
  shapeId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductRingStoneCreateManyProductRingStoneGroupInput = {
  caratId: Scalars['String'];
  caratInfoId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  quality: RingStoneCaratQuality;
  quantity?: InputMaybe<Scalars['Int']>;
  shapeId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductRingStoneCreateManyProductRingStoneGroupInputEnvelope = {
  data: Array<ProductRingStoneCreateManyProductRingStoneGroupInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductRingStoneCreateManyShapeInput = {
  caratId: Scalars['String'];
  caratInfoId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  productRingStoneGroupId: Scalars['String'];
  quality: RingStoneCaratQuality;
  quantity?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductRingStoneCreateManyShapeInputEnvelope = {
  data: Array<ProductRingStoneCreateManyShapeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductRingStoneCreateNestedManyWithoutCaratInfoInput = {
  connect?: InputMaybe<Array<ProductRingStoneWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductRingStoneCreateOrConnectWithoutCaratInfoInput>>;
  create?: InputMaybe<Array<ProductRingStoneCreateWithoutCaratInfoInput>>;
  createMany?: InputMaybe<ProductRingStoneCreateManyCaratInfoInputEnvelope>;
};

export type ProductRingStoneCreateNestedManyWithoutCaratInput = {
  connect?: InputMaybe<Array<ProductRingStoneWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductRingStoneCreateOrConnectWithoutCaratInput>>;
  create?: InputMaybe<Array<ProductRingStoneCreateWithoutCaratInput>>;
  createMany?: InputMaybe<ProductRingStoneCreateManyCaratInputEnvelope>;
};

export type ProductRingStoneCreateNestedManyWithoutProductRingStoneGroupInput = {
  connect?: InputMaybe<Array<ProductRingStoneWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductRingStoneCreateOrConnectWithoutProductRingStoneGroupInput>>;
  create?: InputMaybe<Array<ProductRingStoneCreateWithoutProductRingStoneGroupInput>>;
  createMany?: InputMaybe<ProductRingStoneCreateManyProductRingStoneGroupInputEnvelope>;
};

export type ProductRingStoneCreateNestedManyWithoutShapeInput = {
  connect?: InputMaybe<Array<ProductRingStoneWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductRingStoneCreateOrConnectWithoutShapeInput>>;
  create?: InputMaybe<Array<ProductRingStoneCreateWithoutShapeInput>>;
  createMany?: InputMaybe<ProductRingStoneCreateManyShapeInputEnvelope>;
};

export type ProductRingStoneCreateOrConnectWithoutCaratInfoInput = {
  create: ProductRingStoneCreateWithoutCaratInfoInput;
  where: ProductRingStoneWhereUniqueInput;
};

export type ProductRingStoneCreateOrConnectWithoutCaratInput = {
  create: ProductRingStoneCreateWithoutCaratInput;
  where: ProductRingStoneWhereUniqueInput;
};

export type ProductRingStoneCreateOrConnectWithoutProductRingStoneGroupInput = {
  create: ProductRingStoneCreateWithoutProductRingStoneGroupInput;
  where: ProductRingStoneWhereUniqueInput;
};

export type ProductRingStoneCreateOrConnectWithoutShapeInput = {
  create: ProductRingStoneCreateWithoutShapeInput;
  where: ProductRingStoneWhereUniqueInput;
};

export type ProductRingStoneCreateWithoutCaratInfoInput = {
  carat: RingStoneCaratCreateNestedOneWithoutProductRingStoneInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  productRingStoneGroup: ProductRingStoneGroupCreateNestedOneWithoutStonesInput;
  quality: RingStoneCaratQuality;
  quantity?: InputMaybe<Scalars['Int']>;
  shape?: InputMaybe<RingStoneShapeCreateNestedOneWithoutProductRingStoneInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductRingStoneCreateWithoutCaratInput = {
  caratInfo: RingStoneCaratInfoCreateNestedOneWithoutProductRingStoneInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  productRingStoneGroup: ProductRingStoneGroupCreateNestedOneWithoutStonesInput;
  quality: RingStoneCaratQuality;
  quantity?: InputMaybe<Scalars['Int']>;
  shape?: InputMaybe<RingStoneShapeCreateNestedOneWithoutProductRingStoneInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductRingStoneCreateWithoutProductRingStoneGroupInput = {
  carat: RingStoneCaratCreateNestedOneWithoutProductRingStoneInput;
  caratInfo: RingStoneCaratInfoCreateNestedOneWithoutProductRingStoneInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  quality: RingStoneCaratQuality;
  quantity?: InputMaybe<Scalars['Int']>;
  shape?: InputMaybe<RingStoneShapeCreateNestedOneWithoutProductRingStoneInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductRingStoneCreateWithoutShapeInput = {
  carat: RingStoneCaratCreateNestedOneWithoutProductRingStoneInput;
  caratInfo: RingStoneCaratInfoCreateNestedOneWithoutProductRingStoneInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  productRingStoneGroup: ProductRingStoneGroupCreateNestedOneWithoutStonesInput;
  quality: RingStoneCaratQuality;
  quantity?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductRingStoneGroup = {
  __typename?: 'ProductRingStoneGroup';
  _count?: Maybe<ProductRingStoneGroupCount>;
  basketItems: Array<BasketItem>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  photos: Array<Scalars['String']>;
  product: Product;
  productId: Scalars['String'];
  stones: Array<ProductRingStone>;
  updatedAt: Scalars['DateTime'];
};


export type ProductRingStoneGroupBasketItemsArgs = {
  cursor?: InputMaybe<BasketItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<BasketItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BasketItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BasketItemWhereInput>;
};


export type ProductRingStoneGroupStonesArgs = {
  cursor?: InputMaybe<ProductRingStoneWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductRingStoneScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductRingStoneOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductRingStoneWhereInput>;
};

export type ProductRingStoneGroupBy = {
  __typename?: 'ProductRingStoneGroupBy';
  _avg?: Maybe<ProductRingStoneAvgAggregate>;
  _count?: Maybe<ProductRingStoneCountAggregate>;
  _max?: Maybe<ProductRingStoneMaxAggregate>;
  _min?: Maybe<ProductRingStoneMinAggregate>;
  _sum?: Maybe<ProductRingStoneSumAggregate>;
  caratId: Scalars['String'];
  caratInfoId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  productRingStoneGroupId: Scalars['String'];
  quality: RingStoneCaratQuality;
  quantity: Scalars['Int'];
  shapeId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ProductRingStoneGroupCount = {
  __typename?: 'ProductRingStoneGroupCount';
  basketItems: Scalars['Int'];
  stones: Scalars['Int'];
};

export type ProductRingStoneGroupCountAggregate = {
  __typename?: 'ProductRingStoneGroupCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  photos: Scalars['Int'];
  productId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ProductRingStoneGroupCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProductRingStoneGroupCreateInput = {
  basketItems?: InputMaybe<BasketItemCreateNestedManyWithoutStoneGroupInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  photos?: InputMaybe<ProductRingStoneGroupCreatephotosInput>;
  product: ProductCreateNestedOneWithoutStoneGroupsInput;
  stones?: InputMaybe<ProductRingStoneCreateNestedManyWithoutProductRingStoneGroupInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductRingStoneGroupCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  photos?: InputMaybe<ProductRingStoneGroupCreatephotosInput>;
  productId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductRingStoneGroupCreateManyProductInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  photos?: InputMaybe<ProductRingStoneGroupCreatephotosInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductRingStoneGroupCreateManyProductInputEnvelope = {
  data: Array<ProductRingStoneGroupCreateManyProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductRingStoneGroupCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<ProductRingStoneGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductRingStoneGroupCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<ProductRingStoneGroupCreateWithoutProductInput>>;
  createMany?: InputMaybe<ProductRingStoneGroupCreateManyProductInputEnvelope>;
};

export type ProductRingStoneGroupCreateNestedOneWithoutBasketItemsInput = {
  connect?: InputMaybe<ProductRingStoneGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductRingStoneGroupCreateOrConnectWithoutBasketItemsInput>;
  create?: InputMaybe<ProductRingStoneGroupCreateWithoutBasketItemsInput>;
};

export type ProductRingStoneGroupCreateNestedOneWithoutStonesInput = {
  connect?: InputMaybe<ProductRingStoneGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductRingStoneGroupCreateOrConnectWithoutStonesInput>;
  create?: InputMaybe<ProductRingStoneGroupCreateWithoutStonesInput>;
};

export type ProductRingStoneGroupCreateOrConnectWithoutBasketItemsInput = {
  create: ProductRingStoneGroupCreateWithoutBasketItemsInput;
  where: ProductRingStoneGroupWhereUniqueInput;
};

export type ProductRingStoneGroupCreateOrConnectWithoutProductInput = {
  create: ProductRingStoneGroupCreateWithoutProductInput;
  where: ProductRingStoneGroupWhereUniqueInput;
};

export type ProductRingStoneGroupCreateOrConnectWithoutStonesInput = {
  create: ProductRingStoneGroupCreateWithoutStonesInput;
  where: ProductRingStoneGroupWhereUniqueInput;
};

export type ProductRingStoneGroupCreateWithoutBasketItemsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  photos?: InputMaybe<ProductRingStoneGroupCreatephotosInput>;
  product: ProductCreateNestedOneWithoutStoneGroupsInput;
  stones?: InputMaybe<ProductRingStoneCreateNestedManyWithoutProductRingStoneGroupInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductRingStoneGroupCreateWithoutProductInput = {
  basketItems?: InputMaybe<BasketItemCreateNestedManyWithoutStoneGroupInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  photos?: InputMaybe<ProductRingStoneGroupCreatephotosInput>;
  stones?: InputMaybe<ProductRingStoneCreateNestedManyWithoutProductRingStoneGroupInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductRingStoneGroupCreateWithoutStonesInput = {
  basketItems?: InputMaybe<BasketItemCreateNestedManyWithoutStoneGroupInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  photos?: InputMaybe<ProductRingStoneGroupCreatephotosInput>;
  product: ProductCreateNestedOneWithoutStoneGroupsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductRingStoneGroupCreatephotosInput = {
  set: Array<Scalars['String']>;
};

export type ProductRingStoneGroupGroupBy = {
  __typename?: 'ProductRingStoneGroupGroupBy';
  _count?: Maybe<ProductRingStoneGroupCountAggregate>;
  _max?: Maybe<ProductRingStoneGroupMaxAggregate>;
  _min?: Maybe<ProductRingStoneGroupMinAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  photos?: Maybe<Array<Scalars['String']>>;
  productId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ProductRingStoneGroupListRelationFilter = {
  every?: InputMaybe<ProductRingStoneGroupWhereInput>;
  none?: InputMaybe<ProductRingStoneGroupWhereInput>;
  some?: InputMaybe<ProductRingStoneGroupWhereInput>;
};

export type ProductRingStoneGroupMaxAggregate = {
  __typename?: 'ProductRingStoneGroupMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductRingStoneGroupMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProductRingStoneGroupMinAggregate = {
  __typename?: 'ProductRingStoneGroupMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductRingStoneGroupMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProductRingStoneGroupOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProductRingStoneGroupOrderByWithAggregationInput = {
  _count?: InputMaybe<ProductRingStoneGroupCountOrderByAggregateInput>;
  _max?: InputMaybe<ProductRingStoneGroupMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProductRingStoneGroupMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProductRingStoneGroupOrderByWithRelationInput = {
  basketItems?: InputMaybe<BasketItemOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  product?: InputMaybe<ProductOrderByWithRelationInput>;
  productId?: InputMaybe<SortOrder>;
  stones?: InputMaybe<ProductRingStoneOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProductRingStoneGroupRelationFilter = {
  is?: InputMaybe<ProductRingStoneGroupWhereInput>;
  isNot?: InputMaybe<ProductRingStoneGroupWhereInput>;
};

export enum ProductRingStoneGroupScalarFieldEnum {
  createdAt = 'createdAt',
  id = 'id',
  photos = 'photos',
  productId = 'productId',
  updatedAt = 'updatedAt'
}

export type ProductRingStoneGroupScalarWhereInput = {
  AND?: InputMaybe<Array<ProductRingStoneGroupScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProductRingStoneGroupScalarWhereInput>>;
  OR?: InputMaybe<Array<ProductRingStoneGroupScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  photos?: InputMaybe<StringNullableListFilter>;
  productId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProductRingStoneGroupScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ProductRingStoneGroupScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ProductRingStoneGroupScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ProductRingStoneGroupScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  photos?: InputMaybe<StringNullableListFilter>;
  productId?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type ProductRingStoneGroupUpdateInput = {
  basketItems?: InputMaybe<BasketItemUpdateManyWithoutStoneGroupNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  photos?: InputMaybe<ProductRingStoneGroupUpdatephotosInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutStoneGroupsNestedInput>;
  stones?: InputMaybe<ProductRingStoneUpdateManyWithoutProductRingStoneGroupNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductRingStoneGroupUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  photos?: InputMaybe<ProductRingStoneGroupUpdatephotosInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductRingStoneGroupUpdateManyWithWhereWithoutProductInput = {
  data: ProductRingStoneGroupUpdateManyMutationInput;
  where: ProductRingStoneGroupScalarWhereInput;
};

export type ProductRingStoneGroupUpdateManyWithoutProductNestedInput = {
  connect?: InputMaybe<Array<ProductRingStoneGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductRingStoneGroupCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<ProductRingStoneGroupCreateWithoutProductInput>>;
  createMany?: InputMaybe<ProductRingStoneGroupCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<ProductRingStoneGroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductRingStoneGroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductRingStoneGroupWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductRingStoneGroupWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductRingStoneGroupUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<ProductRingStoneGroupUpdateManyWithWhereWithoutProductInput>>;
  upsert?: InputMaybe<Array<ProductRingStoneGroupUpsertWithWhereUniqueWithoutProductInput>>;
};

export type ProductRingStoneGroupUpdateOneRequiredWithoutStonesNestedInput = {
  connect?: InputMaybe<ProductRingStoneGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductRingStoneGroupCreateOrConnectWithoutStonesInput>;
  create?: InputMaybe<ProductRingStoneGroupCreateWithoutStonesInput>;
  update?: InputMaybe<ProductRingStoneGroupUpdateWithoutStonesInput>;
  upsert?: InputMaybe<ProductRingStoneGroupUpsertWithoutStonesInput>;
};

export type ProductRingStoneGroupUpdateOneWithoutBasketItemsNestedInput = {
  connect?: InputMaybe<ProductRingStoneGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductRingStoneGroupCreateOrConnectWithoutBasketItemsInput>;
  create?: InputMaybe<ProductRingStoneGroupCreateWithoutBasketItemsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ProductRingStoneGroupUpdateWithoutBasketItemsInput>;
  upsert?: InputMaybe<ProductRingStoneGroupUpsertWithoutBasketItemsInput>;
};

export type ProductRingStoneGroupUpdateWithWhereUniqueWithoutProductInput = {
  data: ProductRingStoneGroupUpdateWithoutProductInput;
  where: ProductRingStoneGroupWhereUniqueInput;
};

export type ProductRingStoneGroupUpdateWithoutBasketItemsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  photos?: InputMaybe<ProductRingStoneGroupUpdatephotosInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutStoneGroupsNestedInput>;
  stones?: InputMaybe<ProductRingStoneUpdateManyWithoutProductRingStoneGroupNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductRingStoneGroupUpdateWithoutProductInput = {
  basketItems?: InputMaybe<BasketItemUpdateManyWithoutStoneGroupNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  photos?: InputMaybe<ProductRingStoneGroupUpdatephotosInput>;
  stones?: InputMaybe<ProductRingStoneUpdateManyWithoutProductRingStoneGroupNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductRingStoneGroupUpdateWithoutStonesInput = {
  basketItems?: InputMaybe<BasketItemUpdateManyWithoutStoneGroupNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  photos?: InputMaybe<ProductRingStoneGroupUpdatephotosInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutStoneGroupsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductRingStoneGroupUpdatephotosInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ProductRingStoneGroupUpsertWithWhereUniqueWithoutProductInput = {
  create: ProductRingStoneGroupCreateWithoutProductInput;
  update: ProductRingStoneGroupUpdateWithoutProductInput;
  where: ProductRingStoneGroupWhereUniqueInput;
};

export type ProductRingStoneGroupUpsertWithoutBasketItemsInput = {
  create: ProductRingStoneGroupCreateWithoutBasketItemsInput;
  update: ProductRingStoneGroupUpdateWithoutBasketItemsInput;
};

export type ProductRingStoneGroupUpsertWithoutStonesInput = {
  create: ProductRingStoneGroupCreateWithoutStonesInput;
  update: ProductRingStoneGroupUpdateWithoutStonesInput;
};

export type ProductRingStoneGroupWhereInput = {
  AND?: InputMaybe<Array<ProductRingStoneGroupWhereInput>>;
  NOT?: InputMaybe<Array<ProductRingStoneGroupWhereInput>>;
  OR?: InputMaybe<Array<ProductRingStoneGroupWhereInput>>;
  basketItems?: InputMaybe<BasketItemListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  photos?: InputMaybe<StringNullableListFilter>;
  product?: InputMaybe<ProductRelationFilter>;
  productId?: InputMaybe<StringFilter>;
  stones?: InputMaybe<ProductRingStoneListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProductRingStoneGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ProductRingStoneListRelationFilter = {
  every?: InputMaybe<ProductRingStoneWhereInput>;
  none?: InputMaybe<ProductRingStoneWhereInput>;
  some?: InputMaybe<ProductRingStoneWhereInput>;
};

export type ProductRingStoneMaxAggregate = {
  __typename?: 'ProductRingStoneMaxAggregate';
  caratId?: Maybe<Scalars['String']>;
  caratInfoId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  productRingStoneGroupId?: Maybe<Scalars['String']>;
  quality?: Maybe<RingStoneCaratQuality>;
  quantity?: Maybe<Scalars['Int']>;
  shapeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductRingStoneMaxOrderByAggregateInput = {
  caratId?: InputMaybe<SortOrder>;
  caratInfoId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  productRingStoneGroupId?: InputMaybe<SortOrder>;
  quality?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  shapeId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProductRingStoneMinAggregate = {
  __typename?: 'ProductRingStoneMinAggregate';
  caratId?: Maybe<Scalars['String']>;
  caratInfoId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  productRingStoneGroupId?: Maybe<Scalars['String']>;
  quality?: Maybe<RingStoneCaratQuality>;
  quantity?: Maybe<Scalars['Int']>;
  shapeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductRingStoneMinOrderByAggregateInput = {
  caratId?: InputMaybe<SortOrder>;
  caratInfoId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  productRingStoneGroupId?: InputMaybe<SortOrder>;
  quality?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  shapeId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProductRingStoneOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProductRingStoneOrderByWithAggregationInput = {
  _avg?: InputMaybe<ProductRingStoneAvgOrderByAggregateInput>;
  _count?: InputMaybe<ProductRingStoneCountOrderByAggregateInput>;
  _max?: InputMaybe<ProductRingStoneMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProductRingStoneMinOrderByAggregateInput>;
  _sum?: InputMaybe<ProductRingStoneSumOrderByAggregateInput>;
  caratId?: InputMaybe<SortOrder>;
  caratInfoId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  productRingStoneGroupId?: InputMaybe<SortOrder>;
  quality?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  shapeId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProductRingStoneOrderByWithRelationInput = {
  carat?: InputMaybe<RingStoneCaratOrderByWithRelationInput>;
  caratId?: InputMaybe<SortOrder>;
  caratInfo?: InputMaybe<RingStoneCaratInfoOrderByWithRelationInput>;
  caratInfoId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  productRingStoneGroup?: InputMaybe<ProductRingStoneGroupOrderByWithRelationInput>;
  productRingStoneGroupId?: InputMaybe<SortOrder>;
  quality?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  shape?: InputMaybe<RingStoneShapeOrderByWithRelationInput>;
  shapeId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ProductRingStoneScalarFieldEnum {
  caratId = 'caratId',
  caratInfoId = 'caratInfoId',
  createdAt = 'createdAt',
  id = 'id',
  productRingStoneGroupId = 'productRingStoneGroupId',
  quality = 'quality',
  quantity = 'quantity',
  shapeId = 'shapeId',
  updatedAt = 'updatedAt'
}

export type ProductRingStoneScalarWhereInput = {
  AND?: InputMaybe<Array<ProductRingStoneScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProductRingStoneScalarWhereInput>>;
  OR?: InputMaybe<Array<ProductRingStoneScalarWhereInput>>;
  caratId?: InputMaybe<StringFilter>;
  caratInfoId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  productRingStoneGroupId?: InputMaybe<StringFilter>;
  quality?: InputMaybe<EnumRingStoneCaratQualityFilter>;
  quantity?: InputMaybe<IntFilter>;
  shapeId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProductRingStoneScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ProductRingStoneScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ProductRingStoneScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ProductRingStoneScalarWhereWithAggregatesInput>>;
  caratId?: InputMaybe<StringWithAggregatesFilter>;
  caratInfoId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  productRingStoneGroupId?: InputMaybe<StringWithAggregatesFilter>;
  quality?: InputMaybe<EnumRingStoneCaratQualityWithAggregatesFilter>;
  quantity?: InputMaybe<IntWithAggregatesFilter>;
  shapeId?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type ProductRingStoneSumAggregate = {
  __typename?: 'ProductRingStoneSumAggregate';
  quantity?: Maybe<Scalars['Int']>;
};

export type ProductRingStoneSumOrderByAggregateInput = {
  quantity?: InputMaybe<SortOrder>;
};

export type ProductRingStoneUpdateInput = {
  carat?: InputMaybe<RingStoneCaratUpdateOneRequiredWithoutProductRingStoneNestedInput>;
  caratInfo?: InputMaybe<RingStoneCaratInfoUpdateOneRequiredWithoutProductRingStoneNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  productRingStoneGroup?: InputMaybe<ProductRingStoneGroupUpdateOneRequiredWithoutStonesNestedInput>;
  quality?: InputMaybe<EnumRingStoneCaratQualityFieldUpdateOperationsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  shape?: InputMaybe<RingStoneShapeUpdateOneWithoutProductRingStoneNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductRingStoneUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  quality?: InputMaybe<EnumRingStoneCaratQualityFieldUpdateOperationsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductRingStoneUpdateManyWithWhereWithoutCaratInfoInput = {
  data: ProductRingStoneUpdateManyMutationInput;
  where: ProductRingStoneScalarWhereInput;
};

export type ProductRingStoneUpdateManyWithWhereWithoutCaratInput = {
  data: ProductRingStoneUpdateManyMutationInput;
  where: ProductRingStoneScalarWhereInput;
};

export type ProductRingStoneUpdateManyWithWhereWithoutProductRingStoneGroupInput = {
  data: ProductRingStoneUpdateManyMutationInput;
  where: ProductRingStoneScalarWhereInput;
};

export type ProductRingStoneUpdateManyWithWhereWithoutShapeInput = {
  data: ProductRingStoneUpdateManyMutationInput;
  where: ProductRingStoneScalarWhereInput;
};

export type ProductRingStoneUpdateManyWithoutCaratInfoNestedInput = {
  connect?: InputMaybe<Array<ProductRingStoneWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductRingStoneCreateOrConnectWithoutCaratInfoInput>>;
  create?: InputMaybe<Array<ProductRingStoneCreateWithoutCaratInfoInput>>;
  createMany?: InputMaybe<ProductRingStoneCreateManyCaratInfoInputEnvelope>;
  delete?: InputMaybe<Array<ProductRingStoneWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductRingStoneScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductRingStoneWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductRingStoneWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductRingStoneUpdateWithWhereUniqueWithoutCaratInfoInput>>;
  updateMany?: InputMaybe<Array<ProductRingStoneUpdateManyWithWhereWithoutCaratInfoInput>>;
  upsert?: InputMaybe<Array<ProductRingStoneUpsertWithWhereUniqueWithoutCaratInfoInput>>;
};

export type ProductRingStoneUpdateManyWithoutCaratNestedInput = {
  connect?: InputMaybe<Array<ProductRingStoneWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductRingStoneCreateOrConnectWithoutCaratInput>>;
  create?: InputMaybe<Array<ProductRingStoneCreateWithoutCaratInput>>;
  createMany?: InputMaybe<ProductRingStoneCreateManyCaratInputEnvelope>;
  delete?: InputMaybe<Array<ProductRingStoneWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductRingStoneScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductRingStoneWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductRingStoneWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductRingStoneUpdateWithWhereUniqueWithoutCaratInput>>;
  updateMany?: InputMaybe<Array<ProductRingStoneUpdateManyWithWhereWithoutCaratInput>>;
  upsert?: InputMaybe<Array<ProductRingStoneUpsertWithWhereUniqueWithoutCaratInput>>;
};

export type ProductRingStoneUpdateManyWithoutProductRingStoneGroupNestedInput = {
  connect?: InputMaybe<Array<ProductRingStoneWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductRingStoneCreateOrConnectWithoutProductRingStoneGroupInput>>;
  create?: InputMaybe<Array<ProductRingStoneCreateWithoutProductRingStoneGroupInput>>;
  createMany?: InputMaybe<ProductRingStoneCreateManyProductRingStoneGroupInputEnvelope>;
  delete?: InputMaybe<Array<ProductRingStoneWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductRingStoneScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductRingStoneWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductRingStoneWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductRingStoneUpdateWithWhereUniqueWithoutProductRingStoneGroupInput>>;
  updateMany?: InputMaybe<Array<ProductRingStoneUpdateManyWithWhereWithoutProductRingStoneGroupInput>>;
  upsert?: InputMaybe<Array<ProductRingStoneUpsertWithWhereUniqueWithoutProductRingStoneGroupInput>>;
};

export type ProductRingStoneUpdateManyWithoutShapeNestedInput = {
  connect?: InputMaybe<Array<ProductRingStoneWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductRingStoneCreateOrConnectWithoutShapeInput>>;
  create?: InputMaybe<Array<ProductRingStoneCreateWithoutShapeInput>>;
  createMany?: InputMaybe<ProductRingStoneCreateManyShapeInputEnvelope>;
  delete?: InputMaybe<Array<ProductRingStoneWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductRingStoneScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductRingStoneWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductRingStoneWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductRingStoneUpdateWithWhereUniqueWithoutShapeInput>>;
  updateMany?: InputMaybe<Array<ProductRingStoneUpdateManyWithWhereWithoutShapeInput>>;
  upsert?: InputMaybe<Array<ProductRingStoneUpsertWithWhereUniqueWithoutShapeInput>>;
};

export type ProductRingStoneUpdateWithWhereUniqueWithoutCaratInfoInput = {
  data: ProductRingStoneUpdateWithoutCaratInfoInput;
  where: ProductRingStoneWhereUniqueInput;
};

export type ProductRingStoneUpdateWithWhereUniqueWithoutCaratInput = {
  data: ProductRingStoneUpdateWithoutCaratInput;
  where: ProductRingStoneWhereUniqueInput;
};

export type ProductRingStoneUpdateWithWhereUniqueWithoutProductRingStoneGroupInput = {
  data: ProductRingStoneUpdateWithoutProductRingStoneGroupInput;
  where: ProductRingStoneWhereUniqueInput;
};

export type ProductRingStoneUpdateWithWhereUniqueWithoutShapeInput = {
  data: ProductRingStoneUpdateWithoutShapeInput;
  where: ProductRingStoneWhereUniqueInput;
};

export type ProductRingStoneUpdateWithoutCaratInfoInput = {
  carat?: InputMaybe<RingStoneCaratUpdateOneRequiredWithoutProductRingStoneNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  productRingStoneGroup?: InputMaybe<ProductRingStoneGroupUpdateOneRequiredWithoutStonesNestedInput>;
  quality?: InputMaybe<EnumRingStoneCaratQualityFieldUpdateOperationsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  shape?: InputMaybe<RingStoneShapeUpdateOneWithoutProductRingStoneNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductRingStoneUpdateWithoutCaratInput = {
  caratInfo?: InputMaybe<RingStoneCaratInfoUpdateOneRequiredWithoutProductRingStoneNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  productRingStoneGroup?: InputMaybe<ProductRingStoneGroupUpdateOneRequiredWithoutStonesNestedInput>;
  quality?: InputMaybe<EnumRingStoneCaratQualityFieldUpdateOperationsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  shape?: InputMaybe<RingStoneShapeUpdateOneWithoutProductRingStoneNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductRingStoneUpdateWithoutProductRingStoneGroupInput = {
  carat?: InputMaybe<RingStoneCaratUpdateOneRequiredWithoutProductRingStoneNestedInput>;
  caratInfo?: InputMaybe<RingStoneCaratInfoUpdateOneRequiredWithoutProductRingStoneNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  quality?: InputMaybe<EnumRingStoneCaratQualityFieldUpdateOperationsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  shape?: InputMaybe<RingStoneShapeUpdateOneWithoutProductRingStoneNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductRingStoneUpdateWithoutShapeInput = {
  carat?: InputMaybe<RingStoneCaratUpdateOneRequiredWithoutProductRingStoneNestedInput>;
  caratInfo?: InputMaybe<RingStoneCaratInfoUpdateOneRequiredWithoutProductRingStoneNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  productRingStoneGroup?: InputMaybe<ProductRingStoneGroupUpdateOneRequiredWithoutStonesNestedInput>;
  quality?: InputMaybe<EnumRingStoneCaratQualityFieldUpdateOperationsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductRingStoneUpsertWithWhereUniqueWithoutCaratInfoInput = {
  create: ProductRingStoneCreateWithoutCaratInfoInput;
  update: ProductRingStoneUpdateWithoutCaratInfoInput;
  where: ProductRingStoneWhereUniqueInput;
};

export type ProductRingStoneUpsertWithWhereUniqueWithoutCaratInput = {
  create: ProductRingStoneCreateWithoutCaratInput;
  update: ProductRingStoneUpdateWithoutCaratInput;
  where: ProductRingStoneWhereUniqueInput;
};

export type ProductRingStoneUpsertWithWhereUniqueWithoutProductRingStoneGroupInput = {
  create: ProductRingStoneCreateWithoutProductRingStoneGroupInput;
  update: ProductRingStoneUpdateWithoutProductRingStoneGroupInput;
  where: ProductRingStoneWhereUniqueInput;
};

export type ProductRingStoneUpsertWithWhereUniqueWithoutShapeInput = {
  create: ProductRingStoneCreateWithoutShapeInput;
  update: ProductRingStoneUpdateWithoutShapeInput;
  where: ProductRingStoneWhereUniqueInput;
};

export type ProductRingStoneWhereInput = {
  AND?: InputMaybe<Array<ProductRingStoneWhereInput>>;
  NOT?: InputMaybe<Array<ProductRingStoneWhereInput>>;
  OR?: InputMaybe<Array<ProductRingStoneWhereInput>>;
  carat?: InputMaybe<RingStoneCaratRelationFilter>;
  caratId?: InputMaybe<StringFilter>;
  caratInfo?: InputMaybe<RingStoneCaratInfoRelationFilter>;
  caratInfoId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  productRingStoneGroup?: InputMaybe<ProductRingStoneGroupRelationFilter>;
  productRingStoneGroupId?: InputMaybe<StringFilter>;
  quality?: InputMaybe<EnumRingStoneCaratQualityFilter>;
  quantity?: InputMaybe<IntFilter>;
  shape?: InputMaybe<RingStoneShapeRelationFilter>;
  shapeId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProductRingStoneWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum ProductScalarFieldEnum {
  barcode = 'barcode',
  categoryId = 'categoryId',
  configuratorData = 'configuratorData',
  createdAt = 'createdAt',
  discountAmount = 'discountAmount',
  discountType = 'discountType',
  id = 'id',
  oldPrice = 'oldPrice',
  order = 'order',
  photos = 'photos',
  price = 'price',
  sku = 'sku',
  status = 'status',
  styleId = 'styleId',
  updatedAt = 'updatedAt',
  weight = 'weight'
}

export type ProductScalarWhereInput = {
  AND?: InputMaybe<Array<ProductScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProductScalarWhereInput>>;
  OR?: InputMaybe<Array<ProductScalarWhereInput>>;
  barcode?: InputMaybe<StringNullableFilter>;
  categoryId?: InputMaybe<StringFilter>;
  configuratorData?: InputMaybe<JsonNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  discountAmount?: InputMaybe<DecimalFilter>;
  discountType?: InputMaybe<EnumDiscountTypeFilter>;
  id?: InputMaybe<StringFilter>;
  oldPrice?: InputMaybe<DecimalNullableFilter>;
  order?: InputMaybe<IntFilter>;
  photos?: InputMaybe<StringNullableListFilter>;
  price?: InputMaybe<DecimalFilter>;
  sku?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<BoolFilter>;
  styleId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  weight?: InputMaybe<DecimalFilter>;
};

export type ProductScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ProductScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ProductScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ProductScalarWhereWithAggregatesInput>>;
  barcode?: InputMaybe<StringNullableWithAggregatesFilter>;
  categoryId?: InputMaybe<StringWithAggregatesFilter>;
  configuratorData?: InputMaybe<JsonNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  discountAmount?: InputMaybe<DecimalWithAggregatesFilter>;
  discountType?: InputMaybe<EnumDiscountTypeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  oldPrice?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  order?: InputMaybe<IntWithAggregatesFilter>;
  photos?: InputMaybe<StringNullableListFilter>;
  price?: InputMaybe<DecimalWithAggregatesFilter>;
  sku?: InputMaybe<StringNullableWithAggregatesFilter>;
  status?: InputMaybe<BoolWithAggregatesFilter>;
  styleId?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  weight?: InputMaybe<DecimalWithAggregatesFilter>;
};

export type ProductStone = {
  carat: Scalars['String'];
  color: Scalars['String'];
  quality: RingStoneCaratQuality;
  quantity: Scalars['Float'];
  shape?: InputMaybe<Scalars['String']>;
};

export type ProductStoneGroups = {
  photos?: InputMaybe<Array<Scalars['String']>>;
  stones: Array<ProductStone>;
};

export type ProductSumAggregate = {
  __typename?: 'ProductSumAggregate';
  discountAmount?: Maybe<Scalars['Decimal']>;
  oldPrice?: Maybe<Scalars['Decimal']>;
  order?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Decimal']>;
  weight?: Maybe<Scalars['Decimal']>;
};

export type ProductSumOrderByAggregateInput = {
  discountAmount?: InputMaybe<SortOrder>;
  oldPrice?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  weight?: InputMaybe<SortOrder>;
};

export type ProductUpdateCustomInput = {
  barcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutProductsNestedInput>;
  colorIds?: InputMaybe<Array<Scalars['String']>>;
  configuratorData?: InputMaybe<Scalars['String']>;
  creatableIds?: InputMaybe<Array<Scalars['String']>>;
  discountAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  discountType?: InputMaybe<EnumDiscountTypeFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<ProductUpdatephotosInput>;
  price?: InputMaybe<Scalars['Float']>;
  ringSizeStandartIds?: InputMaybe<Array<Scalars['String']>>;
  sku?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stoneColor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stoneGroups?: InputMaybe<Array<ProductStoneGroups>>;
  style?: InputMaybe<StyleUpdateOneWithoutProductsNestedInput>;
  weight?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type ProductUpdateInput = {
  barcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  basketItems?: InputMaybe<BasketItemUpdateManyWithoutProductNestedInput>;
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutProductsNestedInput>;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  creatables?: InputMaybe<ProductCreatableUpdateManyWithoutProductNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutProductNestedInput>;
  discountAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  discountType?: InputMaybe<EnumDiscountTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  oldPrice?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<ProductUpdatephotosInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  productOrders?: InputMaybe<ProductOrderUpdateManyWithoutProductNestedInput>;
  productRingColors?: InputMaybe<ProductRingColorUpdateManyWithoutProductNestedInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartUpdateManyWithoutProductNestedInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutProductNestedInput>;
  sku?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sliders?: InputMaybe<SliderUpdateManyWithoutProductNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stoneGroups?: InputMaybe<ProductRingStoneGroupUpdateManyWithoutProductNestedInput>;
  style?: InputMaybe<StyleUpdateOneWithoutProductsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  weight?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type ProductUpdateManyMutationInput = {
  barcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  discountAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  discountType?: InputMaybe<EnumDiscountTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  oldPrice?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<ProductUpdatephotosInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  sku?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  weight?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type ProductUpdateManyWithWhereWithoutCategoryInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutStyleInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithoutCategoryNestedInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<ProductCreateManyCategoryInputEnvelope>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type ProductUpdateManyWithoutStyleNestedInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutStyleInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutStyleInput>>;
  createMany?: InputMaybe<ProductCreateManyStyleInputEnvelope>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutStyleInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereWithoutStyleInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutStyleInput>>;
};

export type ProductUpdateOneRequiredWithoutBasketItemsNestedInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutBasketItemsInput>;
  create?: InputMaybe<ProductCreateWithoutBasketItemsInput>;
  update?: InputMaybe<ProductUpdateWithoutBasketItemsInput>;
  upsert?: InputMaybe<ProductUpsertWithoutBasketItemsInput>;
};

export type ProductUpdateOneRequiredWithoutCreatablesNestedInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutCreatablesInput>;
  create?: InputMaybe<ProductCreateWithoutCreatablesInput>;
  update?: InputMaybe<ProductUpdateWithoutCreatablesInput>;
  upsert?: InputMaybe<ProductUpsertWithoutCreatablesInput>;
};

export type ProductUpdateOneRequiredWithoutProductOrdersNestedInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutProductOrdersInput>;
  create?: InputMaybe<ProductCreateWithoutProductOrdersInput>;
  update?: InputMaybe<ProductUpdateWithoutProductOrdersInput>;
  upsert?: InputMaybe<ProductUpsertWithoutProductOrdersInput>;
};

export type ProductUpdateOneRequiredWithoutProductRingColorsNestedInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutProductRingColorsInput>;
  create?: InputMaybe<ProductCreateWithoutProductRingColorsInput>;
  update?: InputMaybe<ProductUpdateWithoutProductRingColorsInput>;
  upsert?: InputMaybe<ProductUpsertWithoutProductRingColorsInput>;
};

export type ProductUpdateOneRequiredWithoutProductRingSizeStandartsNestedInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutProductRingSizeStandartsInput>;
  create?: InputMaybe<ProductCreateWithoutProductRingSizeStandartsInput>;
  update?: InputMaybe<ProductUpdateWithoutProductRingSizeStandartsInput>;
  upsert?: InputMaybe<ProductUpsertWithoutProductRingSizeStandartsInput>;
};

export type ProductUpdateOneRequiredWithoutStoneGroupsNestedInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutStoneGroupsInput>;
  create?: InputMaybe<ProductCreateWithoutStoneGroupsInput>;
  update?: InputMaybe<ProductUpdateWithoutStoneGroupsInput>;
  upsert?: InputMaybe<ProductUpsertWithoutStoneGroupsInput>;
};

export type ProductUpdateOneWithoutDescriptionsNestedInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<ProductCreateWithoutDescriptionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ProductUpdateWithoutDescriptionsInput>;
  upsert?: InputMaybe<ProductUpsertWithoutDescriptionsInput>;
};

export type ProductUpdateOneWithoutSeosNestedInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutSeosInput>;
  create?: InputMaybe<ProductCreateWithoutSeosInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ProductUpdateWithoutSeosInput>;
  upsert?: InputMaybe<ProductUpsertWithoutSeosInput>;
};

export type ProductUpdateOneWithoutSlidersNestedInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutSlidersInput>;
  create?: InputMaybe<ProductCreateWithoutSlidersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ProductUpdateWithoutSlidersInput>;
  upsert?: InputMaybe<ProductUpsertWithoutSlidersInput>;
};

export type ProductUpdateWithWhereUniqueWithoutCategoryInput = {
  data: ProductUpdateWithoutCategoryInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutStyleInput = {
  data: ProductUpdateWithoutStyleInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithoutBasketItemsInput = {
  barcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutProductsNestedInput>;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  creatables?: InputMaybe<ProductCreatableUpdateManyWithoutProductNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutProductNestedInput>;
  discountAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  discountType?: InputMaybe<EnumDiscountTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  oldPrice?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<ProductUpdatephotosInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  productOrders?: InputMaybe<ProductOrderUpdateManyWithoutProductNestedInput>;
  productRingColors?: InputMaybe<ProductRingColorUpdateManyWithoutProductNestedInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartUpdateManyWithoutProductNestedInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutProductNestedInput>;
  sku?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sliders?: InputMaybe<SliderUpdateManyWithoutProductNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stoneGroups?: InputMaybe<ProductRingStoneGroupUpdateManyWithoutProductNestedInput>;
  style?: InputMaybe<StyleUpdateOneWithoutProductsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  weight?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutCategoryInput = {
  barcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  basketItems?: InputMaybe<BasketItemUpdateManyWithoutProductNestedInput>;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  creatables?: InputMaybe<ProductCreatableUpdateManyWithoutProductNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutProductNestedInput>;
  discountAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  discountType?: InputMaybe<EnumDiscountTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  oldPrice?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<ProductUpdatephotosInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  productOrders?: InputMaybe<ProductOrderUpdateManyWithoutProductNestedInput>;
  productRingColors?: InputMaybe<ProductRingColorUpdateManyWithoutProductNestedInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartUpdateManyWithoutProductNestedInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutProductNestedInput>;
  sku?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sliders?: InputMaybe<SliderUpdateManyWithoutProductNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stoneGroups?: InputMaybe<ProductRingStoneGroupUpdateManyWithoutProductNestedInput>;
  style?: InputMaybe<StyleUpdateOneWithoutProductsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  weight?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutCreatablesInput = {
  barcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  basketItems?: InputMaybe<BasketItemUpdateManyWithoutProductNestedInput>;
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutProductsNestedInput>;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutProductNestedInput>;
  discountAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  discountType?: InputMaybe<EnumDiscountTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  oldPrice?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<ProductUpdatephotosInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  productOrders?: InputMaybe<ProductOrderUpdateManyWithoutProductNestedInput>;
  productRingColors?: InputMaybe<ProductRingColorUpdateManyWithoutProductNestedInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartUpdateManyWithoutProductNestedInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutProductNestedInput>;
  sku?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sliders?: InputMaybe<SliderUpdateManyWithoutProductNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stoneGroups?: InputMaybe<ProductRingStoneGroupUpdateManyWithoutProductNestedInput>;
  style?: InputMaybe<StyleUpdateOneWithoutProductsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  weight?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutDescriptionsInput = {
  barcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  basketItems?: InputMaybe<BasketItemUpdateManyWithoutProductNestedInput>;
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutProductsNestedInput>;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  creatables?: InputMaybe<ProductCreatableUpdateManyWithoutProductNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  discountAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  discountType?: InputMaybe<EnumDiscountTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  oldPrice?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<ProductUpdatephotosInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  productOrders?: InputMaybe<ProductOrderUpdateManyWithoutProductNestedInput>;
  productRingColors?: InputMaybe<ProductRingColorUpdateManyWithoutProductNestedInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartUpdateManyWithoutProductNestedInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutProductNestedInput>;
  sku?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sliders?: InputMaybe<SliderUpdateManyWithoutProductNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stoneGroups?: InputMaybe<ProductRingStoneGroupUpdateManyWithoutProductNestedInput>;
  style?: InputMaybe<StyleUpdateOneWithoutProductsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  weight?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutProductOrdersInput = {
  barcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  basketItems?: InputMaybe<BasketItemUpdateManyWithoutProductNestedInput>;
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutProductsNestedInput>;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  creatables?: InputMaybe<ProductCreatableUpdateManyWithoutProductNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutProductNestedInput>;
  discountAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  discountType?: InputMaybe<EnumDiscountTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  oldPrice?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<ProductUpdatephotosInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  productRingColors?: InputMaybe<ProductRingColorUpdateManyWithoutProductNestedInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartUpdateManyWithoutProductNestedInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutProductNestedInput>;
  sku?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sliders?: InputMaybe<SliderUpdateManyWithoutProductNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stoneGroups?: InputMaybe<ProductRingStoneGroupUpdateManyWithoutProductNestedInput>;
  style?: InputMaybe<StyleUpdateOneWithoutProductsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  weight?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutProductRingColorsInput = {
  barcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  basketItems?: InputMaybe<BasketItemUpdateManyWithoutProductNestedInput>;
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutProductsNestedInput>;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  creatables?: InputMaybe<ProductCreatableUpdateManyWithoutProductNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutProductNestedInput>;
  discountAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  discountType?: InputMaybe<EnumDiscountTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  oldPrice?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<ProductUpdatephotosInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  productOrders?: InputMaybe<ProductOrderUpdateManyWithoutProductNestedInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartUpdateManyWithoutProductNestedInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutProductNestedInput>;
  sku?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sliders?: InputMaybe<SliderUpdateManyWithoutProductNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stoneGroups?: InputMaybe<ProductRingStoneGroupUpdateManyWithoutProductNestedInput>;
  style?: InputMaybe<StyleUpdateOneWithoutProductsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  weight?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutProductRingSizeStandartsInput = {
  barcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  basketItems?: InputMaybe<BasketItemUpdateManyWithoutProductNestedInput>;
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutProductsNestedInput>;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  creatables?: InputMaybe<ProductCreatableUpdateManyWithoutProductNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutProductNestedInput>;
  discountAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  discountType?: InputMaybe<EnumDiscountTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  oldPrice?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<ProductUpdatephotosInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  productOrders?: InputMaybe<ProductOrderUpdateManyWithoutProductNestedInput>;
  productRingColors?: InputMaybe<ProductRingColorUpdateManyWithoutProductNestedInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutProductNestedInput>;
  sku?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sliders?: InputMaybe<SliderUpdateManyWithoutProductNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stoneGroups?: InputMaybe<ProductRingStoneGroupUpdateManyWithoutProductNestedInput>;
  style?: InputMaybe<StyleUpdateOneWithoutProductsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  weight?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutSeosInput = {
  barcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  basketItems?: InputMaybe<BasketItemUpdateManyWithoutProductNestedInput>;
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutProductsNestedInput>;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  creatables?: InputMaybe<ProductCreatableUpdateManyWithoutProductNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutProductNestedInput>;
  discountAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  discountType?: InputMaybe<EnumDiscountTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  oldPrice?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<ProductUpdatephotosInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  productOrders?: InputMaybe<ProductOrderUpdateManyWithoutProductNestedInput>;
  productRingColors?: InputMaybe<ProductRingColorUpdateManyWithoutProductNestedInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartUpdateManyWithoutProductNestedInput>;
  sku?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sliders?: InputMaybe<SliderUpdateManyWithoutProductNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stoneGroups?: InputMaybe<ProductRingStoneGroupUpdateManyWithoutProductNestedInput>;
  style?: InputMaybe<StyleUpdateOneWithoutProductsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  weight?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutSlidersInput = {
  barcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  basketItems?: InputMaybe<BasketItemUpdateManyWithoutProductNestedInput>;
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutProductsNestedInput>;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  creatables?: InputMaybe<ProductCreatableUpdateManyWithoutProductNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutProductNestedInput>;
  discountAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  discountType?: InputMaybe<EnumDiscountTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  oldPrice?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<ProductUpdatephotosInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  productOrders?: InputMaybe<ProductOrderUpdateManyWithoutProductNestedInput>;
  productRingColors?: InputMaybe<ProductRingColorUpdateManyWithoutProductNestedInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartUpdateManyWithoutProductNestedInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutProductNestedInput>;
  sku?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stoneGroups?: InputMaybe<ProductRingStoneGroupUpdateManyWithoutProductNestedInput>;
  style?: InputMaybe<StyleUpdateOneWithoutProductsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  weight?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutStoneGroupsInput = {
  barcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  basketItems?: InputMaybe<BasketItemUpdateManyWithoutProductNestedInput>;
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutProductsNestedInput>;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  creatables?: InputMaybe<ProductCreatableUpdateManyWithoutProductNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutProductNestedInput>;
  discountAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  discountType?: InputMaybe<EnumDiscountTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  oldPrice?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<ProductUpdatephotosInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  productOrders?: InputMaybe<ProductOrderUpdateManyWithoutProductNestedInput>;
  productRingColors?: InputMaybe<ProductRingColorUpdateManyWithoutProductNestedInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartUpdateManyWithoutProductNestedInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutProductNestedInput>;
  sku?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sliders?: InputMaybe<SliderUpdateManyWithoutProductNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  style?: InputMaybe<StyleUpdateOneWithoutProductsNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  weight?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutStyleInput = {
  barcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  basketItems?: InputMaybe<BasketItemUpdateManyWithoutProductNestedInput>;
  category?: InputMaybe<CategoryUpdateOneRequiredWithoutProductsNestedInput>;
  configuratorData?: InputMaybe<Scalars['JSON']>;
  creatables?: InputMaybe<ProductCreatableUpdateManyWithoutProductNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutProductNestedInput>;
  discountAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  discountType?: InputMaybe<EnumDiscountTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  oldPrice?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<ProductUpdatephotosInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  productOrders?: InputMaybe<ProductOrderUpdateManyWithoutProductNestedInput>;
  productRingColors?: InputMaybe<ProductRingColorUpdateManyWithoutProductNestedInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartUpdateManyWithoutProductNestedInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutProductNestedInput>;
  sku?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sliders?: InputMaybe<SliderUpdateManyWithoutProductNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stoneGroups?: InputMaybe<ProductRingStoneGroupUpdateManyWithoutProductNestedInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  weight?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type ProductUpdatephotosInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ProductUpsertWithWhereUniqueWithoutCategoryInput = {
  create: ProductCreateWithoutCategoryInput;
  update: ProductUpdateWithoutCategoryInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutStyleInput = {
  create: ProductCreateWithoutStyleInput;
  update: ProductUpdateWithoutStyleInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithoutBasketItemsInput = {
  create: ProductCreateWithoutBasketItemsInput;
  update: ProductUpdateWithoutBasketItemsInput;
};

export type ProductUpsertWithoutCreatablesInput = {
  create: ProductCreateWithoutCreatablesInput;
  update: ProductUpdateWithoutCreatablesInput;
};

export type ProductUpsertWithoutDescriptionsInput = {
  create: ProductCreateWithoutDescriptionsInput;
  update: ProductUpdateWithoutDescriptionsInput;
};

export type ProductUpsertWithoutProductOrdersInput = {
  create: ProductCreateWithoutProductOrdersInput;
  update: ProductUpdateWithoutProductOrdersInput;
};

export type ProductUpsertWithoutProductRingColorsInput = {
  create: ProductCreateWithoutProductRingColorsInput;
  update: ProductUpdateWithoutProductRingColorsInput;
};

export type ProductUpsertWithoutProductRingSizeStandartsInput = {
  create: ProductCreateWithoutProductRingSizeStandartsInput;
  update: ProductUpdateWithoutProductRingSizeStandartsInput;
};

export type ProductUpsertWithoutSeosInput = {
  create: ProductCreateWithoutSeosInput;
  update: ProductUpdateWithoutSeosInput;
};

export type ProductUpsertWithoutSlidersInput = {
  create: ProductCreateWithoutSlidersInput;
  update: ProductUpdateWithoutSlidersInput;
};

export type ProductUpsertWithoutStoneGroupsInput = {
  create: ProductCreateWithoutStoneGroupsInput;
  update: ProductUpdateWithoutStoneGroupsInput;
};

export type ProductWhereInput = {
  AND?: InputMaybe<Array<ProductWhereInput>>;
  NOT?: InputMaybe<Array<ProductWhereInput>>;
  OR?: InputMaybe<Array<ProductWhereInput>>;
  barcode?: InputMaybe<StringNullableFilter>;
  basketItems?: InputMaybe<BasketItemListRelationFilter>;
  category?: InputMaybe<CategoryRelationFilter>;
  categoryId?: InputMaybe<StringFilter>;
  configuratorData?: InputMaybe<JsonNullableFilter>;
  creatables?: InputMaybe<ProductCreatableListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  descriptions?: InputMaybe<DescriptionListRelationFilter>;
  discountAmount?: InputMaybe<DecimalFilter>;
  discountType?: InputMaybe<EnumDiscountTypeFilter>;
  id?: InputMaybe<StringFilter>;
  oldPrice?: InputMaybe<DecimalNullableFilter>;
  order?: InputMaybe<IntFilter>;
  photos?: InputMaybe<StringNullableListFilter>;
  price?: InputMaybe<DecimalFilter>;
  productOrders?: InputMaybe<ProductOrderListRelationFilter>;
  productRingColors?: InputMaybe<ProductRingColorListRelationFilter>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartListRelationFilter>;
  seos?: InputMaybe<SeoListRelationFilter>;
  sku?: InputMaybe<StringNullableFilter>;
  sliders?: InputMaybe<SliderListRelationFilter>;
  status?: InputMaybe<BoolFilter>;
  stoneGroups?: InputMaybe<ProductRingStoneGroupListRelationFilter>;
  style?: InputMaybe<StyleRelationFilter>;
  styleId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  weight?: InputMaybe<DecimalFilter>;
};

export type ProductWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ProductsPayload = {
  __typename?: 'ProductsPayload';
  count: Scalars['Int'];
  items: Array<Product>;
};

export type Query = {
  __typename?: 'Query';
  aggregateBasket: AggregateBasket;
  aggregateBasketItem: AggregateBasketItem;
  aggregateBlog: AggregateBlog;
  aggregateBlogCategory: AggregateBlogCategory;
  aggregateCategory: AggregateCategory;
  aggregateCreatable: AggregateCreatable;
  aggregateCustomer: AggregateCustomer;
  aggregateDescription: AggregateDescription;
  aggregateDiscountCoupon: AggregateDiscountCoupon;
  aggregateOrder: AggregateOrder;
  aggregatePage: AggregatePage;
  aggregateProduct: AggregateProduct;
  aggregateProductPriceConstant: AggregateProductPriceConstant;
  aggregateProductRingStone: AggregateProductRingStone;
  aggregateProductRingStoneGroup: AggregateProductRingStoneGroup;
  aggregateRingColor: AggregateRingColor;
  aggregateRingColorPurity: AggregateRingColorPurity;
  aggregateRingSize: AggregateRingSize;
  aggregateRingSizeStandart: AggregateRingSizeStandart;
  aggregateRingStoneCarat: AggregateRingStoneCarat;
  aggregateRingStoneCaratInfo: AggregateRingStoneCaratInfo;
  aggregateRingStoneShape: AggregateRingStoneShape;
  aggregateSeo: AggregateSeo;
  aggregateSlider: AggregateSlider;
  aggregateStaff: AggregateStaff;
  aggregateStaffLog: AggregateStaffLog;
  aggregateStyle: AggregateStyle;
  basket: Basket;
  basketItem?: Maybe<BasketItem>;
  basketItems: Array<BasketItem>;
  baskets: Array<Basket>;
  blog?: Maybe<Blog>;
  blogCategories: Array<BlogCategory>;
  blogCategory?: Maybe<BlogCategory>;
  blogs: Array<Blog>;
  categories: Array<Category>;
  category?: Maybe<Category>;
  checkMeCustomer: Customer;
  checkOrderResultCustomer: Order;
  checkStaff: Scalars['Boolean'];
  countBasket: BasketInputPayload;
  countGuestBasket: BasketInputPayload;
  countries: CountriesPayload;
  country?: Maybe<Country>;
  creatable?: Maybe<Creatable>;
  creatables: Array<Creatable>;
  customProducts: CustomProductsPayload;
  customer?: Maybe<Customer>;
  customers: CustomersPayload;
  dashboardStatistics: DashboardStatisticsPayload;
  description?: Maybe<Description>;
  descriptions: Array<Description>;
  discountCoupon?: Maybe<DiscountCoupon>;
  discountCoupons: Array<DiscountCoupon>;
  findFirstBasket?: Maybe<Basket>;
  findFirstBasketItem?: Maybe<BasketItem>;
  findFirstBasketItemOrThrow?: Maybe<BasketItem>;
  findFirstBasketOrThrow?: Maybe<Basket>;
  findFirstBlog?: Maybe<Blog>;
  findFirstBlogCategory?: Maybe<BlogCategory>;
  findFirstBlogCategoryOrThrow?: Maybe<BlogCategory>;
  findFirstBlogOrThrow?: Maybe<Blog>;
  findFirstCategory?: Maybe<Category>;
  findFirstCategoryOrThrow?: Maybe<Category>;
  findFirstCreatable?: Maybe<Creatable>;
  findFirstCreatableOrThrow?: Maybe<Creatable>;
  findFirstCustomer?: Maybe<Customer>;
  findFirstCustomerOrThrow?: Maybe<Customer>;
  findFirstDescription?: Maybe<Description>;
  findFirstDescriptionOrThrow?: Maybe<Description>;
  findFirstDiscountCoupon?: Maybe<DiscountCoupon>;
  findFirstDiscountCouponOrThrow?: Maybe<DiscountCoupon>;
  findFirstOrder?: Maybe<Order>;
  findFirstOrderOrThrow?: Maybe<Order>;
  findFirstPage?: Maybe<Page>;
  findFirstPageOrThrow?: Maybe<Page>;
  findFirstProduct?: Maybe<Product>;
  findFirstProductOrThrow?: Maybe<Product>;
  findFirstProductPriceConstant?: Maybe<ProductPriceConstant>;
  findFirstProductPriceConstantOrThrow?: Maybe<ProductPriceConstant>;
  findFirstProductRingStone?: Maybe<ProductRingStone>;
  findFirstProductRingStoneGroup?: Maybe<ProductRingStoneGroup>;
  findFirstProductRingStoneGroupOrThrow?: Maybe<ProductRingStoneGroup>;
  findFirstProductRingStoneOrThrow?: Maybe<ProductRingStone>;
  findFirstRingColor?: Maybe<RingColor>;
  findFirstRingColorOrThrow?: Maybe<RingColor>;
  findFirstRingColorPurity?: Maybe<RingColorPurity>;
  findFirstRingColorPurityOrThrow?: Maybe<RingColorPurity>;
  findFirstRingSize?: Maybe<RingSize>;
  findFirstRingSizeOrThrow?: Maybe<RingSize>;
  findFirstRingSizeStandart?: Maybe<RingSizeStandart>;
  findFirstRingSizeStandartOrThrow?: Maybe<RingSizeStandart>;
  findFirstRingStoneCarat?: Maybe<RingStoneCarat>;
  findFirstRingStoneCaratInfo?: Maybe<RingStoneCaratInfo>;
  findFirstRingStoneCaratInfoOrThrow?: Maybe<RingStoneCaratInfo>;
  findFirstRingStoneCaratOrThrow?: Maybe<RingStoneCarat>;
  findFirstRingStoneShape?: Maybe<RingStoneShape>;
  findFirstRingStoneShapeOrThrow?: Maybe<RingStoneShape>;
  findFirstSeo?: Maybe<Seo>;
  findFirstSeoOrThrow?: Maybe<Seo>;
  findFirstSlider?: Maybe<Slider>;
  findFirstSliderOrThrow?: Maybe<Slider>;
  findFirstStaff?: Maybe<Staff>;
  findFirstStaffLog?: Maybe<StaffLog>;
  findFirstStaffLogOrThrow?: Maybe<StaffLog>;
  findFirstStaffOrThrow?: Maybe<Staff>;
  findFirstStyle?: Maybe<Style>;
  findFirstStyleOrThrow?: Maybe<Style>;
  findFirstTax?: Maybe<Tax>;
  findManyStaff: Array<Staff>;
  findUniqueStaff?: Maybe<Staff>;
  findUniqueStaffOrThrow?: Maybe<Staff>;
  generateSitemap: Scalars['String'];
  getAgreementSale: Page;
  getAllLogs: GetAllLogs;
  getBasket?: Maybe<Basket>;
  getBasketItem?: Maybe<BasketItem>;
  getBlog?: Maybe<Blog>;
  getBlogCategory?: Maybe<BlogCategory>;
  getCategory?: Maybe<Category>;
  getCreatable?: Maybe<Creatable>;
  getCurrency: Scalars['String'];
  getCustomer?: Maybe<Customer>;
  getDescription?: Maybe<Description>;
  getDiscountCoupon?: Maybe<DiscountCoupon>;
  getGuestBasket: Array<GuestBasketItem>;
  getHomePage: HomePagePayload;
  getOrder?: Maybe<Order>;
  getPage?: Maybe<Page>;
  getProduct?: Maybe<Product>;
  getProductPriceConstant?: Maybe<ProductPriceConstant>;
  getProductRingStone?: Maybe<ProductRingStone>;
  getProductRingStoneGroup?: Maybe<ProductRingStoneGroup>;
  getProductUploadedImages: Scalars['String'];
  getRingColor?: Maybe<RingColor>;
  getRingColorPurity?: Maybe<RingColorPurity>;
  getRingSize?: Maybe<RingSize>;
  getRingSizeStandart?: Maybe<RingSizeStandart>;
  getRingStoneCarat?: Maybe<RingStoneCarat>;
  getRingStoneCaratInfo?: Maybe<RingStoneCaratInfo>;
  getRingStoneShape?: Maybe<RingStoneShape>;
  getSeo?: Maybe<Seo>;
  getSlider?: Maybe<Slider>;
  getStaffLog?: Maybe<StaffLog>;
  getStyle?: Maybe<Style>;
  groupByBasket: Array<BasketGroupBy>;
  groupByBasketItem: Array<BasketItemGroupBy>;
  groupByBlog: Array<BlogGroupBy>;
  groupByBlogCategory: Array<BlogCategoryGroupBy>;
  groupByCategory: Array<CategoryGroupBy>;
  groupByCreatable: Array<CreatableGroupBy>;
  groupByCustomer: Array<CustomerGroupBy>;
  groupByDescription: Array<DescriptionGroupBy>;
  groupByDiscountCoupon: Array<DiscountCouponGroupBy>;
  groupByOrder: Array<OrderGroupBy>;
  groupByPage: Array<PageGroupBy>;
  groupByProduct: Array<ProductGroupBy>;
  groupByProductPriceConstant: Array<ProductPriceConstantGroupBy>;
  groupByProductRingStone: Array<ProductRingStoneGroupBy>;
  groupByProductRingStoneGroup: Array<ProductRingStoneGroupGroupBy>;
  groupByRingColor: Array<RingColorGroupBy>;
  groupByRingColorPurity: Array<RingColorPurityGroupBy>;
  groupByRingSize: Array<RingSizeGroupBy>;
  groupByRingSizeStandart: Array<RingSizeStandartGroupBy>;
  groupByRingStoneCarat: Array<RingStoneCaratGroupBy>;
  groupByRingStoneCaratInfo: Array<RingStoneCaratInfoGroupBy>;
  groupByRingStoneShape: Array<RingStoneShapeGroupBy>;
  groupBySeo: Array<SeoGroupBy>;
  groupBySlider: Array<SliderGroupBy>;
  groupByStaff: Array<StaffGroupBy>;
  groupByStaffLog: Array<StaffLogGroupBy>;
  groupByStyle: Array<StyleGroupBy>;
  myBasketItems: Array<BasketItem>;
  myOrder: Order;
  myOrders: Array<Order>;
  order?: Maybe<Order>;
  orders: Array<Order>;
  page?: Maybe<Page>;
  pages: Array<Page>;
  product?: Maybe<Product>;
  productPriceConstant?: Maybe<ProductPriceConstant>;
  productPriceConstants: Array<ProductPriceConstant>;
  productRingStone?: Maybe<ProductRingStone>;
  productRingStoneGroup?: Maybe<ProductRingStoneGroup>;
  productRingStoneGroups: Array<ProductRingStoneGroup>;
  productRingStones: Array<ProductRingStone>;
  products: ProductsPayload;
  ringColor?: Maybe<RingColor>;
  ringColorPurities: Array<RingColorPurity>;
  ringColorPurity?: Maybe<RingColorPurity>;
  ringColors: Array<RingColor>;
  ringSize?: Maybe<RingSize>;
  ringSizeStandart?: Maybe<RingSizeStandart>;
  ringSizeStandarts: Array<RingSizeStandart>;
  ringSizes: Array<RingSize>;
  ringStoneCarat?: Maybe<RingStoneCarat>;
  ringStoneCaratInfo?: Maybe<RingStoneCaratInfo>;
  ringStoneCaratInfos: Array<RingStoneCaratInfo>;
  ringStoneCarats: Array<RingStoneCarat>;
  ringStoneShape?: Maybe<RingStoneShape>;
  ringStoneShapes: Array<RingStoneShape>;
  seo?: Maybe<Seo>;
  seos: Array<Seo>;
  slider?: Maybe<Slider>;
  sliders: Array<Slider>;
  staffLog?: Maybe<StaffLog>;
  staffLogs: Array<StaffLog>;
  style?: Maybe<Style>;
  styles: Array<Style>;
  taxes: Array<Tax>;
  webPage: Page;
};


export type QueryAggregateBasketArgs = {
  cursor?: InputMaybe<BasketWhereUniqueInput>;
  orderBy?: InputMaybe<Array<BasketOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BasketWhereInput>;
};


export type QueryAggregateBasketItemArgs = {
  cursor?: InputMaybe<BasketItemWhereUniqueInput>;
  orderBy?: InputMaybe<Array<BasketItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BasketItemWhereInput>;
};


export type QueryAggregateBlogArgs = {
  cursor?: InputMaybe<BlogWhereUniqueInput>;
  orderBy?: InputMaybe<Array<BlogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BlogWhereInput>;
};


export type QueryAggregateBlogCategoryArgs = {
  cursor?: InputMaybe<BlogCategoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<BlogCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BlogCategoryWhereInput>;
};


export type QueryAggregateCategoryArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryAggregateCreatableArgs = {
  cursor?: InputMaybe<CreatableWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CreatableOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CreatableWhereInput>;
};


export type QueryAggregateCustomerArgs = {
  cursor?: InputMaybe<CustomerWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CustomerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CustomerWhereInput>;
};


export type QueryAggregateDescriptionArgs = {
  cursor?: InputMaybe<DescriptionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};


export type QueryAggregateDiscountCouponArgs = {
  cursor?: InputMaybe<DiscountCouponWhereUniqueInput>;
  orderBy?: InputMaybe<Array<DiscountCouponOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DiscountCouponWhereInput>;
};


export type QueryAggregateOrderArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type QueryAggregatePageArgs = {
  cursor?: InputMaybe<PageWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageWhereInput>;
};


export type QueryAggregateProductArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryAggregateProductPriceConstantArgs = {
  cursor?: InputMaybe<ProductPriceConstantWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProductPriceConstantOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductPriceConstantWhereInput>;
};


export type QueryAggregateProductRingStoneArgs = {
  cursor?: InputMaybe<ProductRingStoneWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProductRingStoneOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductRingStoneWhereInput>;
};


export type QueryAggregateProductRingStoneGroupArgs = {
  cursor?: InputMaybe<ProductRingStoneGroupWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProductRingStoneGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductRingStoneGroupWhereInput>;
};


export type QueryAggregateRingColorArgs = {
  cursor?: InputMaybe<RingColorWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RingColorOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingColorWhereInput>;
};


export type QueryAggregateRingColorPurityArgs = {
  cursor?: InputMaybe<RingColorPurityWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RingColorPurityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingColorPurityWhereInput>;
};


export type QueryAggregateRingSizeArgs = {
  cursor?: InputMaybe<RingSizeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RingSizeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingSizeWhereInput>;
};


export type QueryAggregateRingSizeStandartArgs = {
  cursor?: InputMaybe<RingSizeStandartWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RingSizeStandartOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingSizeStandartWhereInput>;
};


export type QueryAggregateRingStoneCaratArgs = {
  cursor?: InputMaybe<RingStoneCaratWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RingStoneCaratOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingStoneCaratWhereInput>;
};


export type QueryAggregateRingStoneCaratInfoArgs = {
  cursor?: InputMaybe<RingStoneCaratInfoWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RingStoneCaratInfoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingStoneCaratInfoWhereInput>;
};


export type QueryAggregateRingStoneShapeArgs = {
  cursor?: InputMaybe<RingStoneShapeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RingStoneShapeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingStoneShapeWhereInput>;
};


export type QueryAggregateSeoArgs = {
  cursor?: InputMaybe<SeoWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SeoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SeoWhereInput>;
};


export type QueryAggregateSliderArgs = {
  cursor?: InputMaybe<SliderWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SliderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SliderWhereInput>;
};


export type QueryAggregateStaffArgs = {
  cursor?: InputMaybe<StaffWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StaffOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StaffWhereInput>;
};


export type QueryAggregateStaffLogArgs = {
  cursor?: InputMaybe<StaffLogWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StaffLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StaffLogWhereInput>;
};


export type QueryAggregateStyleArgs = {
  cursor?: InputMaybe<StyleWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StyleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StyleWhereInput>;
};


export type QueryBasketItemArgs = {
  where: BasketItemWhereUniqueInput;
};


export type QueryBasketItemsArgs = {
  cursor?: InputMaybe<BasketItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<BasketItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BasketItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BasketItemWhereInput>;
};


export type QueryBasketsArgs = {
  cursor?: InputMaybe<BasketWhereUniqueInput>;
  distinct?: InputMaybe<Array<BasketScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BasketOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BasketWhereInput>;
};


export type QueryBlogArgs = {
  where: BlogWhereUniqueInput;
};


export type QueryBlogCategoriesArgs = {
  cursor?: InputMaybe<BlogCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<BlogCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BlogCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BlogCategoryWhereInput>;
};


export type QueryBlogCategoryArgs = {
  where: BlogCategoryWhereUniqueInput;
};


export type QueryBlogsArgs = {
  cursor?: InputMaybe<BlogWhereUniqueInput>;
  distinct?: InputMaybe<Array<BlogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BlogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BlogWhereInput>;
};


export type QueryCategoriesArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryCategoryArgs = {
  where: CategoryWhereUniqueInput;
};


export type QueryCheckOrderResultCustomerArgs = {
  conversationId: Scalars['String'];
};


export type QueryCountGuestBasketArgs = {
  code: Scalars['String'];
};


export type QueryCountriesArgs = {
  orderBy?: InputMaybe<Array<CountryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CountryWhereInput>;
};


export type QueryCountryArgs = {
  where: CountryWhereUniqueInput;
};


export type QueryCreatableArgs = {
  where: CreatableWhereUniqueInput;
};


export type QueryCreatablesArgs = {
  cursor?: InputMaybe<CreatableWhereUniqueInput>;
  distinct?: InputMaybe<Array<CreatableScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CreatableOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CreatableWhereInput>;
};


export type QueryCustomProductsArgs = {
  CustomProductsOrderByFilter?: InputMaybe<CustomProductsOrderByFilter>;
  categorySlug?: InputMaybe<Scalars['String']>;
  filterValue?: InputMaybe<Scalars['String']>;
  language: Scalars['String'];
  searchValue?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryCustomerArgs = {
  where: CustomerWhereUniqueInput;
};


export type QueryCustomersArgs = {
  orderBy?: InputMaybe<Array<CustomerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CustomerWhereInput>;
};


export type QueryDescriptionArgs = {
  where: DescriptionWhereUniqueInput;
};


export type QueryDescriptionsArgs = {
  cursor?: InputMaybe<DescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<DescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};


export type QueryDiscountCouponArgs = {
  where: DiscountCouponWhereUniqueInput;
};


export type QueryDiscountCouponsArgs = {
  cursor?: InputMaybe<DiscountCouponWhereUniqueInput>;
  distinct?: InputMaybe<Array<DiscountCouponScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DiscountCouponOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DiscountCouponWhereInput>;
};


export type QueryFindFirstBasketArgs = {
  cursor?: InputMaybe<BasketWhereUniqueInput>;
  distinct?: InputMaybe<Array<BasketScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BasketOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BasketWhereInput>;
};


export type QueryFindFirstBasketItemArgs = {
  cursor?: InputMaybe<BasketItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<BasketItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BasketItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BasketItemWhereInput>;
};


export type QueryFindFirstBasketItemOrThrowArgs = {
  cursor?: InputMaybe<BasketItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<BasketItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BasketItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BasketItemWhereInput>;
};


export type QueryFindFirstBasketOrThrowArgs = {
  cursor?: InputMaybe<BasketWhereUniqueInput>;
  distinct?: InputMaybe<Array<BasketScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BasketOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BasketWhereInput>;
};


export type QueryFindFirstBlogArgs = {
  cursor?: InputMaybe<BlogWhereUniqueInput>;
  distinct?: InputMaybe<Array<BlogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BlogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BlogWhereInput>;
};


export type QueryFindFirstBlogCategoryArgs = {
  cursor?: InputMaybe<BlogCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<BlogCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BlogCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BlogCategoryWhereInput>;
};


export type QueryFindFirstBlogCategoryOrThrowArgs = {
  cursor?: InputMaybe<BlogCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<BlogCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BlogCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BlogCategoryWhereInput>;
};


export type QueryFindFirstBlogOrThrowArgs = {
  cursor?: InputMaybe<BlogWhereUniqueInput>;
  distinct?: InputMaybe<Array<BlogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BlogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BlogWhereInput>;
};


export type QueryFindFirstCategoryArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryFindFirstCategoryOrThrowArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryFindFirstCreatableArgs = {
  cursor?: InputMaybe<CreatableWhereUniqueInput>;
  distinct?: InputMaybe<Array<CreatableScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CreatableOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CreatableWhereInput>;
};


export type QueryFindFirstCreatableOrThrowArgs = {
  cursor?: InputMaybe<CreatableWhereUniqueInput>;
  distinct?: InputMaybe<Array<CreatableScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CreatableOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CreatableWhereInput>;
};


export type QueryFindFirstCustomerArgs = {
  cursor?: InputMaybe<CustomerWhereUniqueInput>;
  distinct?: InputMaybe<Array<CustomerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CustomerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CustomerWhereInput>;
};


export type QueryFindFirstCustomerOrThrowArgs = {
  cursor?: InputMaybe<CustomerWhereUniqueInput>;
  distinct?: InputMaybe<Array<CustomerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CustomerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CustomerWhereInput>;
};


export type QueryFindFirstDescriptionArgs = {
  cursor?: InputMaybe<DescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<DescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};


export type QueryFindFirstDescriptionOrThrowArgs = {
  cursor?: InputMaybe<DescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<DescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};


export type QueryFindFirstDiscountCouponArgs = {
  cursor?: InputMaybe<DiscountCouponWhereUniqueInput>;
  distinct?: InputMaybe<Array<DiscountCouponScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DiscountCouponOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DiscountCouponWhereInput>;
};


export type QueryFindFirstDiscountCouponOrThrowArgs = {
  cursor?: InputMaybe<DiscountCouponWhereUniqueInput>;
  distinct?: InputMaybe<Array<DiscountCouponScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DiscountCouponOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DiscountCouponWhereInput>;
};


export type QueryFindFirstOrderArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type QueryFindFirstOrderOrThrowArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type QueryFindFirstPageArgs = {
  cursor?: InputMaybe<PageWhereUniqueInput>;
  distinct?: InputMaybe<Array<PageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageWhereInput>;
};


export type QueryFindFirstPageOrThrowArgs = {
  cursor?: InputMaybe<PageWhereUniqueInput>;
  distinct?: InputMaybe<Array<PageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageWhereInput>;
};


export type QueryFindFirstProductArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryFindFirstProductOrThrowArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryFindFirstProductPriceConstantArgs = {
  cursor?: InputMaybe<ProductPriceConstantWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductPriceConstantScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductPriceConstantOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductPriceConstantWhereInput>;
};


export type QueryFindFirstProductPriceConstantOrThrowArgs = {
  cursor?: InputMaybe<ProductPriceConstantWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductPriceConstantScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductPriceConstantOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductPriceConstantWhereInput>;
};


export type QueryFindFirstProductRingStoneArgs = {
  cursor?: InputMaybe<ProductRingStoneWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductRingStoneScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductRingStoneOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductRingStoneWhereInput>;
};


export type QueryFindFirstProductRingStoneGroupArgs = {
  cursor?: InputMaybe<ProductRingStoneGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductRingStoneGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductRingStoneGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductRingStoneGroupWhereInput>;
};


export type QueryFindFirstProductRingStoneGroupOrThrowArgs = {
  cursor?: InputMaybe<ProductRingStoneGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductRingStoneGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductRingStoneGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductRingStoneGroupWhereInput>;
};


export type QueryFindFirstProductRingStoneOrThrowArgs = {
  cursor?: InputMaybe<ProductRingStoneWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductRingStoneScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductRingStoneOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductRingStoneWhereInput>;
};


export type QueryFindFirstRingColorArgs = {
  cursor?: InputMaybe<RingColorWhereUniqueInput>;
  distinct?: InputMaybe<Array<RingColorScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RingColorOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingColorWhereInput>;
};


export type QueryFindFirstRingColorOrThrowArgs = {
  cursor?: InputMaybe<RingColorWhereUniqueInput>;
  distinct?: InputMaybe<Array<RingColorScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RingColorOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingColorWhereInput>;
};


export type QueryFindFirstRingColorPurityArgs = {
  cursor?: InputMaybe<RingColorPurityWhereUniqueInput>;
  distinct?: InputMaybe<Array<RingColorPurityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RingColorPurityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingColorPurityWhereInput>;
};


export type QueryFindFirstRingColorPurityOrThrowArgs = {
  cursor?: InputMaybe<RingColorPurityWhereUniqueInput>;
  distinct?: InputMaybe<Array<RingColorPurityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RingColorPurityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingColorPurityWhereInput>;
};


export type QueryFindFirstRingSizeArgs = {
  cursor?: InputMaybe<RingSizeWhereUniqueInput>;
  distinct?: InputMaybe<Array<RingSizeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RingSizeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingSizeWhereInput>;
};


export type QueryFindFirstRingSizeOrThrowArgs = {
  cursor?: InputMaybe<RingSizeWhereUniqueInput>;
  distinct?: InputMaybe<Array<RingSizeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RingSizeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingSizeWhereInput>;
};


export type QueryFindFirstRingSizeStandartArgs = {
  cursor?: InputMaybe<RingSizeStandartWhereUniqueInput>;
  distinct?: InputMaybe<Array<RingSizeStandartScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RingSizeStandartOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingSizeStandartWhereInput>;
};


export type QueryFindFirstRingSizeStandartOrThrowArgs = {
  cursor?: InputMaybe<RingSizeStandartWhereUniqueInput>;
  distinct?: InputMaybe<Array<RingSizeStandartScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RingSizeStandartOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingSizeStandartWhereInput>;
};


export type QueryFindFirstRingStoneCaratArgs = {
  cursor?: InputMaybe<RingStoneCaratWhereUniqueInput>;
  distinct?: InputMaybe<Array<RingStoneCaratScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RingStoneCaratOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingStoneCaratWhereInput>;
};


export type QueryFindFirstRingStoneCaratInfoArgs = {
  cursor?: InputMaybe<RingStoneCaratInfoWhereUniqueInput>;
  distinct?: InputMaybe<Array<RingStoneCaratInfoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RingStoneCaratInfoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingStoneCaratInfoWhereInput>;
};


export type QueryFindFirstRingStoneCaratInfoOrThrowArgs = {
  cursor?: InputMaybe<RingStoneCaratInfoWhereUniqueInput>;
  distinct?: InputMaybe<Array<RingStoneCaratInfoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RingStoneCaratInfoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingStoneCaratInfoWhereInput>;
};


export type QueryFindFirstRingStoneCaratOrThrowArgs = {
  cursor?: InputMaybe<RingStoneCaratWhereUniqueInput>;
  distinct?: InputMaybe<Array<RingStoneCaratScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RingStoneCaratOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingStoneCaratWhereInput>;
};


export type QueryFindFirstRingStoneShapeArgs = {
  cursor?: InputMaybe<RingStoneShapeWhereUniqueInput>;
  distinct?: InputMaybe<Array<RingStoneShapeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RingStoneShapeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingStoneShapeWhereInput>;
};


export type QueryFindFirstRingStoneShapeOrThrowArgs = {
  cursor?: InputMaybe<RingStoneShapeWhereUniqueInput>;
  distinct?: InputMaybe<Array<RingStoneShapeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RingStoneShapeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingStoneShapeWhereInput>;
};


export type QueryFindFirstSeoArgs = {
  cursor?: InputMaybe<SeoWhereUniqueInput>;
  distinct?: InputMaybe<Array<SeoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SeoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SeoWhereInput>;
};


export type QueryFindFirstSeoOrThrowArgs = {
  cursor?: InputMaybe<SeoWhereUniqueInput>;
  distinct?: InputMaybe<Array<SeoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SeoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SeoWhereInput>;
};


export type QueryFindFirstSliderArgs = {
  cursor?: InputMaybe<SliderWhereUniqueInput>;
  distinct?: InputMaybe<Array<SliderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SliderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SliderWhereInput>;
};


export type QueryFindFirstSliderOrThrowArgs = {
  cursor?: InputMaybe<SliderWhereUniqueInput>;
  distinct?: InputMaybe<Array<SliderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SliderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SliderWhereInput>;
};


export type QueryFindFirstStaffArgs = {
  cursor?: InputMaybe<StaffWhereUniqueInput>;
  distinct?: InputMaybe<Array<StaffScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StaffOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StaffWhereInput>;
};


export type QueryFindFirstStaffLogArgs = {
  cursor?: InputMaybe<StaffLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<StaffLogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StaffLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StaffLogWhereInput>;
};


export type QueryFindFirstStaffLogOrThrowArgs = {
  cursor?: InputMaybe<StaffLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<StaffLogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StaffLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StaffLogWhereInput>;
};


export type QueryFindFirstStaffOrThrowArgs = {
  cursor?: InputMaybe<StaffWhereUniqueInput>;
  distinct?: InputMaybe<Array<StaffScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StaffOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StaffWhereInput>;
};


export type QueryFindFirstStyleArgs = {
  cursor?: InputMaybe<StyleWhereUniqueInput>;
  distinct?: InputMaybe<Array<StyleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StyleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StyleWhereInput>;
};


export type QueryFindFirstStyleOrThrowArgs = {
  cursor?: InputMaybe<StyleWhereUniqueInput>;
  distinct?: InputMaybe<Array<StyleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StyleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StyleWhereInput>;
};


export type QueryFindFirstTaxArgs = {
  cursor?: InputMaybe<TaxWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaxScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaxOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaxWhereInput>;
};


export type QueryFindManyStaffArgs = {
  cursor?: InputMaybe<StaffWhereUniqueInput>;
  distinct?: InputMaybe<Array<StaffScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StaffOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StaffWhereInput>;
};


export type QueryFindUniqueStaffArgs = {
  where: StaffWhereUniqueInput;
};


export type QueryFindUniqueStaffOrThrowArgs = {
  where: StaffWhereUniqueInput;
};


export type QueryGetAgreementSaleArgs = {
  language: Scalars['String'];
};


export type QueryGetAllLogsArgs = {
  cursor?: InputMaybe<StaffLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<StaffLogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StaffLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StaffLogWhereInput>;
};


export type QueryGetBasketArgs = {
  where: BasketWhereUniqueInput;
};


export type QueryGetBasketItemArgs = {
  where: BasketItemWhereUniqueInput;
};


export type QueryGetBlogArgs = {
  where: BlogWhereUniqueInput;
};


export type QueryGetBlogCategoryArgs = {
  where: BlogCategoryWhereUniqueInput;
};


export type QueryGetCategoryArgs = {
  where: CategoryWhereUniqueInput;
};


export type QueryGetCreatableArgs = {
  where: CreatableWhereUniqueInput;
};


export type QueryGetCustomerArgs = {
  where: CustomerWhereUniqueInput;
};


export type QueryGetDescriptionArgs = {
  where: DescriptionWhereUniqueInput;
};


export type QueryGetDiscountCouponArgs = {
  where: DiscountCouponWhereUniqueInput;
};


export type QueryGetGuestBasketArgs = {
  code: Scalars['String'];
};


export type QueryGetHomePageArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


export type QueryGetOrderArgs = {
  where: OrderWhereUniqueInput;
};


export type QueryGetPageArgs = {
  where: PageWhereUniqueInput;
};


export type QueryGetProductArgs = {
  where: ProductWhereUniqueInput;
};


export type QueryGetProductPriceConstantArgs = {
  where: ProductPriceConstantWhereUniqueInput;
};


export type QueryGetProductRingStoneArgs = {
  where: ProductRingStoneWhereUniqueInput;
};


export type QueryGetProductRingStoneGroupArgs = {
  where: ProductRingStoneGroupWhereUniqueInput;
};


export type QueryGetProductUploadedImagesArgs = {
  productId: Scalars['String'];
};


export type QueryGetRingColorArgs = {
  where: RingColorWhereUniqueInput;
};


export type QueryGetRingColorPurityArgs = {
  where: RingColorPurityWhereUniqueInput;
};


export type QueryGetRingSizeArgs = {
  where: RingSizeWhereUniqueInput;
};


export type QueryGetRingSizeStandartArgs = {
  where: RingSizeStandartWhereUniqueInput;
};


export type QueryGetRingStoneCaratArgs = {
  where: RingStoneCaratWhereUniqueInput;
};


export type QueryGetRingStoneCaratInfoArgs = {
  where: RingStoneCaratInfoWhereUniqueInput;
};


export type QueryGetRingStoneShapeArgs = {
  where: RingStoneShapeWhereUniqueInput;
};


export type QueryGetSeoArgs = {
  where: SeoWhereUniqueInput;
};


export type QueryGetSliderArgs = {
  where: SliderWhereUniqueInput;
};


export type QueryGetStaffLogArgs = {
  where: StaffLogWhereUniqueInput;
};


export type QueryGetStyleArgs = {
  where: StyleWhereUniqueInput;
};


export type QueryGroupByBasketArgs = {
  by: Array<BasketScalarFieldEnum>;
  having?: InputMaybe<BasketScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<BasketOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BasketWhereInput>;
};


export type QueryGroupByBasketItemArgs = {
  by: Array<BasketItemScalarFieldEnum>;
  having?: InputMaybe<BasketItemScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<BasketItemOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BasketItemWhereInput>;
};


export type QueryGroupByBlogArgs = {
  by: Array<BlogScalarFieldEnum>;
  having?: InputMaybe<BlogScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<BlogOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BlogWhereInput>;
};


export type QueryGroupByBlogCategoryArgs = {
  by: Array<BlogCategoryScalarFieldEnum>;
  having?: InputMaybe<BlogCategoryScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<BlogCategoryOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BlogCategoryWhereInput>;
};


export type QueryGroupByCategoryArgs = {
  by: Array<CategoryScalarFieldEnum>;
  having?: InputMaybe<CategoryScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryGroupByCreatableArgs = {
  by: Array<CreatableScalarFieldEnum>;
  having?: InputMaybe<CreatableScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CreatableOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CreatableWhereInput>;
};


export type QueryGroupByCustomerArgs = {
  by: Array<CustomerScalarFieldEnum>;
  having?: InputMaybe<CustomerScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CustomerOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CustomerWhereInput>;
};


export type QueryGroupByDescriptionArgs = {
  by: Array<DescriptionScalarFieldEnum>;
  having?: InputMaybe<DescriptionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};


export type QueryGroupByDiscountCouponArgs = {
  by: Array<DiscountCouponScalarFieldEnum>;
  having?: InputMaybe<DiscountCouponScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<DiscountCouponOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DiscountCouponWhereInput>;
};


export type QueryGroupByOrderArgs = {
  by: Array<OrderScalarFieldEnum>;
  having?: InputMaybe<OrderScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<OrderOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type QueryGroupByPageArgs = {
  by: Array<PageScalarFieldEnum>;
  having?: InputMaybe<PageScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PageOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageWhereInput>;
};


export type QueryGroupByProductArgs = {
  by: Array<ProductScalarFieldEnum>;
  having?: InputMaybe<ProductScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ProductOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryGroupByProductPriceConstantArgs = {
  by: Array<ProductPriceConstantScalarFieldEnum>;
  having?: InputMaybe<ProductPriceConstantScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ProductPriceConstantOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductPriceConstantWhereInput>;
};


export type QueryGroupByProductRingStoneArgs = {
  by: Array<ProductRingStoneScalarFieldEnum>;
  having?: InputMaybe<ProductRingStoneScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ProductRingStoneOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductRingStoneWhereInput>;
};


export type QueryGroupByProductRingStoneGroupArgs = {
  by: Array<ProductRingStoneGroupScalarFieldEnum>;
  having?: InputMaybe<ProductRingStoneGroupScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ProductRingStoneGroupOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductRingStoneGroupWhereInput>;
};


export type QueryGroupByRingColorArgs = {
  by: Array<RingColorScalarFieldEnum>;
  having?: InputMaybe<RingColorScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<RingColorOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingColorWhereInput>;
};


export type QueryGroupByRingColorPurityArgs = {
  by: Array<RingColorPurityScalarFieldEnum>;
  having?: InputMaybe<RingColorPurityScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<RingColorPurityOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingColorPurityWhereInput>;
};


export type QueryGroupByRingSizeArgs = {
  by: Array<RingSizeScalarFieldEnum>;
  having?: InputMaybe<RingSizeScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<RingSizeOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingSizeWhereInput>;
};


export type QueryGroupByRingSizeStandartArgs = {
  by: Array<RingSizeStandartScalarFieldEnum>;
  having?: InputMaybe<RingSizeStandartScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<RingSizeStandartOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingSizeStandartWhereInput>;
};


export type QueryGroupByRingStoneCaratArgs = {
  by: Array<RingStoneCaratScalarFieldEnum>;
  having?: InputMaybe<RingStoneCaratScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<RingStoneCaratOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingStoneCaratWhereInput>;
};


export type QueryGroupByRingStoneCaratInfoArgs = {
  by: Array<RingStoneCaratInfoScalarFieldEnum>;
  having?: InputMaybe<RingStoneCaratInfoScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<RingStoneCaratInfoOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingStoneCaratInfoWhereInput>;
};


export type QueryGroupByRingStoneShapeArgs = {
  by: Array<RingStoneShapeScalarFieldEnum>;
  having?: InputMaybe<RingStoneShapeScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<RingStoneShapeOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingStoneShapeWhereInput>;
};


export type QueryGroupBySeoArgs = {
  by: Array<SeoScalarFieldEnum>;
  having?: InputMaybe<SeoScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<SeoOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SeoWhereInput>;
};


export type QueryGroupBySliderArgs = {
  by: Array<SliderScalarFieldEnum>;
  having?: InputMaybe<SliderScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<SliderOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SliderWhereInput>;
};


export type QueryGroupByStaffArgs = {
  by: Array<StaffScalarFieldEnum>;
  having?: InputMaybe<StaffScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<StaffOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StaffWhereInput>;
};


export type QueryGroupByStaffLogArgs = {
  by: Array<StaffLogScalarFieldEnum>;
  having?: InputMaybe<StaffLogScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<StaffLogOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StaffLogWhereInput>;
};


export type QueryGroupByStyleArgs = {
  by: Array<StyleScalarFieldEnum>;
  having?: InputMaybe<StyleScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<StyleOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StyleWhereInput>;
};


export type QueryMyOrderArgs = {
  orderId: Scalars['String'];
};


export type QueryMyOrdersArgs = {
  where?: InputMaybe<OrderWhereInput>;
};


export type QueryOrderArgs = {
  where: OrderWhereUniqueInput;
};


export type QueryOrdersArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type QueryPageArgs = {
  where: PageWhereUniqueInput;
};


export type QueryPagesArgs = {
  cursor?: InputMaybe<PageWhereUniqueInput>;
  distinct?: InputMaybe<Array<PageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageWhereInput>;
};


export type QueryProductArgs = {
  where: ProductWhereUniqueInput;
};


export type QueryProductPriceConstantArgs = {
  where: ProductPriceConstantWhereUniqueInput;
};


export type QueryProductPriceConstantsArgs = {
  cursor?: InputMaybe<ProductPriceConstantWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductPriceConstantScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductPriceConstantOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductPriceConstantWhereInput>;
};


export type QueryProductRingStoneArgs = {
  where: ProductRingStoneWhereUniqueInput;
};


export type QueryProductRingStoneGroupArgs = {
  where: ProductRingStoneGroupWhereUniqueInput;
};


export type QueryProductRingStoneGroupsArgs = {
  cursor?: InputMaybe<ProductRingStoneGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductRingStoneGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductRingStoneGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductRingStoneGroupWhereInput>;
};


export type QueryProductRingStonesArgs = {
  cursor?: InputMaybe<ProductRingStoneWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductRingStoneScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductRingStoneOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductRingStoneWhereInput>;
};


export type QueryProductsArgs = {
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryRingColorArgs = {
  where: RingColorWhereUniqueInput;
};


export type QueryRingColorPuritiesArgs = {
  cursor?: InputMaybe<RingColorPurityWhereUniqueInput>;
  distinct?: InputMaybe<Array<RingColorPurityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RingColorPurityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingColorPurityWhereInput>;
};


export type QueryRingColorPurityArgs = {
  where: RingColorPurityWhereUniqueInput;
};


export type QueryRingColorsArgs = {
  cursor?: InputMaybe<RingColorWhereUniqueInput>;
  distinct?: InputMaybe<Array<RingColorScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RingColorOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingColorWhereInput>;
};


export type QueryRingSizeArgs = {
  where: RingSizeWhereUniqueInput;
};


export type QueryRingSizeStandartArgs = {
  where: RingSizeStandartWhereUniqueInput;
};


export type QueryRingSizeStandartsArgs = {
  cursor?: InputMaybe<RingSizeStandartWhereUniqueInput>;
  distinct?: InputMaybe<Array<RingSizeStandartScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RingSizeStandartOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingSizeStandartWhereInput>;
};


export type QueryRingSizesArgs = {
  cursor?: InputMaybe<RingSizeWhereUniqueInput>;
  distinct?: InputMaybe<Array<RingSizeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RingSizeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingSizeWhereInput>;
};


export type QueryRingStoneCaratArgs = {
  where: RingStoneCaratWhereUniqueInput;
};


export type QueryRingStoneCaratInfoArgs = {
  where: RingStoneCaratInfoWhereUniqueInput;
};


export type QueryRingStoneCaratInfosArgs = {
  cursor?: InputMaybe<RingStoneCaratInfoWhereUniqueInput>;
  distinct?: InputMaybe<Array<RingStoneCaratInfoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RingStoneCaratInfoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingStoneCaratInfoWhereInput>;
};


export type QueryRingStoneCaratsArgs = {
  cursor?: InputMaybe<RingStoneCaratWhereUniqueInput>;
  distinct?: InputMaybe<Array<RingStoneCaratScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RingStoneCaratOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingStoneCaratWhereInput>;
};


export type QueryRingStoneShapeArgs = {
  where: RingStoneShapeWhereUniqueInput;
};


export type QueryRingStoneShapesArgs = {
  cursor?: InputMaybe<RingStoneShapeWhereUniqueInput>;
  distinct?: InputMaybe<Array<RingStoneShapeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RingStoneShapeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingStoneShapeWhereInput>;
};


export type QuerySeoArgs = {
  where: SeoWhereUniqueInput;
};


export type QuerySeosArgs = {
  cursor?: InputMaybe<SeoWhereUniqueInput>;
  distinct?: InputMaybe<Array<SeoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SeoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SeoWhereInput>;
};


export type QuerySliderArgs = {
  where: SliderWhereUniqueInput;
};


export type QuerySlidersArgs = {
  cursor?: InputMaybe<SliderWhereUniqueInput>;
  distinct?: InputMaybe<Array<SliderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SliderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SliderWhereInput>;
};


export type QueryStaffLogArgs = {
  where: StaffLogWhereUniqueInput;
};


export type QueryStaffLogsArgs = {
  cursor?: InputMaybe<StaffLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<StaffLogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StaffLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StaffLogWhereInput>;
};


export type QueryStyleArgs = {
  where: StyleWhereUniqueInput;
};


export type QueryStylesArgs = {
  cursor?: InputMaybe<StyleWhereUniqueInput>;
  distinct?: InputMaybe<Array<StyleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StyleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StyleWhereInput>;
};


export type QueryTaxesArgs = {
  cursor?: InputMaybe<TaxWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaxScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaxOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaxWhereInput>;
};


export type QueryWebPageArgs = {
  language: Scalars['String'];
  slug: Scalars['String'];
};

export enum QueryMode {
  default = 'default',
  insensitive = 'insensitive'
}

export type RingColor = {
  __typename?: 'RingColor';
  _count?: Maybe<RingColorCount>;
  basketItems: Array<BasketItem>;
  createdAt: Scalars['DateTime'];
  descriptions: Array<Description>;
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  price: Scalars['Decimal'];
  productRingColors: Array<ProductRingColor>;
  purities: Array<RingColorPurity>;
  updatedAt: Scalars['DateTime'];
};


export type RingColorBasketItemsArgs = {
  cursor?: InputMaybe<BasketItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<BasketItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BasketItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BasketItemWhereInput>;
};


export type RingColorDescriptionsArgs = {
  cursor?: InputMaybe<DescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<DescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};


export type RingColorProductRingColorsArgs = {
  cursor?: InputMaybe<ProductRingColorWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductRingColorScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductRingColorOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductRingColorWhereInput>;
};


export type RingColorPuritiesArgs = {
  cursor?: InputMaybe<RingColorPurityWhereUniqueInput>;
  distinct?: InputMaybe<Array<RingColorPurityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RingColorPurityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingColorPurityWhereInput>;
};

export type RingColorAvgAggregate = {
  __typename?: 'RingColorAvgAggregate';
  price?: Maybe<Scalars['Decimal']>;
};

export type RingColorAvgOrderByAggregateInput = {
  price?: InputMaybe<SortOrder>;
};

export type RingColorCount = {
  __typename?: 'RingColorCount';
  basketItems: Scalars['Int'];
  descriptions: Scalars['Int'];
  productRingColors: Scalars['Int'];
  purities: Scalars['Int'];
};

export type RingColorCountAggregate = {
  __typename?: 'RingColorCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isDefault: Scalars['Int'];
  price: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type RingColorCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RingColorCreateInput = {
  basketItems?: InputMaybe<BasketItemCreateNestedManyWithoutRingColorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutRingColorInput>;
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  price: Scalars['Decimal'];
  productRingColors?: InputMaybe<ProductRingColorCreateNestedManyWithoutColorInput>;
  purities?: InputMaybe<RingColorPurityCreateNestedManyWithoutColorInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RingColorCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  price: Scalars['Decimal'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RingColorCreateNestedOneWithoutBasketItemsInput = {
  connect?: InputMaybe<RingColorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RingColorCreateOrConnectWithoutBasketItemsInput>;
  create?: InputMaybe<RingColorCreateWithoutBasketItemsInput>;
};

export type RingColorCreateNestedOneWithoutDescriptionsInput = {
  connect?: InputMaybe<RingColorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RingColorCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<RingColorCreateWithoutDescriptionsInput>;
};

export type RingColorCreateNestedOneWithoutProductRingColorsInput = {
  connect?: InputMaybe<RingColorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RingColorCreateOrConnectWithoutProductRingColorsInput>;
  create?: InputMaybe<RingColorCreateWithoutProductRingColorsInput>;
};

export type RingColorCreateNestedOneWithoutPuritiesInput = {
  connect?: InputMaybe<RingColorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RingColorCreateOrConnectWithoutPuritiesInput>;
  create?: InputMaybe<RingColorCreateWithoutPuritiesInput>;
};

export type RingColorCreateOrConnectWithoutBasketItemsInput = {
  create: RingColorCreateWithoutBasketItemsInput;
  where: RingColorWhereUniqueInput;
};

export type RingColorCreateOrConnectWithoutDescriptionsInput = {
  create: RingColorCreateWithoutDescriptionsInput;
  where: RingColorWhereUniqueInput;
};

export type RingColorCreateOrConnectWithoutProductRingColorsInput = {
  create: RingColorCreateWithoutProductRingColorsInput;
  where: RingColorWhereUniqueInput;
};

export type RingColorCreateOrConnectWithoutPuritiesInput = {
  create: RingColorCreateWithoutPuritiesInput;
  where: RingColorWhereUniqueInput;
};

export type RingColorCreateWithoutBasketItemsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutRingColorInput>;
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  price: Scalars['Decimal'];
  productRingColors?: InputMaybe<ProductRingColorCreateNestedManyWithoutColorInput>;
  purities?: InputMaybe<RingColorPurityCreateNestedManyWithoutColorInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RingColorCreateWithoutDescriptionsInput = {
  basketItems?: InputMaybe<BasketItemCreateNestedManyWithoutRingColorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  price: Scalars['Decimal'];
  productRingColors?: InputMaybe<ProductRingColorCreateNestedManyWithoutColorInput>;
  purities?: InputMaybe<RingColorPurityCreateNestedManyWithoutColorInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RingColorCreateWithoutProductRingColorsInput = {
  basketItems?: InputMaybe<BasketItemCreateNestedManyWithoutRingColorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutRingColorInput>;
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  price: Scalars['Decimal'];
  purities?: InputMaybe<RingColorPurityCreateNestedManyWithoutColorInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RingColorCreateWithoutPuritiesInput = {
  basketItems?: InputMaybe<BasketItemCreateNestedManyWithoutRingColorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutRingColorInput>;
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  price: Scalars['Decimal'];
  productRingColors?: InputMaybe<ProductRingColorCreateNestedManyWithoutColorInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RingColorGroupBy = {
  __typename?: 'RingColorGroupBy';
  _avg?: Maybe<RingColorAvgAggregate>;
  _count?: Maybe<RingColorCountAggregate>;
  _max?: Maybe<RingColorMaxAggregate>;
  _min?: Maybe<RingColorMinAggregate>;
  _sum?: Maybe<RingColorSumAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  price: Scalars['Decimal'];
  updatedAt: Scalars['DateTime'];
};

export type RingColorMaxAggregate = {
  __typename?: 'RingColorMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Decimal']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RingColorMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RingColorMinAggregate = {
  __typename?: 'RingColorMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Decimal']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RingColorMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RingColorOrderByWithAggregationInput = {
  _avg?: InputMaybe<RingColorAvgOrderByAggregateInput>;
  _count?: InputMaybe<RingColorCountOrderByAggregateInput>;
  _max?: InputMaybe<RingColorMaxOrderByAggregateInput>;
  _min?: InputMaybe<RingColorMinOrderByAggregateInput>;
  _sum?: InputMaybe<RingColorSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RingColorOrderByWithRelationInput = {
  basketItems?: InputMaybe<BasketItemOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  descriptions?: InputMaybe<DescriptionOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  productRingColors?: InputMaybe<ProductRingColorOrderByRelationAggregateInput>;
  purities?: InputMaybe<RingColorPurityOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RingColorPurity = {
  __typename?: 'RingColorPurity';
  _count?: Maybe<RingColorPurityCount>;
  basketItems: Array<BasketItem>;
  color: RingColor;
  colorId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  price: Scalars['Decimal'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['Int'];
};


export type RingColorPurityBasketItemsArgs = {
  cursor?: InputMaybe<BasketItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<BasketItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BasketItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BasketItemWhereInput>;
};

export type RingColorPurityAvgAggregate = {
  __typename?: 'RingColorPurityAvgAggregate';
  price?: Maybe<Scalars['Decimal']>;
  value?: Maybe<Scalars['Float']>;
};

export type RingColorPurityAvgOrderByAggregateInput = {
  price?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type RingColorPurityCount = {
  __typename?: 'RingColorPurityCount';
  basketItems: Scalars['Int'];
};

export type RingColorPurityCountAggregate = {
  __typename?: 'RingColorPurityCountAggregate';
  _all: Scalars['Int'];
  colorId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isDefault: Scalars['Int'];
  price: Scalars['Int'];
  updatedAt: Scalars['Int'];
  value: Scalars['Int'];
};

export type RingColorPurityCountOrderByAggregateInput = {
  colorId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type RingColorPurityCreateInput = {
  basketItems?: InputMaybe<BasketItemCreateNestedManyWithoutRingColorPurityInput>;
  color: RingColorCreateNestedOneWithoutPuritiesInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  price: Scalars['Decimal'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  value?: InputMaybe<Scalars['Int']>;
};

export type RingColorPurityCreateManyColorInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  price: Scalars['Decimal'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  value?: InputMaybe<Scalars['Int']>;
};

export type RingColorPurityCreateManyColorInputEnvelope = {
  data: Array<RingColorPurityCreateManyColorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type RingColorPurityCreateManyInput = {
  colorId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  price: Scalars['Decimal'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  value?: InputMaybe<Scalars['Int']>;
};

export type RingColorPurityCreateNestedManyWithoutColorInput = {
  connect?: InputMaybe<Array<RingColorPurityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RingColorPurityCreateOrConnectWithoutColorInput>>;
  create?: InputMaybe<Array<RingColorPurityCreateWithoutColorInput>>;
  createMany?: InputMaybe<RingColorPurityCreateManyColorInputEnvelope>;
};

export type RingColorPurityCreateNestedOneWithoutBasketItemsInput = {
  connect?: InputMaybe<RingColorPurityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RingColorPurityCreateOrConnectWithoutBasketItemsInput>;
  create?: InputMaybe<RingColorPurityCreateWithoutBasketItemsInput>;
};

export type RingColorPurityCreateOrConnectWithoutBasketItemsInput = {
  create: RingColorPurityCreateWithoutBasketItemsInput;
  where: RingColorPurityWhereUniqueInput;
};

export type RingColorPurityCreateOrConnectWithoutColorInput = {
  create: RingColorPurityCreateWithoutColorInput;
  where: RingColorPurityWhereUniqueInput;
};

export type RingColorPurityCreateWithoutBasketItemsInput = {
  color: RingColorCreateNestedOneWithoutPuritiesInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  price: Scalars['Decimal'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  value?: InputMaybe<Scalars['Int']>;
};

export type RingColorPurityCreateWithoutColorInput = {
  basketItems?: InputMaybe<BasketItemCreateNestedManyWithoutRingColorPurityInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  price: Scalars['Decimal'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  value?: InputMaybe<Scalars['Int']>;
};

export type RingColorPurityGroupBy = {
  __typename?: 'RingColorPurityGroupBy';
  _avg?: Maybe<RingColorPurityAvgAggregate>;
  _count?: Maybe<RingColorPurityCountAggregate>;
  _max?: Maybe<RingColorPurityMaxAggregate>;
  _min?: Maybe<RingColorPurityMinAggregate>;
  _sum?: Maybe<RingColorPuritySumAggregate>;
  colorId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  price: Scalars['Decimal'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['Int'];
};

export type RingColorPurityListRelationFilter = {
  every?: InputMaybe<RingColorPurityWhereInput>;
  none?: InputMaybe<RingColorPurityWhereInput>;
  some?: InputMaybe<RingColorPurityWhereInput>;
};

export type RingColorPurityMaxAggregate = {
  __typename?: 'RingColorPurityMaxAggregate';
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Decimal']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Int']>;
};

export type RingColorPurityMaxOrderByAggregateInput = {
  colorId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type RingColorPurityMinAggregate = {
  __typename?: 'RingColorPurityMinAggregate';
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Decimal']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Int']>;
};

export type RingColorPurityMinOrderByAggregateInput = {
  colorId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type RingColorPurityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RingColorPurityOrderByWithAggregationInput = {
  _avg?: InputMaybe<RingColorPurityAvgOrderByAggregateInput>;
  _count?: InputMaybe<RingColorPurityCountOrderByAggregateInput>;
  _max?: InputMaybe<RingColorPurityMaxOrderByAggregateInput>;
  _min?: InputMaybe<RingColorPurityMinOrderByAggregateInput>;
  _sum?: InputMaybe<RingColorPuritySumOrderByAggregateInput>;
  colorId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type RingColorPurityOrderByWithRelationInput = {
  basketItems?: InputMaybe<BasketItemOrderByRelationAggregateInput>;
  color?: InputMaybe<RingColorOrderByWithRelationInput>;
  colorId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type RingColorPurityRelationFilter = {
  is?: InputMaybe<RingColorPurityWhereInput>;
  isNot?: InputMaybe<RingColorPurityWhereInput>;
};

export enum RingColorPurityScalarFieldEnum {
  colorId = 'colorId',
  createdAt = 'createdAt',
  id = 'id',
  isDefault = 'isDefault',
  price = 'price',
  updatedAt = 'updatedAt',
  value = 'value'
}

export type RingColorPurityScalarWhereInput = {
  AND?: InputMaybe<Array<RingColorPurityScalarWhereInput>>;
  NOT?: InputMaybe<Array<RingColorPurityScalarWhereInput>>;
  OR?: InputMaybe<Array<RingColorPurityScalarWhereInput>>;
  colorId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isDefault?: InputMaybe<BoolFilter>;
  price?: InputMaybe<DecimalFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  value?: InputMaybe<IntFilter>;
};

export type RingColorPurityScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<RingColorPurityScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<RingColorPurityScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<RingColorPurityScalarWhereWithAggregatesInput>>;
  colorId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isDefault?: InputMaybe<BoolWithAggregatesFilter>;
  price?: InputMaybe<DecimalWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  value?: InputMaybe<IntWithAggregatesFilter>;
};

export type RingColorPuritySumAggregate = {
  __typename?: 'RingColorPuritySumAggregate';
  price?: Maybe<Scalars['Decimal']>;
  value?: Maybe<Scalars['Int']>;
};

export type RingColorPuritySumOrderByAggregateInput = {
  price?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type RingColorPurityUpdateInput = {
  basketItems?: InputMaybe<BasketItemUpdateManyWithoutRingColorPurityNestedInput>;
  color?: InputMaybe<RingColorUpdateOneRequiredWithoutPuritiesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDefault?: InputMaybe<BoolFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type RingColorPurityUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDefault?: InputMaybe<BoolFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type RingColorPurityUpdateManyWithWhereWithoutColorInput = {
  data: RingColorPurityUpdateManyMutationInput;
  where: RingColorPurityScalarWhereInput;
};

export type RingColorPurityUpdateManyWithoutColorNestedInput = {
  connect?: InputMaybe<Array<RingColorPurityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RingColorPurityCreateOrConnectWithoutColorInput>>;
  create?: InputMaybe<Array<RingColorPurityCreateWithoutColorInput>>;
  createMany?: InputMaybe<RingColorPurityCreateManyColorInputEnvelope>;
  delete?: InputMaybe<Array<RingColorPurityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RingColorPurityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RingColorPurityWhereUniqueInput>>;
  set?: InputMaybe<Array<RingColorPurityWhereUniqueInput>>;
  update?: InputMaybe<Array<RingColorPurityUpdateWithWhereUniqueWithoutColorInput>>;
  updateMany?: InputMaybe<Array<RingColorPurityUpdateManyWithWhereWithoutColorInput>>;
  upsert?: InputMaybe<Array<RingColorPurityUpsertWithWhereUniqueWithoutColorInput>>;
};

export type RingColorPurityUpdateOneWithoutBasketItemsNestedInput = {
  connect?: InputMaybe<RingColorPurityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RingColorPurityCreateOrConnectWithoutBasketItemsInput>;
  create?: InputMaybe<RingColorPurityCreateWithoutBasketItemsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<RingColorPurityUpdateWithoutBasketItemsInput>;
  upsert?: InputMaybe<RingColorPurityUpsertWithoutBasketItemsInput>;
};

export type RingColorPurityUpdateWithWhereUniqueWithoutColorInput = {
  data: RingColorPurityUpdateWithoutColorInput;
  where: RingColorPurityWhereUniqueInput;
};

export type RingColorPurityUpdateWithoutBasketItemsInput = {
  color?: InputMaybe<RingColorUpdateOneRequiredWithoutPuritiesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDefault?: InputMaybe<BoolFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type RingColorPurityUpdateWithoutColorInput = {
  basketItems?: InputMaybe<BasketItemUpdateManyWithoutRingColorPurityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDefault?: InputMaybe<BoolFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type RingColorPurityUpsertWithWhereUniqueWithoutColorInput = {
  create: RingColorPurityCreateWithoutColorInput;
  update: RingColorPurityUpdateWithoutColorInput;
  where: RingColorPurityWhereUniqueInput;
};

export type RingColorPurityUpsertWithoutBasketItemsInput = {
  create: RingColorPurityCreateWithoutBasketItemsInput;
  update: RingColorPurityUpdateWithoutBasketItemsInput;
};

export type RingColorPurityWhereInput = {
  AND?: InputMaybe<Array<RingColorPurityWhereInput>>;
  NOT?: InputMaybe<Array<RingColorPurityWhereInput>>;
  OR?: InputMaybe<Array<RingColorPurityWhereInput>>;
  basketItems?: InputMaybe<BasketItemListRelationFilter>;
  color?: InputMaybe<RingColorRelationFilter>;
  colorId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isDefault?: InputMaybe<BoolFilter>;
  price?: InputMaybe<DecimalFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  value?: InputMaybe<IntFilter>;
};

export type RingColorPurityWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type RingColorRelationFilter = {
  is?: InputMaybe<RingColorWhereInput>;
  isNot?: InputMaybe<RingColorWhereInput>;
};

export enum RingColorScalarFieldEnum {
  createdAt = 'createdAt',
  id = 'id',
  isDefault = 'isDefault',
  price = 'price',
  updatedAt = 'updatedAt'
}

export type RingColorScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<RingColorScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<RingColorScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<RingColorScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isDefault?: InputMaybe<BoolWithAggregatesFilter>;
  price?: InputMaybe<DecimalWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type RingColorSumAggregate = {
  __typename?: 'RingColorSumAggregate';
  price?: Maybe<Scalars['Decimal']>;
};

export type RingColorSumOrderByAggregateInput = {
  price?: InputMaybe<SortOrder>;
};

export type RingColorUpdateInput = {
  basketItems?: InputMaybe<BasketItemUpdateManyWithoutRingColorNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutRingColorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDefault?: InputMaybe<BoolFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  productRingColors?: InputMaybe<ProductRingColorUpdateManyWithoutColorNestedInput>;
  purities?: InputMaybe<RingColorPurityUpdateManyWithoutColorNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RingColorUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDefault?: InputMaybe<BoolFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RingColorUpdateOneRequiredWithoutProductRingColorsNestedInput = {
  connect?: InputMaybe<RingColorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RingColorCreateOrConnectWithoutProductRingColorsInput>;
  create?: InputMaybe<RingColorCreateWithoutProductRingColorsInput>;
  update?: InputMaybe<RingColorUpdateWithoutProductRingColorsInput>;
  upsert?: InputMaybe<RingColorUpsertWithoutProductRingColorsInput>;
};

export type RingColorUpdateOneRequiredWithoutPuritiesNestedInput = {
  connect?: InputMaybe<RingColorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RingColorCreateOrConnectWithoutPuritiesInput>;
  create?: InputMaybe<RingColorCreateWithoutPuritiesInput>;
  update?: InputMaybe<RingColorUpdateWithoutPuritiesInput>;
  upsert?: InputMaybe<RingColorUpsertWithoutPuritiesInput>;
};

export type RingColorUpdateOneWithoutBasketItemsNestedInput = {
  connect?: InputMaybe<RingColorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RingColorCreateOrConnectWithoutBasketItemsInput>;
  create?: InputMaybe<RingColorCreateWithoutBasketItemsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<RingColorUpdateWithoutBasketItemsInput>;
  upsert?: InputMaybe<RingColorUpsertWithoutBasketItemsInput>;
};

export type RingColorUpdateOneWithoutDescriptionsNestedInput = {
  connect?: InputMaybe<RingColorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RingColorCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<RingColorCreateWithoutDescriptionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<RingColorUpdateWithoutDescriptionsInput>;
  upsert?: InputMaybe<RingColorUpsertWithoutDescriptionsInput>;
};

export type RingColorUpdateWithoutBasketItemsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutRingColorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDefault?: InputMaybe<BoolFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  productRingColors?: InputMaybe<ProductRingColorUpdateManyWithoutColorNestedInput>;
  purities?: InputMaybe<RingColorPurityUpdateManyWithoutColorNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RingColorUpdateWithoutDescriptionsInput = {
  basketItems?: InputMaybe<BasketItemUpdateManyWithoutRingColorNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDefault?: InputMaybe<BoolFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  productRingColors?: InputMaybe<ProductRingColorUpdateManyWithoutColorNestedInput>;
  purities?: InputMaybe<RingColorPurityUpdateManyWithoutColorNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RingColorUpdateWithoutProductRingColorsInput = {
  basketItems?: InputMaybe<BasketItemUpdateManyWithoutRingColorNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutRingColorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDefault?: InputMaybe<BoolFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  purities?: InputMaybe<RingColorPurityUpdateManyWithoutColorNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RingColorUpdateWithoutPuritiesInput = {
  basketItems?: InputMaybe<BasketItemUpdateManyWithoutRingColorNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutRingColorNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDefault?: InputMaybe<BoolFieldUpdateOperationsInput>;
  price?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  productRingColors?: InputMaybe<ProductRingColorUpdateManyWithoutColorNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RingColorUpsertWithoutBasketItemsInput = {
  create: RingColorCreateWithoutBasketItemsInput;
  update: RingColorUpdateWithoutBasketItemsInput;
};

export type RingColorUpsertWithoutDescriptionsInput = {
  create: RingColorCreateWithoutDescriptionsInput;
  update: RingColorUpdateWithoutDescriptionsInput;
};

export type RingColorUpsertWithoutProductRingColorsInput = {
  create: RingColorCreateWithoutProductRingColorsInput;
  update: RingColorUpdateWithoutProductRingColorsInput;
};

export type RingColorUpsertWithoutPuritiesInput = {
  create: RingColorCreateWithoutPuritiesInput;
  update: RingColorUpdateWithoutPuritiesInput;
};

export type RingColorWhereInput = {
  AND?: InputMaybe<Array<RingColorWhereInput>>;
  NOT?: InputMaybe<Array<RingColorWhereInput>>;
  OR?: InputMaybe<Array<RingColorWhereInput>>;
  basketItems?: InputMaybe<BasketItemListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  descriptions?: InputMaybe<DescriptionListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  isDefault?: InputMaybe<BoolFilter>;
  price?: InputMaybe<DecimalFilter>;
  productRingColors?: InputMaybe<ProductRingColorListRelationFilter>;
  purities?: InputMaybe<RingColorPurityListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RingColorWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type RingSize = {
  __typename?: 'RingSize';
  _count?: Maybe<RingSizeCount>;
  basketItems: Array<BasketItem>;
  circumferenceMM: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  diameterMM: Scalars['Decimal'];
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  ringSizeStandart: RingSizeStandart;
  ringSizeStandartId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};


export type RingSizeBasketItemsArgs = {
  cursor?: InputMaybe<BasketItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<BasketItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BasketItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BasketItemWhereInput>;
};

export type RingSizeAvgAggregate = {
  __typename?: 'RingSizeAvgAggregate';
  circumferenceMM?: Maybe<Scalars['Decimal']>;
  diameterMM?: Maybe<Scalars['Decimal']>;
};

export type RingSizeAvgOrderByAggregateInput = {
  circumferenceMM?: InputMaybe<SortOrder>;
  diameterMM?: InputMaybe<SortOrder>;
};

export type RingSizeCount = {
  __typename?: 'RingSizeCount';
  basketItems: Scalars['Int'];
};

export type RingSizeCountAggregate = {
  __typename?: 'RingSizeCountAggregate';
  _all: Scalars['Int'];
  circumferenceMM: Scalars['Int'];
  createdAt: Scalars['Int'];
  diameterMM: Scalars['Int'];
  id: Scalars['Int'];
  isDefault: Scalars['Int'];
  ringSizeStandartId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  value: Scalars['Int'];
};

export type RingSizeCountOrderByAggregateInput = {
  circumferenceMM?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  diameterMM?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  ringSizeStandartId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type RingSizeCreateInput = {
  basketItems?: InputMaybe<BasketItemCreateNestedManyWithoutRingSizeInput>;
  circumferenceMM: Scalars['Decimal'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  diameterMM: Scalars['Decimal'];
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  ringSizeStandart: RingSizeStandartCreateNestedOneWithoutValuesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type RingSizeCreateManyInput = {
  circumferenceMM: Scalars['Decimal'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  diameterMM: Scalars['Decimal'];
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  ringSizeStandartId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type RingSizeCreateManyRingSizeStandartInput = {
  circumferenceMM: Scalars['Decimal'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  diameterMM: Scalars['Decimal'];
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type RingSizeCreateManyRingSizeStandartInputEnvelope = {
  data: Array<RingSizeCreateManyRingSizeStandartInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type RingSizeCreateNestedManyWithoutRingSizeStandartInput = {
  connect?: InputMaybe<Array<RingSizeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RingSizeCreateOrConnectWithoutRingSizeStandartInput>>;
  create?: InputMaybe<Array<RingSizeCreateWithoutRingSizeStandartInput>>;
  createMany?: InputMaybe<RingSizeCreateManyRingSizeStandartInputEnvelope>;
};

export type RingSizeCreateNestedOneWithoutBasketItemsInput = {
  connect?: InputMaybe<RingSizeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RingSizeCreateOrConnectWithoutBasketItemsInput>;
  create?: InputMaybe<RingSizeCreateWithoutBasketItemsInput>;
};

export type RingSizeCreateOrConnectWithoutBasketItemsInput = {
  create: RingSizeCreateWithoutBasketItemsInput;
  where: RingSizeWhereUniqueInput;
};

export type RingSizeCreateOrConnectWithoutRingSizeStandartInput = {
  create: RingSizeCreateWithoutRingSizeStandartInput;
  where: RingSizeWhereUniqueInput;
};

export type RingSizeCreateWithoutBasketItemsInput = {
  circumferenceMM: Scalars['Decimal'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  diameterMM: Scalars['Decimal'];
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  ringSizeStandart: RingSizeStandartCreateNestedOneWithoutValuesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type RingSizeCreateWithoutRingSizeStandartInput = {
  basketItems?: InputMaybe<BasketItemCreateNestedManyWithoutRingSizeInput>;
  circumferenceMM: Scalars['Decimal'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  diameterMM: Scalars['Decimal'];
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type RingSizeGroupBy = {
  __typename?: 'RingSizeGroupBy';
  _avg?: Maybe<RingSizeAvgAggregate>;
  _count?: Maybe<RingSizeCountAggregate>;
  _max?: Maybe<RingSizeMaxAggregate>;
  _min?: Maybe<RingSizeMinAggregate>;
  _sum?: Maybe<RingSizeSumAggregate>;
  circumferenceMM: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  diameterMM: Scalars['Decimal'];
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  ringSizeStandartId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type RingSizeListRelationFilter = {
  every?: InputMaybe<RingSizeWhereInput>;
  none?: InputMaybe<RingSizeWhereInput>;
  some?: InputMaybe<RingSizeWhereInput>;
};

export type RingSizeMaxAggregate = {
  __typename?: 'RingSizeMaxAggregate';
  circumferenceMM?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  diameterMM?: Maybe<Scalars['Decimal']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  ringSizeStandartId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type RingSizeMaxOrderByAggregateInput = {
  circumferenceMM?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  diameterMM?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  ringSizeStandartId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type RingSizeMinAggregate = {
  __typename?: 'RingSizeMinAggregate';
  circumferenceMM?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  diameterMM?: Maybe<Scalars['Decimal']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  ringSizeStandartId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type RingSizeMinOrderByAggregateInput = {
  circumferenceMM?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  diameterMM?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  ringSizeStandartId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type RingSizeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RingSizeOrderByWithAggregationInput = {
  _avg?: InputMaybe<RingSizeAvgOrderByAggregateInput>;
  _count?: InputMaybe<RingSizeCountOrderByAggregateInput>;
  _max?: InputMaybe<RingSizeMaxOrderByAggregateInput>;
  _min?: InputMaybe<RingSizeMinOrderByAggregateInput>;
  _sum?: InputMaybe<RingSizeSumOrderByAggregateInput>;
  circumferenceMM?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  diameterMM?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  ringSizeStandartId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type RingSizeOrderByWithRelationInput = {
  basketItems?: InputMaybe<BasketItemOrderByRelationAggregateInput>;
  circumferenceMM?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  diameterMM?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  ringSizeStandart?: InputMaybe<RingSizeStandartOrderByWithRelationInput>;
  ringSizeStandartId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type RingSizeRelationFilter = {
  is?: InputMaybe<RingSizeWhereInput>;
  isNot?: InputMaybe<RingSizeWhereInput>;
};

export enum RingSizeScalarFieldEnum {
  circumferenceMM = 'circumferenceMM',
  createdAt = 'createdAt',
  diameterMM = 'diameterMM',
  id = 'id',
  isDefault = 'isDefault',
  ringSizeStandartId = 'ringSizeStandartId',
  updatedAt = 'updatedAt',
  value = 'value'
}

export type RingSizeScalarWhereInput = {
  AND?: InputMaybe<Array<RingSizeScalarWhereInput>>;
  NOT?: InputMaybe<Array<RingSizeScalarWhereInput>>;
  OR?: InputMaybe<Array<RingSizeScalarWhereInput>>;
  circumferenceMM?: InputMaybe<DecimalFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  diameterMM?: InputMaybe<DecimalFilter>;
  id?: InputMaybe<StringFilter>;
  isDefault?: InputMaybe<BoolFilter>;
  ringSizeStandartId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type RingSizeScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<RingSizeScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<RingSizeScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<RingSizeScalarWhereWithAggregatesInput>>;
  circumferenceMM?: InputMaybe<DecimalWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  diameterMM?: InputMaybe<DecimalWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isDefault?: InputMaybe<BoolWithAggregatesFilter>;
  ringSizeStandartId?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  value?: InputMaybe<StringWithAggregatesFilter>;
};

export type RingSizeStandart = {
  __typename?: 'RingSizeStandart';
  _count?: Maybe<RingSizeStandartCount>;
  categoryName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  productRingSizeStandarts: Array<ProductRingSizeStandart>;
  updatedAt: Scalars['DateTime'];
  values: Array<RingSize>;
};


export type RingSizeStandartProductRingSizeStandartsArgs = {
  cursor?: InputMaybe<ProductRingSizeStandartWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductRingSizeStandartScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductRingSizeStandartOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductRingSizeStandartWhereInput>;
};


export type RingSizeStandartValuesArgs = {
  cursor?: InputMaybe<RingSizeWhereUniqueInput>;
  distinct?: InputMaybe<Array<RingSizeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RingSizeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingSizeWhereInput>;
};

export type RingSizeStandartCategoryNameNameCompoundUniqueInput = {
  categoryName: Scalars['String'];
  name: Scalars['String'];
};

export type RingSizeStandartCount = {
  __typename?: 'RingSizeStandartCount';
  productRingSizeStandarts: Scalars['Int'];
  values: Scalars['Int'];
};

export type RingSizeStandartCountAggregate = {
  __typename?: 'RingSizeStandartCountAggregate';
  _all: Scalars['Int'];
  categoryName: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isDefault: Scalars['Int'];
  name: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type RingSizeStandartCountOrderByAggregateInput = {
  categoryName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RingSizeStandartCreateInput = {
  categoryName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartCreateNestedManyWithoutRingSizeStandartInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  values?: InputMaybe<RingSizeCreateNestedManyWithoutRingSizeStandartInput>;
};

export type RingSizeStandartCreateManyInput = {
  categoryName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RingSizeStandartCreateNestedOneWithoutProductRingSizeStandartsInput = {
  connect?: InputMaybe<RingSizeStandartWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RingSizeStandartCreateOrConnectWithoutProductRingSizeStandartsInput>;
  create?: InputMaybe<RingSizeStandartCreateWithoutProductRingSizeStandartsInput>;
};

export type RingSizeStandartCreateNestedOneWithoutValuesInput = {
  connect?: InputMaybe<RingSizeStandartWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RingSizeStandartCreateOrConnectWithoutValuesInput>;
  create?: InputMaybe<RingSizeStandartCreateWithoutValuesInput>;
};

export type RingSizeStandartCreateOrConnectWithoutProductRingSizeStandartsInput = {
  create: RingSizeStandartCreateWithoutProductRingSizeStandartsInput;
  where: RingSizeStandartWhereUniqueInput;
};

export type RingSizeStandartCreateOrConnectWithoutValuesInput = {
  create: RingSizeStandartCreateWithoutValuesInput;
  where: RingSizeStandartWhereUniqueInput;
};

export type RingSizeStandartCreateWithoutProductRingSizeStandartsInput = {
  categoryName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  values?: InputMaybe<RingSizeCreateNestedManyWithoutRingSizeStandartInput>;
};

export type RingSizeStandartCreateWithoutValuesInput = {
  categoryName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartCreateNestedManyWithoutRingSizeStandartInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RingSizeStandartGroupBy = {
  __typename?: 'RingSizeStandartGroupBy';
  _count?: Maybe<RingSizeStandartCountAggregate>;
  _max?: Maybe<RingSizeStandartMaxAggregate>;
  _min?: Maybe<RingSizeStandartMinAggregate>;
  categoryName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type RingSizeStandartMaxAggregate = {
  __typename?: 'RingSizeStandartMaxAggregate';
  categoryName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RingSizeStandartMaxOrderByAggregateInput = {
  categoryName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RingSizeStandartMinAggregate = {
  __typename?: 'RingSizeStandartMinAggregate';
  categoryName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RingSizeStandartMinOrderByAggregateInput = {
  categoryName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RingSizeStandartOrderByWithAggregationInput = {
  _count?: InputMaybe<RingSizeStandartCountOrderByAggregateInput>;
  _max?: InputMaybe<RingSizeStandartMaxOrderByAggregateInput>;
  _min?: InputMaybe<RingSizeStandartMinOrderByAggregateInput>;
  categoryName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RingSizeStandartOrderByWithRelationInput = {
  categoryName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDefault?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  values?: InputMaybe<RingSizeOrderByRelationAggregateInput>;
};

export type RingSizeStandartRelationFilter = {
  is?: InputMaybe<RingSizeStandartWhereInput>;
  isNot?: InputMaybe<RingSizeStandartWhereInput>;
};

export enum RingSizeStandartScalarFieldEnum {
  categoryName = 'categoryName',
  createdAt = 'createdAt',
  id = 'id',
  isDefault = 'isDefault',
  name = 'name',
  updatedAt = 'updatedAt'
}

export type RingSizeStandartScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<RingSizeStandartScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<RingSizeStandartScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<RingSizeStandartScalarWhereWithAggregatesInput>>;
  categoryName?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  isDefault?: InputMaybe<BoolWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type RingSizeStandartUpdateInput = {
  categoryName?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDefault?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartUpdateManyWithoutRingSizeStandartNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  values?: InputMaybe<RingSizeUpdateManyWithoutRingSizeStandartNestedInput>;
};

export type RingSizeStandartUpdateManyMutationInput = {
  categoryName?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDefault?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RingSizeStandartUpdateOneRequiredWithoutProductRingSizeStandartsNestedInput = {
  connect?: InputMaybe<RingSizeStandartWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RingSizeStandartCreateOrConnectWithoutProductRingSizeStandartsInput>;
  create?: InputMaybe<RingSizeStandartCreateWithoutProductRingSizeStandartsInput>;
  update?: InputMaybe<RingSizeStandartUpdateWithoutProductRingSizeStandartsInput>;
  upsert?: InputMaybe<RingSizeStandartUpsertWithoutProductRingSizeStandartsInput>;
};

export type RingSizeStandartUpdateOneRequiredWithoutValuesNestedInput = {
  connect?: InputMaybe<RingSizeStandartWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RingSizeStandartCreateOrConnectWithoutValuesInput>;
  create?: InputMaybe<RingSizeStandartCreateWithoutValuesInput>;
  update?: InputMaybe<RingSizeStandartUpdateWithoutValuesInput>;
  upsert?: InputMaybe<RingSizeStandartUpsertWithoutValuesInput>;
};

export type RingSizeStandartUpdateWithoutProductRingSizeStandartsInput = {
  categoryName?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDefault?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  values?: InputMaybe<RingSizeUpdateManyWithoutRingSizeStandartNestedInput>;
};

export type RingSizeStandartUpdateWithoutValuesInput = {
  categoryName?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDefault?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartUpdateManyWithoutRingSizeStandartNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RingSizeStandartUpsertWithoutProductRingSizeStandartsInput = {
  create: RingSizeStandartCreateWithoutProductRingSizeStandartsInput;
  update: RingSizeStandartUpdateWithoutProductRingSizeStandartsInput;
};

export type RingSizeStandartUpsertWithoutValuesInput = {
  create: RingSizeStandartCreateWithoutValuesInput;
  update: RingSizeStandartUpdateWithoutValuesInput;
};

export type RingSizeStandartWhereInput = {
  AND?: InputMaybe<Array<RingSizeStandartWhereInput>>;
  NOT?: InputMaybe<Array<RingSizeStandartWhereInput>>;
  OR?: InputMaybe<Array<RingSizeStandartWhereInput>>;
  categoryName?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isDefault?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  productRingSizeStandarts?: InputMaybe<ProductRingSizeStandartListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  values?: InputMaybe<RingSizeListRelationFilter>;
};

export type RingSizeStandartWhereUniqueInput = {
  categoryName_name?: InputMaybe<RingSizeStandartCategoryNameNameCompoundUniqueInput>;
  id?: InputMaybe<Scalars['String']>;
};

export type RingSizeSumAggregate = {
  __typename?: 'RingSizeSumAggregate';
  circumferenceMM?: Maybe<Scalars['Decimal']>;
  diameterMM?: Maybe<Scalars['Decimal']>;
};

export type RingSizeSumOrderByAggregateInput = {
  circumferenceMM?: InputMaybe<SortOrder>;
  diameterMM?: InputMaybe<SortOrder>;
};

export type RingSizeUpdateInput = {
  basketItems?: InputMaybe<BasketItemUpdateManyWithoutRingSizeNestedInput>;
  circumferenceMM?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  diameterMM?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDefault?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ringSizeStandart?: InputMaybe<RingSizeStandartUpdateOneRequiredWithoutValuesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RingSizeUpdateManyMutationInput = {
  circumferenceMM?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  diameterMM?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDefault?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RingSizeUpdateManyWithWhereWithoutRingSizeStandartInput = {
  data: RingSizeUpdateManyMutationInput;
  where: RingSizeScalarWhereInput;
};

export type RingSizeUpdateManyWithoutRingSizeStandartNestedInput = {
  connect?: InputMaybe<Array<RingSizeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RingSizeCreateOrConnectWithoutRingSizeStandartInput>>;
  create?: InputMaybe<Array<RingSizeCreateWithoutRingSizeStandartInput>>;
  createMany?: InputMaybe<RingSizeCreateManyRingSizeStandartInputEnvelope>;
  delete?: InputMaybe<Array<RingSizeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RingSizeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RingSizeWhereUniqueInput>>;
  set?: InputMaybe<Array<RingSizeWhereUniqueInput>>;
  update?: InputMaybe<Array<RingSizeUpdateWithWhereUniqueWithoutRingSizeStandartInput>>;
  updateMany?: InputMaybe<Array<RingSizeUpdateManyWithWhereWithoutRingSizeStandartInput>>;
  upsert?: InputMaybe<Array<RingSizeUpsertWithWhereUniqueWithoutRingSizeStandartInput>>;
};

export type RingSizeUpdateOneWithoutBasketItemsNestedInput = {
  connect?: InputMaybe<RingSizeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RingSizeCreateOrConnectWithoutBasketItemsInput>;
  create?: InputMaybe<RingSizeCreateWithoutBasketItemsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<RingSizeUpdateWithoutBasketItemsInput>;
  upsert?: InputMaybe<RingSizeUpsertWithoutBasketItemsInput>;
};

export type RingSizeUpdateWithWhereUniqueWithoutRingSizeStandartInput = {
  data: RingSizeUpdateWithoutRingSizeStandartInput;
  where: RingSizeWhereUniqueInput;
};

export type RingSizeUpdateWithoutBasketItemsInput = {
  circumferenceMM?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  diameterMM?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDefault?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ringSizeStandart?: InputMaybe<RingSizeStandartUpdateOneRequiredWithoutValuesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RingSizeUpdateWithoutRingSizeStandartInput = {
  basketItems?: InputMaybe<BasketItemUpdateManyWithoutRingSizeNestedInput>;
  circumferenceMM?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  diameterMM?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDefault?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RingSizeUpsertWithWhereUniqueWithoutRingSizeStandartInput = {
  create: RingSizeCreateWithoutRingSizeStandartInput;
  update: RingSizeUpdateWithoutRingSizeStandartInput;
  where: RingSizeWhereUniqueInput;
};

export type RingSizeUpsertWithoutBasketItemsInput = {
  create: RingSizeCreateWithoutBasketItemsInput;
  update: RingSizeUpdateWithoutBasketItemsInput;
};

export type RingSizeWhereInput = {
  AND?: InputMaybe<Array<RingSizeWhereInput>>;
  NOT?: InputMaybe<Array<RingSizeWhereInput>>;
  OR?: InputMaybe<Array<RingSizeWhereInput>>;
  basketItems?: InputMaybe<BasketItemListRelationFilter>;
  circumferenceMM?: InputMaybe<DecimalFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  diameterMM?: InputMaybe<DecimalFilter>;
  id?: InputMaybe<StringFilter>;
  isDefault?: InputMaybe<BoolFilter>;
  ringSizeStandart?: InputMaybe<RingSizeStandartRelationFilter>;
  ringSizeStandartId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type RingSizeWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type RingStoneCarat = {
  __typename?: 'RingStoneCarat';
  _count?: Maybe<RingStoneCaratCount>;
  carat: Scalars['String'];
  caratInfos: Array<RingStoneCaratInfo>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  productRingStone: Array<ProductRingStone>;
  updatedAt: Scalars['DateTime'];
};


export type RingStoneCaratCaratInfosArgs = {
  cursor?: InputMaybe<RingStoneCaratInfoWhereUniqueInput>;
  distinct?: InputMaybe<Array<RingStoneCaratInfoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RingStoneCaratInfoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RingStoneCaratInfoWhereInput>;
};


export type RingStoneCaratProductRingStoneArgs = {
  cursor?: InputMaybe<ProductRingStoneWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductRingStoneScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductRingStoneOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductRingStoneWhereInput>;
};

export type RingStoneCaratCount = {
  __typename?: 'RingStoneCaratCount';
  caratInfos: Scalars['Int'];
  productRingStone: Scalars['Int'];
};

export type RingStoneCaratCountAggregate = {
  __typename?: 'RingStoneCaratCountAggregate';
  _all: Scalars['Int'];
  carat: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type RingStoneCaratCountOrderByAggregateInput = {
  carat?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RingStoneCaratCreateInput = {
  carat: Scalars['String'];
  caratInfos?: InputMaybe<RingStoneCaratInfoCreateNestedManyWithoutRingStoneCaratInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  productRingStone?: InputMaybe<ProductRingStoneCreateNestedManyWithoutCaratInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RingStoneCaratCreateManyInput = {
  carat: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RingStoneCaratCreateNestedOneWithoutCaratInfosInput = {
  connect?: InputMaybe<RingStoneCaratWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RingStoneCaratCreateOrConnectWithoutCaratInfosInput>;
  create?: InputMaybe<RingStoneCaratCreateWithoutCaratInfosInput>;
};

export type RingStoneCaratCreateNestedOneWithoutProductRingStoneInput = {
  connect?: InputMaybe<RingStoneCaratWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RingStoneCaratCreateOrConnectWithoutProductRingStoneInput>;
  create?: InputMaybe<RingStoneCaratCreateWithoutProductRingStoneInput>;
};

export type RingStoneCaratCreateOrConnectWithoutCaratInfosInput = {
  create: RingStoneCaratCreateWithoutCaratInfosInput;
  where: RingStoneCaratWhereUniqueInput;
};

export type RingStoneCaratCreateOrConnectWithoutProductRingStoneInput = {
  create: RingStoneCaratCreateWithoutProductRingStoneInput;
  where: RingStoneCaratWhereUniqueInput;
};

export type RingStoneCaratCreateWithoutCaratInfosInput = {
  carat: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  productRingStone?: InputMaybe<ProductRingStoneCreateNestedManyWithoutCaratInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RingStoneCaratCreateWithoutProductRingStoneInput = {
  carat: Scalars['String'];
  caratInfos?: InputMaybe<RingStoneCaratInfoCreateNestedManyWithoutRingStoneCaratInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RingStoneCaratGroupBy = {
  __typename?: 'RingStoneCaratGroupBy';
  _count?: Maybe<RingStoneCaratCountAggregate>;
  _max?: Maybe<RingStoneCaratMaxAggregate>;
  _min?: Maybe<RingStoneCaratMinAggregate>;
  carat: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type RingStoneCaratInfo = {
  __typename?: 'RingStoneCaratInfo';
  I1: Scalars['Decimal'];
  I2: Scalars['Decimal'];
  I3: Scalars['Decimal'];
  IF: Scalars['Decimal'];
  SI1: Scalars['Decimal'];
  SI2: Scalars['Decimal'];
  SI3: Scalars['Decimal'];
  VS1: Scalars['Decimal'];
  VS2: Scalars['Decimal'];
  VSS1: Scalars['Decimal'];
  VSS2: Scalars['Decimal'];
  _count?: Maybe<RingStoneCaratInfoCount>;
  color: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  productRingStone: Array<ProductRingStone>;
  ringStoneCarat: RingStoneCarat;
  ringStoneCaratId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type RingStoneCaratInfoProductRingStoneArgs = {
  cursor?: InputMaybe<ProductRingStoneWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductRingStoneScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductRingStoneOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductRingStoneWhereInput>;
};

export type RingStoneCaratInfoAvgAggregate = {
  __typename?: 'RingStoneCaratInfoAvgAggregate';
  I1?: Maybe<Scalars['Decimal']>;
  I2?: Maybe<Scalars['Decimal']>;
  I3?: Maybe<Scalars['Decimal']>;
  IF?: Maybe<Scalars['Decimal']>;
  SI1?: Maybe<Scalars['Decimal']>;
  SI2?: Maybe<Scalars['Decimal']>;
  SI3?: Maybe<Scalars['Decimal']>;
  VS1?: Maybe<Scalars['Decimal']>;
  VS2?: Maybe<Scalars['Decimal']>;
  VSS1?: Maybe<Scalars['Decimal']>;
  VSS2?: Maybe<Scalars['Decimal']>;
};

export type RingStoneCaratInfoAvgOrderByAggregateInput = {
  I1?: InputMaybe<SortOrder>;
  I2?: InputMaybe<SortOrder>;
  I3?: InputMaybe<SortOrder>;
  IF?: InputMaybe<SortOrder>;
  SI1?: InputMaybe<SortOrder>;
  SI2?: InputMaybe<SortOrder>;
  SI3?: InputMaybe<SortOrder>;
  VS1?: InputMaybe<SortOrder>;
  VS2?: InputMaybe<SortOrder>;
  VSS1?: InputMaybe<SortOrder>;
  VSS2?: InputMaybe<SortOrder>;
};

export type RingStoneCaratInfoCount = {
  __typename?: 'RingStoneCaratInfoCount';
  productRingStone: Scalars['Int'];
};

export type RingStoneCaratInfoCountAggregate = {
  __typename?: 'RingStoneCaratInfoCountAggregate';
  I1: Scalars['Int'];
  I2: Scalars['Int'];
  I3: Scalars['Int'];
  IF: Scalars['Int'];
  SI1: Scalars['Int'];
  SI2: Scalars['Int'];
  SI3: Scalars['Int'];
  VS1: Scalars['Int'];
  VS2: Scalars['Int'];
  VSS1: Scalars['Int'];
  VSS2: Scalars['Int'];
  _all: Scalars['Int'];
  color: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  ringStoneCaratId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type RingStoneCaratInfoCountOrderByAggregateInput = {
  I1?: InputMaybe<SortOrder>;
  I2?: InputMaybe<SortOrder>;
  I3?: InputMaybe<SortOrder>;
  IF?: InputMaybe<SortOrder>;
  SI1?: InputMaybe<SortOrder>;
  SI2?: InputMaybe<SortOrder>;
  SI3?: InputMaybe<SortOrder>;
  VS1?: InputMaybe<SortOrder>;
  VS2?: InputMaybe<SortOrder>;
  VSS1?: InputMaybe<SortOrder>;
  VSS2?: InputMaybe<SortOrder>;
  color?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ringStoneCaratId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RingStoneCaratInfoCreateInput = {
  I1: Scalars['Decimal'];
  I2: Scalars['Decimal'];
  I3: Scalars['Decimal'];
  IF: Scalars['Decimal'];
  SI1: Scalars['Decimal'];
  SI2: Scalars['Decimal'];
  SI3: Scalars['Decimal'];
  VS1: Scalars['Decimal'];
  VS2: Scalars['Decimal'];
  VSS1: Scalars['Decimal'];
  VSS2: Scalars['Decimal'];
  color: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  productRingStone?: InputMaybe<ProductRingStoneCreateNestedManyWithoutCaratInfoInput>;
  ringStoneCarat: RingStoneCaratCreateNestedOneWithoutCaratInfosInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RingStoneCaratInfoCreateManyInput = {
  I1: Scalars['Decimal'];
  I2: Scalars['Decimal'];
  I3: Scalars['Decimal'];
  IF: Scalars['Decimal'];
  SI1: Scalars['Decimal'];
  SI2: Scalars['Decimal'];
  SI3: Scalars['Decimal'];
  VS1: Scalars['Decimal'];
  VS2: Scalars['Decimal'];
  VSS1: Scalars['Decimal'];
  VSS2: Scalars['Decimal'];
  color: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  ringStoneCaratId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RingStoneCaratInfoCreateManyRingStoneCaratInput = {
  I1: Scalars['Decimal'];
  I2: Scalars['Decimal'];
  I3: Scalars['Decimal'];
  IF: Scalars['Decimal'];
  SI1: Scalars['Decimal'];
  SI2: Scalars['Decimal'];
  SI3: Scalars['Decimal'];
  VS1: Scalars['Decimal'];
  VS2: Scalars['Decimal'];
  VSS1: Scalars['Decimal'];
  VSS2: Scalars['Decimal'];
  color: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RingStoneCaratInfoCreateManyRingStoneCaratInputEnvelope = {
  data: Array<RingStoneCaratInfoCreateManyRingStoneCaratInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type RingStoneCaratInfoCreateNestedManyWithoutRingStoneCaratInput = {
  connect?: InputMaybe<Array<RingStoneCaratInfoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RingStoneCaratInfoCreateOrConnectWithoutRingStoneCaratInput>>;
  create?: InputMaybe<Array<RingStoneCaratInfoCreateWithoutRingStoneCaratInput>>;
  createMany?: InputMaybe<RingStoneCaratInfoCreateManyRingStoneCaratInputEnvelope>;
};

export type RingStoneCaratInfoCreateNestedOneWithoutProductRingStoneInput = {
  connect?: InputMaybe<RingStoneCaratInfoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RingStoneCaratInfoCreateOrConnectWithoutProductRingStoneInput>;
  create?: InputMaybe<RingStoneCaratInfoCreateWithoutProductRingStoneInput>;
};

export type RingStoneCaratInfoCreateOrConnectWithoutProductRingStoneInput = {
  create: RingStoneCaratInfoCreateWithoutProductRingStoneInput;
  where: RingStoneCaratInfoWhereUniqueInput;
};

export type RingStoneCaratInfoCreateOrConnectWithoutRingStoneCaratInput = {
  create: RingStoneCaratInfoCreateWithoutRingStoneCaratInput;
  where: RingStoneCaratInfoWhereUniqueInput;
};

export type RingStoneCaratInfoCreateWithoutProductRingStoneInput = {
  I1: Scalars['Decimal'];
  I2: Scalars['Decimal'];
  I3: Scalars['Decimal'];
  IF: Scalars['Decimal'];
  SI1: Scalars['Decimal'];
  SI2: Scalars['Decimal'];
  SI3: Scalars['Decimal'];
  VS1: Scalars['Decimal'];
  VS2: Scalars['Decimal'];
  VSS1: Scalars['Decimal'];
  VSS2: Scalars['Decimal'];
  color: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  ringStoneCarat: RingStoneCaratCreateNestedOneWithoutCaratInfosInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RingStoneCaratInfoCreateWithoutRingStoneCaratInput = {
  I1: Scalars['Decimal'];
  I2: Scalars['Decimal'];
  I3: Scalars['Decimal'];
  IF: Scalars['Decimal'];
  SI1: Scalars['Decimal'];
  SI2: Scalars['Decimal'];
  SI3: Scalars['Decimal'];
  VS1: Scalars['Decimal'];
  VS2: Scalars['Decimal'];
  VSS1: Scalars['Decimal'];
  VSS2: Scalars['Decimal'];
  color: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  productRingStone?: InputMaybe<ProductRingStoneCreateNestedManyWithoutCaratInfoInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RingStoneCaratInfoGroupBy = {
  __typename?: 'RingStoneCaratInfoGroupBy';
  I1: Scalars['Decimal'];
  I2: Scalars['Decimal'];
  I3: Scalars['Decimal'];
  IF: Scalars['Decimal'];
  SI1: Scalars['Decimal'];
  SI2: Scalars['Decimal'];
  SI3: Scalars['Decimal'];
  VS1: Scalars['Decimal'];
  VS2: Scalars['Decimal'];
  VSS1: Scalars['Decimal'];
  VSS2: Scalars['Decimal'];
  _avg?: Maybe<RingStoneCaratInfoAvgAggregate>;
  _count?: Maybe<RingStoneCaratInfoCountAggregate>;
  _max?: Maybe<RingStoneCaratInfoMaxAggregate>;
  _min?: Maybe<RingStoneCaratInfoMinAggregate>;
  _sum?: Maybe<RingStoneCaratInfoSumAggregate>;
  color: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  ringStoneCaratId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type RingStoneCaratInfoListRelationFilter = {
  every?: InputMaybe<RingStoneCaratInfoWhereInput>;
  none?: InputMaybe<RingStoneCaratInfoWhereInput>;
  some?: InputMaybe<RingStoneCaratInfoWhereInput>;
};

export type RingStoneCaratInfoMaxAggregate = {
  __typename?: 'RingStoneCaratInfoMaxAggregate';
  I1?: Maybe<Scalars['Decimal']>;
  I2?: Maybe<Scalars['Decimal']>;
  I3?: Maybe<Scalars['Decimal']>;
  IF?: Maybe<Scalars['Decimal']>;
  SI1?: Maybe<Scalars['Decimal']>;
  SI2?: Maybe<Scalars['Decimal']>;
  SI3?: Maybe<Scalars['Decimal']>;
  VS1?: Maybe<Scalars['Decimal']>;
  VS2?: Maybe<Scalars['Decimal']>;
  VSS1?: Maybe<Scalars['Decimal']>;
  VSS2?: Maybe<Scalars['Decimal']>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ringStoneCaratId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RingStoneCaratInfoMaxOrderByAggregateInput = {
  I1?: InputMaybe<SortOrder>;
  I2?: InputMaybe<SortOrder>;
  I3?: InputMaybe<SortOrder>;
  IF?: InputMaybe<SortOrder>;
  SI1?: InputMaybe<SortOrder>;
  SI2?: InputMaybe<SortOrder>;
  SI3?: InputMaybe<SortOrder>;
  VS1?: InputMaybe<SortOrder>;
  VS2?: InputMaybe<SortOrder>;
  VSS1?: InputMaybe<SortOrder>;
  VSS2?: InputMaybe<SortOrder>;
  color?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ringStoneCaratId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RingStoneCaratInfoMinAggregate = {
  __typename?: 'RingStoneCaratInfoMinAggregate';
  I1?: Maybe<Scalars['Decimal']>;
  I2?: Maybe<Scalars['Decimal']>;
  I3?: Maybe<Scalars['Decimal']>;
  IF?: Maybe<Scalars['Decimal']>;
  SI1?: Maybe<Scalars['Decimal']>;
  SI2?: Maybe<Scalars['Decimal']>;
  SI3?: Maybe<Scalars['Decimal']>;
  VS1?: Maybe<Scalars['Decimal']>;
  VS2?: Maybe<Scalars['Decimal']>;
  VSS1?: Maybe<Scalars['Decimal']>;
  VSS2?: Maybe<Scalars['Decimal']>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ringStoneCaratId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RingStoneCaratInfoMinOrderByAggregateInput = {
  I1?: InputMaybe<SortOrder>;
  I2?: InputMaybe<SortOrder>;
  I3?: InputMaybe<SortOrder>;
  IF?: InputMaybe<SortOrder>;
  SI1?: InputMaybe<SortOrder>;
  SI2?: InputMaybe<SortOrder>;
  SI3?: InputMaybe<SortOrder>;
  VS1?: InputMaybe<SortOrder>;
  VS2?: InputMaybe<SortOrder>;
  VSS1?: InputMaybe<SortOrder>;
  VSS2?: InputMaybe<SortOrder>;
  color?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ringStoneCaratId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RingStoneCaratInfoOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RingStoneCaratInfoOrderByWithAggregationInput = {
  I1?: InputMaybe<SortOrder>;
  I2?: InputMaybe<SortOrder>;
  I3?: InputMaybe<SortOrder>;
  IF?: InputMaybe<SortOrder>;
  SI1?: InputMaybe<SortOrder>;
  SI2?: InputMaybe<SortOrder>;
  SI3?: InputMaybe<SortOrder>;
  VS1?: InputMaybe<SortOrder>;
  VS2?: InputMaybe<SortOrder>;
  VSS1?: InputMaybe<SortOrder>;
  VSS2?: InputMaybe<SortOrder>;
  _avg?: InputMaybe<RingStoneCaratInfoAvgOrderByAggregateInput>;
  _count?: InputMaybe<RingStoneCaratInfoCountOrderByAggregateInput>;
  _max?: InputMaybe<RingStoneCaratInfoMaxOrderByAggregateInput>;
  _min?: InputMaybe<RingStoneCaratInfoMinOrderByAggregateInput>;
  _sum?: InputMaybe<RingStoneCaratInfoSumOrderByAggregateInput>;
  color?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ringStoneCaratId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RingStoneCaratInfoOrderByWithRelationInput = {
  I1?: InputMaybe<SortOrder>;
  I2?: InputMaybe<SortOrder>;
  I3?: InputMaybe<SortOrder>;
  IF?: InputMaybe<SortOrder>;
  SI1?: InputMaybe<SortOrder>;
  SI2?: InputMaybe<SortOrder>;
  SI3?: InputMaybe<SortOrder>;
  VS1?: InputMaybe<SortOrder>;
  VS2?: InputMaybe<SortOrder>;
  VSS1?: InputMaybe<SortOrder>;
  VSS2?: InputMaybe<SortOrder>;
  color?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  productRingStone?: InputMaybe<ProductRingStoneOrderByRelationAggregateInput>;
  ringStoneCarat?: InputMaybe<RingStoneCaratOrderByWithRelationInput>;
  ringStoneCaratId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RingStoneCaratInfoRelationFilter = {
  is?: InputMaybe<RingStoneCaratInfoWhereInput>;
  isNot?: InputMaybe<RingStoneCaratInfoWhereInput>;
};

export enum RingStoneCaratInfoScalarFieldEnum {
  I1 = 'I1',
  I2 = 'I2',
  I3 = 'I3',
  IF = 'IF',
  SI1 = 'SI1',
  SI2 = 'SI2',
  SI3 = 'SI3',
  VS1 = 'VS1',
  VS2 = 'VS2',
  VSS1 = 'VSS1',
  VSS2 = 'VSS2',
  color = 'color',
  createdAt = 'createdAt',
  id = 'id',
  ringStoneCaratId = 'ringStoneCaratId',
  updatedAt = 'updatedAt'
}

export type RingStoneCaratInfoScalarWhereInput = {
  AND?: InputMaybe<Array<RingStoneCaratInfoScalarWhereInput>>;
  I1?: InputMaybe<DecimalFilter>;
  I2?: InputMaybe<DecimalFilter>;
  I3?: InputMaybe<DecimalFilter>;
  IF?: InputMaybe<DecimalFilter>;
  NOT?: InputMaybe<Array<RingStoneCaratInfoScalarWhereInput>>;
  OR?: InputMaybe<Array<RingStoneCaratInfoScalarWhereInput>>;
  SI1?: InputMaybe<DecimalFilter>;
  SI2?: InputMaybe<DecimalFilter>;
  SI3?: InputMaybe<DecimalFilter>;
  VS1?: InputMaybe<DecimalFilter>;
  VS2?: InputMaybe<DecimalFilter>;
  VSS1?: InputMaybe<DecimalFilter>;
  VSS2?: InputMaybe<DecimalFilter>;
  color?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  ringStoneCaratId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RingStoneCaratInfoScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<RingStoneCaratInfoScalarWhereWithAggregatesInput>>;
  I1?: InputMaybe<DecimalWithAggregatesFilter>;
  I2?: InputMaybe<DecimalWithAggregatesFilter>;
  I3?: InputMaybe<DecimalWithAggregatesFilter>;
  IF?: InputMaybe<DecimalWithAggregatesFilter>;
  NOT?: InputMaybe<Array<RingStoneCaratInfoScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<RingStoneCaratInfoScalarWhereWithAggregatesInput>>;
  SI1?: InputMaybe<DecimalWithAggregatesFilter>;
  SI2?: InputMaybe<DecimalWithAggregatesFilter>;
  SI3?: InputMaybe<DecimalWithAggregatesFilter>;
  VS1?: InputMaybe<DecimalWithAggregatesFilter>;
  VS2?: InputMaybe<DecimalWithAggregatesFilter>;
  VSS1?: InputMaybe<DecimalWithAggregatesFilter>;
  VSS2?: InputMaybe<DecimalWithAggregatesFilter>;
  color?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  ringStoneCaratId?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type RingStoneCaratInfoSumAggregate = {
  __typename?: 'RingStoneCaratInfoSumAggregate';
  I1?: Maybe<Scalars['Decimal']>;
  I2?: Maybe<Scalars['Decimal']>;
  I3?: Maybe<Scalars['Decimal']>;
  IF?: Maybe<Scalars['Decimal']>;
  SI1?: Maybe<Scalars['Decimal']>;
  SI2?: Maybe<Scalars['Decimal']>;
  SI3?: Maybe<Scalars['Decimal']>;
  VS1?: Maybe<Scalars['Decimal']>;
  VS2?: Maybe<Scalars['Decimal']>;
  VSS1?: Maybe<Scalars['Decimal']>;
  VSS2?: Maybe<Scalars['Decimal']>;
};

export type RingStoneCaratInfoSumOrderByAggregateInput = {
  I1?: InputMaybe<SortOrder>;
  I2?: InputMaybe<SortOrder>;
  I3?: InputMaybe<SortOrder>;
  IF?: InputMaybe<SortOrder>;
  SI1?: InputMaybe<SortOrder>;
  SI2?: InputMaybe<SortOrder>;
  SI3?: InputMaybe<SortOrder>;
  VS1?: InputMaybe<SortOrder>;
  VS2?: InputMaybe<SortOrder>;
  VSS1?: InputMaybe<SortOrder>;
  VSS2?: InputMaybe<SortOrder>;
};

export type RingStoneCaratInfoUpdateInput = {
  I1?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  I2?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  I3?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  IF?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  SI1?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  SI2?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  SI3?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  VS1?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  VS2?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  VSS1?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  VSS2?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  color?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  productRingStone?: InputMaybe<ProductRingStoneUpdateManyWithoutCaratInfoNestedInput>;
  ringStoneCarat?: InputMaybe<RingStoneCaratUpdateOneRequiredWithoutCaratInfosNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RingStoneCaratInfoUpdateManyMutationInput = {
  I1?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  I2?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  I3?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  IF?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  SI1?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  SI2?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  SI3?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  VS1?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  VS2?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  VSS1?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  VSS2?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  color?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RingStoneCaratInfoUpdateManyWithWhereWithoutRingStoneCaratInput = {
  data: RingStoneCaratInfoUpdateManyMutationInput;
  where: RingStoneCaratInfoScalarWhereInput;
};

export type RingStoneCaratInfoUpdateManyWithoutRingStoneCaratNestedInput = {
  connect?: InputMaybe<Array<RingStoneCaratInfoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RingStoneCaratInfoCreateOrConnectWithoutRingStoneCaratInput>>;
  create?: InputMaybe<Array<RingStoneCaratInfoCreateWithoutRingStoneCaratInput>>;
  createMany?: InputMaybe<RingStoneCaratInfoCreateManyRingStoneCaratInputEnvelope>;
  delete?: InputMaybe<Array<RingStoneCaratInfoWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RingStoneCaratInfoScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RingStoneCaratInfoWhereUniqueInput>>;
  set?: InputMaybe<Array<RingStoneCaratInfoWhereUniqueInput>>;
  update?: InputMaybe<Array<RingStoneCaratInfoUpdateWithWhereUniqueWithoutRingStoneCaratInput>>;
  updateMany?: InputMaybe<Array<RingStoneCaratInfoUpdateManyWithWhereWithoutRingStoneCaratInput>>;
  upsert?: InputMaybe<Array<RingStoneCaratInfoUpsertWithWhereUniqueWithoutRingStoneCaratInput>>;
};

export type RingStoneCaratInfoUpdateOneRequiredWithoutProductRingStoneNestedInput = {
  connect?: InputMaybe<RingStoneCaratInfoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RingStoneCaratInfoCreateOrConnectWithoutProductRingStoneInput>;
  create?: InputMaybe<RingStoneCaratInfoCreateWithoutProductRingStoneInput>;
  update?: InputMaybe<RingStoneCaratInfoUpdateWithoutProductRingStoneInput>;
  upsert?: InputMaybe<RingStoneCaratInfoUpsertWithoutProductRingStoneInput>;
};

export type RingStoneCaratInfoUpdateWithWhereUniqueWithoutRingStoneCaratInput = {
  data: RingStoneCaratInfoUpdateWithoutRingStoneCaratInput;
  where: RingStoneCaratInfoWhereUniqueInput;
};

export type RingStoneCaratInfoUpdateWithoutProductRingStoneInput = {
  I1?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  I2?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  I3?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  IF?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  SI1?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  SI2?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  SI3?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  VS1?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  VS2?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  VSS1?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  VSS2?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  color?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ringStoneCarat?: InputMaybe<RingStoneCaratUpdateOneRequiredWithoutCaratInfosNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RingStoneCaratInfoUpdateWithoutRingStoneCaratInput = {
  I1?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  I2?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  I3?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  IF?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  SI1?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  SI2?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  SI3?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  VS1?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  VS2?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  VSS1?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  VSS2?: InputMaybe<DecimalFieldUpdateOperationsInput>;
  color?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  productRingStone?: InputMaybe<ProductRingStoneUpdateManyWithoutCaratInfoNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RingStoneCaratInfoUpsertWithWhereUniqueWithoutRingStoneCaratInput = {
  create: RingStoneCaratInfoCreateWithoutRingStoneCaratInput;
  update: RingStoneCaratInfoUpdateWithoutRingStoneCaratInput;
  where: RingStoneCaratInfoWhereUniqueInput;
};

export type RingStoneCaratInfoUpsertWithoutProductRingStoneInput = {
  create: RingStoneCaratInfoCreateWithoutProductRingStoneInput;
  update: RingStoneCaratInfoUpdateWithoutProductRingStoneInput;
};

export type RingStoneCaratInfoWhereInput = {
  AND?: InputMaybe<Array<RingStoneCaratInfoWhereInput>>;
  I1?: InputMaybe<DecimalFilter>;
  I2?: InputMaybe<DecimalFilter>;
  I3?: InputMaybe<DecimalFilter>;
  IF?: InputMaybe<DecimalFilter>;
  NOT?: InputMaybe<Array<RingStoneCaratInfoWhereInput>>;
  OR?: InputMaybe<Array<RingStoneCaratInfoWhereInput>>;
  SI1?: InputMaybe<DecimalFilter>;
  SI2?: InputMaybe<DecimalFilter>;
  SI3?: InputMaybe<DecimalFilter>;
  VS1?: InputMaybe<DecimalFilter>;
  VS2?: InputMaybe<DecimalFilter>;
  VSS1?: InputMaybe<DecimalFilter>;
  VSS2?: InputMaybe<DecimalFilter>;
  color?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  productRingStone?: InputMaybe<ProductRingStoneListRelationFilter>;
  ringStoneCarat?: InputMaybe<RingStoneCaratRelationFilter>;
  ringStoneCaratId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RingStoneCaratInfoWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type RingStoneCaratMaxAggregate = {
  __typename?: 'RingStoneCaratMaxAggregate';
  carat?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RingStoneCaratMaxOrderByAggregateInput = {
  carat?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RingStoneCaratMinAggregate = {
  __typename?: 'RingStoneCaratMinAggregate';
  carat?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RingStoneCaratMinOrderByAggregateInput = {
  carat?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RingStoneCaratOrderByWithAggregationInput = {
  _count?: InputMaybe<RingStoneCaratCountOrderByAggregateInput>;
  _max?: InputMaybe<RingStoneCaratMaxOrderByAggregateInput>;
  _min?: InputMaybe<RingStoneCaratMinOrderByAggregateInput>;
  carat?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RingStoneCaratOrderByWithRelationInput = {
  carat?: InputMaybe<SortOrder>;
  caratInfos?: InputMaybe<RingStoneCaratInfoOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  productRingStone?: InputMaybe<ProductRingStoneOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum RingStoneCaratQuality {
  I1 = 'I1',
  I2 = 'I2',
  I3 = 'I3',
  IF = 'IF',
  SI1 = 'SI1',
  SI2 = 'SI2',
  SI3 = 'SI3',
  VS1 = 'VS1',
  VS2 = 'VS2',
  VSS1 = 'VSS1',
  VSS2 = 'VSS2'
}

export type RingStoneCaratRelationFilter = {
  is?: InputMaybe<RingStoneCaratWhereInput>;
  isNot?: InputMaybe<RingStoneCaratWhereInput>;
};

export enum RingStoneCaratScalarFieldEnum {
  carat = 'carat',
  createdAt = 'createdAt',
  id = 'id',
  updatedAt = 'updatedAt'
}

export type RingStoneCaratScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<RingStoneCaratScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<RingStoneCaratScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<RingStoneCaratScalarWhereWithAggregatesInput>>;
  carat?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type RingStoneCaratUpdateInput = {
  carat?: InputMaybe<StringFieldUpdateOperationsInput>;
  caratInfos?: InputMaybe<RingStoneCaratInfoUpdateManyWithoutRingStoneCaratNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  productRingStone?: InputMaybe<ProductRingStoneUpdateManyWithoutCaratNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RingStoneCaratUpdateManyMutationInput = {
  carat?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RingStoneCaratUpdateOneRequiredWithoutCaratInfosNestedInput = {
  connect?: InputMaybe<RingStoneCaratWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RingStoneCaratCreateOrConnectWithoutCaratInfosInput>;
  create?: InputMaybe<RingStoneCaratCreateWithoutCaratInfosInput>;
  update?: InputMaybe<RingStoneCaratUpdateWithoutCaratInfosInput>;
  upsert?: InputMaybe<RingStoneCaratUpsertWithoutCaratInfosInput>;
};

export type RingStoneCaratUpdateOneRequiredWithoutProductRingStoneNestedInput = {
  connect?: InputMaybe<RingStoneCaratWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RingStoneCaratCreateOrConnectWithoutProductRingStoneInput>;
  create?: InputMaybe<RingStoneCaratCreateWithoutProductRingStoneInput>;
  update?: InputMaybe<RingStoneCaratUpdateWithoutProductRingStoneInput>;
  upsert?: InputMaybe<RingStoneCaratUpsertWithoutProductRingStoneInput>;
};

export type RingStoneCaratUpdateWithoutCaratInfosInput = {
  carat?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  productRingStone?: InputMaybe<ProductRingStoneUpdateManyWithoutCaratNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RingStoneCaratUpdateWithoutProductRingStoneInput = {
  carat?: InputMaybe<StringFieldUpdateOperationsInput>;
  caratInfos?: InputMaybe<RingStoneCaratInfoUpdateManyWithoutRingStoneCaratNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RingStoneCaratUpsertWithoutCaratInfosInput = {
  create: RingStoneCaratCreateWithoutCaratInfosInput;
  update: RingStoneCaratUpdateWithoutCaratInfosInput;
};

export type RingStoneCaratUpsertWithoutProductRingStoneInput = {
  create: RingStoneCaratCreateWithoutProductRingStoneInput;
  update: RingStoneCaratUpdateWithoutProductRingStoneInput;
};

export type RingStoneCaratWhereInput = {
  AND?: InputMaybe<Array<RingStoneCaratWhereInput>>;
  NOT?: InputMaybe<Array<RingStoneCaratWhereInput>>;
  OR?: InputMaybe<Array<RingStoneCaratWhereInput>>;
  carat?: InputMaybe<StringFilter>;
  caratInfos?: InputMaybe<RingStoneCaratInfoListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  productRingStone?: InputMaybe<ProductRingStoneListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RingStoneCaratWhereUniqueInput = {
  carat?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type RingStoneShape = {
  __typename?: 'RingStoneShape';
  _count?: Maybe<RingStoneShapeCount>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  productRingStone: Array<ProductRingStone>;
  shape: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type RingStoneShapeProductRingStoneArgs = {
  cursor?: InputMaybe<ProductRingStoneWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductRingStoneScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductRingStoneOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductRingStoneWhereInput>;
};

export type RingStoneShapeCount = {
  __typename?: 'RingStoneShapeCount';
  productRingStone: Scalars['Int'];
};

export type RingStoneShapeCountAggregate = {
  __typename?: 'RingStoneShapeCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  shape: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type RingStoneShapeCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  shape?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RingStoneShapeCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  productRingStone?: InputMaybe<ProductRingStoneCreateNestedManyWithoutShapeInput>;
  shape: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RingStoneShapeCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  shape: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RingStoneShapeCreateNestedOneWithoutProductRingStoneInput = {
  connect?: InputMaybe<RingStoneShapeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RingStoneShapeCreateOrConnectWithoutProductRingStoneInput>;
  create?: InputMaybe<RingStoneShapeCreateWithoutProductRingStoneInput>;
};

export type RingStoneShapeCreateOrConnectWithoutProductRingStoneInput = {
  create: RingStoneShapeCreateWithoutProductRingStoneInput;
  where: RingStoneShapeWhereUniqueInput;
};

export type RingStoneShapeCreateWithoutProductRingStoneInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  shape: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RingStoneShapeGroupBy = {
  __typename?: 'RingStoneShapeGroupBy';
  _count?: Maybe<RingStoneShapeCountAggregate>;
  _max?: Maybe<RingStoneShapeMaxAggregate>;
  _min?: Maybe<RingStoneShapeMinAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  shape: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type RingStoneShapeMaxAggregate = {
  __typename?: 'RingStoneShapeMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  shape?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RingStoneShapeMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  shape?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RingStoneShapeMinAggregate = {
  __typename?: 'RingStoneShapeMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  shape?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RingStoneShapeMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  shape?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RingStoneShapeOrderByWithAggregationInput = {
  _count?: InputMaybe<RingStoneShapeCountOrderByAggregateInput>;
  _max?: InputMaybe<RingStoneShapeMaxOrderByAggregateInput>;
  _min?: InputMaybe<RingStoneShapeMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  shape?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RingStoneShapeOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  productRingStone?: InputMaybe<ProductRingStoneOrderByRelationAggregateInput>;
  shape?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RingStoneShapeRelationFilter = {
  is?: InputMaybe<RingStoneShapeWhereInput>;
  isNot?: InputMaybe<RingStoneShapeWhereInput>;
};

export enum RingStoneShapeScalarFieldEnum {
  createdAt = 'createdAt',
  id = 'id',
  shape = 'shape',
  updatedAt = 'updatedAt'
}

export type RingStoneShapeScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<RingStoneShapeScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<RingStoneShapeScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<RingStoneShapeScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  shape?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type RingStoneShapeUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  productRingStone?: InputMaybe<ProductRingStoneUpdateManyWithoutShapeNestedInput>;
  shape?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RingStoneShapeUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  shape?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RingStoneShapeUpdateOneWithoutProductRingStoneNestedInput = {
  connect?: InputMaybe<RingStoneShapeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RingStoneShapeCreateOrConnectWithoutProductRingStoneInput>;
  create?: InputMaybe<RingStoneShapeCreateWithoutProductRingStoneInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<RingStoneShapeUpdateWithoutProductRingStoneInput>;
  upsert?: InputMaybe<RingStoneShapeUpsertWithoutProductRingStoneInput>;
};

export type RingStoneShapeUpdateWithoutProductRingStoneInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  shape?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RingStoneShapeUpsertWithoutProductRingStoneInput = {
  create: RingStoneShapeCreateWithoutProductRingStoneInput;
  update: RingStoneShapeUpdateWithoutProductRingStoneInput;
};

export type RingStoneShapeWhereInput = {
  AND?: InputMaybe<Array<RingStoneShapeWhereInput>>;
  NOT?: InputMaybe<Array<RingStoneShapeWhereInput>>;
  OR?: InputMaybe<Array<RingStoneShapeWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  productRingStone?: InputMaybe<ProductRingStoneListRelationFilter>;
  shape?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RingStoneShapeWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Seo = {
  __typename?: 'Seo';
  Blog?: Maybe<Blog>;
  BlogCategory?: Maybe<BlogCategory>;
  Category?: Maybe<Category>;
  Page?: Maybe<Page>;
  Product?: Maybe<Product>;
  Style?: Maybe<Style>;
  blogCategoryId?: Maybe<Scalars['String']>;
  blogId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  keywords?: Maybe<Scalars['String']>;
  language: LanguageOptions;
  pageId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  styleId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SeoCountAggregate = {
  __typename?: 'SeoCountAggregate';
  _all: Scalars['Int'];
  blogCategoryId: Scalars['Int'];
  blogId: Scalars['Int'];
  categoryId: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  keywords: Scalars['Int'];
  language: Scalars['Int'];
  pageId: Scalars['Int'];
  productId: Scalars['Int'];
  styleId: Scalars['Int'];
  title: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type SeoCountOrderByAggregateInput = {
  blogCategoryId?: InputMaybe<SortOrder>;
  blogId?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  keywords?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  pageId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  styleId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SeoCreateInput = {
  Blog?: InputMaybe<BlogCreateNestedOneWithoutSeosInput>;
  BlogCategory?: InputMaybe<BlogCategoryCreateNestedOneWithoutSeosInput>;
  Category?: InputMaybe<CategoryCreateNestedOneWithoutSeosInput>;
  Page?: InputMaybe<PageCreateNestedOneWithoutSeosInput>;
  Product?: InputMaybe<ProductCreateNestedOneWithoutSeosInput>;
  Style?: InputMaybe<StyleCreateNestedOneWithoutSeosInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SeoCreateManyBlogCategoryInput = {
  blogId?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  pageId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  styleId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SeoCreateManyBlogCategoryInputEnvelope = {
  data: Array<SeoCreateManyBlogCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SeoCreateManyBlogInput = {
  blogCategoryId?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  pageId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  styleId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SeoCreateManyBlogInputEnvelope = {
  data: Array<SeoCreateManyBlogInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SeoCreateManyCategoryInput = {
  blogCategoryId?: InputMaybe<Scalars['String']>;
  blogId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  pageId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  styleId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SeoCreateManyCategoryInputEnvelope = {
  data: Array<SeoCreateManyCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SeoCreateManyInput = {
  blogCategoryId?: InputMaybe<Scalars['String']>;
  blogId?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  pageId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  styleId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SeoCreateManyPageInput = {
  blogCategoryId?: InputMaybe<Scalars['String']>;
  blogId?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  productId?: InputMaybe<Scalars['String']>;
  styleId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SeoCreateManyPageInputEnvelope = {
  data: Array<SeoCreateManyPageInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SeoCreateManyProductInput = {
  blogCategoryId?: InputMaybe<Scalars['String']>;
  blogId?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  pageId?: InputMaybe<Scalars['String']>;
  styleId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SeoCreateManyProductInputEnvelope = {
  data: Array<SeoCreateManyProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SeoCreateManyStyleInput = {
  blogCategoryId?: InputMaybe<Scalars['String']>;
  blogId?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  pageId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SeoCreateManyStyleInputEnvelope = {
  data: Array<SeoCreateManyStyleInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SeoCreateNestedManyWithoutBlogCategoryInput = {
  connect?: InputMaybe<Array<SeoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SeoCreateOrConnectWithoutBlogCategoryInput>>;
  create?: InputMaybe<Array<SeoCreateWithoutBlogCategoryInput>>;
  createMany?: InputMaybe<SeoCreateManyBlogCategoryInputEnvelope>;
};

export type SeoCreateNestedManyWithoutBlogInput = {
  connect?: InputMaybe<Array<SeoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SeoCreateOrConnectWithoutBlogInput>>;
  create?: InputMaybe<Array<SeoCreateWithoutBlogInput>>;
  createMany?: InputMaybe<SeoCreateManyBlogInputEnvelope>;
};

export type SeoCreateNestedManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<SeoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SeoCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<SeoCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<SeoCreateManyCategoryInputEnvelope>;
};

export type SeoCreateNestedManyWithoutPageInput = {
  connect?: InputMaybe<Array<SeoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SeoCreateOrConnectWithoutPageInput>>;
  create?: InputMaybe<Array<SeoCreateWithoutPageInput>>;
  createMany?: InputMaybe<SeoCreateManyPageInputEnvelope>;
};

export type SeoCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<SeoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SeoCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<SeoCreateWithoutProductInput>>;
  createMany?: InputMaybe<SeoCreateManyProductInputEnvelope>;
};

export type SeoCreateNestedManyWithoutStyleInput = {
  connect?: InputMaybe<Array<SeoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SeoCreateOrConnectWithoutStyleInput>>;
  create?: InputMaybe<Array<SeoCreateWithoutStyleInput>>;
  createMany?: InputMaybe<SeoCreateManyStyleInputEnvelope>;
};

export type SeoCreateOrConnectWithoutBlogCategoryInput = {
  create: SeoCreateWithoutBlogCategoryInput;
  where: SeoWhereUniqueInput;
};

export type SeoCreateOrConnectWithoutBlogInput = {
  create: SeoCreateWithoutBlogInput;
  where: SeoWhereUniqueInput;
};

export type SeoCreateOrConnectWithoutCategoryInput = {
  create: SeoCreateWithoutCategoryInput;
  where: SeoWhereUniqueInput;
};

export type SeoCreateOrConnectWithoutPageInput = {
  create: SeoCreateWithoutPageInput;
  where: SeoWhereUniqueInput;
};

export type SeoCreateOrConnectWithoutProductInput = {
  create: SeoCreateWithoutProductInput;
  where: SeoWhereUniqueInput;
};

export type SeoCreateOrConnectWithoutStyleInput = {
  create: SeoCreateWithoutStyleInput;
  where: SeoWhereUniqueInput;
};

export type SeoCreateWithoutBlogCategoryInput = {
  Blog?: InputMaybe<BlogCreateNestedOneWithoutSeosInput>;
  Category?: InputMaybe<CategoryCreateNestedOneWithoutSeosInput>;
  Page?: InputMaybe<PageCreateNestedOneWithoutSeosInput>;
  Product?: InputMaybe<ProductCreateNestedOneWithoutSeosInput>;
  Style?: InputMaybe<StyleCreateNestedOneWithoutSeosInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SeoCreateWithoutBlogInput = {
  BlogCategory?: InputMaybe<BlogCategoryCreateNestedOneWithoutSeosInput>;
  Category?: InputMaybe<CategoryCreateNestedOneWithoutSeosInput>;
  Page?: InputMaybe<PageCreateNestedOneWithoutSeosInput>;
  Product?: InputMaybe<ProductCreateNestedOneWithoutSeosInput>;
  Style?: InputMaybe<StyleCreateNestedOneWithoutSeosInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SeoCreateWithoutCategoryInput = {
  Blog?: InputMaybe<BlogCreateNestedOneWithoutSeosInput>;
  BlogCategory?: InputMaybe<BlogCategoryCreateNestedOneWithoutSeosInput>;
  Page?: InputMaybe<PageCreateNestedOneWithoutSeosInput>;
  Product?: InputMaybe<ProductCreateNestedOneWithoutSeosInput>;
  Style?: InputMaybe<StyleCreateNestedOneWithoutSeosInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SeoCreateWithoutPageInput = {
  Blog?: InputMaybe<BlogCreateNestedOneWithoutSeosInput>;
  BlogCategory?: InputMaybe<BlogCategoryCreateNestedOneWithoutSeosInput>;
  Category?: InputMaybe<CategoryCreateNestedOneWithoutSeosInput>;
  Product?: InputMaybe<ProductCreateNestedOneWithoutSeosInput>;
  Style?: InputMaybe<StyleCreateNestedOneWithoutSeosInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SeoCreateWithoutProductInput = {
  Blog?: InputMaybe<BlogCreateNestedOneWithoutSeosInput>;
  BlogCategory?: InputMaybe<BlogCategoryCreateNestedOneWithoutSeosInput>;
  Category?: InputMaybe<CategoryCreateNestedOneWithoutSeosInput>;
  Page?: InputMaybe<PageCreateNestedOneWithoutSeosInput>;
  Style?: InputMaybe<StyleCreateNestedOneWithoutSeosInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SeoCreateWithoutStyleInput = {
  Blog?: InputMaybe<BlogCreateNestedOneWithoutSeosInput>;
  BlogCategory?: InputMaybe<BlogCategoryCreateNestedOneWithoutSeosInput>;
  Category?: InputMaybe<CategoryCreateNestedOneWithoutSeosInput>;
  Page?: InputMaybe<PageCreateNestedOneWithoutSeosInput>;
  Product?: InputMaybe<ProductCreateNestedOneWithoutSeosInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageOptions>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SeoGroupBy = {
  __typename?: 'SeoGroupBy';
  _count?: Maybe<SeoCountAggregate>;
  _max?: Maybe<SeoMaxAggregate>;
  _min?: Maybe<SeoMinAggregate>;
  blogCategoryId?: Maybe<Scalars['String']>;
  blogId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  keywords?: Maybe<Scalars['String']>;
  language: LanguageOptions;
  pageId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  styleId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SeoListRelationFilter = {
  every?: InputMaybe<SeoWhereInput>;
  none?: InputMaybe<SeoWhereInput>;
  some?: InputMaybe<SeoWhereInput>;
};

export type SeoMaxAggregate = {
  __typename?: 'SeoMaxAggregate';
  blogCategoryId?: Maybe<Scalars['String']>;
  blogId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  language?: Maybe<LanguageOptions>;
  pageId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  styleId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SeoMaxOrderByAggregateInput = {
  blogCategoryId?: InputMaybe<SortOrder>;
  blogId?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  keywords?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  pageId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  styleId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SeoMinAggregate = {
  __typename?: 'SeoMinAggregate';
  blogCategoryId?: Maybe<Scalars['String']>;
  blogId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  language?: Maybe<LanguageOptions>;
  pageId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  styleId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SeoMinOrderByAggregateInput = {
  blogCategoryId?: InputMaybe<SortOrder>;
  blogId?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  keywords?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  pageId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  styleId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SeoOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SeoOrderByWithAggregationInput = {
  _count?: InputMaybe<SeoCountOrderByAggregateInput>;
  _max?: InputMaybe<SeoMaxOrderByAggregateInput>;
  _min?: InputMaybe<SeoMinOrderByAggregateInput>;
  blogCategoryId?: InputMaybe<SortOrder>;
  blogId?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  keywords?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  pageId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  styleId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SeoOrderByWithRelationInput = {
  Blog?: InputMaybe<BlogOrderByWithRelationInput>;
  BlogCategory?: InputMaybe<BlogCategoryOrderByWithRelationInput>;
  Category?: InputMaybe<CategoryOrderByWithRelationInput>;
  Page?: InputMaybe<PageOrderByWithRelationInput>;
  Product?: InputMaybe<ProductOrderByWithRelationInput>;
  Style?: InputMaybe<StyleOrderByWithRelationInput>;
  blogCategoryId?: InputMaybe<SortOrder>;
  blogId?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  keywords?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  pageId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  styleId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum SeoScalarFieldEnum {
  blogCategoryId = 'blogCategoryId',
  blogId = 'blogId',
  categoryId = 'categoryId',
  createdAt = 'createdAt',
  description = 'description',
  id = 'id',
  keywords = 'keywords',
  language = 'language',
  pageId = 'pageId',
  productId = 'productId',
  styleId = 'styleId',
  title = 'title',
  updatedAt = 'updatedAt'
}

export type SeoScalarWhereInput = {
  AND?: InputMaybe<Array<SeoScalarWhereInput>>;
  NOT?: InputMaybe<Array<SeoScalarWhereInput>>;
  OR?: InputMaybe<Array<SeoScalarWhereInput>>;
  blogCategoryId?: InputMaybe<StringNullableFilter>;
  blogId?: InputMaybe<StringNullableFilter>;
  categoryId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  keywords?: InputMaybe<StringNullableFilter>;
  language?: InputMaybe<EnumLanguageOptionsFilter>;
  pageId?: InputMaybe<StringNullableFilter>;
  productId?: InputMaybe<StringNullableFilter>;
  styleId?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SeoScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<SeoScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<SeoScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<SeoScalarWhereWithAggregatesInput>>;
  blogCategoryId?: InputMaybe<StringNullableWithAggregatesFilter>;
  blogId?: InputMaybe<StringNullableWithAggregatesFilter>;
  categoryId?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  keywords?: InputMaybe<StringNullableWithAggregatesFilter>;
  language?: InputMaybe<EnumLanguageOptionsWithAggregatesFilter>;
  pageId?: InputMaybe<StringNullableWithAggregatesFilter>;
  productId?: InputMaybe<StringNullableWithAggregatesFilter>;
  styleId?: InputMaybe<StringNullableWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type SeoUpdateInput = {
  Blog?: InputMaybe<BlogUpdateOneWithoutSeosNestedInput>;
  BlogCategory?: InputMaybe<BlogCategoryUpdateOneWithoutSeosNestedInput>;
  Category?: InputMaybe<CategoryUpdateOneWithoutSeosNestedInput>;
  Page?: InputMaybe<PageUpdateOneWithoutSeosNestedInput>;
  Product?: InputMaybe<ProductUpdateOneWithoutSeosNestedInput>;
  Style?: InputMaybe<StyleUpdateOneWithoutSeosNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  keywords?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SeoUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  keywords?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SeoUpdateManyWithWhereWithoutBlogCategoryInput = {
  data: SeoUpdateManyMutationInput;
  where: SeoScalarWhereInput;
};

export type SeoUpdateManyWithWhereWithoutBlogInput = {
  data: SeoUpdateManyMutationInput;
  where: SeoScalarWhereInput;
};

export type SeoUpdateManyWithWhereWithoutCategoryInput = {
  data: SeoUpdateManyMutationInput;
  where: SeoScalarWhereInput;
};

export type SeoUpdateManyWithWhereWithoutPageInput = {
  data: SeoUpdateManyMutationInput;
  where: SeoScalarWhereInput;
};

export type SeoUpdateManyWithWhereWithoutProductInput = {
  data: SeoUpdateManyMutationInput;
  where: SeoScalarWhereInput;
};

export type SeoUpdateManyWithWhereWithoutStyleInput = {
  data: SeoUpdateManyMutationInput;
  where: SeoScalarWhereInput;
};

export type SeoUpdateManyWithoutBlogCategoryNestedInput = {
  connect?: InputMaybe<Array<SeoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SeoCreateOrConnectWithoutBlogCategoryInput>>;
  create?: InputMaybe<Array<SeoCreateWithoutBlogCategoryInput>>;
  createMany?: InputMaybe<SeoCreateManyBlogCategoryInputEnvelope>;
  delete?: InputMaybe<Array<SeoWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SeoScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SeoWhereUniqueInput>>;
  set?: InputMaybe<Array<SeoWhereUniqueInput>>;
  update?: InputMaybe<Array<SeoUpdateWithWhereUniqueWithoutBlogCategoryInput>>;
  updateMany?: InputMaybe<Array<SeoUpdateManyWithWhereWithoutBlogCategoryInput>>;
  upsert?: InputMaybe<Array<SeoUpsertWithWhereUniqueWithoutBlogCategoryInput>>;
};

export type SeoUpdateManyWithoutBlogNestedInput = {
  connect?: InputMaybe<Array<SeoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SeoCreateOrConnectWithoutBlogInput>>;
  create?: InputMaybe<Array<SeoCreateWithoutBlogInput>>;
  createMany?: InputMaybe<SeoCreateManyBlogInputEnvelope>;
  delete?: InputMaybe<Array<SeoWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SeoScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SeoWhereUniqueInput>>;
  set?: InputMaybe<Array<SeoWhereUniqueInput>>;
  update?: InputMaybe<Array<SeoUpdateWithWhereUniqueWithoutBlogInput>>;
  updateMany?: InputMaybe<Array<SeoUpdateManyWithWhereWithoutBlogInput>>;
  upsert?: InputMaybe<Array<SeoUpsertWithWhereUniqueWithoutBlogInput>>;
};

export type SeoUpdateManyWithoutCategoryNestedInput = {
  connect?: InputMaybe<Array<SeoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SeoCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<SeoCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<SeoCreateManyCategoryInputEnvelope>;
  delete?: InputMaybe<Array<SeoWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SeoScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SeoWhereUniqueInput>>;
  set?: InputMaybe<Array<SeoWhereUniqueInput>>;
  update?: InputMaybe<Array<SeoUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: InputMaybe<Array<SeoUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: InputMaybe<Array<SeoUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type SeoUpdateManyWithoutPageNestedInput = {
  connect?: InputMaybe<Array<SeoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SeoCreateOrConnectWithoutPageInput>>;
  create?: InputMaybe<Array<SeoCreateWithoutPageInput>>;
  createMany?: InputMaybe<SeoCreateManyPageInputEnvelope>;
  delete?: InputMaybe<Array<SeoWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SeoScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SeoWhereUniqueInput>>;
  set?: InputMaybe<Array<SeoWhereUniqueInput>>;
  update?: InputMaybe<Array<SeoUpdateWithWhereUniqueWithoutPageInput>>;
  updateMany?: InputMaybe<Array<SeoUpdateManyWithWhereWithoutPageInput>>;
  upsert?: InputMaybe<Array<SeoUpsertWithWhereUniqueWithoutPageInput>>;
};

export type SeoUpdateManyWithoutProductNestedInput = {
  connect?: InputMaybe<Array<SeoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SeoCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<SeoCreateWithoutProductInput>>;
  createMany?: InputMaybe<SeoCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<SeoWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SeoScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SeoWhereUniqueInput>>;
  set?: InputMaybe<Array<SeoWhereUniqueInput>>;
  update?: InputMaybe<Array<SeoUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<SeoUpdateManyWithWhereWithoutProductInput>>;
  upsert?: InputMaybe<Array<SeoUpsertWithWhereUniqueWithoutProductInput>>;
};

export type SeoUpdateManyWithoutStyleNestedInput = {
  connect?: InputMaybe<Array<SeoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SeoCreateOrConnectWithoutStyleInput>>;
  create?: InputMaybe<Array<SeoCreateWithoutStyleInput>>;
  createMany?: InputMaybe<SeoCreateManyStyleInputEnvelope>;
  delete?: InputMaybe<Array<SeoWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SeoScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SeoWhereUniqueInput>>;
  set?: InputMaybe<Array<SeoWhereUniqueInput>>;
  update?: InputMaybe<Array<SeoUpdateWithWhereUniqueWithoutStyleInput>>;
  updateMany?: InputMaybe<Array<SeoUpdateManyWithWhereWithoutStyleInput>>;
  upsert?: InputMaybe<Array<SeoUpsertWithWhereUniqueWithoutStyleInput>>;
};

export type SeoUpdateWithWhereUniqueWithoutBlogCategoryInput = {
  data: SeoUpdateWithoutBlogCategoryInput;
  where: SeoWhereUniqueInput;
};

export type SeoUpdateWithWhereUniqueWithoutBlogInput = {
  data: SeoUpdateWithoutBlogInput;
  where: SeoWhereUniqueInput;
};

export type SeoUpdateWithWhereUniqueWithoutCategoryInput = {
  data: SeoUpdateWithoutCategoryInput;
  where: SeoWhereUniqueInput;
};

export type SeoUpdateWithWhereUniqueWithoutPageInput = {
  data: SeoUpdateWithoutPageInput;
  where: SeoWhereUniqueInput;
};

export type SeoUpdateWithWhereUniqueWithoutProductInput = {
  data: SeoUpdateWithoutProductInput;
  where: SeoWhereUniqueInput;
};

export type SeoUpdateWithWhereUniqueWithoutStyleInput = {
  data: SeoUpdateWithoutStyleInput;
  where: SeoWhereUniqueInput;
};

export type SeoUpdateWithoutBlogCategoryInput = {
  Blog?: InputMaybe<BlogUpdateOneWithoutSeosNestedInput>;
  Category?: InputMaybe<CategoryUpdateOneWithoutSeosNestedInput>;
  Page?: InputMaybe<PageUpdateOneWithoutSeosNestedInput>;
  Product?: InputMaybe<ProductUpdateOneWithoutSeosNestedInput>;
  Style?: InputMaybe<StyleUpdateOneWithoutSeosNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  keywords?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SeoUpdateWithoutBlogInput = {
  BlogCategory?: InputMaybe<BlogCategoryUpdateOneWithoutSeosNestedInput>;
  Category?: InputMaybe<CategoryUpdateOneWithoutSeosNestedInput>;
  Page?: InputMaybe<PageUpdateOneWithoutSeosNestedInput>;
  Product?: InputMaybe<ProductUpdateOneWithoutSeosNestedInput>;
  Style?: InputMaybe<StyleUpdateOneWithoutSeosNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  keywords?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SeoUpdateWithoutCategoryInput = {
  Blog?: InputMaybe<BlogUpdateOneWithoutSeosNestedInput>;
  BlogCategory?: InputMaybe<BlogCategoryUpdateOneWithoutSeosNestedInput>;
  Page?: InputMaybe<PageUpdateOneWithoutSeosNestedInput>;
  Product?: InputMaybe<ProductUpdateOneWithoutSeosNestedInput>;
  Style?: InputMaybe<StyleUpdateOneWithoutSeosNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  keywords?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SeoUpdateWithoutPageInput = {
  Blog?: InputMaybe<BlogUpdateOneWithoutSeosNestedInput>;
  BlogCategory?: InputMaybe<BlogCategoryUpdateOneWithoutSeosNestedInput>;
  Category?: InputMaybe<CategoryUpdateOneWithoutSeosNestedInput>;
  Product?: InputMaybe<ProductUpdateOneWithoutSeosNestedInput>;
  Style?: InputMaybe<StyleUpdateOneWithoutSeosNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  keywords?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SeoUpdateWithoutProductInput = {
  Blog?: InputMaybe<BlogUpdateOneWithoutSeosNestedInput>;
  BlogCategory?: InputMaybe<BlogCategoryUpdateOneWithoutSeosNestedInput>;
  Category?: InputMaybe<CategoryUpdateOneWithoutSeosNestedInput>;
  Page?: InputMaybe<PageUpdateOneWithoutSeosNestedInput>;
  Style?: InputMaybe<StyleUpdateOneWithoutSeosNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  keywords?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SeoUpdateWithoutStyleInput = {
  Blog?: InputMaybe<BlogUpdateOneWithoutSeosNestedInput>;
  BlogCategory?: InputMaybe<BlogCategoryUpdateOneWithoutSeosNestedInput>;
  Category?: InputMaybe<CategoryUpdateOneWithoutSeosNestedInput>;
  Page?: InputMaybe<PageUpdateOneWithoutSeosNestedInput>;
  Product?: InputMaybe<ProductUpdateOneWithoutSeosNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  keywords?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  language?: InputMaybe<EnumLanguageOptionsFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SeoUpsertWithWhereUniqueWithoutBlogCategoryInput = {
  create: SeoCreateWithoutBlogCategoryInput;
  update: SeoUpdateWithoutBlogCategoryInput;
  where: SeoWhereUniqueInput;
};

export type SeoUpsertWithWhereUniqueWithoutBlogInput = {
  create: SeoCreateWithoutBlogInput;
  update: SeoUpdateWithoutBlogInput;
  where: SeoWhereUniqueInput;
};

export type SeoUpsertWithWhereUniqueWithoutCategoryInput = {
  create: SeoCreateWithoutCategoryInput;
  update: SeoUpdateWithoutCategoryInput;
  where: SeoWhereUniqueInput;
};

export type SeoUpsertWithWhereUniqueWithoutPageInput = {
  create: SeoCreateWithoutPageInput;
  update: SeoUpdateWithoutPageInput;
  where: SeoWhereUniqueInput;
};

export type SeoUpsertWithWhereUniqueWithoutProductInput = {
  create: SeoCreateWithoutProductInput;
  update: SeoUpdateWithoutProductInput;
  where: SeoWhereUniqueInput;
};

export type SeoUpsertWithWhereUniqueWithoutStyleInput = {
  create: SeoCreateWithoutStyleInput;
  update: SeoUpdateWithoutStyleInput;
  where: SeoWhereUniqueInput;
};

export type SeoWhereInput = {
  AND?: InputMaybe<Array<SeoWhereInput>>;
  Blog?: InputMaybe<BlogRelationFilter>;
  BlogCategory?: InputMaybe<BlogCategoryRelationFilter>;
  Category?: InputMaybe<CategoryRelationFilter>;
  NOT?: InputMaybe<Array<SeoWhereInput>>;
  OR?: InputMaybe<Array<SeoWhereInput>>;
  Page?: InputMaybe<PageRelationFilter>;
  Product?: InputMaybe<ProductRelationFilter>;
  Style?: InputMaybe<StyleRelationFilter>;
  blogCategoryId?: InputMaybe<StringNullableFilter>;
  blogId?: InputMaybe<StringNullableFilter>;
  categoryId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  keywords?: InputMaybe<StringNullableFilter>;
  language?: InputMaybe<EnumLanguageOptionsFilter>;
  pageId?: InputMaybe<StringNullableFilter>;
  productId?: InputMaybe<StringNullableFilter>;
  styleId?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SeoWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type SetGuestBasketForNewUserInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  basketId: Scalars['ID'];
  price: Scalars['Float'];
  productId: Scalars['ID'];
  quantity?: InputMaybe<Scalars['Int']>;
  ringColorId?: InputMaybe<Scalars['String']>;
  ringColorPurityId?: InputMaybe<Scalars['String']>;
  ringSizeId?: InputMaybe<Scalars['String']>;
  ringText?: InputMaybe<Scalars['String']>;
  selectedCreatableIds: Array<Scalars['ID']>;
  stoneGroupId?: InputMaybe<Scalars['String']>;
};

export type Slider = {
  __typename?: 'Slider';
  _count?: Maybe<SliderCount>;
  createdAt: Scalars['DateTime'];
  descriptions: Array<Description>;
  id: Scalars['String'];
  photo: Scalars['String'];
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['String']>;
};


export type SliderDescriptionsArgs = {
  cursor?: InputMaybe<DescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<DescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};

export type SliderCount = {
  __typename?: 'SliderCount';
  descriptions: Scalars['Int'];
};

export type SliderCountAggregate = {
  __typename?: 'SliderCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  photo: Scalars['Int'];
  productId: Scalars['Int'];
};

export type SliderCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  photo?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
};

export type SliderCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutSliderInput>;
  id?: InputMaybe<Scalars['String']>;
  photo: Scalars['String'];
  product?: InputMaybe<ProductCreateNestedOneWithoutSlidersInput>;
};

export type SliderCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  photo: Scalars['String'];
  productId?: InputMaybe<Scalars['String']>;
};

export type SliderCreateManyProductInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  photo: Scalars['String'];
};

export type SliderCreateManyProductInputEnvelope = {
  data: Array<SliderCreateManyProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SliderCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<SliderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SliderCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<SliderCreateWithoutProductInput>>;
  createMany?: InputMaybe<SliderCreateManyProductInputEnvelope>;
};

export type SliderCreateNestedOneWithoutDescriptionsInput = {
  connect?: InputMaybe<SliderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SliderCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<SliderCreateWithoutDescriptionsInput>;
};

export type SliderCreateOrConnectWithoutDescriptionsInput = {
  create: SliderCreateWithoutDescriptionsInput;
  where: SliderWhereUniqueInput;
};

export type SliderCreateOrConnectWithoutProductInput = {
  create: SliderCreateWithoutProductInput;
  where: SliderWhereUniqueInput;
};

export type SliderCreateWithoutDescriptionsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  photo: Scalars['String'];
  product?: InputMaybe<ProductCreateNestedOneWithoutSlidersInput>;
};

export type SliderCreateWithoutProductInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutSliderInput>;
  id?: InputMaybe<Scalars['String']>;
  photo: Scalars['String'];
};

export type SliderGroupBy = {
  __typename?: 'SliderGroupBy';
  _count?: Maybe<SliderCountAggregate>;
  _max?: Maybe<SliderMaxAggregate>;
  _min?: Maybe<SliderMinAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  photo: Scalars['String'];
  productId?: Maybe<Scalars['String']>;
};

export type SliderListRelationFilter = {
  every?: InputMaybe<SliderWhereInput>;
  none?: InputMaybe<SliderWhereInput>;
  some?: InputMaybe<SliderWhereInput>;
};

export type SliderMaxAggregate = {
  __typename?: 'SliderMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
};

export type SliderMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  photo?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
};

export type SliderMinAggregate = {
  __typename?: 'SliderMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
};

export type SliderMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  photo?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
};

export type SliderOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SliderOrderByWithAggregationInput = {
  _count?: InputMaybe<SliderCountOrderByAggregateInput>;
  _max?: InputMaybe<SliderMaxOrderByAggregateInput>;
  _min?: InputMaybe<SliderMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  photo?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
};

export type SliderOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  descriptions?: InputMaybe<DescriptionOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  photo?: InputMaybe<SortOrder>;
  product?: InputMaybe<ProductOrderByWithRelationInput>;
  productId?: InputMaybe<SortOrder>;
};

export type SliderRelationFilter = {
  is?: InputMaybe<SliderWhereInput>;
  isNot?: InputMaybe<SliderWhereInput>;
};

export enum SliderScalarFieldEnum {
  createdAt = 'createdAt',
  id = 'id',
  photo = 'photo',
  productId = 'productId'
}

export type SliderScalarWhereInput = {
  AND?: InputMaybe<Array<SliderScalarWhereInput>>;
  NOT?: InputMaybe<Array<SliderScalarWhereInput>>;
  OR?: InputMaybe<Array<SliderScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  photo?: InputMaybe<StringFilter>;
  productId?: InputMaybe<StringNullableFilter>;
};

export type SliderScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<SliderScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<SliderScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<SliderScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  photo?: InputMaybe<StringWithAggregatesFilter>;
  productId?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type SliderUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutSliderNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  photo?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutSlidersNestedInput>;
};

export type SliderUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  photo?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SliderUpdateManyWithWhereWithoutProductInput = {
  data: SliderUpdateManyMutationInput;
  where: SliderScalarWhereInput;
};

export type SliderUpdateManyWithoutProductNestedInput = {
  connect?: InputMaybe<Array<SliderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SliderCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<SliderCreateWithoutProductInput>>;
  createMany?: InputMaybe<SliderCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<SliderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SliderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SliderWhereUniqueInput>>;
  set?: InputMaybe<Array<SliderWhereUniqueInput>>;
  update?: InputMaybe<Array<SliderUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<SliderUpdateManyWithWhereWithoutProductInput>>;
  upsert?: InputMaybe<Array<SliderUpsertWithWhereUniqueWithoutProductInput>>;
};

export type SliderUpdateOneWithoutDescriptionsNestedInput = {
  connect?: InputMaybe<SliderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SliderCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<SliderCreateWithoutDescriptionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<SliderUpdateWithoutDescriptionsInput>;
  upsert?: InputMaybe<SliderUpsertWithoutDescriptionsInput>;
};

export type SliderUpdateWithWhereUniqueWithoutProductInput = {
  data: SliderUpdateWithoutProductInput;
  where: SliderWhereUniqueInput;
};

export type SliderUpdateWithoutDescriptionsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  photo?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutSlidersNestedInput>;
};

export type SliderUpdateWithoutProductInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutSliderNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  photo?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SliderUpsertWithWhereUniqueWithoutProductInput = {
  create: SliderCreateWithoutProductInput;
  update: SliderUpdateWithoutProductInput;
  where: SliderWhereUniqueInput;
};

export type SliderUpsertWithoutDescriptionsInput = {
  create: SliderCreateWithoutDescriptionsInput;
  update: SliderUpdateWithoutDescriptionsInput;
};

export type SliderWhereInput = {
  AND?: InputMaybe<Array<SliderWhereInput>>;
  NOT?: InputMaybe<Array<SliderWhereInput>>;
  OR?: InputMaybe<Array<SliderWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  descriptions?: InputMaybe<DescriptionListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  photo?: InputMaybe<StringFilter>;
  product?: InputMaybe<ProductRelationFilter>;
  productId?: InputMaybe<StringNullableFilter>;
};

export type SliderWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum SortOrder {
  asc = 'asc',
  desc = 'desc'
}

export type Staff = {
  __typename?: 'Staff';
  _count?: Maybe<StaffCount>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  role: StaffRoleType;
  surname?: Maybe<Scalars['String']>;
};

export type StaffCount = {
  __typename?: 'StaffCount';
  logs: Scalars['Int'];
};

export type StaffCountAggregate = {
  __typename?: 'StaffCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  email: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  password: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  role: Scalars['Int'];
  surname: Scalars['Int'];
};

export type StaffCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  surname?: InputMaybe<SortOrder>;
};

export type StaffCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  logs?: InputMaybe<StaffLogCreateNestedManyWithoutStaffInput>;
  name: Scalars['String'];
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<StaffRoleType>;
  surname?: InputMaybe<Scalars['String']>;
};

export type StaffCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<StaffRoleType>;
  surname?: InputMaybe<Scalars['String']>;
};

export type StaffCreateNestedOneWithoutLogsInput = {
  connect?: InputMaybe<StaffWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StaffCreateOrConnectWithoutLogsInput>;
  create?: InputMaybe<StaffCreateWithoutLogsInput>;
};

export type StaffCreateOrConnectWithoutLogsInput = {
  create: StaffCreateWithoutLogsInput;
  where: StaffWhereUniqueInput;
};

export type StaffCreateWithoutLogsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<StaffRoleType>;
  surname?: InputMaybe<Scalars['String']>;
};

export type StaffGroupBy = {
  __typename?: 'StaffGroupBy';
  _count?: Maybe<StaffCountAggregate>;
  _max?: Maybe<StaffMaxAggregate>;
  _min?: Maybe<StaffMinAggregate>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  role: StaffRoleType;
  surname?: Maybe<Scalars['String']>;
};

export type StaffLog = {
  __typename?: 'StaffLog';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  json: Scalars['JSON'];
  staff?: Maybe<Staff>;
  staffId?: Maybe<Scalars['String']>;
  tableName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type StaffLogCountAggregate = {
  __typename?: 'StaffLogCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  json: Scalars['Int'];
  staffId: Scalars['Int'];
  tableName: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type StaffLogCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  json?: InputMaybe<SortOrder>;
  staffId?: InputMaybe<SortOrder>;
  tableName?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type StaffLogCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  json: Scalars['JSON'];
  staff?: InputMaybe<StaffCreateNestedOneWithoutLogsInput>;
  tableName: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type StaffLogCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  json: Scalars['JSON'];
  staffId?: InputMaybe<Scalars['String']>;
  tableName: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type StaffLogCreateManyStaffInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  json: Scalars['JSON'];
  tableName: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type StaffLogCreateManyStaffInputEnvelope = {
  data: Array<StaffLogCreateManyStaffInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type StaffLogCreateNestedManyWithoutStaffInput = {
  connect?: InputMaybe<Array<StaffLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StaffLogCreateOrConnectWithoutStaffInput>>;
  create?: InputMaybe<Array<StaffLogCreateWithoutStaffInput>>;
  createMany?: InputMaybe<StaffLogCreateManyStaffInputEnvelope>;
};

export type StaffLogCreateOrConnectWithoutStaffInput = {
  create: StaffLogCreateWithoutStaffInput;
  where: StaffLogWhereUniqueInput;
};

export type StaffLogCreateWithoutStaffInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  json: Scalars['JSON'];
  tableName: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type StaffLogGroupBy = {
  __typename?: 'StaffLogGroupBy';
  _count?: Maybe<StaffLogCountAggregate>;
  _max?: Maybe<StaffLogMaxAggregate>;
  _min?: Maybe<StaffLogMinAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  json: Scalars['JSON'];
  staffId?: Maybe<Scalars['String']>;
  tableName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type StaffLogListRelationFilter = {
  every?: InputMaybe<StaffLogWhereInput>;
  none?: InputMaybe<StaffLogWhereInput>;
  some?: InputMaybe<StaffLogWhereInput>;
};

export type StaffLogMaxAggregate = {
  __typename?: 'StaffLogMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffLogMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  staffId?: InputMaybe<SortOrder>;
  tableName?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type StaffLogMinAggregate = {
  __typename?: 'StaffLogMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffLogMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  staffId?: InputMaybe<SortOrder>;
  tableName?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type StaffLogOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StaffLogOrderByWithAggregationInput = {
  _count?: InputMaybe<StaffLogCountOrderByAggregateInput>;
  _max?: InputMaybe<StaffLogMaxOrderByAggregateInput>;
  _min?: InputMaybe<StaffLogMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  json?: InputMaybe<SortOrder>;
  staffId?: InputMaybe<SortOrder>;
  tableName?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type StaffLogOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  json?: InputMaybe<SortOrder>;
  staff?: InputMaybe<StaffOrderByWithRelationInput>;
  staffId?: InputMaybe<SortOrder>;
  tableName?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum StaffLogScalarFieldEnum {
  createdAt = 'createdAt',
  id = 'id',
  json = 'json',
  staffId = 'staffId',
  tableName = 'tableName',
  updatedAt = 'updatedAt'
}

export type StaffLogScalarWhereInput = {
  AND?: InputMaybe<Array<StaffLogScalarWhereInput>>;
  NOT?: InputMaybe<Array<StaffLogScalarWhereInput>>;
  OR?: InputMaybe<Array<StaffLogScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  json?: InputMaybe<JsonFilter>;
  staffId?: InputMaybe<StringNullableFilter>;
  tableName?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StaffLogScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<StaffLogScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<StaffLogScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<StaffLogScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  json?: InputMaybe<JsonWithAggregatesFilter>;
  staffId?: InputMaybe<StringNullableWithAggregatesFilter>;
  tableName?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type StaffLogUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  json?: InputMaybe<Scalars['JSON']>;
  staff?: InputMaybe<StaffUpdateOneWithoutLogsNestedInput>;
  tableName?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StaffLogUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  json?: InputMaybe<Scalars['JSON']>;
  tableName?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StaffLogUpdateManyWithWhereWithoutStaffInput = {
  data: StaffLogUpdateManyMutationInput;
  where: StaffLogScalarWhereInput;
};

export type StaffLogUpdateManyWithoutStaffNestedInput = {
  connect?: InputMaybe<Array<StaffLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StaffLogCreateOrConnectWithoutStaffInput>>;
  create?: InputMaybe<Array<StaffLogCreateWithoutStaffInput>>;
  createMany?: InputMaybe<StaffLogCreateManyStaffInputEnvelope>;
  delete?: InputMaybe<Array<StaffLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StaffLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StaffLogWhereUniqueInput>>;
  set?: InputMaybe<Array<StaffLogWhereUniqueInput>>;
  update?: InputMaybe<Array<StaffLogUpdateWithWhereUniqueWithoutStaffInput>>;
  updateMany?: InputMaybe<Array<StaffLogUpdateManyWithWhereWithoutStaffInput>>;
  upsert?: InputMaybe<Array<StaffLogUpsertWithWhereUniqueWithoutStaffInput>>;
};

export type StaffLogUpdateWithWhereUniqueWithoutStaffInput = {
  data: StaffLogUpdateWithoutStaffInput;
  where: StaffLogWhereUniqueInput;
};

export type StaffLogUpdateWithoutStaffInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  json?: InputMaybe<Scalars['JSON']>;
  tableName?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StaffLogUpsertWithWhereUniqueWithoutStaffInput = {
  create: StaffLogCreateWithoutStaffInput;
  update: StaffLogUpdateWithoutStaffInput;
  where: StaffLogWhereUniqueInput;
};

export type StaffLogWhereInput = {
  AND?: InputMaybe<Array<StaffLogWhereInput>>;
  NOT?: InputMaybe<Array<StaffLogWhereInput>>;
  OR?: InputMaybe<Array<StaffLogWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  json?: InputMaybe<JsonFilter>;
  staff?: InputMaybe<StaffRelationFilter>;
  staffId?: InputMaybe<StringNullableFilter>;
  tableName?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StaffLogWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type StaffLoginPayload = {
  __typename?: 'StaffLoginPayload';
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  role: StaffRoleType;
  token: Scalars['String'];
};

export type StaffLogoutPayload = {
  staffId: Scalars['String'];
};

export type StaffMaxAggregate = {
  __typename?: 'StaffMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  role?: Maybe<StaffRoleType>;
  surname?: Maybe<Scalars['String']>;
};

export type StaffMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  surname?: InputMaybe<SortOrder>;
};

export type StaffMinAggregate = {
  __typename?: 'StaffMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  role?: Maybe<StaffRoleType>;
  surname?: Maybe<Scalars['String']>;
};

export type StaffMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  surname?: InputMaybe<SortOrder>;
};

export type StaffOrderByWithAggregationInput = {
  _count?: InputMaybe<StaffCountOrderByAggregateInput>;
  _max?: InputMaybe<StaffMaxOrderByAggregateInput>;
  _min?: InputMaybe<StaffMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  surname?: InputMaybe<SortOrder>;
};

export type StaffOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  logs?: InputMaybe<StaffLogOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  surname?: InputMaybe<SortOrder>;
};

export type StaffRelationFilter = {
  is?: InputMaybe<StaffWhereInput>;
  isNot?: InputMaybe<StaffWhereInput>;
};

export enum StaffRoleType {
  ADMIN = 'ADMIN',
  MARKETING = 'MARKETING',
  PRODUCT = 'PRODUCT',
  SALES = 'SALES',
  SEO = 'SEO',
  SUPERADMIN = 'SUPERADMIN'
}

export enum StaffScalarFieldEnum {
  createdAt = 'createdAt',
  email = 'email',
  id = 'id',
  name = 'name',
  password = 'password',
  phoneNumber = 'phoneNumber',
  role = 'role',
  surname = 'surname'
}

export type StaffScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<StaffScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<StaffScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<StaffScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  email?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  password?: InputMaybe<StringWithAggregatesFilter>;
  phoneNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  role?: InputMaybe<EnumStaffRoleTypeWithAggregatesFilter>;
  surname?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type StaffUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  logs?: InputMaybe<StaffLogUpdateManyWithoutStaffNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumStaffRoleTypeFieldUpdateOperationsInput>;
  surname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type StaffUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumStaffRoleTypeFieldUpdateOperationsInput>;
  surname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type StaffUpdateOneWithoutLogsNestedInput = {
  connect?: InputMaybe<StaffWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StaffCreateOrConnectWithoutLogsInput>;
  create?: InputMaybe<StaffCreateWithoutLogsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<StaffUpdateWithoutLogsInput>;
  upsert?: InputMaybe<StaffUpsertWithoutLogsInput>;
};

export type StaffUpdateWithoutLogsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumStaffRoleTypeFieldUpdateOperationsInput>;
  surname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type StaffUpsertWithoutLogsInput = {
  create: StaffCreateWithoutLogsInput;
  update: StaffUpdateWithoutLogsInput;
};

export type StaffWhereInput = {
  AND?: InputMaybe<Array<StaffWhereInput>>;
  NOT?: InputMaybe<Array<StaffWhereInput>>;
  OR?: InputMaybe<Array<StaffWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  logs?: InputMaybe<StaffLogListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  password?: InputMaybe<StringFilter>;
  phoneNumber?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumStaffRoleTypeFilter>;
  surname?: InputMaybe<StringNullableFilter>;
};

export type StaffWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']>>;
  has?: InputMaybe<Scalars['String']>;
  hasEvery?: InputMaybe<Array<Scalars['String']>>;
  hasSome?: InputMaybe<Array<Scalars['String']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Style = {
  __typename?: 'Style';
  _count?: Maybe<StyleCount>;
  createdAt: Scalars['DateTime'];
  descriptions: Array<Description>;
  id: Scalars['String'];
  order: Scalars['Int'];
  photos: Array<Scalars['String']>;
  products: Array<Product>;
  seos: Array<Seo>;
  status: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};


export type StyleDescriptionsArgs = {
  cursor?: InputMaybe<DescriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<DescriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DescriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DescriptionWhereInput>;
};


export type StyleProductsArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type StyleSeosArgs = {
  cursor?: InputMaybe<SeoWhereUniqueInput>;
  distinct?: InputMaybe<Array<SeoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SeoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SeoWhereInput>;
};

export type StyleAvgAggregate = {
  __typename?: 'StyleAvgAggregate';
  order?: Maybe<Scalars['Float']>;
};

export type StyleAvgOrderByAggregateInput = {
  order?: InputMaybe<SortOrder>;
};

export type StyleCount = {
  __typename?: 'StyleCount';
  descriptions: Scalars['Int'];
  products: Scalars['Int'];
  seos: Scalars['Int'];
};

export type StyleCountAggregate = {
  __typename?: 'StyleCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  order: Scalars['Int'];
  photos: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type StyleCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type StyleCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutStyleInput>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<StyleCreatephotosInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutStyleInput>;
  seos?: InputMaybe<SeoCreateNestedManyWithoutStyleInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type StyleCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<StyleCreatephotosInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type StyleCreateNestedOneWithoutDescriptionsInput = {
  connect?: InputMaybe<StyleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StyleCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<StyleCreateWithoutDescriptionsInput>;
};

export type StyleCreateNestedOneWithoutProductsInput = {
  connect?: InputMaybe<StyleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StyleCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<StyleCreateWithoutProductsInput>;
};

export type StyleCreateNestedOneWithoutSeosInput = {
  connect?: InputMaybe<StyleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StyleCreateOrConnectWithoutSeosInput>;
  create?: InputMaybe<StyleCreateWithoutSeosInput>;
};

export type StyleCreateOrConnectWithoutDescriptionsInput = {
  create: StyleCreateWithoutDescriptionsInput;
  where: StyleWhereUniqueInput;
};

export type StyleCreateOrConnectWithoutProductsInput = {
  create: StyleCreateWithoutProductsInput;
  where: StyleWhereUniqueInput;
};

export type StyleCreateOrConnectWithoutSeosInput = {
  create: StyleCreateWithoutSeosInput;
  where: StyleWhereUniqueInput;
};

export type StyleCreateWithoutDescriptionsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<StyleCreatephotosInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutStyleInput>;
  seos?: InputMaybe<SeoCreateNestedManyWithoutStyleInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type StyleCreateWithoutProductsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutStyleInput>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<StyleCreatephotosInput>;
  seos?: InputMaybe<SeoCreateNestedManyWithoutStyleInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type StyleCreateWithoutSeosInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descriptions?: InputMaybe<DescriptionCreateNestedManyWithoutStyleInput>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<StyleCreatephotosInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutStyleInput>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type StyleCreatephotosInput = {
  set: Array<Scalars['String']>;
};

export type StyleGroupBy = {
  __typename?: 'StyleGroupBy';
  _avg?: Maybe<StyleAvgAggregate>;
  _count?: Maybe<StyleCountAggregate>;
  _max?: Maybe<StyleMaxAggregate>;
  _min?: Maybe<StyleMinAggregate>;
  _sum?: Maybe<StyleSumAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  order: Scalars['Int'];
  photos?: Maybe<Array<Scalars['String']>>;
  status: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type StyleMaxAggregate = {
  __typename?: 'StyleMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StyleMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type StyleMinAggregate = {
  __typename?: 'StyleMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StyleMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type StyleOrderByWithAggregationInput = {
  _avg?: InputMaybe<StyleAvgOrderByAggregateInput>;
  _count?: InputMaybe<StyleCountOrderByAggregateInput>;
  _max?: InputMaybe<StyleMaxOrderByAggregateInput>;
  _min?: InputMaybe<StyleMinOrderByAggregateInput>;
  _sum?: InputMaybe<StyleSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type StyleOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  descriptions?: InputMaybe<DescriptionOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  products?: InputMaybe<ProductOrderByRelationAggregateInput>;
  seos?: InputMaybe<SeoOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type StyleRelationFilter = {
  is?: InputMaybe<StyleWhereInput>;
  isNot?: InputMaybe<StyleWhereInput>;
};

export enum StyleScalarFieldEnum {
  createdAt = 'createdAt',
  id = 'id',
  order = 'order',
  photos = 'photos',
  status = 'status',
  updatedAt = 'updatedAt'
}

export type StyleScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<StyleScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<StyleScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<StyleScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  order?: InputMaybe<IntWithAggregatesFilter>;
  photos?: InputMaybe<StringNullableListFilter>;
  status?: InputMaybe<BoolWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type StyleSumAggregate = {
  __typename?: 'StyleSumAggregate';
  order?: Maybe<Scalars['Int']>;
};

export type StyleSumOrderByAggregateInput = {
  order?: InputMaybe<SortOrder>;
};

export type StyleUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutStyleNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<StyleUpdatephotosInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStyleNestedInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutStyleNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StyleUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<StyleUpdatephotosInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StyleUpdateOneWithoutDescriptionsNestedInput = {
  connect?: InputMaybe<StyleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StyleCreateOrConnectWithoutDescriptionsInput>;
  create?: InputMaybe<StyleCreateWithoutDescriptionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<StyleUpdateWithoutDescriptionsInput>;
  upsert?: InputMaybe<StyleUpsertWithoutDescriptionsInput>;
};

export type StyleUpdateOneWithoutProductsNestedInput = {
  connect?: InputMaybe<StyleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StyleCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<StyleCreateWithoutProductsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<StyleUpdateWithoutProductsInput>;
  upsert?: InputMaybe<StyleUpsertWithoutProductsInput>;
};

export type StyleUpdateOneWithoutSeosNestedInput = {
  connect?: InputMaybe<StyleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StyleCreateOrConnectWithoutSeosInput>;
  create?: InputMaybe<StyleCreateWithoutSeosInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<StyleUpdateWithoutSeosInput>;
  upsert?: InputMaybe<StyleUpsertWithoutSeosInput>;
};

export type StyleUpdateWithoutDescriptionsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<StyleUpdatephotosInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStyleNestedInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutStyleNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StyleUpdateWithoutProductsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutStyleNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<StyleUpdatephotosInput>;
  seos?: InputMaybe<SeoUpdateManyWithoutStyleNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StyleUpdateWithoutSeosInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descriptions?: InputMaybe<DescriptionUpdateManyWithoutStyleNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  photos?: InputMaybe<StyleUpdatephotosInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStyleNestedInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StyleUpdatephotosInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type StyleUpsertWithoutDescriptionsInput = {
  create: StyleCreateWithoutDescriptionsInput;
  update: StyleUpdateWithoutDescriptionsInput;
};

export type StyleUpsertWithoutProductsInput = {
  create: StyleCreateWithoutProductsInput;
  update: StyleUpdateWithoutProductsInput;
};

export type StyleUpsertWithoutSeosInput = {
  create: StyleCreateWithoutSeosInput;
  update: StyleUpdateWithoutSeosInput;
};

export type StyleWhereInput = {
  AND?: InputMaybe<Array<StyleWhereInput>>;
  NOT?: InputMaybe<Array<StyleWhereInput>>;
  OR?: InputMaybe<Array<StyleWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  descriptions?: InputMaybe<DescriptionListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  photos?: InputMaybe<StringNullableListFilter>;
  products?: InputMaybe<ProductListRelationFilter>;
  seos?: InputMaybe<SeoListRelationFilter>;
  status?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StyleWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type SwitchProductStatusInput = {
  productId: Scalars['ID'];
  status: Scalars['Boolean'];
};

export type Tax = {
  __typename?: 'Tax';
  country: Country;
  countryId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  shortcode: Scalars['String'];
  value: Scalars['Decimal'];
};

export type TaxCreateInput = {
  country: CountryCreateNestedOneWithoutTaxInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  shortcode: Scalars['String'];
  value: Scalars['Decimal'];
};

export type TaxCreateOrConnectWithoutCountryInput = {
  create: TaxCreateWithoutCountryInput;
  where: TaxWhereUniqueInput;
};

export type TaxCreateWithoutCountryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  shortcode: Scalars['String'];
  value: Scalars['Decimal'];
};

export type TaxOrderByWithRelationInput = {
  country?: InputMaybe<CountryOrderByWithRelationInput>;
  countryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  shortcode?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type TaxRelationFilter = {
  is?: InputMaybe<TaxWhereInput>;
  isNot?: InputMaybe<TaxWhereInput>;
};

export enum TaxScalarFieldEnum {
  countryId = 'countryId',
  createdAt = 'createdAt',
  id = 'id',
  shortcode = 'shortcode',
  value = 'value'
}

export type TaxUpdateInput = {
  country?: InputMaybe<CountryUpdateOneRequiredWithoutTaxNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  shortcode?: InputMaybe<StringFieldUpdateOperationsInput>;
  value?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type TaxUpdateOneWithoutCountryNestedInput = {
  connect?: InputMaybe<TaxWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TaxCreateOrConnectWithoutCountryInput>;
  create?: InputMaybe<TaxCreateWithoutCountryInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<TaxUpdateWithoutCountryInput>;
  upsert?: InputMaybe<TaxUpsertWithoutCountryInput>;
};

export type TaxUpdateWithoutCountryInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  shortcode?: InputMaybe<StringFieldUpdateOperationsInput>;
  value?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type TaxUpsertWithoutCountryInput = {
  create: TaxCreateWithoutCountryInput;
  update: TaxUpdateWithoutCountryInput;
};

export type TaxWhereInput = {
  AND?: InputMaybe<Array<TaxWhereInput>>;
  NOT?: InputMaybe<Array<TaxWhereInput>>;
  OR?: InputMaybe<Array<TaxWhereInput>>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  shortcode?: InputMaybe<StringFilter>;
  value?: InputMaybe<DecimalFilter>;
};

export type TaxWhereUniqueInput = {
  countryId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type TempProduct = {
  __typename?: 'TempProduct';
  createdAt: Scalars['DateTime'];
  customerId?: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  id: Scalars['String'];
  materials: Array<Scalars['JSON']>;
  name: Scalars['String'];
  photo: Scalars['String'];
  price: Scalars['Float'];
  size: Scalars['Float'];
  status: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  width: Scalars['Float'];
};

export type TempProductCreateManyCustomerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  materials?: InputMaybe<TempProductCreatematerialsInput>;
  name: Scalars['String'];
  photo: Scalars['String'];
  price: Scalars['Float'];
  size: Scalars['Float'];
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  width: Scalars['Float'];
};

export type TempProductCreateManyCustomerInputEnvelope = {
  data: Array<TempProductCreateManyCustomerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TempProductCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<TempProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TempProductCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<TempProductCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<TempProductCreateManyCustomerInputEnvelope>;
};

export type TempProductCreateOrConnectWithoutCustomerInput = {
  create: TempProductCreateWithoutCustomerInput;
  where: TempProductWhereUniqueInput;
};

export type TempProductCreateWithoutCustomerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  materials?: InputMaybe<TempProductCreatematerialsInput>;
  name: Scalars['String'];
  photo: Scalars['String'];
  price: Scalars['Float'];
  size: Scalars['Float'];
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  width: Scalars['Float'];
};

export type TempProductCreatematerialsInput = {
  set: Array<Scalars['JSON']>;
};

export type TempProductListRelationFilter = {
  every?: InputMaybe<TempProductWhereInput>;
  none?: InputMaybe<TempProductWhereInput>;
  some?: InputMaybe<TempProductWhereInput>;
};

export type TempProductOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TempProductOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  materials?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  photo?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  size?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  width?: InputMaybe<SortOrder>;
};

export enum TempProductScalarFieldEnum {
  createdAt = 'createdAt',
  customerId = 'customerId',
  gender = 'gender',
  id = 'id',
  materials = 'materials',
  name = 'name',
  photo = 'photo',
  price = 'price',
  size = 'size',
  status = 'status',
  updatedAt = 'updatedAt',
  width = 'width'
}

export type TempProductScalarWhereInput = {
  AND?: InputMaybe<Array<TempProductScalarWhereInput>>;
  NOT?: InputMaybe<Array<TempProductScalarWhereInput>>;
  OR?: InputMaybe<Array<TempProductScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringNullableFilter>;
  gender?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  materials?: InputMaybe<JsonNullableListFilter>;
  name?: InputMaybe<StringFilter>;
  photo?: InputMaybe<StringFilter>;
  price?: InputMaybe<FloatFilter>;
  size?: InputMaybe<FloatFilter>;
  status?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  width?: InputMaybe<FloatFilter>;
};

export type TempProductUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  gender?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  materials?: InputMaybe<TempProductUpdatematerialsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  photo?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  size?: InputMaybe<FloatFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  width?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type TempProductUpdateManyWithWhereWithoutCustomerInput = {
  data: TempProductUpdateManyMutationInput;
  where: TempProductScalarWhereInput;
};

export type TempProductUpdateManyWithoutCustomerNestedInput = {
  connect?: InputMaybe<Array<TempProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TempProductCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<TempProductCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<TempProductCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<TempProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TempProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TempProductWhereUniqueInput>>;
  set?: InputMaybe<Array<TempProductWhereUniqueInput>>;
  update?: InputMaybe<Array<TempProductUpdateWithWhereUniqueWithoutCustomerInput>>;
  updateMany?: InputMaybe<Array<TempProductUpdateManyWithWhereWithoutCustomerInput>>;
  upsert?: InputMaybe<Array<TempProductUpsertWithWhereUniqueWithoutCustomerInput>>;
};

export type TempProductUpdateWithWhereUniqueWithoutCustomerInput = {
  data: TempProductUpdateWithoutCustomerInput;
  where: TempProductWhereUniqueInput;
};

export type TempProductUpdateWithoutCustomerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  gender?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  materials?: InputMaybe<TempProductUpdatematerialsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  photo?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  size?: InputMaybe<FloatFieldUpdateOperationsInput>;
  status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  width?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type TempProductUpdatematerialsInput = {
  push?: InputMaybe<Array<Scalars['JSON']>>;
  set?: InputMaybe<Array<Scalars['JSON']>>;
};

export type TempProductUpsertWithWhereUniqueWithoutCustomerInput = {
  create: TempProductCreateWithoutCustomerInput;
  update: TempProductUpdateWithoutCustomerInput;
  where: TempProductWhereUniqueInput;
};

export type TempProductWhereInput = {
  AND?: InputMaybe<Array<TempProductWhereInput>>;
  NOT?: InputMaybe<Array<TempProductWhereInput>>;
  OR?: InputMaybe<Array<TempProductWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringNullableFilter>;
  gender?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  materials?: InputMaybe<JsonNullableListFilter>;
  name?: InputMaybe<StringFilter>;
  photo?: InputMaybe<StringFilter>;
  price?: InputMaybe<FloatFilter>;
  size?: InputMaybe<FloatFilter>;
  status?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  width?: InputMaybe<FloatFilter>;
};

export type TempProductWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type UpdateMeInput = {
  address: Scalars['String'];
  city: Scalars['JSONObject'];
  country: Scalars['JSONObject'];
  currentPassword?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  identityNumber: Scalars['String'];
  name: Scalars['String'];
  newPassword?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber: Scalars['String'];
  postalCode: Scalars['String'];
  surname: Scalars['String'];
};

export type CreateOneBlogMutationVariables = Exact<{
  data: BlogCreateInput;
}>;


export type CreateOneBlogMutation = { __typename?: 'Mutation', createOneBlog: { __typename?: 'Blog', id: string, blogCategoryId?: string | null, photo: string, status: boolean, createdAt: any, updatedAt: any, category?: { __typename?: 'BlogCategory', id: string, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, slug: string, language: LanguageOptions }> } | null, descriptions: Array<{ __typename?: 'Description', title: string, description?: string | null, slug: string, language: LanguageOptions }> } };

export type DeleteOneBlogMutationVariables = Exact<{
  where: BlogWhereUniqueInput;
}>;


export type DeleteOneBlogMutation = { __typename?: 'Mutation', deleteOneBlog?: { __typename?: 'Blog', id: string } | null };

export type GetBlogQueryVariables = Exact<{
  where: BlogWhereUniqueInput;
}>;


export type GetBlogQuery = { __typename?: 'Query', getBlog?: { __typename?: 'Blog', id: string, status: boolean, photo: string, blogCategoryId?: string | null, createdAt: any, updatedAt: any, category?: { __typename?: 'BlogCategory', author?: string | null, id: string, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, language: LanguageOptions }> } | null, seos: Array<{ __typename?: 'Seo', id: string, title: string, description?: string | null, keywords?: string | null, language: LanguageOptions }>, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, language: LanguageOptions }> } | null };

export type BlogsQueryVariables = Exact<{ [key: string]: never; }>;


export type BlogsQuery = { __typename?: 'Query', blogs: Array<{ __typename?: 'Blog', id: string, status: boolean, photo: string, blogCategoryId?: string | null, createdAt: any, updatedAt: any, category?: { __typename?: 'BlogCategory', author?: string | null, id: string, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, language: LanguageOptions }> } | null, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, language: LanguageOptions }> }> };

export type UpdateOneBlogMutationVariables = Exact<{
  data: BlogUpdateInput;
  where: BlogWhereUniqueInput;
}>;


export type UpdateOneBlogMutation = { __typename?: 'Mutation', updateOneBlog?: { __typename?: 'Blog', id: string, photo: string, status: boolean, blogCategoryId?: string | null, createdAt: any, updatedAt: any, category?: { __typename?: 'BlogCategory', author?: string | null, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, language: LanguageOptions, slug: string }> } | null } | null };

export type CreateOneBlogCategoryMutationVariables = Exact<{
  data: BlogCategoryCreateInput;
}>;


export type CreateOneBlogCategoryMutation = { __typename?: 'Mutation', createOneBlogCategory: { __typename?: 'BlogCategory', id: string, author?: string | null, createdAt: any, updatedAt: any, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, language: LanguageOptions }> } };

export type DeleteOneBlogCategoryMutationVariables = Exact<{
  where: BlogCategoryWhereUniqueInput;
}>;


export type DeleteOneBlogCategoryMutation = { __typename?: 'Mutation', deleteOneBlogCategory?: { __typename?: 'BlogCategory', id: string } | null };

export type GetBlogCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBlogCategoriesQuery = { __typename?: 'Query', blogCategories: Array<{ __typename?: 'BlogCategory', id: string, author?: string | null, status: boolean, createdAt: any, updatedAt: any, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, language: LanguageOptions }>, _count?: { __typename?: 'BlogCategoryCount', Blog: number } | null }> };

export type GetBlogCategoryQueryVariables = Exact<{
  where: BlogCategoryWhereUniqueInput;
}>;


export type GetBlogCategoryQuery = { __typename?: 'Query', getBlogCategory?: { __typename?: 'BlogCategory', id: string, author?: string | null, status: boolean, createdAt: any, updatedAt: any, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, language: LanguageOptions }>, _count?: { __typename?: 'BlogCategoryCount', Blog: number } | null } | null };

export type UpdateOneBlogCategoryMutationVariables = Exact<{
  data: BlogCategoryUpdateInput;
  where: BlogCategoryWhereUniqueInput;
}>;


export type UpdateOneBlogCategoryMutation = { __typename?: 'Mutation', updateOneBlogCategory?: { __typename?: 'BlogCategory', id: string, author?: string | null, createdAt: any, updatedAt: any, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, language: LanguageOptions }> } | null };

export type CategoriesQueryVariables = Exact<{
  where?: InputMaybe<CategoryWhereInput>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput> | CategoryOrderByWithRelationInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type CategoriesQuery = { __typename?: 'Query', categories: Array<{ __typename?: 'Category', id: string, createdAt: any, photos: Array<string>, discountAmount: any, status: boolean, parentCategoryId?: string | null, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, slug: string, headline?: string | null, buttonText?: string | null, buttonUrl?: string | null, language: LanguageOptions }>, _count?: { __typename?: 'CategoryCount', subCategories: number } | null, parentCategory?: { __typename?: 'Category', descriptions: Array<{ __typename?: 'Description', title: string, language: LanguageOptions }> } | null, subCategories: Array<{ __typename?: 'Category', id: string, descriptions: Array<{ __typename?: 'Description', id: string, title: string, language: LanguageOptions }> }> }> };

export type CategoryQueryVariables = Exact<{
  where: CategoryWhereUniqueInput;
}>;


export type CategoryQuery = { __typename?: 'Query', category?: { __typename?: 'Category', id: string, createdAt: any, photos: Array<string>, parentCategoryId?: string | null, discountAmount: any, status: boolean, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, slug: string, headline?: string | null, buttonText?: string | null, buttonUrl?: string | null, language: LanguageOptions }>, seos: Array<{ __typename?: 'Seo', id: string, title: string, description?: string | null, keywords?: string | null, language: LanguageOptions }> } | null };

export type CreateCategoryMutationVariables = Exact<{
  data: CategoryCreateInput;
}>;


export type CreateCategoryMutation = { __typename?: 'Mutation', createOneCategory: { __typename?: 'Category', id: string } };

export type DeleteCategoryMutationVariables = Exact<{
  where: CategoryWhereUniqueInput;
}>;


export type DeleteCategoryMutation = { __typename?: 'Mutation', deleteOneCategory?: { __typename?: 'Category', id: string } | null };

export type MakeCategoryDiscountMutationVariables = Exact<{
  where: CategoryWhereUniqueInput;
  discountAmount: Scalars['Float'];
}>;


export type MakeCategoryDiscountMutation = { __typename?: 'Mutation', makeCategoryDiscount: boolean };

export type OrderCategoryMutationVariables = Exact<{
  id: Scalars['String'];
  order: Scalars['Int'];
}>;


export type OrderCategoryMutation = { __typename?: 'Mutation', updateOneCategory?: { __typename?: 'Category', id: string } | null };

export type UpdateCategoryMutationVariables = Exact<{
  data: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
}>;


export type UpdateCategoryMutation = { __typename?: 'Mutation', updateOneCategory?: { __typename?: 'Category', id: string } | null };

export type DashboardStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardStatisticsQuery = { __typename?: 'Query', dashboardStatistics: { __typename?: 'DashboardStatisticsPayload', todayOrderCount?: number | null, weeklyOrderCount?: number | null, todayEarnings?: number | null, weeklyEarnings?: number | null, last4Products?: Array<{ __typename?: 'Product', id: string, photos: Array<string>, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, slug: string, headline?: string | null, buttonText?: string | null, buttonUrl?: string | null, language: LanguageOptions }> }> | null, pendingOrders?: Array<{ __typename?: 'Order', id: string, identityNumber?: string | null, conversationId?: string | null, paymentFirstName?: string | null, paymentLastName?: string | null, shippingFirstName?: string | null, shippingLastName?: string | null, isCompleted: boolean, orderStatus: OrderStatus, createdAt: any, customer: { __typename?: 'Customer', id: string, name: string, surname: string }, payment?: { __typename?: 'Payment', id: string, price: number, paidPrice: number, discount: number, installment: number } | null, _count?: { __typename?: 'OrderCount', products: number } | null }> | null } };

export type CreateDescriptionMutationVariables = Exact<{
  data: DescriptionCreateInput;
}>;


export type CreateDescriptionMutation = { __typename?: 'Mutation', createOneDescription: { __typename?: 'Description', id: string } };

export type DeleteDescriptionMutationVariables = Exact<{
  where: DescriptionWhereUniqueInput;
}>;


export type DeleteDescriptionMutation = { __typename?: 'Mutation', deleteOneDescription?: { __typename?: 'Description', id: string } | null };

export type DescriptionQueryVariables = Exact<{
  where: DescriptionWhereUniqueInput;
}>;


export type DescriptionQuery = { __typename?: 'Query', description?: { __typename?: 'Description', id: string, title: string, slug: string, description?: string | null, headline?: string | null, buttonText?: string | null, buttonUrl?: string | null, language: LanguageOptions } | null };

export type UpdateDescriptionMutationVariables = Exact<{
  data: DescriptionUpdateInput;
  where: DescriptionWhereUniqueInput;
}>;


export type UpdateDescriptionMutation = { __typename?: 'Mutation', updateOneDescription?: { __typename?: 'Description', id: string } | null };

export type CreatePageMutationVariables = Exact<{
  data: PageCreateInput;
}>;


export type CreatePageMutation = { __typename?: 'Mutation', createOnePage: { __typename?: 'Page', id: string } };

export type DeletePageMutationVariables = Exact<{
  where: PageWhereUniqueInput;
}>;


export type DeletePageMutation = { __typename?: 'Mutation', deleteOnePage?: { __typename?: 'Page', id: string } | null };

export type OrderPageMutationVariables = Exact<{
  id: Scalars['String'];
  order: Scalars['Int'];
}>;


export type OrderPageMutation = { __typename?: 'Mutation', updateOnePage?: { __typename?: 'Page', id: string } | null };

export type PageQueryVariables = Exact<{
  where: PageWhereUniqueInput;
}>;


export type PageQuery = { __typename?: 'Query', page?: { __typename?: 'Page', id: string, status: boolean, pageType: PageType, showOnHeader: boolean, showOnFooter: boolean, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, slug: string, headline?: string | null, buttonText?: string | null, buttonUrl?: string | null, language: LanguageOptions }>, seos: Array<{ __typename?: 'Seo', id: string, title: string, description?: string | null, keywords?: string | null, language: LanguageOptions }> } | null };

export type PagesQueryVariables = Exact<{
  where?: InputMaybe<PageWhereInput>;
  orderBy?: InputMaybe<Array<PageOrderByWithRelationInput> | PageOrderByWithRelationInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type PagesQuery = { __typename?: 'Query', pages: Array<{ __typename?: 'Page', id: string, status: boolean, pageType: PageType, showOnHeader: boolean, showOnFooter: boolean, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, slug: string, headline?: string | null, buttonText?: string | null, buttonUrl?: string | null, language: LanguageOptions }> }> };

export type UpdatePageMutationVariables = Exact<{
  data: PageUpdateInput;
  where: PageWhereUniqueInput;
}>;


export type UpdatePageMutation = { __typename?: 'Mutation', updateOnePage?: { __typename?: 'Page', id: string } | null };

export type CalculatePriceMutationVariables = Exact<{
  data: ProductCreateCustomInput;
}>;


export type CalculatePriceMutation = { __typename?: 'Mutation', calculatePrice: number };

export type CreateProductCustomMutationVariables = Exact<{
  data: ProductCreateCustomInput;
}>;


export type CreateProductCustomMutation = { __typename?: 'Mutation', createProductCustom: { __typename?: 'Product', id: string } };

export type DeleteProductMutationVariables = Exact<{
  where: ProductWhereUniqueInput;
}>;


export type DeleteProductMutation = { __typename?: 'Mutation', deleteOneProduct?: { __typename?: 'Product', id: string } | null };

export type MakeProductDiscountMutationVariables = Exact<{
  productId: Scalars['String'];
  discountAmount: Scalars['Int'];
  discountType: DiscountType;
}>;


export type MakeProductDiscountMutation = { __typename?: 'Mutation', makeProductDiscount: boolean };

export type OrderProductMutationVariables = Exact<{
  id: Scalars['String'];
  order: Scalars['Int'];
}>;


export type OrderProductMutation = { __typename?: 'Mutation', updateProduct: { __typename?: 'Product', id: string } };

export type ProductQueryVariables = Exact<{
  creatablesWhere2?: InputMaybe<ProductCreatableWhereInput>;
  where: ProductWhereUniqueInput;
}>;


export type ProductQuery = { __typename?: 'Query', product?: { __typename?: 'Product', id: string, createdAt: any, photos: Array<string>, price: any, oldPrice?: any | null, discountType: DiscountType, discountAmount: any, status: boolean, sku?: string | null, barcode?: string | null, order: number, categoryId: string, styleId?: string | null, weight: any, seos: Array<{ __typename?: 'Seo', id: string, title: string, description?: string | null, keywords?: string | null, language: LanguageOptions }>, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, slug: string, headline?: string | null, buttonText?: string | null, buttonUrl?: string | null, language: LanguageOptions }>, creatables: Array<{ __typename?: 'ProductCreatable', id: string, creatableId: string, creatable: { __typename?: 'Creatable', value: any, type: CreatableType, operation: CreatableOperationType, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, slug: string, headline?: string | null, buttonText?: string | null, buttonUrl?: string | null, language: LanguageOptions }> } }>, productRingSizeStandarts: Array<{ __typename?: 'ProductRingSizeStandart', ringSizeStandart: { __typename?: 'RingSizeStandart', name: string, id: string, values: Array<{ __typename?: 'RingSize', circumferenceMM: any, diameterMM: any, value: string, id: string }> } }>, stoneGroups: Array<{ __typename?: 'ProductRingStoneGroup', id: string, photos: Array<string>, stones: Array<{ __typename?: 'ProductRingStone', id: string, quantity: number, quality: RingStoneCaratQuality, shape?: { __typename?: 'RingStoneShape', id: string, shape: string } | null, carat: { __typename?: 'RingStoneCarat', id: string, carat: string }, caratInfo: { __typename?: 'RingStoneCaratInfo', id: string, color: string, IF: any, VSS1: any, VSS2: any, VS1: any, VS2: any, SI1: any, SI2: any, SI3: any, I1: any, I2: any, I3: any } }> }> } | null };

export type ProductsQueryVariables = Exact<{
  where?: InputMaybe<ProductWhereInput>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput> | ProductOrderByWithRelationInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type ProductsQuery = { __typename?: 'Query', products: { __typename?: 'ProductsPayload', count: number, items: Array<{ __typename?: 'Product', id: string, createdAt: any, photos: Array<string>, discountType: DiscountType, discountAmount: any, sku?: string | null, barcode?: string | null, status: boolean, order: number, price: any, seos: Array<{ __typename?: 'Seo', id: string, title: string, description?: string | null, keywords?: string | null, language: LanguageOptions }>, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, slug: string, headline?: string | null, buttonText?: string | null, buttonUrl?: string | null, language: LanguageOptions }>, category: { __typename?: 'Category', id: string, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, slug: string, headline?: string | null, buttonText?: string | null, buttonUrl?: string | null, language: LanguageOptions }> } }> } };

export type RefreshProductPricesMutationVariables = Exact<{
  productIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type RefreshProductPricesMutation = { __typename?: 'Mutation', refreshProductPrices: boolean };

export type SwitchProductStatusMutationVariables = Exact<{
  data: SwitchProductStatusInput;
}>;


export type SwitchProductStatusMutation = { __typename?: 'Mutation', switchProductStatus: boolean };

export type UpdateOneProductMutationVariables = Exact<{
  data: ProductUpdateInput;
  where: ProductWhereUniqueInput;
}>;


export type UpdateOneProductMutation = { __typename?: 'Mutation', updateOneProduct?: { __typename?: 'Product', id: string } | null };

export type UpdateProductMutationVariables = Exact<{
  data: ProductUpdateCustomInput;
  where: ProductWhereUniqueInput;
}>;


export type UpdateProductMutation = { __typename?: 'Mutation', updateProduct: { __typename?: 'Product', id: string } };

export type GetFilterCategoriesQueryVariables = Exact<{
  where?: InputMaybe<CategoryWhereInput>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput> | CategoryOrderByWithRelationInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type GetFilterCategoriesQuery = { __typename?: 'Query', categories: Array<{ __typename?: 'Category', id: string, createdAt: any, photos: Array<string>, discountAmount: any, status: boolean, parentCategoryId?: string | null, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, slug: string, headline?: string | null, buttonText?: string | null, buttonUrl?: string | null, language: LanguageOptions }>, _count?: { __typename?: 'CategoryCount', subCategories: number } | null, parentCategory?: { __typename?: 'Category', descriptions: Array<{ __typename?: 'Description', title: string, language: LanguageOptions }> } | null, subCategories: Array<{ __typename?: 'Category', id: string, descriptions: Array<{ __typename?: 'Description', id: string, title: string, language: LanguageOptions }> }> }> };

export type GetFilterCreatablesQueryVariables = Exact<{
  where?: InputMaybe<CreatableWhereInput>;
  orderBy?: InputMaybe<Array<CreatableOrderByWithRelationInput> | CreatableOrderByWithRelationInput>;
  cursor?: InputMaybe<CreatableWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type GetFilterCreatablesQuery = { __typename?: 'Query', creatables: Array<{ __typename?: 'Creatable', id: string, type: CreatableType, value: any, optionalValue?: any | null, operation: CreatableOperationType, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, slug: string, headline?: string | null, buttonText?: string | null, buttonUrl?: string | null, language: LanguageOptions }> }> };

export type GetFilterRingSizeStandartsQueryVariables = Exact<{
  where?: InputMaybe<RingSizeStandartWhereInput>;
  orderBy?: InputMaybe<Array<RingSizeStandartOrderByWithRelationInput> | RingSizeStandartOrderByWithRelationInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type GetFilterRingSizeStandartsQuery = { __typename?: 'Query', ringSizeStandarts: Array<{ __typename?: 'RingSizeStandart', id: string, name: string, createdAt: any, updatedAt: any, _count?: { __typename?: 'RingSizeStandartCount', values: number, productRingSizeStandarts: number } | null }> };

export type GetFilterStylesQueryVariables = Exact<{
  where?: InputMaybe<StyleWhereInput>;
  orderBy?: InputMaybe<Array<StyleOrderByWithRelationInput> | StyleOrderByWithRelationInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type GetFilterStylesQuery = { __typename?: 'Query', styles: Array<{ __typename?: 'Style', id: string, photos: Array<string>, status: boolean, order: number, createdAt: any, updatedAt: any, _count?: { __typename?: 'StyleCount', products: number } | null, descriptions: Array<{ __typename?: 'Description', id: string, title: string, description?: string | null, slug: string, headline?: string | null, buttonText?: string | null, buttonUrl?: string | null, language: LanguageOptions }> }> };

export type SeosQueryVariables = Exact<{
  where?: InputMaybe<SeoWhereInput>;
  orderBy?: InputMaybe<Array<SeoOrderByWithRelationInput> | SeoOrderByWithRelationInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type SeosQuery = { __typename?: 'Query', seos: Array<{ __typename?: 'Seo', id: string, title: string, description?: string | null, keywords?: string | null, language: LanguageOptions, createdAt: any, updatedAt: any, categoryId?: string | null, productId?: string | null, styleId?: string | null, pageId?: string | null, blogCategoryId?: string | null, blogId?: string | null }> };

export type UpdateOneSeoMutationVariables = Exact<{
  data: SeoUpdateInput;
  where: SeoWhereUniqueInput;
}>;


export type UpdateOneSeoMutation = { __typename?: 'Mutation', updateOneSeo?: { __typename?: 'Seo', id: string } | null };

export type CheckStaffQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckStaffQuery = { __typename?: 'Query', checkStaff: boolean };

export type CreateStaffMutationVariables = Exact<{
  data: StaffCreateInput;
}>;


export type CreateStaffMutation = { __typename?: 'Mutation', createOneStaff: { __typename?: 'Staff', id: string } };

export type FindManyStaffQueryVariables = Exact<{ [key: string]: never; }>;


export type FindManyStaffQuery = { __typename?: 'Query', findManyStaff: Array<{ __typename?: 'Staff', id: string, name: string, surname?: string | null, email: string, phoneNumber?: string | null, role: StaffRoleType, createdAt: any }> };

export type FindUniqueStaffQueryVariables = Exact<{
  where: StaffWhereUniqueInput;
}>;


export type FindUniqueStaffQuery = { __typename?: 'Query', findUniqueStaff?: { __typename?: 'Staff', createdAt: any, email: string, id: string, name: string, phoneNumber?: string | null, role: StaffRoleType, surname?: string | null } | null };

export type StaffLoginMutationVariables = Exact<{
  data: LoginInput;
}>;


export type StaffLoginMutation = { __typename?: 'Mutation', staffLogin: { __typename?: 'StaffLoginPayload', id: string, name: string, email: string, token: string, role: StaffRoleType } };

export type UpdateStaffMutationVariables = Exact<{
  data: StaffUpdateInput;
  where: StaffWhereUniqueInput;
}>;


export type UpdateStaffMutation = { __typename?: 'Mutation', updateOneStaff?: { __typename?: 'Staff', id: string } | null };

export type CreateOneStaffLogMutationVariables = Exact<{
  data: StaffLogCreateInput;
}>;


export type CreateOneStaffLogMutation = { __typename?: 'Mutation', createOneStaffLog: { __typename?: 'StaffLog', id: string } };

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadFileMutation = { __typename?: 'Mutation', uploadFile: any };


export const CreateOneBlogDocument = gql`
    mutation CreateOneBlog($data: BlogCreateInput!) {
  createOneBlog(data: $data) {
    id
    blogCategoryId
    category {
      id
      descriptions {
        id
        title
        description
        slug
        language
      }
    }
    descriptions {
      title
      description
      slug
      language
    }
    photo
    status
    createdAt
    updatedAt
  }
}
    `;
export type CreateOneBlogMutationFn = Apollo.MutationFunction<CreateOneBlogMutation, CreateOneBlogMutationVariables>;

/**
 * __useCreateOneBlogMutation__
 *
 * To run a mutation, you first call `useCreateOneBlogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneBlogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneBlogMutation, { data, loading, error }] = useCreateOneBlogMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneBlogMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneBlogMutation, CreateOneBlogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneBlogMutation, CreateOneBlogMutationVariables>(CreateOneBlogDocument, options);
      }
export type CreateOneBlogMutationHookResult = ReturnType<typeof useCreateOneBlogMutation>;
export type CreateOneBlogMutationResult = Apollo.MutationResult<CreateOneBlogMutation>;
export type CreateOneBlogMutationOptions = Apollo.BaseMutationOptions<CreateOneBlogMutation, CreateOneBlogMutationVariables>;
export const DeleteOneBlogDocument = gql`
    mutation DeleteOneBlog($where: BlogWhereUniqueInput!) {
  deleteOneBlog(where: $where) {
    id
  }
}
    `;
export type DeleteOneBlogMutationFn = Apollo.MutationFunction<DeleteOneBlogMutation, DeleteOneBlogMutationVariables>;

/**
 * __useDeleteOneBlogMutation__
 *
 * To run a mutation, you first call `useDeleteOneBlogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneBlogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneBlogMutation, { data, loading, error }] = useDeleteOneBlogMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteOneBlogMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneBlogMutation, DeleteOneBlogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneBlogMutation, DeleteOneBlogMutationVariables>(DeleteOneBlogDocument, options);
      }
export type DeleteOneBlogMutationHookResult = ReturnType<typeof useDeleteOneBlogMutation>;
export type DeleteOneBlogMutationResult = Apollo.MutationResult<DeleteOneBlogMutation>;
export type DeleteOneBlogMutationOptions = Apollo.BaseMutationOptions<DeleteOneBlogMutation, DeleteOneBlogMutationVariables>;
export const GetBlogDocument = gql`
    query GetBlog($where: BlogWhereUniqueInput!) {
  getBlog(where: $where) {
    id
    status
    photo
    blogCategoryId
    category {
      author
      id
      descriptions {
        id
        title
        description
        language
      }
    }
    seos {
      id
      title
      description
      keywords
      language
    }
    descriptions {
      id
      title
      description
      language
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetBlogQuery__
 *
 * To run a query within a React component, call `useGetBlogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBlogQuery(baseOptions: Apollo.QueryHookOptions<GetBlogQuery, GetBlogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlogQuery, GetBlogQueryVariables>(GetBlogDocument, options);
      }
export function useGetBlogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlogQuery, GetBlogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlogQuery, GetBlogQueryVariables>(GetBlogDocument, options);
        }
export type GetBlogQueryHookResult = ReturnType<typeof useGetBlogQuery>;
export type GetBlogLazyQueryHookResult = ReturnType<typeof useGetBlogLazyQuery>;
export type GetBlogQueryResult = Apollo.QueryResult<GetBlogQuery, GetBlogQueryVariables>;
export const BlogsDocument = gql`
    query Blogs {
  blogs {
    id
    status
    photo
    blogCategoryId
    category {
      author
      id
      descriptions {
        id
        title
        description
        language
      }
    }
    descriptions {
      id
      title
      description
      language
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useBlogsQuery__
 *
 * To run a query within a React component, call `useBlogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlogsQuery(baseOptions?: Apollo.QueryHookOptions<BlogsQuery, BlogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlogsQuery, BlogsQueryVariables>(BlogsDocument, options);
      }
export function useBlogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlogsQuery, BlogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlogsQuery, BlogsQueryVariables>(BlogsDocument, options);
        }
export type BlogsQueryHookResult = ReturnType<typeof useBlogsQuery>;
export type BlogsLazyQueryHookResult = ReturnType<typeof useBlogsLazyQuery>;
export type BlogsQueryResult = Apollo.QueryResult<BlogsQuery, BlogsQueryVariables>;
export const UpdateOneBlogDocument = gql`
    mutation UpdateOneBlog($data: BlogUpdateInput!, $where: BlogWhereUniqueInput!) {
  updateOneBlog(data: $data, where: $where) {
    id
    photo
    status
    blogCategoryId
    category {
      descriptions {
        id
        title
        description
        language
        slug
      }
      author
    }
    createdAt
    updatedAt
  }
}
    `;
export type UpdateOneBlogMutationFn = Apollo.MutationFunction<UpdateOneBlogMutation, UpdateOneBlogMutationVariables>;

/**
 * __useUpdateOneBlogMutation__
 *
 * To run a mutation, you first call `useUpdateOneBlogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneBlogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneBlogMutation, { data, loading, error }] = useUpdateOneBlogMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneBlogMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneBlogMutation, UpdateOneBlogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneBlogMutation, UpdateOneBlogMutationVariables>(UpdateOneBlogDocument, options);
      }
export type UpdateOneBlogMutationHookResult = ReturnType<typeof useUpdateOneBlogMutation>;
export type UpdateOneBlogMutationResult = Apollo.MutationResult<UpdateOneBlogMutation>;
export type UpdateOneBlogMutationOptions = Apollo.BaseMutationOptions<UpdateOneBlogMutation, UpdateOneBlogMutationVariables>;
export const CreateOneBlogCategoryDocument = gql`
    mutation createOneBlogCategory($data: BlogCategoryCreateInput!) {
  createOneBlogCategory(data: $data) {
    id
    author
    descriptions {
      id
      title
      description
      language
    }
    createdAt
    updatedAt
  }
}
    `;
export type CreateOneBlogCategoryMutationFn = Apollo.MutationFunction<CreateOneBlogCategoryMutation, CreateOneBlogCategoryMutationVariables>;

/**
 * __useCreateOneBlogCategoryMutation__
 *
 * To run a mutation, you first call `useCreateOneBlogCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneBlogCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneBlogCategoryMutation, { data, loading, error }] = useCreateOneBlogCategoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneBlogCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneBlogCategoryMutation, CreateOneBlogCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneBlogCategoryMutation, CreateOneBlogCategoryMutationVariables>(CreateOneBlogCategoryDocument, options);
      }
export type CreateOneBlogCategoryMutationHookResult = ReturnType<typeof useCreateOneBlogCategoryMutation>;
export type CreateOneBlogCategoryMutationResult = Apollo.MutationResult<CreateOneBlogCategoryMutation>;
export type CreateOneBlogCategoryMutationOptions = Apollo.BaseMutationOptions<CreateOneBlogCategoryMutation, CreateOneBlogCategoryMutationVariables>;
export const DeleteOneBlogCategoryDocument = gql`
    mutation DeleteOneBlogCategory($where: BlogCategoryWhereUniqueInput!) {
  deleteOneBlogCategory(where: $where) {
    id
  }
}
    `;
export type DeleteOneBlogCategoryMutationFn = Apollo.MutationFunction<DeleteOneBlogCategoryMutation, DeleteOneBlogCategoryMutationVariables>;

/**
 * __useDeleteOneBlogCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteOneBlogCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneBlogCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneBlogCategoryMutation, { data, loading, error }] = useDeleteOneBlogCategoryMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteOneBlogCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneBlogCategoryMutation, DeleteOneBlogCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneBlogCategoryMutation, DeleteOneBlogCategoryMutationVariables>(DeleteOneBlogCategoryDocument, options);
      }
export type DeleteOneBlogCategoryMutationHookResult = ReturnType<typeof useDeleteOneBlogCategoryMutation>;
export type DeleteOneBlogCategoryMutationResult = Apollo.MutationResult<DeleteOneBlogCategoryMutation>;
export type DeleteOneBlogCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteOneBlogCategoryMutation, DeleteOneBlogCategoryMutationVariables>;
export const GetBlogCategoriesDocument = gql`
    query GetBlogCategories {
  blogCategories {
    id
    author
    status
    descriptions {
      id
      title
      description
      language
    }
    createdAt
    updatedAt
    _count {
      Blog
    }
  }
}
    `;

/**
 * __useGetBlogCategoriesQuery__
 *
 * To run a query within a React component, call `useGetBlogCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBlogCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetBlogCategoriesQuery, GetBlogCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlogCategoriesQuery, GetBlogCategoriesQueryVariables>(GetBlogCategoriesDocument, options);
      }
export function useGetBlogCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlogCategoriesQuery, GetBlogCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlogCategoriesQuery, GetBlogCategoriesQueryVariables>(GetBlogCategoriesDocument, options);
        }
export type GetBlogCategoriesQueryHookResult = ReturnType<typeof useGetBlogCategoriesQuery>;
export type GetBlogCategoriesLazyQueryHookResult = ReturnType<typeof useGetBlogCategoriesLazyQuery>;
export type GetBlogCategoriesQueryResult = Apollo.QueryResult<GetBlogCategoriesQuery, GetBlogCategoriesQueryVariables>;
export const GetBlogCategoryDocument = gql`
    query GetBlogCategory($where: BlogCategoryWhereUniqueInput!) {
  getBlogCategory(where: $where) {
    id
    author
    status
    descriptions {
      id
      title
      description
      language
    }
    createdAt
    updatedAt
    _count {
      Blog
    }
  }
}
    `;

/**
 * __useGetBlogCategoryQuery__
 *
 * To run a query within a React component, call `useGetBlogCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogCategoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBlogCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetBlogCategoryQuery, GetBlogCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlogCategoryQuery, GetBlogCategoryQueryVariables>(GetBlogCategoryDocument, options);
      }
export function useGetBlogCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlogCategoryQuery, GetBlogCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlogCategoryQuery, GetBlogCategoryQueryVariables>(GetBlogCategoryDocument, options);
        }
export type GetBlogCategoryQueryHookResult = ReturnType<typeof useGetBlogCategoryQuery>;
export type GetBlogCategoryLazyQueryHookResult = ReturnType<typeof useGetBlogCategoryLazyQuery>;
export type GetBlogCategoryQueryResult = Apollo.QueryResult<GetBlogCategoryQuery, GetBlogCategoryQueryVariables>;
export const UpdateOneBlogCategoryDocument = gql`
    mutation UpdateOneBlogCategory($data: BlogCategoryUpdateInput!, $where: BlogCategoryWhereUniqueInput!) {
  updateOneBlogCategory(data: $data, where: $where) {
    id
    author
    descriptions {
      id
      title
      description
      language
    }
    createdAt
    updatedAt
  }
}
    `;
export type UpdateOneBlogCategoryMutationFn = Apollo.MutationFunction<UpdateOneBlogCategoryMutation, UpdateOneBlogCategoryMutationVariables>;

/**
 * __useUpdateOneBlogCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateOneBlogCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneBlogCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneBlogCategoryMutation, { data, loading, error }] = useUpdateOneBlogCategoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneBlogCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneBlogCategoryMutation, UpdateOneBlogCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneBlogCategoryMutation, UpdateOneBlogCategoryMutationVariables>(UpdateOneBlogCategoryDocument, options);
      }
export type UpdateOneBlogCategoryMutationHookResult = ReturnType<typeof useUpdateOneBlogCategoryMutation>;
export type UpdateOneBlogCategoryMutationResult = Apollo.MutationResult<UpdateOneBlogCategoryMutation>;
export type UpdateOneBlogCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateOneBlogCategoryMutation, UpdateOneBlogCategoryMutationVariables>;
export const CategoriesDocument = gql`
    query Categories($where: CategoryWhereInput, $orderBy: [CategoryOrderByWithRelationInput!], $take: Int, $skip: Int) {
  categories(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
    id
    createdAt
    descriptions {
      id
      title
      description
      slug
      headline
      buttonText
      buttonUrl
      language
    }
    _count {
      subCategories
    }
    photos
    discountAmount
    status
    parentCategoryId
    parentCategory {
      descriptions {
        title
        language
      }
    }
    subCategories {
      id
      descriptions {
        id
        title
        language
      }
    }
  }
}
    `;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const CategoryDocument = gql`
    query Category($where: CategoryWhereUniqueInput!) {
  category(where: $where) {
    id
    createdAt
    descriptions {
      id
      title
      description
      slug
      headline
      buttonText
      buttonUrl
      language
    }
    seos {
      id
      title
      description
      keywords
      language
    }
    photos
    parentCategoryId
    discountAmount
    status
  }
}
    `;

/**
 * __useCategoryQuery__
 *
 * To run a query within a React component, call `useCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCategoryQuery(baseOptions: Apollo.QueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
      }
export function useCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
        }
export type CategoryQueryHookResult = ReturnType<typeof useCategoryQuery>;
export type CategoryLazyQueryHookResult = ReturnType<typeof useCategoryLazyQuery>;
export type CategoryQueryResult = Apollo.QueryResult<CategoryQuery, CategoryQueryVariables>;
export const CreateCategoryDocument = gql`
    mutation CreateCategory($data: CategoryCreateInput!) {
  createOneCategory(data: $data) {
    id
  }
}
    `;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, options);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation DeleteCategory($where: CategoryWhereUniqueInput!) {
  deleteOneCategory(where: $where) {
    id
  }
}
    `;
export type DeleteCategoryMutationFn = Apollo.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, options);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = Apollo.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const MakeCategoryDiscountDocument = gql`
    mutation MakeCategoryDiscount($where: CategoryWhereUniqueInput!, $discountAmount: Float!) {
  makeCategoryDiscount(where: $where, discountAmount: $discountAmount)
}
    `;
export type MakeCategoryDiscountMutationFn = Apollo.MutationFunction<MakeCategoryDiscountMutation, MakeCategoryDiscountMutationVariables>;

/**
 * __useMakeCategoryDiscountMutation__
 *
 * To run a mutation, you first call `useMakeCategoryDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeCategoryDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeCategoryDiscountMutation, { data, loading, error }] = useMakeCategoryDiscountMutation({
 *   variables: {
 *      where: // value for 'where'
 *      discountAmount: // value for 'discountAmount'
 *   },
 * });
 */
export function useMakeCategoryDiscountMutation(baseOptions?: Apollo.MutationHookOptions<MakeCategoryDiscountMutation, MakeCategoryDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeCategoryDiscountMutation, MakeCategoryDiscountMutationVariables>(MakeCategoryDiscountDocument, options);
      }
export type MakeCategoryDiscountMutationHookResult = ReturnType<typeof useMakeCategoryDiscountMutation>;
export type MakeCategoryDiscountMutationResult = Apollo.MutationResult<MakeCategoryDiscountMutation>;
export type MakeCategoryDiscountMutationOptions = Apollo.BaseMutationOptions<MakeCategoryDiscountMutation, MakeCategoryDiscountMutationVariables>;
export const OrderCategoryDocument = gql`
    mutation orderCategory($id: String!, $order: Int!) {
  updateOneCategory(where: {id: $id}, data: {order: {set: $order}}) {
    id
  }
}
    `;
export type OrderCategoryMutationFn = Apollo.MutationFunction<OrderCategoryMutation, OrderCategoryMutationVariables>;

/**
 * __useOrderCategoryMutation__
 *
 * To run a mutation, you first call `useOrderCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderCategoryMutation, { data, loading, error }] = useOrderCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useOrderCategoryMutation(baseOptions?: Apollo.MutationHookOptions<OrderCategoryMutation, OrderCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderCategoryMutation, OrderCategoryMutationVariables>(OrderCategoryDocument, options);
      }
export type OrderCategoryMutationHookResult = ReturnType<typeof useOrderCategoryMutation>;
export type OrderCategoryMutationResult = Apollo.MutationResult<OrderCategoryMutation>;
export type OrderCategoryMutationOptions = Apollo.BaseMutationOptions<OrderCategoryMutation, OrderCategoryMutationVariables>;
export const UpdateCategoryDocument = gql`
    mutation UpdateCategory($data: CategoryUpdateInput!, $where: CategoryWhereUniqueInput!) {
  updateOneCategory(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateCategoryMutationFn = Apollo.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, options);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = Apollo.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const DashboardStatisticsDocument = gql`
    query DashboardStatistics {
  dashboardStatistics {
    todayOrderCount
    weeklyOrderCount
    todayEarnings
    weeklyEarnings
    last4Products {
      id
      photos
      descriptions {
        id
        title
        description
        slug
        headline
        buttonText
        buttonUrl
        language
      }
    }
    pendingOrders {
      id
      identityNumber
      conversationId
      paymentFirstName
      paymentLastName
      shippingFirstName
      shippingLastName
      isCompleted
      orderStatus
      createdAt
      customer {
        id
        name
        surname
      }
      payment {
        id
        price
        paidPrice
        discount
        installment
      }
      _count {
        products
      }
    }
  }
}
    `;

/**
 * __useDashboardStatisticsQuery__
 *
 * To run a query within a React component, call `useDashboardStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<DashboardStatisticsQuery, DashboardStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardStatisticsQuery, DashboardStatisticsQueryVariables>(DashboardStatisticsDocument, options);
      }
export function useDashboardStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardStatisticsQuery, DashboardStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardStatisticsQuery, DashboardStatisticsQueryVariables>(DashboardStatisticsDocument, options);
        }
export type DashboardStatisticsQueryHookResult = ReturnType<typeof useDashboardStatisticsQuery>;
export type DashboardStatisticsLazyQueryHookResult = ReturnType<typeof useDashboardStatisticsLazyQuery>;
export type DashboardStatisticsQueryResult = Apollo.QueryResult<DashboardStatisticsQuery, DashboardStatisticsQueryVariables>;
export const CreateDescriptionDocument = gql`
    mutation CreateDescription($data: DescriptionCreateInput!) {
  createOneDescription(data: $data) {
    id
  }
}
    `;
export type CreateDescriptionMutationFn = Apollo.MutationFunction<CreateDescriptionMutation, CreateDescriptionMutationVariables>;

/**
 * __useCreateDescriptionMutation__
 *
 * To run a mutation, you first call `useCreateDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDescriptionMutation, { data, loading, error }] = useCreateDescriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateDescriptionMutation, CreateDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDescriptionMutation, CreateDescriptionMutationVariables>(CreateDescriptionDocument, options);
      }
export type CreateDescriptionMutationHookResult = ReturnType<typeof useCreateDescriptionMutation>;
export type CreateDescriptionMutationResult = Apollo.MutationResult<CreateDescriptionMutation>;
export type CreateDescriptionMutationOptions = Apollo.BaseMutationOptions<CreateDescriptionMutation, CreateDescriptionMutationVariables>;
export const DeleteDescriptionDocument = gql`
    mutation DeleteDescription($where: DescriptionWhereUniqueInput!) {
  deleteOneDescription(where: $where) {
    id
  }
}
    `;
export type DeleteDescriptionMutationFn = Apollo.MutationFunction<DeleteDescriptionMutation, DeleteDescriptionMutationVariables>;

/**
 * __useDeleteDescriptionMutation__
 *
 * To run a mutation, you first call `useDeleteDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDescriptionMutation, { data, loading, error }] = useDeleteDescriptionMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDescriptionMutation, DeleteDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDescriptionMutation, DeleteDescriptionMutationVariables>(DeleteDescriptionDocument, options);
      }
export type DeleteDescriptionMutationHookResult = ReturnType<typeof useDeleteDescriptionMutation>;
export type DeleteDescriptionMutationResult = Apollo.MutationResult<DeleteDescriptionMutation>;
export type DeleteDescriptionMutationOptions = Apollo.BaseMutationOptions<DeleteDescriptionMutation, DeleteDescriptionMutationVariables>;
export const DescriptionDocument = gql`
    query Description($where: DescriptionWhereUniqueInput!) {
  description(where: $where) {
    id
    title
    slug
    description
    headline
    buttonText
    buttonUrl
    language
  }
}
    `;

/**
 * __useDescriptionQuery__
 *
 * To run a query within a React component, call `useDescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDescriptionQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDescriptionQuery(baseOptions: Apollo.QueryHookOptions<DescriptionQuery, DescriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DescriptionQuery, DescriptionQueryVariables>(DescriptionDocument, options);
      }
export function useDescriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DescriptionQuery, DescriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DescriptionQuery, DescriptionQueryVariables>(DescriptionDocument, options);
        }
export type DescriptionQueryHookResult = ReturnType<typeof useDescriptionQuery>;
export type DescriptionLazyQueryHookResult = ReturnType<typeof useDescriptionLazyQuery>;
export type DescriptionQueryResult = Apollo.QueryResult<DescriptionQuery, DescriptionQueryVariables>;
export const UpdateDescriptionDocument = gql`
    mutation UpdateDescription($data: DescriptionUpdateInput!, $where: DescriptionWhereUniqueInput!) {
  updateOneDescription(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateDescriptionMutationFn = Apollo.MutationFunction<UpdateDescriptionMutation, UpdateDescriptionMutationVariables>;

/**
 * __useUpdateDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDescriptionMutation, { data, loading, error }] = useUpdateDescriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDescriptionMutation, UpdateDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDescriptionMutation, UpdateDescriptionMutationVariables>(UpdateDescriptionDocument, options);
      }
export type UpdateDescriptionMutationHookResult = ReturnType<typeof useUpdateDescriptionMutation>;
export type UpdateDescriptionMutationResult = Apollo.MutationResult<UpdateDescriptionMutation>;
export type UpdateDescriptionMutationOptions = Apollo.BaseMutationOptions<UpdateDescriptionMutation, UpdateDescriptionMutationVariables>;
export const CreatePageDocument = gql`
    mutation CreatePage($data: PageCreateInput!) {
  createOnePage(data: $data) {
    id
  }
}
    `;
export type CreatePageMutationFn = Apollo.MutationFunction<CreatePageMutation, CreatePageMutationVariables>;

/**
 * __useCreatePageMutation__
 *
 * To run a mutation, you first call `useCreatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPageMutation, { data, loading, error }] = useCreatePageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePageMutation(baseOptions?: Apollo.MutationHookOptions<CreatePageMutation, CreatePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePageMutation, CreatePageMutationVariables>(CreatePageDocument, options);
      }
export type CreatePageMutationHookResult = ReturnType<typeof useCreatePageMutation>;
export type CreatePageMutationResult = Apollo.MutationResult<CreatePageMutation>;
export type CreatePageMutationOptions = Apollo.BaseMutationOptions<CreatePageMutation, CreatePageMutationVariables>;
export const DeletePageDocument = gql`
    mutation DeletePage($where: PageWhereUniqueInput!) {
  deleteOnePage(where: $where) {
    id
  }
}
    `;
export type DeletePageMutationFn = Apollo.MutationFunction<DeletePageMutation, DeletePageMutationVariables>;

/**
 * __useDeletePageMutation__
 *
 * To run a mutation, you first call `useDeletePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePageMutation, { data, loading, error }] = useDeletePageMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeletePageMutation(baseOptions?: Apollo.MutationHookOptions<DeletePageMutation, DeletePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePageMutation, DeletePageMutationVariables>(DeletePageDocument, options);
      }
export type DeletePageMutationHookResult = ReturnType<typeof useDeletePageMutation>;
export type DeletePageMutationResult = Apollo.MutationResult<DeletePageMutation>;
export type DeletePageMutationOptions = Apollo.BaseMutationOptions<DeletePageMutation, DeletePageMutationVariables>;
export const OrderPageDocument = gql`
    mutation orderPage($id: String!, $order: Int!) {
  updateOnePage(where: {id: $id}, data: {order: {set: $order}}) {
    id
  }
}
    `;
export type OrderPageMutationFn = Apollo.MutationFunction<OrderPageMutation, OrderPageMutationVariables>;

/**
 * __useOrderPageMutation__
 *
 * To run a mutation, you first call `useOrderPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderPageMutation, { data, loading, error }] = useOrderPageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useOrderPageMutation(baseOptions?: Apollo.MutationHookOptions<OrderPageMutation, OrderPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderPageMutation, OrderPageMutationVariables>(OrderPageDocument, options);
      }
export type OrderPageMutationHookResult = ReturnType<typeof useOrderPageMutation>;
export type OrderPageMutationResult = Apollo.MutationResult<OrderPageMutation>;
export type OrderPageMutationOptions = Apollo.BaseMutationOptions<OrderPageMutation, OrderPageMutationVariables>;
export const PageDocument = gql`
    query Page($where: PageWhereUniqueInput!) {
  page(where: $where) {
    id
    status
    pageType
    showOnHeader
    showOnFooter
    descriptions {
      id
      title
      description
      slug
      headline
      buttonText
      buttonUrl
      language
    }
    seos {
      id
      title
      description
      keywords
      language
    }
  }
}
    `;

/**
 * __usePageQuery__
 *
 * To run a query within a React component, call `usePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePageQuery(baseOptions: Apollo.QueryHookOptions<PageQuery, PageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageQuery, PageQueryVariables>(PageDocument, options);
      }
export function usePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageQuery, PageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageQuery, PageQueryVariables>(PageDocument, options);
        }
export type PageQueryHookResult = ReturnType<typeof usePageQuery>;
export type PageLazyQueryHookResult = ReturnType<typeof usePageLazyQuery>;
export type PageQueryResult = Apollo.QueryResult<PageQuery, PageQueryVariables>;
export const PagesDocument = gql`
    query Pages($where: PageWhereInput, $orderBy: [PageOrderByWithRelationInput!], $take: Int, $skip: Int) {
  pages(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
    id
    status
    pageType
    showOnHeader
    showOnFooter
    descriptions {
      id
      title
      description
      slug
      headline
      buttonText
      buttonUrl
      language
    }
  }
}
    `;

/**
 * __usePagesQuery__
 *
 * To run a query within a React component, call `usePagesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function usePagesQuery(baseOptions?: Apollo.QueryHookOptions<PagesQuery, PagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PagesQuery, PagesQueryVariables>(PagesDocument, options);
      }
export function usePagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagesQuery, PagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PagesQuery, PagesQueryVariables>(PagesDocument, options);
        }
export type PagesQueryHookResult = ReturnType<typeof usePagesQuery>;
export type PagesLazyQueryHookResult = ReturnType<typeof usePagesLazyQuery>;
export type PagesQueryResult = Apollo.QueryResult<PagesQuery, PagesQueryVariables>;
export const UpdatePageDocument = gql`
    mutation UpdatePage($data: PageUpdateInput!, $where: PageWhereUniqueInput!) {
  updateOnePage(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdatePageMutationFn = Apollo.MutationFunction<UpdatePageMutation, UpdatePageMutationVariables>;

/**
 * __useUpdatePageMutation__
 *
 * To run a mutation, you first call `useUpdatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageMutation, { data, loading, error }] = useUpdatePageMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdatePageMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePageMutation, UpdatePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePageMutation, UpdatePageMutationVariables>(UpdatePageDocument, options);
      }
export type UpdatePageMutationHookResult = ReturnType<typeof useUpdatePageMutation>;
export type UpdatePageMutationResult = Apollo.MutationResult<UpdatePageMutation>;
export type UpdatePageMutationOptions = Apollo.BaseMutationOptions<UpdatePageMutation, UpdatePageMutationVariables>;
export const CalculatePriceDocument = gql`
    mutation CalculatePrice($data: ProductCreateCustomInput!) {
  calculatePrice(data: $data)
}
    `;
export type CalculatePriceMutationFn = Apollo.MutationFunction<CalculatePriceMutation, CalculatePriceMutationVariables>;

/**
 * __useCalculatePriceMutation__
 *
 * To run a mutation, you first call `useCalculatePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalculatePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calculatePriceMutation, { data, loading, error }] = useCalculatePriceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCalculatePriceMutation(baseOptions?: Apollo.MutationHookOptions<CalculatePriceMutation, CalculatePriceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CalculatePriceMutation, CalculatePriceMutationVariables>(CalculatePriceDocument, options);
      }
export type CalculatePriceMutationHookResult = ReturnType<typeof useCalculatePriceMutation>;
export type CalculatePriceMutationResult = Apollo.MutationResult<CalculatePriceMutation>;
export type CalculatePriceMutationOptions = Apollo.BaseMutationOptions<CalculatePriceMutation, CalculatePriceMutationVariables>;
export const CreateProductCustomDocument = gql`
    mutation CreateProductCustom($data: ProductCreateCustomInput!) {
  createProductCustom(data: $data) {
    id
  }
}
    `;
export type CreateProductCustomMutationFn = Apollo.MutationFunction<CreateProductCustomMutation, CreateProductCustomMutationVariables>;

/**
 * __useCreateProductCustomMutation__
 *
 * To run a mutation, you first call `useCreateProductCustomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductCustomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductCustomMutation, { data, loading, error }] = useCreateProductCustomMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProductCustomMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductCustomMutation, CreateProductCustomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductCustomMutation, CreateProductCustomMutationVariables>(CreateProductCustomDocument, options);
      }
export type CreateProductCustomMutationHookResult = ReturnType<typeof useCreateProductCustomMutation>;
export type CreateProductCustomMutationResult = Apollo.MutationResult<CreateProductCustomMutation>;
export type CreateProductCustomMutationOptions = Apollo.BaseMutationOptions<CreateProductCustomMutation, CreateProductCustomMutationVariables>;
export const DeleteProductDocument = gql`
    mutation DeleteProduct($where: ProductWhereUniqueInput!) {
  deleteOneProduct(where: $where) {
    id
  }
}
    `;
export type DeleteProductMutationFn = Apollo.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, options);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables>;
export const MakeProductDiscountDocument = gql`
    mutation MakeProductDiscount($productId: String!, $discountAmount: Int!, $discountType: DiscountType!) {
  makeProductDiscount(
    productId: $productId
    discountAmount: $discountAmount
    discountType: $discountType
  )
}
    `;
export type MakeProductDiscountMutationFn = Apollo.MutationFunction<MakeProductDiscountMutation, MakeProductDiscountMutationVariables>;

/**
 * __useMakeProductDiscountMutation__
 *
 * To run a mutation, you first call `useMakeProductDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeProductDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeProductDiscountMutation, { data, loading, error }] = useMakeProductDiscountMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      discountAmount: // value for 'discountAmount'
 *      discountType: // value for 'discountType'
 *   },
 * });
 */
export function useMakeProductDiscountMutation(baseOptions?: Apollo.MutationHookOptions<MakeProductDiscountMutation, MakeProductDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeProductDiscountMutation, MakeProductDiscountMutationVariables>(MakeProductDiscountDocument, options);
      }
export type MakeProductDiscountMutationHookResult = ReturnType<typeof useMakeProductDiscountMutation>;
export type MakeProductDiscountMutationResult = Apollo.MutationResult<MakeProductDiscountMutation>;
export type MakeProductDiscountMutationOptions = Apollo.BaseMutationOptions<MakeProductDiscountMutation, MakeProductDiscountMutationVariables>;
export const OrderProductDocument = gql`
    mutation orderProduct($id: String!, $order: Int!) {
  updateProduct(where: {id: $id}, data: {order: {set: $order}}) {
    id
  }
}
    `;
export type OrderProductMutationFn = Apollo.MutationFunction<OrderProductMutation, OrderProductMutationVariables>;

/**
 * __useOrderProductMutation__
 *
 * To run a mutation, you first call `useOrderProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderProductMutation, { data, loading, error }] = useOrderProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useOrderProductMutation(baseOptions?: Apollo.MutationHookOptions<OrderProductMutation, OrderProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderProductMutation, OrderProductMutationVariables>(OrderProductDocument, options);
      }
export type OrderProductMutationHookResult = ReturnType<typeof useOrderProductMutation>;
export type OrderProductMutationResult = Apollo.MutationResult<OrderProductMutation>;
export type OrderProductMutationOptions = Apollo.BaseMutationOptions<OrderProductMutation, OrderProductMutationVariables>;
export const ProductDocument = gql`
    query Product($creatablesWhere2: ProductCreatableWhereInput, $where: ProductWhereUniqueInput!) {
  product(where: $where) {
    id
    createdAt
    photos
    price
    oldPrice
    discountType
    discountAmount
    status
    sku
    barcode
    order
    categoryId
    styleId
    seos {
      id
      title
      description
      keywords
      language
    }
    descriptions {
      id
      title
      description
      slug
      headline
      buttonText
      buttonUrl
      language
    }
    creatables(where: $creatablesWhere2) {
      creatable {
        descriptions {
          id
          title
          description
          slug
          headline
          buttonText
          buttonUrl
          language
        }
        value
        type
        operation
      }
      id
      creatableId
    }
    weight
    productRingSizeStandarts {
      ringSizeStandart {
        name
        id
        values {
          circumferenceMM
          diameterMM
          value
          id
        }
      }
    }
    stoneGroups {
      id
      photos
      stones {
        id
        shape {
          id
          shape
        }
        quantity
        quality
        carat {
          id
          carat
        }
        caratInfo {
          id
          color
          IF
          VSS1
          VSS2
          VS1
          VS2
          SI1
          SI2
          SI3
          I1
          I2
          I3
        }
      }
    }
  }
}
    `;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      creatablesWhere2: // value for 'creatablesWhere2'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProductQuery(baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
      }
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const ProductsDocument = gql`
    query Products($where: ProductWhereInput, $orderBy: [ProductOrderByWithRelationInput!], $take: Int, $skip: Int) {
  products(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
    count
    items {
      id
      createdAt
      photos
      discountType
      discountAmount
      sku
      seos {
        id
        title
        description
        keywords
        language
      }
      barcode
      status
      order
      descriptions {
        id
        title
        description
        slug
        headline
        buttonText
        buttonUrl
        language
      }
      category {
        id
        descriptions {
          id
          title
          description
          slug
          headline
          buttonText
          buttonUrl
          language
        }
      }
      price
    }
  }
}
    `;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const RefreshProductPricesDocument = gql`
    mutation RefreshProductPrices($productIds: [String!]!) {
  refreshProductPrices(productIds: $productIds)
}
    `;
export type RefreshProductPricesMutationFn = Apollo.MutationFunction<RefreshProductPricesMutation, RefreshProductPricesMutationVariables>;

/**
 * __useRefreshProductPricesMutation__
 *
 * To run a mutation, you first call `useRefreshProductPricesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshProductPricesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshProductPricesMutation, { data, loading, error }] = useRefreshProductPricesMutation({
 *   variables: {
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useRefreshProductPricesMutation(baseOptions?: Apollo.MutationHookOptions<RefreshProductPricesMutation, RefreshProductPricesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshProductPricesMutation, RefreshProductPricesMutationVariables>(RefreshProductPricesDocument, options);
      }
export type RefreshProductPricesMutationHookResult = ReturnType<typeof useRefreshProductPricesMutation>;
export type RefreshProductPricesMutationResult = Apollo.MutationResult<RefreshProductPricesMutation>;
export type RefreshProductPricesMutationOptions = Apollo.BaseMutationOptions<RefreshProductPricesMutation, RefreshProductPricesMutationVariables>;
export const SwitchProductStatusDocument = gql`
    mutation SwitchProductStatus($data: SwitchProductStatusInput!) {
  switchProductStatus(data: $data)
}
    `;
export type SwitchProductStatusMutationFn = Apollo.MutationFunction<SwitchProductStatusMutation, SwitchProductStatusMutationVariables>;

/**
 * __useSwitchProductStatusMutation__
 *
 * To run a mutation, you first call `useSwitchProductStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchProductStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchProductStatusMutation, { data, loading, error }] = useSwitchProductStatusMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSwitchProductStatusMutation(baseOptions?: Apollo.MutationHookOptions<SwitchProductStatusMutation, SwitchProductStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SwitchProductStatusMutation, SwitchProductStatusMutationVariables>(SwitchProductStatusDocument, options);
      }
export type SwitchProductStatusMutationHookResult = ReturnType<typeof useSwitchProductStatusMutation>;
export type SwitchProductStatusMutationResult = Apollo.MutationResult<SwitchProductStatusMutation>;
export type SwitchProductStatusMutationOptions = Apollo.BaseMutationOptions<SwitchProductStatusMutation, SwitchProductStatusMutationVariables>;
export const UpdateOneProductDocument = gql`
    mutation UpdateOneProduct($data: ProductUpdateInput!, $where: ProductWhereUniqueInput!) {
  updateOneProduct(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateOneProductMutationFn = Apollo.MutationFunction<UpdateOneProductMutation, UpdateOneProductMutationVariables>;

/**
 * __useUpdateOneProductMutation__
 *
 * To run a mutation, you first call `useUpdateOneProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneProductMutation, { data, loading, error }] = useUpdateOneProductMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneProductMutation, UpdateOneProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneProductMutation, UpdateOneProductMutationVariables>(UpdateOneProductDocument, options);
      }
export type UpdateOneProductMutationHookResult = ReturnType<typeof useUpdateOneProductMutation>;
export type UpdateOneProductMutationResult = Apollo.MutationResult<UpdateOneProductMutation>;
export type UpdateOneProductMutationOptions = Apollo.BaseMutationOptions<UpdateOneProductMutation, UpdateOneProductMutationVariables>;
export const UpdateProductDocument = gql`
    mutation UpdateProduct($data: ProductUpdateCustomInput!, $where: ProductWhereUniqueInput!) {
  updateProduct(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const GetFilterCategoriesDocument = gql`
    query GetFilterCategories($where: CategoryWhereInput, $orderBy: [CategoryOrderByWithRelationInput!], $take: Int, $skip: Int) {
  categories(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
    id
    createdAt
    descriptions {
      id
      title
      description
      slug
      headline
      buttonText
      buttonUrl
      language
    }
    _count {
      subCategories
    }
    photos
    discountAmount
    status
    parentCategoryId
    parentCategory {
      descriptions {
        title
        language
      }
    }
    subCategories {
      id
      descriptions {
        id
        title
        language
      }
    }
  }
}
    `;

/**
 * __useGetFilterCategoriesQuery__
 *
 * To run a query within a React component, call `useGetFilterCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterCategoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetFilterCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetFilterCategoriesQuery, GetFilterCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilterCategoriesQuery, GetFilterCategoriesQueryVariables>(GetFilterCategoriesDocument, options);
      }
export function useGetFilterCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilterCategoriesQuery, GetFilterCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilterCategoriesQuery, GetFilterCategoriesQueryVariables>(GetFilterCategoriesDocument, options);
        }
export type GetFilterCategoriesQueryHookResult = ReturnType<typeof useGetFilterCategoriesQuery>;
export type GetFilterCategoriesLazyQueryHookResult = ReturnType<typeof useGetFilterCategoriesLazyQuery>;
export type GetFilterCategoriesQueryResult = Apollo.QueryResult<GetFilterCategoriesQuery, GetFilterCategoriesQueryVariables>;
export const GetFilterCreatablesDocument = gql`
    query GetFilterCreatables($where: CreatableWhereInput, $orderBy: [CreatableOrderByWithRelationInput!], $cursor: CreatableWhereUniqueInput, $take: Int, $skip: Int) {
  creatables(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    id
    type
    value
    optionalValue
    operation
    descriptions {
      id
      title
      description
      slug
      headline
      buttonText
      buttonUrl
      language
    }
  }
}
    `;

/**
 * __useGetFilterCreatablesQuery__
 *
 * To run a query within a React component, call `useGetFilterCreatablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterCreatablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterCreatablesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetFilterCreatablesQuery(baseOptions?: Apollo.QueryHookOptions<GetFilterCreatablesQuery, GetFilterCreatablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilterCreatablesQuery, GetFilterCreatablesQueryVariables>(GetFilterCreatablesDocument, options);
      }
export function useGetFilterCreatablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilterCreatablesQuery, GetFilterCreatablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilterCreatablesQuery, GetFilterCreatablesQueryVariables>(GetFilterCreatablesDocument, options);
        }
export type GetFilterCreatablesQueryHookResult = ReturnType<typeof useGetFilterCreatablesQuery>;
export type GetFilterCreatablesLazyQueryHookResult = ReturnType<typeof useGetFilterCreatablesLazyQuery>;
export type GetFilterCreatablesQueryResult = Apollo.QueryResult<GetFilterCreatablesQuery, GetFilterCreatablesQueryVariables>;
export const GetFilterRingSizeStandartsDocument = gql`
    query getFilterRingSizeStandarts($where: RingSizeStandartWhereInput, $orderBy: [RingSizeStandartOrderByWithRelationInput!], $take: Int, $skip: Int) {
  ringSizeStandarts(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
    id
    name
    _count {
      values
      productRingSizeStandarts
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetFilterRingSizeStandartsQuery__
 *
 * To run a query within a React component, call `useGetFilterRingSizeStandartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterRingSizeStandartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterRingSizeStandartsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetFilterRingSizeStandartsQuery(baseOptions?: Apollo.QueryHookOptions<GetFilterRingSizeStandartsQuery, GetFilterRingSizeStandartsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilterRingSizeStandartsQuery, GetFilterRingSizeStandartsQueryVariables>(GetFilterRingSizeStandartsDocument, options);
      }
export function useGetFilterRingSizeStandartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilterRingSizeStandartsQuery, GetFilterRingSizeStandartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilterRingSizeStandartsQuery, GetFilterRingSizeStandartsQueryVariables>(GetFilterRingSizeStandartsDocument, options);
        }
export type GetFilterRingSizeStandartsQueryHookResult = ReturnType<typeof useGetFilterRingSizeStandartsQuery>;
export type GetFilterRingSizeStandartsLazyQueryHookResult = ReturnType<typeof useGetFilterRingSizeStandartsLazyQuery>;
export type GetFilterRingSizeStandartsQueryResult = Apollo.QueryResult<GetFilterRingSizeStandartsQuery, GetFilterRingSizeStandartsQueryVariables>;
export const GetFilterStylesDocument = gql`
    query GetFilterStyles($where: StyleWhereInput, $orderBy: [StyleOrderByWithRelationInput!], $take: Int, $skip: Int) {
  styles(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
    id
    photos
    status
    order
    createdAt
    updatedAt
    _count {
      products
    }
    descriptions {
      id
      title
      description
      slug
      headline
      buttonText
      buttonUrl
      language
    }
  }
}
    `;

/**
 * __useGetFilterStylesQuery__
 *
 * To run a query within a React component, call `useGetFilterStylesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterStylesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterStylesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetFilterStylesQuery(baseOptions?: Apollo.QueryHookOptions<GetFilterStylesQuery, GetFilterStylesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilterStylesQuery, GetFilterStylesQueryVariables>(GetFilterStylesDocument, options);
      }
export function useGetFilterStylesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilterStylesQuery, GetFilterStylesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilterStylesQuery, GetFilterStylesQueryVariables>(GetFilterStylesDocument, options);
        }
export type GetFilterStylesQueryHookResult = ReturnType<typeof useGetFilterStylesQuery>;
export type GetFilterStylesLazyQueryHookResult = ReturnType<typeof useGetFilterStylesLazyQuery>;
export type GetFilterStylesQueryResult = Apollo.QueryResult<GetFilterStylesQuery, GetFilterStylesQueryVariables>;
export const SeosDocument = gql`
    query Seos($where: SeoWhereInput, $orderBy: [SeoOrderByWithRelationInput!], $take: Int, $skip: Int) {
  seos(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
    id
    title
    description
    keywords
    language
    createdAt
    updatedAt
    categoryId
    productId
    styleId
    pageId
    blogCategoryId
    blogId
  }
}
    `;

/**
 * __useSeosQuery__
 *
 * To run a query within a React component, call `useSeosQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeosQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useSeosQuery(baseOptions?: Apollo.QueryHookOptions<SeosQuery, SeosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeosQuery, SeosQueryVariables>(SeosDocument, options);
      }
export function useSeosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeosQuery, SeosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeosQuery, SeosQueryVariables>(SeosDocument, options);
        }
export type SeosQueryHookResult = ReturnType<typeof useSeosQuery>;
export type SeosLazyQueryHookResult = ReturnType<typeof useSeosLazyQuery>;
export type SeosQueryResult = Apollo.QueryResult<SeosQuery, SeosQueryVariables>;
export const UpdateOneSeoDocument = gql`
    mutation UpdateOneSeo($data: SeoUpdateInput!, $where: SeoWhereUniqueInput!) {
  updateOneSeo(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateOneSeoMutationFn = Apollo.MutationFunction<UpdateOneSeoMutation, UpdateOneSeoMutationVariables>;

/**
 * __useUpdateOneSeoMutation__
 *
 * To run a mutation, you first call `useUpdateOneSeoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneSeoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneSeoMutation, { data, loading, error }] = useUpdateOneSeoMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneSeoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneSeoMutation, UpdateOneSeoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneSeoMutation, UpdateOneSeoMutationVariables>(UpdateOneSeoDocument, options);
      }
export type UpdateOneSeoMutationHookResult = ReturnType<typeof useUpdateOneSeoMutation>;
export type UpdateOneSeoMutationResult = Apollo.MutationResult<UpdateOneSeoMutation>;
export type UpdateOneSeoMutationOptions = Apollo.BaseMutationOptions<UpdateOneSeoMutation, UpdateOneSeoMutationVariables>;
export const CheckStaffDocument = gql`
    query CheckStaff {
  checkStaff
}
    `;

/**
 * __useCheckStaffQuery__
 *
 * To run a query within a React component, call `useCheckStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckStaffQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckStaffQuery(baseOptions?: Apollo.QueryHookOptions<CheckStaffQuery, CheckStaffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckStaffQuery, CheckStaffQueryVariables>(CheckStaffDocument, options);
      }
export function useCheckStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckStaffQuery, CheckStaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckStaffQuery, CheckStaffQueryVariables>(CheckStaffDocument, options);
        }
export type CheckStaffQueryHookResult = ReturnType<typeof useCheckStaffQuery>;
export type CheckStaffLazyQueryHookResult = ReturnType<typeof useCheckStaffLazyQuery>;
export type CheckStaffQueryResult = Apollo.QueryResult<CheckStaffQuery, CheckStaffQueryVariables>;
export const CreateStaffDocument = gql`
    mutation CreateStaff($data: StaffCreateInput!) {
  createOneStaff(data: $data) {
    id
  }
}
    `;
export type CreateStaffMutationFn = Apollo.MutationFunction<CreateStaffMutation, CreateStaffMutationVariables>;

/**
 * __useCreateStaffMutation__
 *
 * To run a mutation, you first call `useCreateStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStaffMutation, { data, loading, error }] = useCreateStaffMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStaffMutation(baseOptions?: Apollo.MutationHookOptions<CreateStaffMutation, CreateStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStaffMutation, CreateStaffMutationVariables>(CreateStaffDocument, options);
      }
export type CreateStaffMutationHookResult = ReturnType<typeof useCreateStaffMutation>;
export type CreateStaffMutationResult = Apollo.MutationResult<CreateStaffMutation>;
export type CreateStaffMutationOptions = Apollo.BaseMutationOptions<CreateStaffMutation, CreateStaffMutationVariables>;
export const FindManyStaffDocument = gql`
    query FindManyStaff {
  findManyStaff(orderBy: {createdAt: desc}) {
    id
    name
    surname
    email
    phoneNumber
    role
    createdAt
  }
}
    `;

/**
 * __useFindManyStaffQuery__
 *
 * To run a query within a React component, call `useFindManyStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyStaffQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindManyStaffQuery(baseOptions?: Apollo.QueryHookOptions<FindManyStaffQuery, FindManyStaffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManyStaffQuery, FindManyStaffQueryVariables>(FindManyStaffDocument, options);
      }
export function useFindManyStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyStaffQuery, FindManyStaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManyStaffQuery, FindManyStaffQueryVariables>(FindManyStaffDocument, options);
        }
export type FindManyStaffQueryHookResult = ReturnType<typeof useFindManyStaffQuery>;
export type FindManyStaffLazyQueryHookResult = ReturnType<typeof useFindManyStaffLazyQuery>;
export type FindManyStaffQueryResult = Apollo.QueryResult<FindManyStaffQuery, FindManyStaffQueryVariables>;
export const FindUniqueStaffDocument = gql`
    query FindUniqueStaff($where: StaffWhereUniqueInput!) {
  findUniqueStaff(where: $where) {
    createdAt
    email
    id
    name
    phoneNumber
    role
    surname
  }
}
    `;

/**
 * __useFindUniqueStaffQuery__
 *
 * To run a query within a React component, call `useFindUniqueStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUniqueStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUniqueStaffQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindUniqueStaffQuery(baseOptions: Apollo.QueryHookOptions<FindUniqueStaffQuery, FindUniqueStaffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUniqueStaffQuery, FindUniqueStaffQueryVariables>(FindUniqueStaffDocument, options);
      }
export function useFindUniqueStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUniqueStaffQuery, FindUniqueStaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUniqueStaffQuery, FindUniqueStaffQueryVariables>(FindUniqueStaffDocument, options);
        }
export type FindUniqueStaffQueryHookResult = ReturnType<typeof useFindUniqueStaffQuery>;
export type FindUniqueStaffLazyQueryHookResult = ReturnType<typeof useFindUniqueStaffLazyQuery>;
export type FindUniqueStaffQueryResult = Apollo.QueryResult<FindUniqueStaffQuery, FindUniqueStaffQueryVariables>;
export const StaffLoginDocument = gql`
    mutation StaffLogin($data: LoginInput!) {
  staffLogin(data: $data) {
    id
    name
    email
    token
    role
  }
}
    `;
export type StaffLoginMutationFn = Apollo.MutationFunction<StaffLoginMutation, StaffLoginMutationVariables>;

/**
 * __useStaffLoginMutation__
 *
 * To run a mutation, you first call `useStaffLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffLoginMutation, { data, loading, error }] = useStaffLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStaffLoginMutation(baseOptions?: Apollo.MutationHookOptions<StaffLoginMutation, StaffLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StaffLoginMutation, StaffLoginMutationVariables>(StaffLoginDocument, options);
      }
export type StaffLoginMutationHookResult = ReturnType<typeof useStaffLoginMutation>;
export type StaffLoginMutationResult = Apollo.MutationResult<StaffLoginMutation>;
export type StaffLoginMutationOptions = Apollo.BaseMutationOptions<StaffLoginMutation, StaffLoginMutationVariables>;
export const UpdateStaffDocument = gql`
    mutation UpdateStaff($data: StaffUpdateInput!, $where: StaffWhereUniqueInput!) {
  updateOneStaff(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateStaffMutationFn = Apollo.MutationFunction<UpdateStaffMutation, UpdateStaffMutationVariables>;

/**
 * __useUpdateStaffMutation__
 *
 * To run a mutation, you first call `useUpdateStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStaffMutation, { data, loading, error }] = useUpdateStaffMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateStaffMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStaffMutation, UpdateStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStaffMutation, UpdateStaffMutationVariables>(UpdateStaffDocument, options);
      }
export type UpdateStaffMutationHookResult = ReturnType<typeof useUpdateStaffMutation>;
export type UpdateStaffMutationResult = Apollo.MutationResult<UpdateStaffMutation>;
export type UpdateStaffMutationOptions = Apollo.BaseMutationOptions<UpdateStaffMutation, UpdateStaffMutationVariables>;
export const CreateOneStaffLogDocument = gql`
    mutation CreateOneStaffLog($data: StaffLogCreateInput!) {
  createOneStaffLog(data: $data) {
    id
  }
}
    `;
export type CreateOneStaffLogMutationFn = Apollo.MutationFunction<CreateOneStaffLogMutation, CreateOneStaffLogMutationVariables>;

/**
 * __useCreateOneStaffLogMutation__
 *
 * To run a mutation, you first call `useCreateOneStaffLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneStaffLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneStaffLogMutation, { data, loading, error }] = useCreateOneStaffLogMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneStaffLogMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneStaffLogMutation, CreateOneStaffLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneStaffLogMutation, CreateOneStaffLogMutationVariables>(CreateOneStaffLogDocument, options);
      }
export type CreateOneStaffLogMutationHookResult = ReturnType<typeof useCreateOneStaffLogMutation>;
export type CreateOneStaffLogMutationResult = Apollo.MutationResult<CreateOneStaffLogMutation>;
export type CreateOneStaffLogMutationOptions = Apollo.BaseMutationOptions<CreateOneStaffLogMutation, CreateOneStaffLogMutationVariables>;
export const UploadFileDocument = gql`
    mutation uploadFile($file: Upload!) {
  uploadFile(file: $file)
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;