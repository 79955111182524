import { FC } from "react";
import PageLayout from "../../components/ui/PageLayout";
import Form from "./Form";
import { useGetBlogQuery } from "../../generated/graphql";
import { Flex, Box, IconButton } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FcList } from "react-icons/fc";

type Props = {
  id: string;
};

const Update: FC<Props> = ({ id }) => {
  const { data } = useGetBlogQuery({
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: "no-cache",
  });
  const navigate = useNavigate();

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          Update Blog
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            icon={<FcList />}
            aria-label="All Items"
            onClick={() => navigate("/blog")}
          />
        </Flex>
      </Flex>
      <Form actionType="UPDATE" blog={data?.getBlog} />
    </PageLayout>
  );
};

export default Update;
